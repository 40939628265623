import { AbstractGantt } from "../AbstractGantt";
import moment from "moment";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import React from "react";

export class HumanResourceInfoRenderer extends React.Component<{ entityId: number, gantt: any, showSchedules: boolean, showQualification: boolean }> {

    static defaultProps = {
        showSchedules: true,
        showQualification: true
    }

    protected getHrName(hr: any) {
        return (hr?.identifier?.concat(" ") || "") + (hr?.firstName?.concat(" ") || "") + (hr?.lastName?.concat(" ") || "");
    }

    render() {
        const { entities } = this.props.gantt.props;
        const hr = AbstractGantt.findOne("HumanResource", "id", this.props.entityId, entities);
        if (!hr) {
            return null;
        }
        let qualificationTypes: any[] = [];
        AbstractGantt.find("Qualification", "humanResource.id", hr.id, entities).forEach(qualification => 
            qualificationTypes.push(qualification.qualificationType)
        );
        return <>
            {this.getHrName(hr) + 
                (this.props.showSchedules && hr.schedules ? ("\n" + Object.keys(hr.schedules).map(key => hr.schedules[key]).reduce((previousValue, currentData) => previousValue + (previousValue != "" ? ", " : "") + moment(currentData.startTime).format(Utils.timeFormat) + "-" + moment(currentData.endTime).format(Utils.timeFormat), "")) : "") +
                (this.props.showQualification ? ("\n" + qualificationTypes.map(q => q.name).sort().reduce((previousValue, currentValue) => previousValue + (previousValue != "" ? ", " : "") + currentValue, "")) : "") + ""}
        </>
    }
}
