import { FieldDescriptor, Utils } from "@crispico/foundation-react";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/DateFieldRenderer";
import StringFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/StringFieldRenderer";
import React from "react";
import { Icon } from "semantic-ui-react";

export class TaskInfoRenderer extends React.Component<{ entity: any }> {

    render() {
        const ed = entityDescriptors["Task"];
        const options = FieldDescriptor.castAdditionalFieldRendererProps(StringFieldRenderer, { asLabel: true, showTooltip: true });
        const optionsForDate = FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { asLabel: true, showTooltip: true, format: Utils.dateTimeFormatShorter, formatForToday: Utils.timeFormat });
        const { entity } = this.props;

        return entity ? <div className="flex-container-row flex-center gap3">
            {ed.getField("taskType").renderField(entity, options)}
            {ed.getField("name").renderField(entity, options)}
            {ed.getField("quantity").renderField(entity, options)}
            {ed.getField("offloadStatus").renderField(entity, options)}
            {ed.getField("startAddress").renderField(entity, options)}
            {ed.getField("startTime").renderField(entity, optionsForDate)}
            <div><Icon name="arrow right" /></div>
            {ed.getField("endAddress").renderField(entity, options)}
            {ed.getField("endTime").renderField(entity, optionsForDate)}
            {ed.getField("requiredEquipmentResourceQualificationType").renderField(entity, options)}
            {ed.getField("equipmentResourceFillPercentage").renderField(entity, options)}
            {ed.getField("comment").renderField(entity, options)}
        </div> : null;
    }
}
