import { ConnectedComponentInSimpleComponent, ENT_SAVE, EntityEditorPage, PrivateRoute, PrivateRouteProps, Utils, apolloClientHolder, FieldDescriptor } from "@crispico/foundation-react";
import { RRCProps, Reducers, ReduxReusableComponents, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import React from "react";
import { Segment, Button, Header, Icon, Divider, Menu, Label, StrictLabelProps, Modal } from "semantic-ui-react";
import moment from "moment";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import gql from "graphql-tag";
import { ID, entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { getAssociationStringFieldFromIds, getIdsFromAssociationStringField } from "components/AssociationStringFieldEditor";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import _ from "lodash";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { HUMAN_RESOURCE_GET_QUALIFICATIONS_FOR_DATE, HUMAN_RESOURCE_SCHEDULE_LOAD_QUERY } from "./queries";
import { humanResourceScheduleService_findByFilter_humanResourceScheduleService_findByFilter_results, humanResourceScheduleService_findByFilter_humanResourceScheduleService_findByFilter_results_humanResource_qualifications } from "apollo-gen/humanResourceScheduleService_findByFilter";
import { DatePickerReactCalendar } from "@crispico/foundation-react/components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/DateFieldRenderer";
import { AssociationFieldRenderer } from "@crispico/foundation-react/entity_crud/AssociationFieldRenderer";

type HumanResourceSchedule = humanResourceScheduleService_findByFilter_humanResourceScheduleService_findByFilter_results;
type Qualification = humanResourceScheduleService_findByFilter_humanResourceScheduleService_findByFilter_results_humanResource_qualifications;

class HumanResourceScheduleTeamsPageState extends State {
    date = moment(Utils.now().getTime()).startOf("day").valueOf();
    teams = [{ schedules: [] }] as { teamLeader?: HumanResourceSchedule, schedules: HumanResourceSchedule[] }[];
    expandedTeams = {} as { [key: string]: boolean };
    scheduleMenuPosition = false as [number, number] | false;
    scheduleMenuSelection = undefined as { schedule: HumanResourceSchedule, teamLeader?: HumanResourceSchedule } | undefined;
    scheduleEditorOpen = undefined as HumanResourceSchedule | undefined;
    qualificationsForHumanResources = {} as { [key: number]: Qualification[] };
}

class HumanResourceScheduleTeamsPageReducers<S extends HumanResourceScheduleTeamsPageState = HumanResourceScheduleTeamsPageState> extends Reducers<S> {
    createTeamsFromHumanResourceSchedules(schedules: HumanResourceSchedule[]) {
        this.s.teams = [];
        const humanResourceToScheduleMap: { [id: number]: HumanResourceSchedule } = {};
        schedules.forEach(schedule => humanResourceToScheduleMap[schedule.humanResource!.id] = schedule);
        schedules.forEach(schedule => {
            if (!schedule.teamName || !schedule.teamName.trim().length) {
                return;
            }
            const ids = getIdsFromAssociationStringField(schedule.team);
            this.s.teams.push({
                teamLeader: schedule,
                schedules: ids.length > 0 ? ids.map(id => {
                    const schedule = humanResourceToScheduleMap[id];
                    delete humanResourceToScheduleMap[id];
                    return schedule;
                }) : [],
            });
            delete humanResourceToScheduleMap[schedule.humanResource!.id];
        });
        // last team contains schedules that are not assigned to a team and has no team leader
        this.s.teams.push({ schedules: Object.keys(humanResourceToScheduleMap).map(key => humanResourceToScheduleMap[Number(key)]) });
        const expandedTeams: { [key: string]: boolean } = {};
        this.s.teams.forEach(team => {
            const key = team.teamLeader ? String(team.teamLeader.id) : "";
            expandedTeams[key] = this.s.expandedTeams[key] !== undefined ? this.s.expandedTeams[key] : true;
        });
        this.s.expandedTeams = expandedTeams;
    }

    closeScheduleMenu() {
        this.s.scheduleMenuPosition = false;
        this.s.scheduleMenuSelection = undefined;
    }

    expand(teamLeader?: HumanResourceSchedule) {
        const key = teamLeader ? String(teamLeader.id) : "";
        this.s.expandedTeams[key] = !this.s.expandedTeams[key];
    }
}

type Props = RRCProps<HumanResourceScheduleTeamsPageState, HumanResourceScheduleTeamsPageReducers>;

class HumanResourceScheduleTeamsPage extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    async componentDidMount() {
        await this.refresh();
    }

    async componentDidUpdate(prevProps: Props) {
        if (prevProps && prevProps.s.date !== this.props.s.date) {
            await this.refresh();
        }
    }

    private async refresh() {
        const filter = FindByFilterParams.create()
            .filter(Filter.createComposed(FilterOperators.forComposedFilter.and, [
                Filter.create("startTime", FilterOperators.forDate.greaterThanOrEqualTo, moment(this.props.s.date).startOf("day").toISOString()),
                Filter.create("startTime", FilterOperators.forDate.lessThanOrEqualTo, moment(this.props.s.date).endOf("day").toISOString())
            ]))
            .sorts([{ field: "teamName", direction: "ASC" }]);
        const schedules = (await apolloClientHolder.apolloClient.query({ query: HUMAN_RESOURCE_SCHEDULE_LOAD_QUERY, variables: filter, context: { showSpinner: false } })).data["humanResourceScheduleService_findByFilter"].results;
        this.props.r.createTeamsFromHumanResourceSchedules(schedules);
        
        let humanResourcesIds : number[] = [];
        schedules.map((schedule : HumanResourceSchedule) => humanResourcesIds.push(schedule.humanResource?.id));

        let qualifications = (await apolloClientHolder.apolloClient.query({ query: HUMAN_RESOURCE_GET_QUALIFICATIONS_FOR_DATE, variables: {
            date: Utils.now(),
            humanResourcesIds: humanResourcesIds
        }, context: { showSpinner: false } })).data["humanResourceService_qualificationsForDate"];

        let qualificationsForHumanResources : { [key: number]: Qualification[] } = {};

        schedules.map((schedule : HumanResourceSchedule, index : number) => qualificationsForHumanResources[schedule.humanResource?.id] = qualifications[index])
        this.props.r.setInReduxState({ qualificationsForHumanResources: qualificationsForHumanResources });
    }

    async updateSchedule(id: number, fieldsAndValues: {}) {
        const saveFieldsOperationName = `humanResourceScheduleService_save`;
        const mutation = gql(`
            mutation m($params: SaveParams_LongInput) {
                ${saveFieldsOperationName}(params: $params) {
                    ${ID}
                }
            }
        `);
        await apolloClientHolder.apolloClient.mutate({ mutation, variables: { params: { id, fieldsAndValues } }, context: { showSpinner: false } });
    }

    private async onDragEnd(result: DropResult) {
        if (!result.destination) {
            // dropped outside any of the teams
            return;
        }
        if (result.source.droppableId === result.destination.droppableId) {
            // reordering in the same team
            const team = this.props.s.teams[Number(result.source.droppableId)];
            if (!team.teamLeader) {
                return;
            }
            const ids = team.schedules.map(schedule => schedule.humanResource!.id);
            const [removed] = ids.splice(result.source.index, 1);
            ids.splice(result.destination?.index, 0, removed);
            await this.updateSchedule(team.teamLeader.id, { team: getAssociationStringFieldFromIds(ids) });
        } else {
            // team switch
            const source = this.props.s.teams[Number(result.source.droppableId)];
            const sourceIds = source.schedules.map(schedule => schedule.humanResource!.id);
            const destination = this.props.s.teams[Number(result.destination.droppableId)];
            const destinationIds = destination.schedules.map(schedule => schedule.humanResource!.id);
            const [removed] = sourceIds.splice(result.source.index, 1);
            destinationIds.splice(result.destination.index, 0, removed);
            const scheduleId = source.schedules.find(schedule => schedule.humanResource!.id === removed)!.id;

            await Promise.all([
                source.teamLeader && this.updateSchedule(source.teamLeader.id, { team: getAssociationStringFieldFromIds(sourceIds) }),
                destination.teamLeader && this.updateSchedule(destination.teamLeader.id, { team: getAssociationStringFieldFromIds(destinationIds) }),
                destination.teamLeader && this.updateSchedule(scheduleId, { startTime: destination.teamLeader?.startTime, endTime: destination.teamLeader?.endTime })
            ]);
        }
        this.refresh();
    }

    private async switchTeamLeader(schedule: HumanResourceSchedule, teamLeader: HumanResourceSchedule) {
        const ids = getIdsFromAssociationStringField(teamLeader.team);
        ids[ids.indexOf(schedule.humanResource!.id)] = teamLeader.humanResource!.id;
        const { startTime, endTime, teamName } = teamLeader;
        await Promise.all([
            this.updateSchedule(teamLeader.id, { team: "", teamName: "" }),
            this.updateSchedule(schedule.id, { startTime, endTime, team: getAssociationStringFieldFromIds(ids), teamName })
        ]);
    }

    private renderHeader() {
        return <Segment className="flex-container-row less-margin-top-bottom less-padding">
            <DatePickerReactCalendar value={moment(this.props.s.date)} onChange={(date) => date !== null && this.props.r.setInReduxState({ date: date?.valueOf() })} />
            <Button className="small-margin-left" positive compact icon="refresh" onClick={() => this.refresh()} />
        </Segment>;
    }

    private renderHumanResourceDetails(humanResource: HumanResourceSchedule["humanResource"]) {
        const ed = entityDescriptors["HumanResource"];
        return <>{ed.getField("identifier").renderField(humanResource)}&nbsp;-&nbsp;{ed.getField("lastName").renderField(humanResource)},&nbsp;{ed.getField("firstName").renderField(humanResource)}</>;
    }

    private renderScheduleDetails(schedule: HumanResourceSchedule) {
        const ed = entityDescriptors["HumanResourceSchedule"];
        return <>
            {ed.getField("startTime").renderField(schedule, FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { asLabel: true }))}
            {ed.getField("endTime").renderField(schedule, FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { asLabel: true }))}
            &nbsp;
            {this.props.s.qualificationsForHumanResources[schedule.humanResource?.id] && this.props.s.qualificationsForHumanResources[schedule.humanResource?.id].map((qualification : Qualification) => {
                return entityDescriptors["Qualification"].getField("qualificationType").renderField(qualification, FieldDescriptor.castAdditionalFieldRendererProps(AssociationFieldRenderer, { asLabel: true }));
            })}
        </>;
    }

    private renderSchedule(team: number, position?: number) {
        const schedule = position !== undefined ? this.props.s.teams[team].schedules[position] : this.props.s.teams[team].teamLeader;
        if (!schedule) {
            return null;
        }
        const teamLeader = this.props.s.teams[team].teamLeader;
        const content = <div className="flex-center w100">
            <Icon name={schedule === teamLeader ? "group" : "male"} />
            <div className="flex-center">{this.renderHumanResourceDetails(schedule.humanResource)}&nbsp;{this.renderScheduleDetails(schedule)}</div>
            <Button style={{ marginLeft: "auto" }} floated="right" compact icon="bars" size="mini" onClick={e => this.props.r.setInReduxState({ scheduleMenuPosition: [e.clientX, e.clientY], scheduleMenuSelection: { schedule, teamLeader } })} />
        </div>;
        const labelProps: StrictLabelProps = {
            basic: true,
            size: "large",
            className: "flex-container-row less-margin-top-bottom",
            color: schedule.finished ? "red" : "blue",
        }
        if (position === undefined) {
            return <Label {...labelProps}>{content}</Label>;
        } else {
            return <Draggable key={position} draggableId={[team, position].join("_")} index={position}>
                {(provided, snapshot) => {
                    if (snapshot.isDragging) {
                        labelProps.color = "green";
                    }
                    return <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <Label {...labelProps}>{content}</Label>
                    </div>;
                }}
            </Draggable>;
        }
    }

    renderTeam(team: number) {
        const teamLeader = this.props.s.teams[team].teamLeader;
        const expanded = this.props.s.expandedTeams[teamLeader ? String(teamLeader.id) : ""];
        return <Droppable key={team} droppableId={String(team)}>
            {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef} className="w100">
                    <Segment className="less-margin-top-bottom">
                        <div className="flex-grow flex-center">
                            <Button size="mini" compact icon={<Icon name={expanded ? "minus" : "plus"} />} onClick={() => this.props.r.expand(teamLeader)} />
                            <Header className="AuditFieldGraph_header">{teamLeader ? _msg("HumanResourceScheduleTeamsPage.teamTitle", teamLeader.teamName) : _msg("HumanResourceScheduleTeamsPage.noTeam")}</Header>
                        </div>
                        {expanded ? <>
                            {teamLeader || this.props.s.teams[team].schedules.length ? <div style={{ height: "0.5em" }}></div> : null}
                            {this.renderSchedule(team)}
                            {teamLeader ? <Divider className="less-margin-top-bottom" /> : null}
                            {this.props.s.teams[team].schedules.map((schedule, index) => this.renderSchedule(team, index))}
                        </> : null}
                    </Segment>
                    {provided.placeholder}
                </div>
            )}
        </Droppable>;
    }

    renderHumanResourceScheduleEditor() {
        const ed = entityDescriptors["HumanResourceSchedule"];
        const schedule = this.props.s.scheduleEditorOpen;
        const info = ed.getHelpers()[1];
        info.wrappedComponentClass = class extends EntityEditorPage {
            protected onMatchChanged(match: any) {
                this.props.dispatchers.load(schedule?.id);
            }
            protected getMainRoutePath() {
                return "";
            }
        }
        return <ModalExt open={schedule !== undefined} closeOnDimmerClick={true} closeIcon={true}
            onClose={async () => {
                if (!schedule) {
                    return;
                }
                this.props.r.setInReduxState({ scheduleEditorOpen: undefined });
                const loadOperationName = `${_.lowerFirst(ed.name)}Service_findById`;
                const query = gql(`query q($id: Long!) { 
                    ${loadOperationName}(id: $id) {
                        ${ID} startTime endTime team
                    }
                }`);
                const updatedSchedule: HumanResourceSchedule = (await apolloClientHolder.apolloClient.query({ query, variables: { id: schedule.id } })).data[loadOperationName];
                const ids = getIdsFromAssociationStringField(updatedSchedule.team);
                if (ids.length > 0 && schedule.startTime !== updatedSchedule.startTime || schedule.endTime !== updatedSchedule.endTime) {
                    await Promise.all(ids.map(async (id) => {
                        await this.updateSchedule(id, { startTime: updatedSchedule.startTime, endTime: updatedSchedule.endTime });
                    }));
                }
                this.refresh();
            }}>
            <Modal.Content className="AppModal_content">
                <ConnectedComponentInSimpleComponent info={info} />
            </Modal.Content>
        </ModalExt>;
    }

    renderMain() {
        return <DragDropContext onDragEnd={this.onDragEnd}>
            {this.props.s.teams.map((team, index) => this.renderTeam(index))}
        </DragDropContext>;
    }

    renderScheduleMenu() {
        const position = this.props.s.scheduleMenuPosition;
        const selection = this.props.s.scheduleMenuSelection;
        return <ModalExt className='EntityTableSimple_menuModal' closeIcon={false} open={position}
            onClick={() => this.props.r.closeScheduleMenu()} onClose={() => this.props.r.closeScheduleMenu()}>
            {position && selection ? <Menu vertical className="wh100">
                <Menu.Item icon="edit" content={_msg("HumanResourceScheduleTeamsPage.editSchedule")}
                    onClick={() => { this.props.r.setInReduxState({ scheduleEditorOpen: selection.schedule }) }}
                />
                {selection.teamLeader?.finished && selection.schedule !== selection.teamLeader ? <Menu.Item icon="male" content={_msg("HumanResourceScheduleTeamsPage.setAsTeamLeader")}
                    onClick={async () => {
                        this.props.r.closeScheduleMenu();
                        await this.switchTeamLeader(selection.schedule, selection.teamLeader!);
                        this.refresh();
                    }} /> : null}
                <Menu.Item icon={selection.schedule.finished ? "checkmark" : "clock"}
                    content={_msg("HumanResourceScheduleTeamsPage." + (selection.schedule.finished ? "activate" : "finish") + "Schedule")}
                    onClick={async () => {
                        this.props.r.closeScheduleMenu();
                        await this.updateSchedule(selection.schedule.id, { finished: !selection.schedule.finished });
                        this.refresh();
                    }}
                />
                {selection.schedule === selection.teamLeader && !selection.schedule.team?.length ? <Menu.Item icon="remove"
                    content={_msg("HumanResourceScheduleTeamsPage.dissolveTeam")}
                    onClick={async () => {
                        this.props.r.closeScheduleMenu();
                        await this.updateSchedule(selection.schedule.id, { teamName: "" });
                        this.refresh();
                    }} /> : null}
            </Menu> : null}
        </ModalExt>;
    }

    render() {
        return <div className="flex-container flex-grow less-padding">
            {this.renderHeader()}
            {this.renderMain()}
            {this.renderScheduleMenu()}
            {this.renderHumanResourceScheduleEditor()}
        </div>;
    }
}

export const HumanResourceScheduleTeamsPageRRC = ReduxReusableComponents.connectRRC(HumanResourceScheduleTeamsPageState, HumanResourceScheduleTeamsPageReducers, HumanResourceScheduleTeamsPage);

export const humanResourceScheduleTeamsPageUrl = "/HumanResourceScheduleTeams";
export const humanResourceScheduleTeamsPageRoute = (computeRoute: (props: PrivateRouteProps) => JSX.Element) =>
    <PrivateRoute key="humanResourceScheduleTeams"
        path={humanResourceScheduleTeamsPageUrl}
        render={(props) => <HumanResourceScheduleTeamsPageRRC {...props} id="humanResourceScheduleTeams" />}
        permission={Utils.pipeJoin([ENT_SAVE, "HumanResourceSchedule"])}
        computeRoute={computeRoute} />
