import React from "react";
import { GanttUtils } from "./GanttUtils";
import { AbstractGantt } from "./AbstractGantt";
import { FlightInfoRenderer } from "./typeRenderers/FlightInfoRenderer";
import { TaskInfoRenderer } from "./typeRenderers/TaskInfoRenderer";
import { HumanResourceInfoRenderer } from "./typeRenderers/HumanResourceInfoRenderer";
import { MissionInfoRenderer } from "./typeRenderers/MissionInfoRenderer";
import { Divider } from "semantic-ui-react";
import _ from "lodash";

export class GanttInfoRenderer extends React.Component<{ entityUid: string, gantt: AbstractGantt }> {

    render() {
        if (!this.props.entityUid || !this.props.entityUid.includes(":")) {
            return null;
        }
        const { entityName } = GanttUtils.fromEntityUid(this.props.entityUid);
        const { entities } = this.props.gantt.props;
        const entity = AbstractGantt.findByUid(this.props.entityUid, entities);
        if (!entity) {
            return null;
        }
        switch (entityName) {
            case "Flight":
                return <FlightInfoRenderer entity={entity} />;
            case "Task":
                const mission = AbstractGantt.findOne("ObjectActionGroup", "object.id", entity.id, entities)?.mission;
                return <div className="flex-container gap5">
                    <TaskInfoRenderer entity={entity} />
                    {/* <Divider fitted /> */}
                    <FlightInfoRenderer entity={entity.taskGroup} />
                    {/* <Divider fitted /> */}
                    <MissionInfoRenderer entity={mission} />
                </div>;
            case "HumanResource":
                return <HumanResourceInfoRenderer entityId={entity.id} gantt={this.props.gantt} />;
            case "Mission2":
                const mission1 = AbstractGantt.findOne("Mission2", "id", entity.id, entities);
                const oags = AbstractGantt.find("ObjectActionGroup", "mission.id", mission1.id, entities);
                let flights = oags.map(oag => AbstractGantt.findOne("Flight", "id", oag.object.taskGroup.id, entities));
                if (flights.length === 0) {
                    const tasks = AbstractGantt.find("Task", "mission.id", mission1.id, entities);
                    flights = tasks.map(task => AbstractGantt.findOne("Flight", "id", task.taskGroup.id, entities));
                }
                return <div className="flex-container gap5">
                    <MissionInfoRenderer entity={mission1} />
                    {flights.map(flight => {
                        return <div className="flex-container gap5">
                            <Divider fitted />
                            <FlightInfoRenderer entity={flight} />
                            {oags.filter(oag => oag.object.taskGroup.id == flight.id).map(oag => <TaskInfoRenderer entity={oag.object} />)}
                        </div>
                    })}
                </div>
            default:
                return <></>;
        }

    }
}