import React from "react";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { CheckboxProps, Radio } from "semantic-ui-react";
import _ from "lodash";

export const DOMESTIC = 'D';
export const INTERNATIONAL = 'I';

export class FlightSchengenFieldEditor extends React.Component<FieldEditorProps> {

    handleChange = (_: any, data: CheckboxProps) => {
        if (data.value === DOMESTIC) {
            this.props.formikProps.setFieldValue(this.props.fieldDescriptor.getFieldName(), true);
        } else if (data.value === INTERNATIONAL) {
            this.props.formikProps.setFieldValue(this.props.fieldDescriptor.getFieldName(), false);
        }
        // rerender for update the radios
        this.forceUpdate();
    }

    render() {
        const schengen = this.props.fieldDescriptor.getFieldValue(this.props.formikProps.values);
        return <div className="flex-container-row">
            <Radio className="very-small-padding" data-tooltip={_msg("flight.schengen.domestic")}
                label={DOMESTIC} value={DOMESTIC}
                checked={schengen === true}
                onChange={this.handleChange}
            />
            <Radio className="very-small-padding" data-tooltip={_msg("flight.schengen.international")}
                label={INTERNATIONAL} value={INTERNATIONAL}
                checked={schengen === false}
                onChange={this.handleChange}
            />
        </div>;
    }
}
