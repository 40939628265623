import React from "react";
import { SemanticCOLORS, SemanticICONS } from "semantic-ui-react";
import { FlightRenderer } from "./typeRenderers/FlightRenderer";
import { MissionRenderer } from "./typeRenderers/MissionRenderer";
import { TaskRenderer } from "./typeRenderers/TaskRenderer";
import { AbstractTypeRendererProps } from "./typeRenderers/AbstractTypeRenderer";
import { HumanResourceRenderer } from "./typeRenderers/HumanResourceRenderer";
import { ArrivalFlightRenderer } from "./typeRenderers/ArrivalFlightRenderer";
import { DepartureFlightRenderer } from "./typeRenderers/DepartureFlightRenderer";
import { EquipmentResourceRenderer } from "./typeRenderers/EquipmentResourceRenderer";

export type IconItem = {
    name: SemanticICONS,
    color?: SemanticCOLORS,
    tooltip?: string;
};

export interface CommonItemProps {
    leftLabel?: string;
    middleLabel?: string;
    rightLabel?: string;
    icons?: IconItem[];
}

export enum GANTT_TYPES { MISSION, FLIGHT, ARV_FLIGHT, DEP_FLIGHT, TASK, HUMAN_RESOURCE, EQUIPMENT_RESOURCE }
export class GanttTypeRenderer extends React.Component<AbstractTypeRendererProps>  {

    render() {
        const { item } = this.props;
        switch (item.type) {
            case GANTT_TYPES.TASK:
                return <TaskRenderer {...this.props} />;
            case GANTT_TYPES.FLIGHT:
                return <FlightRenderer {...this.props} />;
            case GANTT_TYPES.MISSION:
                return <MissionRenderer {...this.props} />;
            case GANTT_TYPES.HUMAN_RESOURCE:
                return <HumanResourceRenderer {...this.props} />;
            case GANTT_TYPES.ARV_FLIGHT:
                return <ArrivalFlightRenderer {...this.props} />;
            case GANTT_TYPES.DEP_FLIGHT:
                return <DepartureFlightRenderer {...this.props} />;
            case GANTT_TYPES.EQUIPMENT_RESOURCE:
                return <EquipmentResourceRenderer {...this.props} />;
            default:
                return <span></span>;
        }
    }
}
