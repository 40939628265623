import gql from "graphql-tag";

export const GET_MISSION = gql`
    query mission2Service_findById($id: Long) {
        mission2Service_findById(id: $id) {
            id objectActionGroups {
                    id objectId object { 
                        id name eventMonitoringRulesJson taskGroup {
                            id date name
                        } 
                } 
                mission { 
                    id events {
                        id descriptorType creationdate oagId 
                    } 
                } 
            } 
            eventMonitoringActualNumberOfEvents eventMonitoringExpectedNumberOfEvents
        }
    }
`;

export const GET_TASK = gql`
    query taskService_findById($id: Long) {
        taskService_findById(id: $id) {
            id objectActionGroups {
                id objectId object { 
                    id name eventMonitoringRulesJson taskGroup {
                        id date name
                    } 
            } 
            mission { 
                id events {
                    id descriptorType creationdate oagId 
                } 
            } 
            } 
            eventMonitoringActualNumberOfEvents eventMonitoringExpectedNumberOfEvents 
        }
    }
`;