import { TestUtils, Utils, apolloClientHolder } from "@crispico/foundation-react";
import { Reducers, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Icon, Menu, Modal, Popup, Segment, SemanticICONS } from "semantic-ui-react";
import ReactDOM from "react-dom";
import { ModalExt, ModalExtOpen } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { Group, IGanttAction, IGanttActionParamForRun, IGanttOnContextMenuShowParam, Item, RowLayer, getNearestRowNumber, getTimeAtPixel } from "@crispico/react-timeline-10000";
import { GanttTypeRenderer, GANTT_TYPES } from "./ganttTypeRenderers";
import { GanttUtils } from "./GanttUtils";
import gql from "graphql-tag";
import { NavLink } from "react-router-dom";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import XopsTimeline from "./XopsTimeline";
import { GanttInfoRenderer } from "./GanttInfoRenderer";
import { Table, Column } from "fixed-data-table-2";
import Timeline, { PARENT_ELEMENT, TABLE_OFFSET } from "@crispico/react-timeline-10000/types/timeline";
import { isFlexMode } from "app";
import { Droppable } from "@crispico/foundation-react/components/DragAndDrop/DragAndDrop";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";

export const ITEM_HEIGHT = 20;
export const HEADER_HEIGHT = 20;

export enum GROUP_LABEL_PROPRETY {
    ARV_FLIGHT = "arvFlightName",
    DEP_FLIGHT = "depFlightName",
    HUMAN_RESOURCE = "name",
    EQUIPMENT_RESOURCE = "identifier",

}
export interface GanttGroup extends Group {
    [key: string]: any
}
export interface GanttData {
    layers: RowLayer[],
    groups: GanttGroup[],
    items: GanttItem[];
}
export interface GanttItem extends Item {
    entityId: any;
    type: GANTT_TYPES;
}
export class AbstractGanttState extends State {
    data: GanttData = { layers: [], groups: [], items: [] };
    isModalOpen: ModalExtOpen = false;
    modalContent: any;
    tableWidth: number = 200;
    start: number = moment().startOf("day").valueOf();
    end: number = moment().endOf("day").valueOf();
    popupEntityUid: string | undefined;
    isDraggingMode: boolean = false
}
export class AbstractGanttReducers<S extends AbstractGanttState = AbstractGanttState> extends Reducers<S> {

    updatePopupEntityUid(value: string | undefined) {
        this.s.popupEntityUid = value;
    }
}

export type AbstractGanttProps = {
    /**
     * Keeps the data in denormalization mode.
     * Shouldn't be enriched with other fields!
     */
    entities?: { [entityName: string]: { [id: number]: any } },
    hideTopBar?: boolean,
    /**
     * Used to store the HR/ER that shouldn't be displayed 
     * (GanttResources will hide the lines, GanttTasks will calculate if task has mission based on this)
     * This should be replaced with a more general mechanism in the future!
     */
    hideResources?: { [key: string]: number[] },
    /**
     * If set, the topBar component will be displayed on this portal container.
     */
    portalContainerForTopBar?: any
};

export abstract class AbstractGantt<
    P extends AbstractGanttProps = AbstractGanttProps,
    R extends AbstractGanttReducers = AbstractGanttReducers,
    S extends AbstractGanttState = AbstractGanttState,
    LS extends {} = {}> extends React.Component<RRCProps<S, R> & P, LS> {

    protected entitiesJustChanged = false;

    protected timelineRef = React.createRef<XopsTimeline>();

    protected timelineId = "";

    constructor(props: RRCProps<S, R> & P) {
        super(props);

        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.itemRenderer = this.itemRenderer.bind(this);
        this.onContextMenuShow = this.onContextMenuShow.bind(this);
        this.onItemClick = this.onItemClick.bind(this);
        this.renderTable = this.renderTable.bind(this);
        this.onSelectionChange = this.onSelectionChange.bind(this);
        this.canDrop = this.canDrop.bind(this);
        this.onDrop = this.onDrop.bind(this);

        // we need to invoke here, because shouldComponentUpdate() is not invoked for the first render
        this.shouldComponentUpdateInternal(this.props, true);
        this.timelineId = this.constructor.name + "_XopsTimeline";
    }

    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: RRCProps<S, R> & P) {
        this.componentDidUpdateInternal(prevProps);
    }

    shouldComponentUpdate(nextProps: RRCProps<S, R> & P) {
        return this.shouldComponentUpdateInternal(nextProps, false);
    }

    getTimeAtPixel(pixel_location: number) {
        return getTimeAtPixel(pixel_location - PARENT_ELEMENT(this.timelineId).getBoundingClientRect().left,
            this.timelineRef.current?.getStartDate()!,
            this.timelineRef.current?.getEndDate()!,
            this.timelineRef.current?.getTimelineWidth(undefined as unknown as null)!,
            1);
    }

    protected shouldComponentUpdateInternal(nextProps: RRCProps<S, R> & P, firstRender: boolean) {
        if (!firstRender) {
            if (_.isEqual(nextProps.entities, this.props.entities)) {
                this.entitiesJustChanged = false;
                return true;
            } // else => entities was changed 
        }

        this.entitiesChangedHandler(nextProps.entities);

        /** 
         * code inspired from Tree.tsx
         * need to postpone a re-render until gantt data is calculated
         */
        this.entitiesJustChanged = !firstRender; // for firstRender, this is true

        return false;
    }

    showModal(extOpen: ModalExtOpen, content?: any) {
        this.props.r.setInReduxState({ isModalOpen: extOpen, modalContent: content });
    }

    closeModal() {
        this.props.r.setInReduxState({ isModalOpen: false, modalContent: undefined });
    }

    static findByUid(entityUid: string, entities: any): any {
        const { id, entityName } = GanttUtils.fromEntityUid(entityUid);
        return this.findOne(entityName, "id", id, entities);
    }

    static findOne(entityName: string, field: string, value: any, entities: any): any {
        return this.find(entityName, field, value, entities)?.[0];
    }

    static find(entityName: string, field: string, value: any, entities: any): any[] {
        if (entities) {
            const map = entities[entityName];
            if (map) {
                if (field === "id") { // shortcut to get it faster
                    return map[value] ? [GanttUtils.getProxy(entityName, map[value], entities)] : [];
                }
                return Object.keys(map).filter(key => {
                    const entity = map[Number(key)];
                    return Utils.navigate(entity, field, false, ".") === value;
                }).map(key => GanttUtils.getProxy(entityName, map[Number(key)], entities));
            } else {
                // for debug purposes
                // console.log("entities doesn't contain entityName = " + entityName);
            }
        }
        return [];
    }

    protected entitiesChangedHandler(newEntities: any) {
        // nop
    }

    protected async componentDidUpdateInternal(prevProps?: RRCProps<S, R> & P) {
        if (TestUtils.storybookMode) {
            return;
        }
        if (prevProps && (!_.isEqual(this.props.hideResources, prevProps.hideResources))) {
            this.entitiesChangedHandler(this.props.entities);
        }

    }

    protected renderTopBar(): React.ReactNode {
        return <></>;
    }

    public itemRenderer(itemProps: any) {
        return <GanttTypeRenderer {...itemProps} gantt={this} />;
    }

    protected onContextMenuShow(contextMenuShowParam: IGanttOnContextMenuShowParam) {
        if (contextMenuShowParam.actionParam.selection?.length != 1) {
            // show contextMenu only for one selected item
            return [];
        }
        // the key of the item = uid
        const entityUid: { entityName: string, id: number } = GanttUtils.fromEntityUid(contextMenuShowParam.actionParam.selection[0] as string);
        const { entities } = this.props;
        const actions: IGanttAction[] = [
            {
                renderInMenu: (param) => renderEditItem(param, "list alternate outline", _msg("general.edit"), entityUid.entityName, entityUid.id)
            },
            {
                icon: "info",
                label: "Show Tooltip",
                run: (param) => this.showModal(true, <GanttInfoRenderer entityUid={contextMenuShowParam.actionParam.selection[0] as string} gantt={this} />)
            },
            {
                isVisible: () => entityUid.entityName === "Task",
                renderInMenu: (param) => {
                    const task = AbstractGantt.findOne("Task", "id", entityUid.id, entities);
                    // CC: need to understand why task is undefined here when Flight is updated
                    return renderEditItem(param, "edit", _msg("flight.editFlight"), "Flight", task?.taskGroup.id);
                }
            },
            {
                icon: "remove",
                label: _msg("entityCrud.table.delete"),
                isVisible: () => entityUid.entityName === "Task",
                run: async () => {
                    await apolloClientHolder.apolloClient.mutate({
                        mutation: gql(`mutation q($id: Long) { taskService_remove(id: $id) }`),
                        variables: { id: entityUid.id }
                    })
                }
            }
        ];
        return actions;
    }

    onItemClick(e: any, itemKey: any) {
        if (this.props.s.popupEntityUid !== itemKey) {
            this.props.r.updatePopupEntityUid(itemKey);
        }
    }

    protected onSelectionChange(selectedItems: (number | string)[]) {
    }

    protected getTableWidth() {
        return this.props.s.tableWidth - TABLE_OFFSET;
    }

    protected getTableRowsCount() {
        return 0;
    }

    protected renderTableColumns() {
        return [<Column key={0} columnKey={0} width={this.getTableWidth()} flexGrow={1} />];
    }

    protected renderTable(): JSX.Element | null {
        return this.getTableWidth() ? <Table rowHeight={ITEM_HEIGHT} rowsCount={this.getTableRowsCount()}
            width={this.getTableWidth()} headerHeight={HEADER_HEIGHT} >
            {this.renderTableColumns()}
        </Table> : null;
    }


    protected getAcceptedType(): string | undefined {
        return undefined;
    }

    protected canDrop(source: any, destination: any) {
        return true;
    }

    protected onDrop(source: any, destination: any) {
        // use this function only when is not flex mode
        const ganttBodyElement = document.querySelector(`.rct9k-id-${this.timelineId} .rct9k-grid`);
        if (!ganttBodyElement) {
            return;
        }
        const event = window.event as any;
        const { top, bottom, left, right } = ganttBodyElement.getBoundingClientRect();
        if (!(event.clientX < right && event.clientX > left && event.clientY > top && event.clientY < bottom)) {
            // nop if not in gantt body
            return;
        }
        let startTime = source.start;
        let endTime = source.end;
        let rowNumber = source.row;
        if (event) {
            startTime = this.getTimeAtPixel(event.clientX - JSON.parse(event.dataTransfer.getData("text/plain")).offsetX);
            endTime = startTime + source.end - source.start;
            rowNumber = getNearestRowNumber(event.clientX, event.clientY, document.querySelector(this.timelineId) || document);
        }
        // TODO: send the new start/end time for entity to editor
        AppMetaTempGlobals.history.push(entityDescriptors[this.getAcceptedType()!].getEntityEditorUrl(GanttUtils.fromEntityUid(source.key).id));
    }

    render() {
        if (this.entitiesJustChanged) {
            /** 
             * At the moment of writing, if shouldComponentUpdate() returns false => render() is not called. However the docs state that
             * in the future, the result of shouldComponentUpdate() may be taken as a hint; not as a guarantee, and hence render() may still
             * be called. And hence the code will arrive here. Note: that this is also true w/ the functional components / hooks version (i.e. React.memo()).
             * 
             * If this will happen, the only solution I see right now is to "memoize" (or simpler: just cache) the render function; i.e. if the code gets here => 
             * return the previous result.
             * 
             * copied comment from Tree.tsx
             */
            throw new Error("An illegal state was detected. This was anticipated, so please follow the instructions and update the code.")
        }

        const { props } = this;
        return <div className="flex-container flex-grow less-padding">
            {this.props.portalContainerForTopBar
                ? ReactDOM.createPortal(<Segment className="less-padding no-margin flex-container-row flex-center gap5">
                    {this.renderTopBar()}
                </Segment>, this.props.portalContainerForTopBar)
                : !this.props.hideTopBar ? <Segment className="less-padding no-margin flex-container-row flex-center gap5">
                    {this.renderTopBar()}
                </Segment> : null}
            <Droppable className="wh100" item={{ type: this.constructor.name }} accept={this.getAcceptedType()} canDrop={isFlexMode() ? undefined : this.canDrop} drop={isFlexMode() ? undefined : this.onDrop}>
                <XopsTimeline ref={this.timelineRef} componentId={this.timelineId}
                    // @ts-ignore
                    timelineMode={Timeline.TIMELINE_MODES.SELECT}
                    table={this.renderTable()}
                    onSelectionChange={this.onSelectionChange}
                    useMoment={false}
                    onContextMenuShow={this.onContextMenuShow}
                    startDate={moment(this.props.s.start)}
                    endDate={moment(this.props.s.end)}
                    groups={props.s.data.groups}
                    items={props.s.data.items}
                    showCursorTime={false}
                    rowLayers={props.s.data.layers}
                    itemHeight={ITEM_HEIGHT}
                    itemRenderer={(itemProps: any) => this.itemRenderer(itemProps)}
                    onTableResize={(tableWidth: number) => { this.props.r.setInReduxState({ tableWidth }) }}
                    onItemClick={this.onItemClick}
                    onItemContextClick={() => this.props.r.updatePopupEntityUid(undefined)}
                    forceRedrawFunc={() => true}
                />
            </Droppable>
            <Popup flowing onClose={() => this.props.r.updatePopupEntityUid(undefined)} closeOnEscape
                position="bottom right" positionFixed
                context={props.s.popupEntityUid ? document.querySelectorAll('[data-item-index="' + props.s.popupEntityUid + '"]')?.[0] as HTMLElement : undefined}
                open={props.s.popupEntityUid != undefined} >
                {props.s.popupEntityUid ? <GanttInfoRenderer gantt={this} entityUid={props.s.popupEntityUid} /> : null}
            </Popup>
            <ModalExt size="tiny" style={{ whiteSpace: "pre-line" }} transparentDimmer={true}
                open={this.props.s.modalContent !== undefined && this.props.s.isModalOpen}
                onClose={this.closeModal}>
                <Modal.Content className="less-padding">
                    {this.props.s.modalContent}
                </Modal.Content>
            </ModalExt>
        </div>
    }
}

function renderEditItem(param: IGanttActionParamForRun, icon: SemanticICONS, content: string | JSX.Element, entityName: string, id: number) {

    return <Menu.Item as={isFlexMode() ? Menu.Item : NavLink} to={entityDescriptors[entityName].getEntityEditorUrl(id)}
        onClick={() => {
            // don't open the crud editors if is flexMode only send the event to as
            if (isFlexMode()) {
                const header = document.getElementById("root")!;
                const event = new CustomEvent("openEditor", { detail: GanttUtils.toEntityUid(entityName, id) });
                header.dispatchEvent(event);
            }
            param.closeContextMenu();
        }}>
        <Icon name={icon} />
        {content}
    </Menu.Item>
}