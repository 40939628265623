import { Utils } from "@crispico/foundation-react";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Dropdown, DropdownItemProps } from "semantic-ui-react";
import { AbstractGantt, AbstractGanttProps, AbstractGanttReducers, AbstractGanttState, GROUP_LABEL_PROPRETY, GanttData, GanttGroup, ITEM_HEIGHT } from "./AbstractGantt";
import { GANTT_TYPES, GanttTypeRenderer } from "./ganttTypeRenderers";
import { getLayerStyle } from "./ganttLayerStyles";
import { Column, Table } from "fixed-data-table-2";
import { missionEntityDescriptor } from "AppEntityDescriptors";
import { GanttUtils } from "./GanttUtils";

enum Grouping {
    HUMAN_RESOURCE = "HUMAN_RESOURCE",
    EQUIPMENT_RESOURCE = "EQUIPMENT_RESOURCE",
    NONE = "NONE"
}

enum Sorting {
    MISSIONS_TOTAL_TIME = "MISSIONS_TOTAL_TIME",
    SHEDULE_START_AND_END_TIME = "SHEDULE_START_AND_END_TIME"
}

class GanttResourcesState extends AbstractGanttState {
    selectedGroupBy: Grouping | undefined;
    selectedSortBy: Sorting | undefined;
}
class GanttResourcesReducers<S extends GanttResourcesState = GanttResourcesState> extends AbstractGanttReducers<S> { }

type GanttResourcesProps = AbstractGanttProps & { hideGroupBy?: boolean, hideSortBy?: boolean };
type Props = RRCProps<GanttResourcesState, GanttResourcesReducers> & GanttResourcesProps;

export class GanttResources extends AbstractGantt<GanttResourcesProps, GanttResourcesReducers, GanttResourcesState> {

    constructor(props: Props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
    }

    public getResourcesWithTheLeastTimeUsed(endNumber: number): number[] {
        return this.props.s.data.groups.slice(endNumber).map(g => g.entityId);
    }

    protected groupDataByResource(newEntities: any, missions: any[], schedules: any[], per: "HumanResource" | "EquipmentResource" | undefined): { [key: number]: any } {
        const map: { [key: number]: any } = {};
        schedules.forEach(schedule => {
            // for ER schedules probably we'll need to look somewhere else
            const s = AbstractGantt.findOne("HumanResourceSchedule", "id", schedule.id, newEntities);
            if (!s) {
                return;
            }
            let id = 0;
            if (per) {
                id = per === "HumanResource" ? s.humanResource?.id : undefined;
            }
            if (id === undefined) {
                if (per === "HumanResource") {
                    console.log("KO: Schedule w/o resource!", s.id);
                }
                return;
            }
            if (!map[id]) {
                const resource = per ? AbstractGantt.findOne(per, "id", id, newEntities) : {};
                map[id] = { ...resource };
                map[id].timeUsed = 0;
                map[id].missions = {};
                map[id].schedules = {};
            }
            if (!map[id].schedules[s.id]) {
                map[id].schedules[s.id] = { id: s.id, startTime: s.startTime, endTime: s.endTime };
            }
        });
        missions.forEach(mission => {
            const m = AbstractGantt.findOne("Mission2", "id", mission.id, newEntities);
            if (!m) {
                return;
            }
            let id = 0;
            if (per) {
                id = (per === "HumanResource" ? m.humanResource?.id : m.equipmentResource?.id) || 0;
            }
            if (!map[id]) {
                const resource = per ? AbstractGantt.findOne(per, "id", id, newEntities) : {};
                map[id] = { ...resource };
                map[id].timeUsed = 0;
                map[id].missions = {};
                map[id].schedules = {};
            }
            if (!map[id].missions[m.id]) {
                map[id].missions[m.id] = { id: m.id, startTime: m.startTime, endTime: m.endTime, status: m.status, inactivityType: m.inactivityType ? AbstractGantt.findOne("InactivityType", "id", m.inactivityType?.id, this.props.entities) : undefined };
                map[id].missions[m.id].oags = [];
                map[id].timeUsed += moment(m.endTime).toDate().getTime() - moment(m.startTime).toDate().getTime();
            }
            map[id].missions[m.id].oags = AbstractGantt.find("ObjectActionGroup", "mission.id", mission.id, newEntities);
        });
        return map;
    }

    protected sortData(data: { [key: number]: any }, type: Sorting): any[] {
        if (this.props.s.selectedGroupBy === Grouping.EQUIPMENT_RESOURCE) {
            return Object.keys(data).map(key => data[Number(key)]).sort((a, b) => {
                if (type === Sorting.MISSIONS_TOTAL_TIME) {
                    return b.timeUsed - a.timeUsed;
                }
                const valA = a.identifier?.toLocaleLowerCase();
                const valB = b.identifier?.toLocaleLowerCase();
                return valA.localeCompare(valB);
            });
        }
        return Object.keys(data).map(key => data[Number(key)]).sort((a, b) => {
            if (type === Sorting.MISSIONS_TOTAL_TIME) {
                if (this.props.s.selectedGroupBy === Grouping.HUMAN_RESOURCE) {
                    const aSchedule = Object.values(a.schedules).sort((a: any, b: any) => a.startTime - b.startTime)?.[0] as any;
                    const bSchedule = Object.values(b.schedules).sort((a: any, b: any) => a.startTime - b.startTime)?.[0] as any;
                    if (aSchedule && bSchedule && b.timeUsed === a.timeUsed) {
                        return aSchedule.startTime - bSchedule.startTime;
                    }
                    return b.timeUsed - a.timeUsed;
                }
            } else if (type === Sorting.SHEDULE_START_AND_END_TIME) {
                if (this.props.s.selectedGroupBy === Grouping.HUMAN_RESOURCE) {
                    const aSchedule = Object.values(a.schedules).sort((a: any, b: any) => a.startTime - b.startTime)?.[0] as any;
                    const bSchedule = Object.values(b.schedules).sort((a: any, b: any) => a.startTime - b.startTime)?.[0] as any;
                    if (!aSchedule || !bSchedule || aSchedule.startTime === bSchedule.startTime) {
                        return b.timeUsed - a.timeUsed;
                    }
                    return aSchedule.startTime - bSchedule.startTime;
                }
            }
            return 0;
        });
    }

    protected processData(newEntities: any) {
        const data: GanttData = { layers: [], items: [], groups: [] };

        let grouping = this.props.s.selectedGroupBy;
        let hrSchedules = [];
        let missions = [];
        if (newEntities) {
            const entities = newEntities;
            const hrSchedulesMap = entities["HumanResourceSchedule"] ? _.cloneDeep(entities["HumanResourceSchedule"]) : {};
            const missionsMap = entities["Mission2"] ? _.cloneDeep(entities["Mission2"]) : {};

            hrSchedules = Object.keys(hrSchedulesMap).map(key => hrSchedulesMap[Number(key)]);
            missions = Object.keys(missionsMap).map(key => missionsMap[Number(key)]);
        }
        const perType = grouping === Grouping.HUMAN_RESOURCE ? "HumanResource" : grouping === Grouping.EQUIPMENT_RESOURCE ? "EquipmentResource" : undefined;
        const sortedLines = this.sortData(
            this.groupDataByResource(newEntities, missions, grouping === Grouping.HUMAN_RESOURCE ? hrSchedules : [], perType), this.props.s.selectedSortBy as Sorting);
        var indexGroup = -1;
        sortedLines.forEach(r => {
            if (perType && this.props.hideResources?.[perType] && this.props.hideResources[perType]?.findIndex(id => id === r.id) !== -1) {
                return;
            }
            indexGroup++;
            let group: any = {
                id: indexGroup,
                entityId: r.id,
                timeUsed: r.timeUsed,
            }
            if (grouping === Grouping.HUMAN_RESOURCE) {
                group[GROUP_LABEL_PROPRETY.HUMAN_RESOURCE] = (r.firstName || "") + " " + (r.lastName || "") + (!Utils.isNullOrEmpty(r.identifier) ? " (" + r.identifier + ")" : "");
            } else if (grouping === Grouping.EQUIPMENT_RESOURCE) {
                group[GROUP_LABEL_PROPRETY.EQUIPMENT_RESOURCE] = r.identifier;
            }
            data.groups.push(group);
            Object.keys(r.schedules).forEach(sKey => {
                const s = r.schedules[sKey];
                const startTime = moment(s.startTime).valueOf();
                const endTime = moment(s.endTime).valueOf();
                if (startTime >= endTime) {
                    return;
                }
                data.layers.push({
                    rowNumber: indexGroup,
                    start: startTime,
                    end: endTime,
                    style: getLayerStyle("HumanResourceSchedule", AbstractGantt.findOne("HumanResourceSchedule", "id", s.id, newEntities))
                })
            })
            Object.keys(r.missions).forEach(mKey => {
                const mission = r.missions[mKey];
                data.items.push({
                    key: GanttUtils.toEntityUid(missionEntityDescriptor.name, mission.id),
                    row: indexGroup,
                    start: mission.startTime,
                    end: mission.endTime,
                    entityId: mission.id,
                    type: GANTT_TYPES.MISSION
                })
            });
        });
        this.props.r.setInReduxState({ data });
    }

    async componentDidMount() {
        let selectedGroupBy = window.sessionStorage.getItem(GANTT_RESOURCES_GROUP_BY) as Grouping;
        let selectedSortBy = window.sessionStorage.getItem(GANTT_RESOURCES_SORT_BY) as Sorting;
        if (selectedGroupBy === null) {
            selectedGroupBy = Grouping.HUMAN_RESOURCE;
        }
        if (selectedSortBy === null) {
            selectedSortBy = Sorting.MISSIONS_TOTAL_TIME;
        }
        await this.props.r.setInReduxState({ selectedGroupBy: selectedGroupBy, selectedSortBy: selectedSortBy });

        super.componentDidMount();
    }
    async componentDidUpdateInternal(prevProps?: Props) {
        super.componentDidUpdateInternal(prevProps);
        let shouldProcessData: boolean = false;
        if (prevProps && prevProps.s.selectedSortBy !== this.props.s.selectedSortBy) {
            window.sessionStorage.setItem(GANTT_RESOURCES_SORT_BY, this.props.s.selectedSortBy as Sorting);
            shouldProcessData = true;
        }
        if (!prevProps || prevProps.s.selectedGroupBy !== this.props.s.selectedGroupBy) {
            window.sessionStorage.setItem(GANTT_RESOURCES_GROUP_BY, this.props.s.selectedGroupBy as Grouping);
            shouldProcessData = true;
        }
        if (shouldProcessData) {
            this.processData(this.props.entities);
        }
    }

    protected entitiesChangedHandler(newEntities: any) {
        this.processData(newEntities);
    }

    protected getAcceptedType() {
        return "Mission2";
    }

    protected getTableRowsCount() {
        return 2;
    }

    protected renderTableColumns() {
        const grouping = this.props.s.selectedGroupBy;
        const type = grouping === Grouping.HUMAN_RESOURCE ? GANTT_TYPES.HUMAN_RESOURCE : grouping === Grouping.EQUIPMENT_RESOURCE ? GANTT_TYPES.EQUIPMENT_RESOURCE : undefined;
        return [
            <Column key={0} columnKey={0} width={this.props.s.tableWidth * 2 / 3}
                cell={({ rowIndex }) => this.props.s.data.groups[rowIndex] && < GanttTypeRenderer item={{ ...this.props.s.data.groups[rowIndex], type }} gantt={this} />}
            />,
            <Column key={1} columnKey={1} width={this.props.s.tableWidth / 3} flexGrow={1}
                header={<TotalTimeHeader groups={this.props.s.data.groups} />}
                cell={({ rowIndex }) => this.props.s.data.groups[rowIndex] && <DurationRenderer group={this.props.s.data.groups[rowIndex]} columnName="timeUsed" />}
            />
        ]
    }

    protected renderTopBar() {
        const optionsGroup: DropdownItemProps[] = [{ value: Grouping.HUMAN_RESOURCE, text: entityDescriptors["HumanResource"].getLabel() },
        { value: Grouping.EQUIPMENT_RESOURCE, text: entityDescriptors["EquipmentResource"].getLabel() },
        { value: Grouping.NONE, text: _msg("GanttResources.none") }];
        const optionsSort: DropdownItemProps[] = [{ value: Sorting.MISSIONS_TOTAL_TIME, text: _msg("GanttResources.sortBy.missionTime") },
        { value: Sorting.SHEDULE_START_AND_END_TIME, text: _msg("GanttResources.sortBy.sheduleTime") }];
        return <>{!this.props.hideSortBy ? <>
            <div className="flex-container">
                {_msg("GanttResources.sortBy")}
                <Dropdown selection options={optionsSort} value={this.props.s.selectedSortBy} onChange={(e, data) =>
                    this.props.r.setInReduxState({ selectedSortBy: data.value as Sorting })} />
            </div>
        </> : <></>}{!this.props.hideGroupBy ? <>
            <div className="flex-container">
                {_msg("GanttResources.groupBy")}
                <Dropdown selection options={optionsGroup} value={this.props.s.selectedGroupBy} onChange={(e, data) => {
                    this.props.r.setInReduxState({ selectedGroupBy: data.value as Grouping });
                }
                } />
            </div>
        </> : <></>}</>;
    }
}
class DurationRenderer extends React.Component<{ group: GanttGroup, columnName: string }> {
    render() {
        return <div className="wh100 flex-center flex-justify-content-center">
            {Utils.formatDuration(this.props.group?.[this.props.columnName])}
        </div>
    }
}
class TotalTimeHeader extends React.Component<{ groups: GanttGroup[] }> {
    render() {
        let totalTime = 0;
        this.props.groups.forEach(group => {
            if (group.timeUsed) {
                totalTime += group.timeUsed;
            }
        });
        return <div className="wh100 flex-center flex-justify-content-center">
            <div style={{ textAlign: "center" }}>
                <div>{_msg("GanttAssignment.totalTime")}: </div>
                <div>{Utils.formatDuration(totalTime)}</div>
            </div>
        </div>;
    }
}

export const GanttResourcesRRC = ReduxReusableComponents.connectRRC(GanttResourcesState, GanttResourcesReducers, GanttResources);

const GANTT_RESOURCES_SORT_BY = 'ganttResources.sortBy';
const GANTT_RESOURCES_GROUP_BY = 'ganttResources.groupBy';
