import React from "react";
import { Icon } from 'semantic-ui-react';
import { AbstractGantt, ITEM_HEIGHT } from "../AbstractGantt";
import { GANTT_TYPES, IconItem } from "../ganttTypeRenderers";
import { FLIGHT_IMPORTER_SARIA_J, MissionStatus } from "./constants";
import { FlightDepartureFieldRenderer } from "../../flight/FlightDepartureFieldRenderer";
import { FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { Color, XopsColorRegistry } from "pages/XopsColor/XopsColorRegistry";
import { FieldDescriptor } from "@crispico/foundation-react";
import { AbstractTypeRenderer } from "./AbstractTypeRenderer";
import { FlightInfoRenderer } from "./FlightInfoRenderer";

const iconsMap: { [key: string]: IconItem; } = {
    comment: {
        color: "purple",
        name: "comments",
        tooltip: ""
    },
    warning: {
        color: "yellow",
        name: "exclamation triangle",
        tooltip: ""
    },
    prepared: {
        color: "orange",
        name: "check",
        tooltip: ""
    },
    treated: {
        color: "green",
        name: "check",
        tooltip: ""
    },
    flightTreated: {
        color: "green",
        name: "check square outline",
        tooltip: ""
    },
    priority: {
        color: "red",
        name: "exclamation",
        tooltip: ""
    },
    verified: {
        color: "green",
        name: "check",
        tooltip: ""
    },
    modified: {
        color: "blue",
        name: "exclamation",
        tooltip: ""
    },
    flightOrder: {
        color: "black",
        name: "clock outline",
        tooltip: ""
    },
    alert: {
        color: "red",
        name: "exclamation",
        tooltip: ""
    },
    crewShuttleModified: {
        color: "purple",
        name: "exclamation",
        tooltip: ""
    },
    alreadyEngaged: {
        color: "purple",
        name: "exclamation",
        tooltip: ""
    },
    modifiedObjects: {
        color: "pink",
        name: "exclamation",
        tooltip: ""
    },
    modifiedPlaneIdentifier: {
        color: "red",
        name: "plane",
        tooltip: ""
    },
    efuelIcon: {
        color: "black",
        name: "question", // ! no icon for fuel 
        tooltip: ""
    },
    towedGreen: {
        color: "green",
        name: "truck",
        tooltip: ""
    },
    towedYellow: {
        color: "yellow",
        name: "truck",
        tooltip: ""
    },
    towedGrey: {
        color: "grey",
        name: "truck",
        tooltip: ""
    },
    sun: {
        color: "yellow",
        name: "sun",
        tooltip: ""
    },
    hangar: {
        color: "purple",
        name: "exclamation",
        tooltip: ""
    },
    delayedFlight: {
        color: "red",
        name: "exclamation",
        tooltip: ""
    },
    parkingChanged: {
        color: "purple",
        name: "exclamation",
        tooltip: ""
    },
};
export class FlightRenderer extends AbstractTypeRenderer {

    protected getEntityName() {
        return "Flight";
    }

    protected getFlightColor(flight: any) {
        const flighNumber = flight.number;
        if (flighNumber && flighNumber.includes("DEL")) {
            return XopsColorRegistry.INSTANCE.getColor(Color.FLIGHT_DELETED);
        }

        if (flight.canceled) {
            return XopsColorRegistry.INSTANCE.getColor(Color.FLIGHT_CANCELED);
        }

        if (flight.anylor) {
            return flight.color;
        }

        if (flight.temporaryStorage) {
            return XopsColorRegistry.INSTANCE.getColor(Color.MISSION_TO_TEMPORARY_STORAGE);
        }

        if (flight.departure) {
            return XopsColorRegistry.INSTANCE.getColor(Color.FLIGHT_DEPARTURE);
        } else {
            return XopsColorRegistry.INSTANCE.getColor(Color.FLIGHT_ARRIVAL);
        }
    };

    protected getMiddleLabel(flight: any) {
        return flight.name;
    };

    static showWarningIcon(flight: any) {
        return false;
    };

    static showPreparedIcon(flight: any) {
        return false;
    };

    static showTreatedIcon(flight: any) {
        return false;
    };

    static getIcons(flight: any) {
        if (!flight) {
            return [];
        }
        let icons: IconItem[] = [];

        // flight.treated && icons.push(iconsMap.treated);
        FlightRenderer.showWarningIcon(flight) && icons.push(iconsMap.warning);
        FlightRenderer.showPreparedIcon(flight) && icons.push(iconsMap.prepared);
        FlightRenderer.showTreatedIcon(flight) && icons.push(iconsMap.treated);
        flight.parking && flight.parking.hotspot && icons.push(iconsMap.sun);
        flight.priority && flight.importedBy === FLIGHT_IMPORTER_SARIA_J && icons.push(iconsMap.priority);
        flight.verified && icons.push(iconsMap.verified);
        flight.modifiedIcon && icons.push(iconsMap.modified);
        flight.crewShuttleModified && icons.push(iconsMap.crewShuttleModified);
        flight.alreadyEngaged && icons.push(iconsMap.alreadyEngaged);
        flight.modifiedObjects && icons.push(iconsMap.modifiedObjects);
        flight.modifiedPlaneIdentifier && icons.push(iconsMap.modifiedPlaneIdentifier);
        flight.towedStatus && icons.push(flight.towedStatus === "green" ? iconsMap.towedGreen : flight.towedStatus === "yellow" ? iconsMap.towedYellow : iconsMap.towedGrey);
        flight.comment?.trim().length > 0 && icons.push(iconsMap.comment);
        flight.alertCreateDefaultObjectsNoReset && icons.push(iconsMap.alert);
        flight.departure && flight.orderDate && icons.push(iconsMap.flightOrder);
        flight.inHangar && icons.push(iconsMap.hangar);
        flight.delayedFlight && icons.push(iconsMap.delayedFlight);
        flight.parkingChanged && icons.push(iconsMap.parkingChanged);
        
        return icons;
    };

    private getFlightProperties() {
        const { entities } = this.props.gantt.props;
        const flight = AbstractGantt.findOne("Flight", "id", this.props.item.entityId, entities);
        const backgroundColor = this.getBackgroundColor();
        const icons = FlightRenderer.getIcons(flight);
        const leftLabel = this.getLeftLabel();
        const rightLabel = this.getRightLabel();
        const middleLabel = this.getMiddleLabel(flight);

        return { backgroundColor, departure: flight.departure, icons, leftLabel, rightLabel, middleLabel };
    };

    getFlightStyles(backgroundColor: string | undefined, departure: boolean) {
        const bgColor = backgroundColor ? backgroundColor : departure ? 'blue' : 'green';

        const rectangleStyle: React.CSSProperties = {
            width: '50px',
            height: `${ITEM_HEIGHT}px`,
            borderRadius: '5px',
            backgroundColor: bgColor,
            color: "white",
            textAlign: "center"
        };

        const containerStyle: React.CSSProperties = {
            display: "flex",
            height: `${ITEM_HEIGHT}px`,
            width: '100%',
            flexDirection: 'row'
        };

        return { rectangleStyle, containerStyle };
    };

    protected canDrop(source: any, destination: any) {
        const { entities } = this.props.gantt.props;
        if (source.type === GANTT_TYPES.FLIGHT || source.type === GANTT_TYPES.TASK) {
            return false;
        } else if (source.type === GANTT_TYPES.MISSION) {
            const mission = AbstractGantt.findOne("Mission2", "id", source.entityId, entities);
            return mission.inactivityType ? false : true;
        }
        return true;
    }

    protected onDrop(source: any, destination: any) {
        const { entities } = this.props.gantt.props;
        let humanResourceId, missionId;
        if (source.type === GANTT_TYPES.HUMAN_RESOURCE) {
            humanResourceId = source.entityId;
            missionId = AbstractGantt.find("Mission2", "humanResourceId", humanResourceId, entities).filter(m => m.status != MissionStatus.MISSION_STATUS_FINISHED && !m.inactivityType).sort((m1: any, m2: any) => {
                return m2.endTime - m1.endTime;
            })?.[0]?.id; // last mission not finished without inactivityType
        } else if (source.type === GANTT_TYPES.MISSION) {
            missionId = source.entityId;
            humanResourceId = AbstractGantt.findOne("Mission2", "id", missionId, entities)?.humanResource?.id;
        }
        const taskIds = AbstractGantt.find("Task", "taskGroup.id", destination.entityId, entities).map(task => task.id);
        this.openAssignTaskToResource({ humanResourceId, missionId, taskIds, initialSelectedTaskIds: taskIds });
    }

    protected renderTooltip() {
        const entity = AbstractGantt.findOne("Flight", "id", this.props.item.entityId, this.props.gantt.props.entities);
        return entity ? <FlightInfoRenderer entity={entity} /> : undefined;
    }

    protected renderContent() {
        const { backgroundColor, departure, icons, leftLabel, rightLabel, middleLabel } = this.getFlightProperties();
        const { rectangleStyle, containerStyle } = this.getFlightStyles(backgroundColor, departure);
        return <span className='rct9k-items-inner'>
            <span className='rct9k-item-renderer-inner'>
                <div style={containerStyle}>
                    {leftLabel && <div>{leftLabel}</div>}
                    {icons?.map((icon, idx) => <Icon key={idx} name={icon.name} color={icon.color} />)}
                    {React.createElement(FlightDepartureFieldRenderer, { value: departure, fieldDescriptor: new FieldDescriptor() } as FieldRendererProps)}
                    {middleLabel && <div style={rectangleStyle}>{middleLabel}</div>}
                    {rightLabel && <div style={rectangleStyle}>{middleLabel}</div>}
                </div>
            </span>
        </span>;
    }

}
