import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import moment from "moment";
import React from "react";
import { GanttResources, GanttResourcesRRC } from "./GanttResources";
import { GanttTasks, GanttTasksMode, GanttTasksRRC } from "./GanttTasks";
import { DragAndDropArea } from "@crispico/foundation-react/components/DragAndDrop/DragAndDrop";

class GanttDoubleState extends State {
    entities: { [entityName: string]: { [id: number]: any } } = {};
    hideResources: { [key: string]: number[] } = {};
}

export enum GanttDoubleDisplayMode { FLEX_OBJECT_GANTT = "FLEX_OBJECT_GANTT", DEFAULT = "DEFAULT" }

class GanttDoublePageReducers<S extends GanttDoubleState = GanttDoubleState> extends Reducers<S> {

}

type Props = RRCProps<GanttDoubleState, GanttDoublePageReducers> & {
    startDate: string,
    endDate: string,
    portalContainerForTopBar?: any,
    displayMode: GanttDoubleDisplayMode
};

export class GanttDoublePage extends React.Component<Props> {

    protected ganttResourcesRef = React.createRef<GanttResources>();
    protected ganttTasksRef = React.createRef<GanttTasks>();

    getGanttResourcesRef() {
        return this.ganttResourcesRef.current;
    }
    
    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: Props) {
        this.componentDidUpdateInternal(prevProps);
    }

    protected async componentDidUpdateInternal(prevProps?: Props) {
        if (!prevProps || prevProps.displayMode != this.props.displayMode) {
            if (this.props.displayMode === GanttDoubleDisplayMode.FLEX_OBJECT_GANTT) {
                this.ganttTasksRef.current!.props.r.setInReduxState({ mode: this.props.displayMode === GanttDoubleDisplayMode.FLEX_OBJECT_GANTT ? GanttTasksMode.TASKS : GanttTasksMode.FLIGHT_TASKS });
            }
        }
        if (!prevProps || prevProps.startDate != this.props.startDate || prevProps.endDate != this.props.endDate) {
            const start = moment(this.props.startDate).valueOf();
            const end = moment(this.props.endDate).valueOf();
            this.ganttTasksRef.current!.props.r.setInReduxState({ start: start, end: end });
            this.ganttResourcesRef.current?.props.r.setInReduxState({ start: start, end: end });
        }
    }

    render() {
        return <DragAndDropArea>
            <SplitPaneExt defaultSize={this.props.displayMode === GanttDoubleDisplayMode.FLEX_OBJECT_GANTT ? "100%" : "50%"}>
                <GanttTasksRRC id="GanttDoublePage_GanttTasks" ref={this.ganttTasksRef}
                    portalContainerForTopBar={this.props.portalContainerForTopBar} showStatusFilter={true}                   
                    entities={this.props.s.entities} hideResources={this.props.s.hideResources} hideTopBar />
                {this.props.displayMode === GanttDoubleDisplayMode.FLEX_OBJECT_GANTT ? <></> :
                    <GanttResourcesRRC id="GanttDoublePage_GanttResources" ref={this.ganttResourcesRef}
                        portalContainerForTopBar={this.props.portalContainerForTopBar}                      
                        entities={this.props.s.entities} hideResources={this.props.s.hideResources} hideTopBar />}
            </SplitPaneExt>
        </DragAndDropArea >;
    }
}

export const GanttDoublePageRRC = ReduxReusableComponents.connectRRC(GanttDoubleState, GanttDoublePageReducers, GanttDoublePage);
