import { apolloClient, apolloClientHolder, FieldDescriptor, PrivateRoute, PrivateRouteProps, Utils } from "@crispico/foundation-react";
import { AssociationFieldEditor } from "@crispico/foundation-react/entity_crud/AssociationFieldEditor";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import React from "react";
import lodash from "lodash";
import { Button, Checkbox, Input, Label, Radio, Segment, TextArea } from "semantic-ui-react";
import gql from "graphql-tag";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import _ from "lodash";
import { Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import moment from "moment";
import { MISSION_ASSIGN_OBJECT_TO_MISSION, MISSION_CHECK_MISSION_VALIDATION, MISSION_DELETE, MISSION_SAVE, OAG_DELETE } from "./queries";
import { HUMAN_RESOURCE_GET_QUALIFICATIONS_FOR_DATE } from "pages/HumanResourceSchedule/queries";

const MAP_PRIORITY: { [key: string]: number } = { "P": 0, "R": 1, "1R": 2, "2R": 3, "RTL": 4, "S": 5, "T": 6, "1T": 7, "2T": 8, "L": 9, "H": 10 };
const POSITION_AR = "AR";
const POSITION_AV = "AV";

export interface AssignTasksToResourcePageSettings {
    maxTasksToAssign: number;
    verifyEquipmentTypeCapacity: boolean;
    showTemporaryStorageInfo: boolean;
    showEquipmentTypeCapacityInfo: boolean;
    showEquipmentTypeInfo: boolean;
    showEquipmentResourceInfo: boolean;
    showAssignUnitInfo: boolean;
    sortTasksBasedOnFields: string;
    selectTaskWithoutEquipmentType: boolean;
    selectExistingMission: boolean;
    checkAddressesFields: boolean;
    checkQualification: boolean;
    maxTasksForMission: number;
    checkNullOilGroup: boolean;
}

enum MissionStatusGroup { NEW, EXISTING, NEW_UNIT }
enum TemporaryStorageGroup { FROM, TO }

type AssignTasksToResourcePageProps = {
    degradedMode?: boolean;
    taskIds?: number[];
    initialSelectedTaskIds?: number[];
    missionId?: number;
    // Required if mission is undefined.
    equipmentResourceId?: number;
    // Required if mission is undefined.
    humanResourceId?: number;
    equipmentTypeTaskIds?: any[];
    temporaryStorage?: string; // code of address
    createTruckObjectsForFlightUid?: string; // default null
    onAssign?: (uid: string) => void;
    onError?: () => void;
    onCancel?: () => void;
    missionCreationDate?: string; // (GanttWrapperRoots.INSTANCE.isTemplateDay || GanttWrapperRoots.INSTANCE.isFutureDay) ? GanttWrapperRoots.INSTANCE.dateBeginningOfDay : null
}

export class AssignTasksToResourcePageState extends State {
    settings: AssignTasksToResourcePageSettings | undefined;
    mission2Settings: any;
    allTasks: any[] = [];
    tasks: any[] = [];
    mission?: any;
    equipmentResource?: any;
    humanResource?: any;
    equipmentTypeTasks: any[] = [];
    missionStatusGroup = MissionStatusGroup.NEW;
    enableNewUnitMission = true;
    equipmentType?: any;
    attachment?: any;
    temporaryStorage?: any;
    comment?: string;
    temporaryStorageGroup = TemporaryStorageGroup.TO;
    selectedTasksCount: number = 0; // if maxTasksToAssign from setting for don't allow assign more tasks
    taskTypes: { [key: string]: any } = {};
    alerts: string[] = [];
    numberOfMissionsToBeCreated?: number;
}

export class AssignTasksToResourcePageReducers<S extends AssignTasksToResourcePageState = AssignTasksToResourcePageState> extends Reducers<S> {

}

type Props = RRCProps<AssignTasksToResourcePageState, AssignTasksToResourcePageReducers> & AssignTasksToResourcePageProps;

const filterById = (ids: number[]) => Filter.create("id", FilterOperators.forNumber.in, ids.join(", "));

export class AssignTasksToResourcePage extends React.Component<Props> {

    static defaultProps = {
        degradedMode: false
    }

    availableCapacity: { [key: string]: number } = {};
    selectedTasks: { [key: string]: boolean } = {};
    missionTypes: { [key: string]: any } = {};
    baggagesAlertMap: { [key: string]: any } = {};
    baggagesAlertMapMaxPrefixLength: number = -1;
    baggagesAlertMapMinPrefixLength: number = Number.MAX_VALUE;

    constructor(props: Props) {
        super(props);
    }

    async componentDidMount() {
        await this.getSetting();
        if (this.props.temporaryStorage) {
            const temporaryStorage = (await this.loadEntities("Address", Filter.create("code", FilterOperators.forString.equals, this.props.temporaryStorage)))[0];
            this.props.r.setInReduxState({ temporaryStorage });
        }
        await this.getHumanResource();
        await this.getEquipmentResource();
        await this.getMission();
        await this.getTasks();
        await this.getMissionTypes();
        await this.getAttachment();
        await this.getBaggagesAlert();
        await this.getEquipmentTypeTasks();
        if (this.props.s.settings?.selectExistingMission) {
            this.handleMissionStatusGroup(MissionStatusGroup.EXISTING);
        }
        await this.getMission2Setting();
    }

    async componentDidUpdate(prevProps: Readonly<Props>) {
        if (this.props.s.equipmentType != prevProps.s.equipmentType) {
            await this.getEquipmentTypeTasks(!this.props.s.equipmentType);
        }
        if (this.props.s.temporaryStorage && this.props.s.temporaryStorage != prevProps.s.temporaryStorage) {
            this.sortTasks();
        }
        if (this.props.s.temporaryStorageGroup != prevProps.s.temporaryStorageGroup) {
            this.sortTasks();
        }
    }

    private async getSetting() {
        const settings = (await apolloClientHolder.apolloClient.query({
            query: gql(`query q { 
                mission2Service_assignTasksToResourcePageSettings {
                    maxTasksToAssign verifyEquipmentTypeCapacity showTemporaryStorageInfo
                    showEquipmentTypeCapacityInfo showEquipmentTypeInfo showEquipmentResourceInfo
                    showAssignUnitInfo sortTasksBasedOnFields selectTaskWithoutEquipmentType
                    selectExistingMission checkAddressesFields maxTasksForMission checkNullOilGroup checkQualification
                }
            }`)
        })).data["mission2Service_assignTasksToResourcePageSettings"];
        this.props.r.setInReduxState({ settings })
    }

    private async getMission2Setting() {

        const mission2Settings = (await apolloClientHolder.apolloClient.query({
            query: gql(`query q { 
                mission2Service_settings {
                  allowNoEquipment
                }
            }`)
        })).data["mission2Service_settings"];
        this.props.r.setInReduxState({ mission2Settings });
    }

    private async loadEntities(entityName: string, filter?: Filter) {
        const ed = entityDescriptors[entityName];
        const loadOperationName = `${lodash.lowerFirst(entityName)}Service_findByFilter`;
        let fieldsToRequestStr = ed.getGraphQlFieldsToRequest();
        if (entityName === "Mission2") {
            fieldsToRequestStr += " stops { objectActions { dataString type } } inactivityType { id isPartial hideInGantt } objectActionGroups { id } ";
        }
        if (entityName === "Task") {
            fieldsToRequestStr += " taskGroup { id name oilGroup { id } destination airline date number tasks { id objectActionGroups { id } } } taskType { id name requiredQualifications { id qualificationType { id name } } } ";
        }
        if (entityName === "HumanResource") {
            fieldsToRequestStr += " vehicle { id attachmentId } qualifications { id qualificationType { id name } }";
        }
        if (entityName === "BaggagesAlert") {
            fieldsToRequestStr += " objectType { id prefix }";
        }
        if (entityName === "EquipmentTypeTask") {
            fieldsToRequestStr += " taskType { id name ocupiedPositions }";
        }

        const loadQueryParams = {
            loadOperationName,
            loadQuery: gql(`query q($params: FindByFilterParamsInput) { 
                ${loadOperationName}(params: $params) {
                    results { ${fieldsToRequestStr} } totalCount
                }
            }`)
        };
        const entities = (await apolloClientHolder.apolloClient.query({
            query: loadQueryParams.loadQuery,
            variables: FindByFilterParams.create().filter(filter ? Filter.createComposed(FilterOperators.forComposedFilter.and, [filter]) : undefined)
        })).data[loadQueryParams.loadOperationName].results;
        return entities;
    }

    private async getBaggagesAlert() {
        const baggagesAlert = await this.loadEntities("BaggagesAlert");
        baggagesAlert.forEach((bagAl: any) => {
            const prefix = (bagAl.objectType == null) ? "" : bagAl.objectType.prefix;
            const dest = (bagAl.flightDestination == null) ? "" : bagAl.flightDestination;
            const prefixAndDestinationAsKey = prefix + "-" + dest;
            this.baggagesAlertMap[prefixAndDestinationAsKey] = bagAl.maxNumber;
            if (prefix.length > this.baggagesAlertMapMaxPrefixLength) {
                this.baggagesAlertMapMaxPrefixLength = prefix.length;
            }
            if (prefix.length < this.baggagesAlertMapMinPrefixLength) {
                this.baggagesAlertMapMinPrefixLength = prefix.length;
            }
        })
    }

    private async getMission() {
        const mission = this.props.missionId ? (await this.loadEntities("Mission2", filterById([this.props.missionId])))?.[0] : undefined;
        this.props.r.setInReduxState({ mission });
    }

    private async getAttachment() {
        const attachment = this.props.s.humanResource?.vehicle?.attachmentId ? (await this.loadEntities("EquipmentResource", filterById(this.props.s.humanResource.vehicle.attachmentId)))?.[0] : undefined;
        this.props.r.setInReduxState({ attachment });
    }

    private async getEquipmentResource(id?: number) {
        // used id = 0, in prod the id > 0, and on test id < 0
        const equipmentResource = (this.props.equipmentResourceId || id) ? (await this.loadEntities("EquipmentResource", filterById([id ? id : this.props.equipmentResourceId || 0])))?.[0] : undefined;
        this.props.r.setInReduxState({ equipmentResource, equipmentType: equipmentResource?.equipmentType });
    }

    private async getHumanResource() {
        const humanResource = this.props.humanResourceId ? (await this.loadEntities("HumanResource", filterById([this.props.humanResourceId])))?.[0] : undefined;
        this.props.r.setInReduxState({ humanResource });
    }

    private async getEquipmentTypeTasks(initial: boolean = true) {
        const filter = initial && this.props.equipmentTypeTaskIds ? filterById(this.props.equipmentTypeTaskIds) : (this.props.s.equipmentType ? Filter.create("equipmentType.id", FilterOperators.forNumber.equals, this.props.s.equipmentType.id) : undefined);
        const equipmentTypeTasks = (await this.loadEntities("EquipmentTypeTask", filter));
        const taskTypeIds = equipmentTypeTasks.map((eQTT: any) => { return eQTT.taskType.id }) as number[];
        const taskTypes = taskTypeIds.length ? (await this.loadEntities("TaskType", filterById(taskTypeIds))) : [];
        this.availableCapacity = {};
        equipmentTypeTasks.forEach((equipmentTypeTask: any) => this.availableCapacity[equipmentTypeTask.taskType.name] = equipmentTypeTask.quantity);
        this.props.s.missionStatusGroup === MissionStatusGroup.EXISTING && this.props.s.mission?.stops && Object.keys(this.props.s.mission.stops).forEach(key => {
            Object.keys(this.props.s.mission.stops[key].objectActions).forEach(key2 => {
                const objectAction = this.props.s.mission.stops[key].objectActions[key2];
                const data = JSON.parse(objectAction.dataString).map;
                if (this.availableCapacity[data.type] != undefined && objectAction.type === "load") {
                    this.availableCapacity[data.type] -= data.quantity;
                }
            })
        })
        let selectedTasksCount = this.props.s.selectedTasksCount;
        for (let key in this.props.s.tasks) {
            const task = this.props.s.tasks[key];
            if (initial) {
                if (this.props.initialSelectedTaskIds?.filter((id: number) => id == task.id).length && this.availableCapacity[task.taskType.name] - task.quantity >= 0 && !(this.props.s.settings && this.props.s.settings.maxTasksToAssign && selectedTasksCount >= this.props.s.settings.maxTasksToAssign)) {
                    this.availableCapacity[task.taskType.name] -= task.quantity;
                    this.selectedTasks[task.id] = true;
                    selectedTasksCount++;
                }
            } else {
                if (!this.props.s.equipmentType && this.props.s.settings?.selectTaskWithoutEquipmentType || (task.extraDemand && !task.extraDemandValidated && this.availableCapacity[task.taskType.name] != undefined && this.selectedTasks[task.id])) {
                    if (this.props.s.settings && this.props.s.settings.maxTasksToAssign && selectedTasksCount < this.props.s.settings.maxTasksToAssign && this.availableCapacity[task.taskType.name] - task.quantity >= 0) {
                        if (this.props.s.settings?.verifyEquipmentTypeCapacity) {
                            this.availableCapacity[task.taskType.name] -= task.quantity;
                            selectedTasksCount++;
                        }
                    } else {
                        this.availableCapacity[task.taskType.name] += task.quantity;
                        this.selectedTasks[task.id] = false;
                    }
                }
            }
        }
        this.props.r.setInReduxState({ equipmentTypeTasks, taskTypes, selectedTasksCount });
    }

    private async getTasks() {
        if (!this.props.taskIds?.length) {
            return;
        }
        const allTasks = await this.loadEntities("Task", filterById(this.props.taskIds));
        let missionStatusGroup = this.props.s.missionStatusGroup;
        if (this.props.s.settings?.showAssignUnitInfo && this.props.taskIds && _.isEqual(this.props.taskIds, this.props.initialSelectedTaskIds)) {
            missionStatusGroup = MissionStatusGroup.NEW_UNIT;
        }
        this.props.r.setInReduxState({ allTasks, missionStatusGroup, tasks: allTasks });
        this.sortTasks();
    }

    private async getMissionTypes() {
        const results = await this.loadEntities("MissionType");
        this.missionTypes = {};
        results.forEach((missionType: any) => {
            this.missionTypes[missionType.id] = missionType;
        })
    }

    sortFunction(a: any, b: any) {
        const fields = this.props.s.settings?.sortTasksBasedOnFields.split(" ") || [];
        for (let i = 0; i < fields.length; i++) {
            let valA = _.cloneDeep(a), valB = _.cloneDeep(b);
            let composedFileds = fields[i].split(".");
            let nullValueString = "", nullValueInt = Number.MIN_VALUE;
            if (fields[i] === "position") {
                // vea: position is sorted by number [2..], after by type [0:2]
                composedFileds = ["position1", "position2"];
                valA["position1"] = valA["position"] ? valA["position"].substring(2) : undefined;
                valB["position1"] = valB["position"] ? valB["position"].substring(2) : undefined;
                const aPos = valA["position"] ? valA["position"].substring(0, 2) : undefined,
                    bPos = valB["position"] ? valB["position"].substring(0, 2) : undefined;
                valA["position2"] = aPos === POSITION_AR ? 0 : (aPos === POSITION_AV ? 1 : -1);
                valB["position2"] = bPos === POSITION_AR ? 0 : (bPos === POSITION_AV ? 1 : -1);
            }
            for (let j = 0; j < composedFileds.length; j++) {
                if ((composedFileds.length === 2 && composedFileds[0] === "missionType" && composedFileds[1] === "name") ||
                    composedFileds[0] === "position1" || composedFileds[0] === "position2" || composedFileds[0] === "priority") {
                    // onet: missionType.name, priority on sort put first null
                    // vea: position, on sort put first null
                    nullValueString = "Z";
                    nullValueInt = Number.MAX_VALUE;
                }
                if (composedFileds[0] === "priority") {
                    // onet: map priority
                    valA["priority"] = MAP_PRIORITY[valA["priority"]];
                    valB["priority"] = MAP_PRIORITY[valB["priority"]];
                }
                valA = valA[composedFileds[j]] ? valA[composedFileds[j]] : (typeof valA[composedFileds[j]] == "string" ? nullValueString : nullValueInt);
                valB = valB[composedFileds[j]] ? valB[composedFileds[j]] : (typeof valB[composedFileds[j]] == "string" ? nullValueString : nullValueInt);
                if (!valA[composedFileds[j]] || !valA[composedFileds[j]]) {
                    break;
                }
            }
            if (valA === valB) {
                continue;
            } else {
                if (typeof valB == "string") {
                    // compare strings caseInsensitive
                    valA = valA.toLocaleLowerCase(); valB = valB.toLocaleLowerCase();
                    return valA.localeCompare(valB);
                }
                return valA - valB;
            }
        }
        return 0;
    }

    sortTasks() {
        const alerts: any[] = [];
        let tasks = this.props.s.allTasks.filter((task: any) => {
            if (this.props.s.temporaryStorage && ((this.props.s.temporaryStorageGroup === TemporaryStorageGroup.FROM && task.startAddress?.id != this.props.s.temporaryStorage.id)
                || (this.props.s.temporaryStorageGroup === TemporaryStorageGroup.TO && task.endAddress?.id != this.props.s.temporaryStorage.id))) {
                return false;
            }
            return true;
        })
        let enableNewUnitMission = true;
        tasks.forEach(async (task: any) => {
            if (this.props.s.settings?.checkQualification) {
                if (this.props.s.humanResource && task.taskType.requiredQualifications?.length) {
                    let qualifications = (await apolloClientHolder.apolloClient.query({ query: HUMAN_RESOURCE_GET_QUALIFICATIONS_FOR_DATE, variables: {
                        date: task.taskGroup.date,
                        humanResourcesIds: [this.props.s.humanResource.id]
                    }, context: { showSpinner: false } })).data["humanResourceService_qualificationsForDate"];
                    
                    task.taskType.requiredQualifications.forEach((requiredQualification: any) => {
                        let isQualified = false;
                        qualifications.forEach((qualification: any) => {
                            if (qualification.qualificationType.name === requiredQualification.qualificationType.name) {
                                isQualified = true;
                            }
                        })
                        if (!isQualified) {
                            alerts.push(_msg("assignObjects.notQualified", this.props.s.humanResource.lastName + " " + this.props.s.humanResource.firstName, requiredQualification.qualificationType.name))
                        }
                    })
                }
            }
            // if already found a flight without any mission no need to check other tasks/flights
            if (enableNewUnitMission) {
                let foundMissionForFlight = false;
                task.taskGroup.tasks.forEach((task: any) => {
                    if (task.objectActionGroups.length > 0) {
                        foundMissionForFlight = true;
                        return;
                    }
                })
                if (!foundMissionForFlight) {
                    enableNewUnitMission = false;
                }
            }
            
            if (this.selectedTasks[task.id]) {
                this.selectedTasks[task.id] = false;
                this.availableCapacity[task.taskType.name] += task.quantity;
            }
        });
        tasks.sort((a: any, b: any) => this.sortFunction(a, b));
        this.props.r.setInReduxState({ tasks, enableNewUnitMission, selectedTasksCount: 0, alerts });
    }

    private isDisabledTask(task: any) {
        return (!this.selectedTasks[task.id] && this.availableCapacity[task.taskType.name] < task.quantity) || ((task.extraDemand && !task.extraDemandValidated) || (this.props.s.settings && this.props.s.settings.maxTasksToAssign && this.props.s.selectedTasksCount >= this.props.s.settings.maxTasksToAssign && !this.selectedTasks[task.id]));
    }

    private isCheckedTask(task: any) {
        return (task.extraDemand && !task.extraDemandValidated) || this.selectedTasks[task.id];
    }

    protected handleToogleTask(task: any, value: any) {
        this.selectedTasks[task.id] = value || false;
        let selectedTasksCount = this.props.s.selectedTasksCount;
        if (value) {
            if (this.props.s.settings?.verifyEquipmentTypeCapacity) {
                this.availableCapacity[task.taskType.name] -= task.quantity;
            }
            selectedTasksCount++;
        } else {
            if (this.props.s.settings?.verifyEquipmentTypeCapacity) {
                this.availableCapacity[task.taskType.name] += task.quantity;
            }
            selectedTasksCount--;
        }
        this.props.r.setInReduxState({ selectedTasksCount });
    }

    protected handleMissionStatusGroup(missionStatusGroup: MissionStatusGroup) {
        if (missionStatusGroup === MissionStatusGroup.EXISTING && this.props.s.missionStatusGroup != MissionStatusGroup.EXISTING) {
            this.props.s.mission?.stops && Object.keys(this.props.s.mission.stops).forEach(key => {
                Object.keys(this.props.s.mission.stops[key].objectActions).forEach(key2 => {
                    const objectAction = this.props.s.mission.stops[key].objectActions[key2];
                    const data = JSON.parse(objectAction.dataString).map;
                    if (this.availableCapacity[data.type] != undefined && objectAction.type === "load") {
                        this.availableCapacity[data.type] -= data.quantity;
                    }
                })
            })
            this.props.s.tasks.forEach((task: any) => {
                if (this.availableCapacity[task.taskType.name] != undefined && this.selectedTasks[task.id]) {
                    if (this.availableCapacity[task.taskType.name] < 0) {
                        this.availableCapacity[task.taskType.name] += task.quantity;
                        this.selectedTasks[task.id] = false;
                    }
                }
            })
        } else if (missionStatusGroup != MissionStatusGroup.EXISTING && this.props.s.missionStatusGroup === MissionStatusGroup.EXISTING) {
            this.props.s.mission?.stops && Object.keys(this.props.s.mission.stops).forEach(key => {
                Object.keys(this.props.s.mission.stops[key].objectActions).forEach(key2 => {
                    const objectAction = this.props.s.mission.stops[key].objectActions[key2];
                    const data = JSON.parse(objectAction.dataString).map;
                    if (this.availableCapacity[data.type] != undefined && objectAction.type === "load") {
                        this.availableCapacity[data.type] += data.quantity;
                    }
                })
            })
        }
        this.props.r.setInReduxState({ missionStatusGroup })
    }

    protected handleTemporaryStrorageGroup(temporaryStorageGroup: TemporaryStorageGroup) {
        this.props.r.setInReduxState({ temporaryStorageGroup });
    }

    private createMissionParam(selectedTasks: any[]) {
        return {
            missionUid: this.props.s.missionStatusGroup === MissionStatusGroup.EXISTING && this.props.s.mission ? ("Mission2:" + this.props.s.mission.id) : null,
            humanResourceUid: this.props.s.humanResource ? ("HumanResource:" + this.props.s.humanResource.id) : null,
            equipmentResourceUid: this.props.s.equipmentResource ? ("EquipmentResource:" + this.props.s.equipmentResource.id) : null,
            objectUids: selectedTasks.map(task => "Task:" + task.id),
            // formerMissionUid
            // entireFlight: false, was removed from MissionService_AssignObjectToMission.Params
            // preObjectActions // delivery
            // objectToSave
            // objectToSaveAndAssign
            pendingMissionsNumber: this.props.s.numberOfMissionsToBeCreated,
            temporaryStorageAddressName: this.props.s.temporaryStorage ? ("Address:" + this.props.s.temporaryStorage.id) : null,
            equipmentTypeUid: this.props.s.equipmentResource?.equipmentType ? ("EquipmentType:" + this.props.s.equipmentResource.equipmentType.id) : null,
            comment: this.props.s.comment,
            createTruckObjectsForFlightUid: this.props.createTruckObjectsForFlightUid,
            // objectQuantitites, // list of modified quantity values(only for delivery)
            degradedMode: this.props.degradedMode,
            creationMode: null,
            assignToUnit: this.props.s.missionStatusGroup === MissionStatusGroup.NEW_UNIT,
            attachmentUid: this.props.s.attachment ? ("EquipmentResource:" + this.props.s.attachment.id) : null,
            forcedMissionStartDate: null,
            forcedMissionEndDate: null,
            missionCreationDate: this.props.missionCreationDate ? new Date(this.props.missionCreationDate) : null,
            doNotCreateEventOnMissionSave: false,
            assignWithNoEquipment: this.props.s.mission2Settings.allowNoEquipment
        }
    }

    protected handleOk = async () => {
        let noAddress = false;
        const msgNoAddress: string[] = [];
        let baggageExceeded = false;
        const msgBaggageExceeded: string[] = [];
        let incompatibilityMissionType = false;
        let mType;
        const selectedTasks: any[] = [];
        for (let key in this.props.s.tasks) {
            const task = this.props.s.tasks[key];
            if (!this.selectedTasks[task.id]) {
                continue;
            }
            selectedTasks.push(task);
            if (this.props.s.settings?.checkNullOilGroup && task.taskGroup && !task.taskGroup.oilGroup) {
                Utils.showGlobalAlert({ message: _msg("assignObjects.noOilgroup"), title: _msg("assignObjects.error.cannotaddTitle"), severity: Severity.ERROR });
                return;
            }
            if (!task.startAddress || !task.endAddress ||
                (this.props.s.settings?.checkAddressesFields && (task.startAddresses === undefined || !Object.keys(task.startAddresses).length || task.endAddresses === undefined || !Object.keys(task.endAddresses).length))) {
                noAddress = true;
                msgNoAddress.push((msgNoAddress.length + 1) + ". " + (task.taskType ? task.taskType.name : "") + " : " + task.name);
            }
            if (this.baggagesAlertMapMaxPrefixLength != -1) {
                for (let i = this.baggagesAlertMapMaxPrefixLength; i >= this.baggagesAlertMapMinPrefixLength; i--) {
                    const keyNoDest = task.name.substr(0, i) + "-";
                    const keyWithDest = keyNoDest + task.taskGroup.destination;
                    const baggagesLimitControled = JSON.parse(task.dataString)["baggagesLimitControled"];
                    if (this.baggagesAlertMap[keyNoDest] != null) {
                        if (this.baggagesAlertMap[keyNoDest] < task.processedBaggages && !baggagesLimitControled) {
                            baggageExceeded = true;
                            msgBaggageExceeded.push((msgBaggageExceeded.length + 1) + ". " + _msg("assignObjects.baggageNumberExceeded", task.name));
                        }
                        break;
                    } else if (this.baggagesAlertMap[keyWithDest] != null) {
                        if (this.baggagesAlertMap[keyWithDest] < task.processedBaggages && baggagesLimitControled) {
                            baggageExceeded = true;
                            msgBaggageExceeded.push((msgBaggageExceeded.length + 1) + ". " + _msg("assignObjects.baggageNumberExceeded", task.name));
                        }
                        break;
                    }
                }
            }
            if (!mType) {
                mType = task.missionType?.id;
            } else if (mType != task.missionType?.id) {
                incompatibilityMissionType = true;
            }
        }
        if (noAddress) {
            Utils.showGlobalAlert({ message: _msg("assignObjects.nullAddressObjects", msgNoAddress.join("\n")), title: _msg("assignObjects.error.cannotaddTitle"), severity: Severity.ERROR });
            return;
        }
        if (!selectedTasks.length && !this.props.s.temporaryStorage) {
            Utils.showGlobalAlert({ message: _msg("assignObjects.error.cannotaddMessage"), title: _msg("assignObjects.error.cannotaddTitle"), severity: Severity.ERROR });
            return;
        }
        if (this.props.s.settings && this.props.s.settings.maxTasksForMission && (selectedTasks.length > this.props.s.settings.maxTasksForMission || (this.props.s.missionStatusGroup === MissionStatusGroup.EXISTING && this.props.s.mission && Object.keys(this.props.s.mission?.objectActionGroups).length + selectedTasks.length > this.props.s.settings.maxTasksForMission))) {
            Utils.showGlobalAlert({ message: _msg("mission2Service.fullEquipment", this.props.s.settings.maxTasksForMission), title: _msg("assignObjects.error.cannotaddTitle"), severity: Severity.ERROR });
            return;
        }
        if (baggageExceeded) {
            Utils.showGlobalAlert({ message: msgBaggageExceeded.join("\n"), title: _msg("assignObjects.error.cannotaddTitle"), severity: Severity.ERROR });
            return;
        }
        if (incompatibilityMissionType) {
            Utils.showGlobalAlert({ message: _msg("assignObjects.error.incompatibilityMissionType"), title: _msg("assignObjects.error.cannotaddTitle"), severity: Severity.ERROR });
            return;
        }

        try {
            let res: any[] = (await apolloClient.mutate({
                mutation: MISSION_ASSIGN_OBJECT_TO_MISSION, variables: {
                    params: this.createMissionParam(selectedTasks)
                }
            })).data["mission2Service_assignObjectToMission"] as [];

            if (!res?.length) {
                Utils.showGlobalAlert({ message: _msg("assignObjects.error.cannotaddMessage"), title: _msg("assignObjects.error.cannotaddTitle"), severity: Severity.ERROR });
                return;
            } else {
                const savedMission = (await this.loadEntities("Mission2", filterById([res[0].split(":").pop()!])))[0];

                res = (await apolloClient.mutate({
                    mutation: MISSION_CHECK_MISSION_VALIDATION, variables: {
                        missionEntityUid: "Mission2:" + savedMission.id,
                        humanResourceEntityUid: this.props.s.humanResource ? ("HumanResource:" + this.props.s.humanResource.id) : null,
                        equipmentResourceEntityUid: this.props.s.equipmentResource ? ("EquipmentResource:" + this.props.s.equipmentResource.id) : null
                    }
                })).data["mission2Service_checkMissionValidation"] as [];
                if (res && res?.length && res[0] && res[1]) {
                    if (res[0] === "ERROR") {
                        if (selectedTasks.length != savedMission.objectActionGroups.length) {
                            savedMission.objectActionGroups.forEach(async (oag: any) => {
                                (await apolloClient.mutate({ mutation: OAG_DELETE, variables: { id: oag.id } }));
                            })

                        } else {
                            (await apolloClient.mutate({ mutation: MISSION_DELETE, variables: { id: savedMission.id } }));
                        }
                        Utils.showGlobalAlert({ message: res[1], title: _msg("assignObjects.error.cannotaddTitle"), severity: Severity.ERROR });
                        return;
                    } else {
                        if (this.props.s.humanResource) {
                            const missions = await this.loadEntities("Mission2", Filter.create("humanResourceId", FilterOperators.forNumber.equals, this.props.s.humanResource.id))
                            missions.forEqch(async (mission: any) => {
                                if (savedMission.startTime.time >= mission.startTime.time && savedMission.startTime.time <= mission.endTime.time && mission.inactivityType != null && !mission.inactivityType.isPartial && !mission.inactivityType.hideInGantt) {
                                    // change the inactivity endTime with the new mission startTime
                                    (await apolloClient.mutate({ mutation: MISSION_SAVE, variables: { params: { endTime: savedMission.startTime } } }));
                                    await this.getMission();
                                    Utils.showGlobalAlert({ message: _msg("inactivity.stopInactivity"), title: _msg("global.confirmation"), severity: Severity.ERROR });
                                    return;
                                }
                            })
                        }
                        if (savedMission && savedMission.humanResource && savedMission.equipmentResource) {
                            // print mission only for vea and when RealTimeGanttSettings.degradedMode is true
                            // Xops4Utils.printMission(savedMission.id, Boolean(Xops4Plugin.instance.missionGanttSheetOptionsManager.getOption(Xops4Constants.OPT_DEGRADED_MODE)));
                        }
                    }
                } else {
                    if (savedMission && this.props.onAssign) {
                        this.props.onAssign("Mission2:" + savedMission.id);
                    }
                }
            }
        } catch (e) {
            this.props.onError && this.props.onError();
        }
    }

    taskToString(task: any) {
        return entityDescriptors["Task"].entityDescriptorSettings?.fieldsInHeader.map(fieldInHeader => {
            return entityDescriptors["Task"].getField(fieldInHeader.name as string).renderField(task);
        })
    }

    protected renderAlert() {
        return <div className="flex-container">
            {this.props.s.alerts.map((alert: any) => <div className="AssignTasksToRessoruce_alert">{alert}</div>)}
            {!this.props.s.tasks?.length && <div className="AssignTasksToRessoruce_alert">{_msg("assignObjects.noObjects")}</div>}
        </div>
    }

    protected renderTask() {
        const groupTask: { [key: string]: any[] } = {};
        // group by missionType(sorted) after group by taskGroup(sorted)
        this.props.s.tasks?.forEach((task: any) => {
            if (!groupTask[task.missionType?.id]) {
                groupTask[task.missionType?.id] = [];
            }
            if (!groupTask[task.missionType?.id][task.taskGroup.id]) {
                groupTask[task.missionType?.id][task.taskGroup?.id] = [];
            }
            groupTask[task.missionType?.id][task.taskGroup?.id].push(task);
        })
        const tasks: JSX.Element[] = [];
        let noMissionTypeTask;
        Object.keys(groupTask).forEach(key => {
            const missionType = this.missionTypes[key]?.name || _msg("assignObjects.noMissionType");
            const task = <div id={missionType} className="flex-container" >
                <div className="AssignTasksToRessoruce_bold" style={{ color: this.missionTypes[key]?.color }}>
                    {missionType}
                </div>
                {Object.keys(groupTask[key]).map(taskKey => {
                    const flight = groupTask[key][Number(taskKey)][0].taskGroup;
                    const flightLabel = flight.airline + flight.number + " " + moment(flight.date).format(Utils.timeFormat);
                    return <>
                        <div className="AssignTasksToRessoruce_bold very-small-padding gap5">
                            {flightLabel}
                        </div>
                        <div className="flex-container less-padding gap5">
                            {groupTask[key][Number(taskKey)].sort((a: any, b: any) => this.sortFunction(a, b)).map((task: any) => {
                                return <div className="flex-container-row gap5" style={{ opacity: this.isDisabledTask(task) ? 0.5 : 1, pointerEvents: this.isDisabledTask(task) ? "none" : undefined }}>
                                    <Checkbox checked={this.isCheckedTask(task)} disabled={this.isDisabledTask(task)} onChange={(e, data) => this.handleToogleTask(task, data.checked)} />
                                    {this.taskToString(task)}
                                </div>
                            })}
                        </div>
                    </>
                })}
            </div>
            if (missionType === _msg("assignObjects.noMissionType")) {
                noMissionTypeTask = task;
            } else {
                tasks.push(task);
            }
        })
        tasks.sort((a: any, b: any) => a.props.id.localeCompare(b.props.id));
        noMissionTypeTask && tasks.push(noMissionTypeTask);
        return <div className="flex-container-row gap5 no-margin flex-grow" >
            <div className={"flex-container flex-justify-content-center gap5 " + (this.props.temporaryStorage && this.props.s.settings?.showTemporaryStorageInfo ? "" : "no-display")} style={{ width: "20%" }}>
                <Radio label={_msg("assignObjects.toPsa")} value={TemporaryStorageGroup.TO}
                    checked={this.props.s.temporaryStorageGroup === TemporaryStorageGroup.TO}
                    onClick={() => this.handleTemporaryStrorageGroup(TemporaryStorageGroup.TO)}
                />
                <Radio label={_msg("assignObjects.fromPsa")} value={TemporaryStorageGroup.FROM}
                    checked={this.props.s.temporaryStorageGroup === TemporaryStorageGroup.FROM}
                    onClick={() => this.handleTemporaryStrorageGroup(TemporaryStorageGroup.FROM)}
                />
            </div>
            <Segment className="flex-container no-margin flex-grow gap5">
                <div className="AssignTasksToRessoruce_bold">{_msg("taskAssign.task")}</div>
                {tasks}
                {this.renderAlert()}
                <div className={"flex-container-row flex-center " + ((!this.props.s.temporaryStorage || (this.props.s.temporaryStorage && this.props.s.missionStatusGroup == MissionStatusGroup.EXISTING)) ? "no-display" : "")}>
                    <div>{_msg("assignObjects.numberOfMissionsToBeCreated")}</div>
                    <Input defaultValue={1} value={this.props.s.numberOfMissionsToBeCreated} type="number" min={0} max={9} onChange={(e, data) => this.props.r.setInReduxState({ numberOfMissionsToBeCreated: Number(data.value) })}>
                    </Input>
                </div>
            </Segment >
        </div >
    }

    protected renderCapacity() {
        return <Segment className={"no-margin " + (this.props.s.settings?.showEquipmentTypeCapacityInfo ? "" : "no-display")} style={{ width: "35%" }}>
            <div className="AssignTasksToRessoruce_bold">{_msg("taskAssign.capacity")}</div>
            {this.props.s.equipmentTypeTasks.map((equipmentTypeTask: any) => {
                return <div>{equipmentTypeTask.taskType.name + " " + (this.availableCapacity[equipmentTypeTask.taskType.name]) + " / " + equipmentTypeTask.quantity}</div>
            })}
        </Segment>;
    }

    protected renderTemporaryStorage() {
        return <> {
            this.props.s.settings?.showTemporaryStorageInfo && <div className="flex-container-row" style={{ opacity: !this.props.temporaryStorage ? 0.5 : 1, pointerEvents: !this.props.temporaryStorage ? "none" : undefined }} >
                <div className="flex-shrink-0">{_msg("flight.temporaryStorage") + ": "}</div>
                <AssociationFieldEditor selectedValue={this.props.s.temporaryStorage} fieldDescriptor={new FieldDescriptor()} innerEntityDescriptor={entityDescriptors["Address"]}
                    onChange={(temporaryStorage: any) => this.props.r.setInReduxState({ temporaryStorage: temporaryStorage })} />
            </div>
        } </>;
    }

    protected renderAttachment() {
        return <div className="flex-container-row">
            <div className="flex-shrink-0">{_msg("equipmentResource.attachment") + ": "}</div>
            <AssociationFieldEditor selectedValue={this.props.s.attachment} fieldDescriptor={new FieldDescriptor()} innerEntityDescriptor={entityDescriptors["EquipmentResource"]}
                onChange={(attachment: any) => this.props.r.setInReduxState({ attachment })}
                filter={Filter.createComposed(FilterOperators.forComposedFilter.and, [Filter.create("available", FilterOperators.forBoolean.equals, "TRUE"), Filter.create("equipmentType.isAttachable", FilterOperators.forBoolean.equals, "TRUE")])} />
        </div>;
    }

    protected renderEquipmentResource() {
        return <> {
            this.props.s.settings?.showEquipmentResourceInfo && <div className={this.props.s.missionStatusGroup === MissionStatusGroup.NEW_UNIT ? "no-display" : "flex-container-row"}>
                <div className="flex-shrink-0">{_msg("mission.equipmentResource") + ": "}</div>
                <AssociationFieldEditor selectedValue={this.props.s.equipmentResource} fieldDescriptor={new FieldDescriptor()} innerEntityDescriptor={entityDescriptors["EquipmentResource"]} additionalSearchFields={["name"]}
                    onChange={(equipmentResource: any) => {
                        if (equipmentResource) {
                            this.getEquipmentResource(equipmentResource.id);
                        } else {
                            this.props.r.setInReduxState({ equipmentResource });
                        }
                    }}
                    filter={this.props.s.equipmentType ?
                        Filter.createComposed(FilterOperators.forComposedFilter.and, [Filter.create("equipmentType.id", FilterOperators.forNumber.equals, this.props.s.equipmentType.id), Filter.create("available", FilterOperators.forBoolean.equals, "TRUE")]) : undefined} />
            </div>
        } </>;
    }

    protected renderEquipmentType() {
        return <> {
            this.props.s.settings?.showEquipmentTypeInfo &&
            <div className="flex-container-row" style={{ opacity: this.props.s.missionStatusGroup != MissionStatusGroup.NEW ? 0.5 : 1, pointerEvents: this.props.s.missionStatusGroup != MissionStatusGroup.NEW ? "none" : undefined }} >
                <div className="flex-shrink-0">{_msg("equipmentTypeTask.equipmentType") + ": "}</div>
                <AssociationFieldEditor isDisabled={this.props.s.equipmentResource} selectedValue={this.props.s.equipmentType} fieldDescriptor={new FieldDescriptor()} innerEntityDescriptor={entityDescriptors["EquipmentType"]}
                    onChange={(equipmentType: any) => this.props.r.setInReduxState({ equipmentType })} />
            </div>
        } </>;
    }

    protected renderMissionStatusGroup() {
        return <Segment className="flex-container-row gap5 no-margin flex-center justify-content-space-between">
            <Radio label={_msg("assignObjects.newMission")} value={MissionStatusGroup.NEW}
                checked={this.props.s.missionStatusGroup === MissionStatusGroup.NEW}
                onClick={() => this.handleMissionStatusGroup(MissionStatusGroup.NEW)} />
            <Radio label={_msg("assignObjects.existingMission")} value={MissionStatusGroup.EXISTING}
                disabled={(this.props.s.missionStatusGroup != MissionStatusGroup.EXISTING && !this.props.s.equipmentResource && this.props.s.equipmentTypeTasks.filter((euipmentTypeTask: any) => euipmentTypeTask.taskType.ocupiedPositions > 0).length && (!this.props.s.mission || this.props.s.mission.status === "finished")) ? true : false}
                checked={this.props.s.missionStatusGroup === MissionStatusGroup.EXISTING}
                onClick={() => this.handleMissionStatusGroup(MissionStatusGroup.EXISTING)} />
            {this.props.s.settings?.showAssignUnitInfo && this.props.taskIds && _.isEqual(this.props.taskIds, this.props.initialSelectedTaskIds) &&
                <Radio label={_msg("assignObjects.newUnitMission")} value={MissionStatusGroup.NEW_UNIT}
                    disabled={this.props.s.humanResource?.unitId && this.props.s.enableNewUnitMission ? false : true}
                    checked={this.props.s.missionStatusGroup === MissionStatusGroup.NEW_UNIT}
                    onClick={() => this.handleMissionStatusGroup(MissionStatusGroup.NEW_UNIT)} />}
        </Segment>
    }

    render() {
        return <div className="flex-container gap5 flex-grow less-padding">
            <Segment className="no-margin">
                <Button primary disabled={this.props.s.alerts.length ? true : false} onClick={this.handleOk}>{_msg("general.ok")}</Button>
                <Button onClick={this.props.onCancel}>{_msg("general.cancel")}</Button>
            </Segment>
            <Label size="huge">
                {this.props.s.humanResource ? (this.props.s.humanResource.lastName + ", " + this.props.s.humanResource.firstName + (this.props.s.equipmentResource ? " + " + this.props.s.equipmentResource.identifier : "")) : ""}
            </Label>
            {this.renderMissionStatusGroup()}
            <div className="flex-container-row gap5">
                <div className="flex-container w100 gap5">
                    {this.renderEquipmentType()}
                    {this.renderEquipmentResource()}
                    {this.renderAttachment()}
                    {this.renderTemporaryStorage()}
                    <div className={"flex-container-row flex-center " + (this.props.s.missionStatusGroup === MissionStatusGroup.NEW_UNIT ? "no-display" : "")} >
                        <div>{_msg("mission.comment")}</div>
                        <TextArea rows={2} className="flex-grow" value={this.props.s.comment} onChange={(_, data) => {
                            this.props.r.setInReduxState({ comment: data.value as string | undefined });
                        }}>
                        </TextArea>
                    </div>
                </div>
                {this.renderCapacity()}
            </div>           
            <div className="flex-container flex-grow" style={{ minHeight: "30%" }}>
                {this.props.s.missionStatusGroup != MissionStatusGroup.NEW_UNIT && this.renderTask()}
            </div>
        </div>

    }
}

export const AssignTasksToResourcePageRRC = ReduxReusableComponents.connectRRC(AssignTasksToResourcePageState, AssignTasksToResourcePageReducers, AssignTasksToResourcePage);

export const assignTasksToResourcePageUrl = "/assignTasksToResource";
export const assignTasksToResourcePageRoute = (computeRoute: (props: PrivateRouteProps) => JSX.Element) =>
    <PrivateRoute key="assignTasksToResourcePage"
        path={assignTasksToResourcePageUrl}
        render={(props) => {
            return <AssignTasksToResourcePageRRC id="assignTasksToResource" />
        }}
        computeRoute={computeRoute} />

export const assignTasksToResourcePageMenuEntry = () => {
    return {
        id: "assignTasksToResourcePage",
        content: _msg("AssignTasksToResourcePage.title"),
        to: assignTasksToResourcePageUrl, exact: true,
    }
};

// onAssign, onError, onCancel - TODO: add callbacks from flex
export const assignTasksToResourcePageFlexUrl = "/assignTasksToResourceFlex";
export const assignTasksToResourcePageFlexRoute = (computeRoute: (props: PrivateRouteProps) => JSX.Element) =>
    <PrivateRoute key="assignTasksToResourcePageFlex"
        path={assignTasksToResourcePageFlexUrl}
        render={(props) => {
            let params = {} as any;
            for (const [key, value] of new URLSearchParams(props.location?.search).entries()) {
                if (key === "taskIds" || key === "initialSelectedTaskIds") {
                    params[key] = value.split(",");
                } else {
                    params[key] = value;
                }
            }
            return <AssignTasksToResourcePageRRC id="assignTasksToResourceFlex" {...params} />
        }}
        computeRoute={computeRoute} />
