import { Utils } from "@crispico/foundation-react";
import { Color, XopsColorRegistry } from "pages/XopsColor/XopsColorRegistry";
import { Icon } from "semantic-ui-react";
import { AbstractGantt, ITEM_HEIGHT } from "../AbstractGantt";
import { GanttTasks } from "../GanttTasks";
import { GANTT_TYPES, IconItem } from "../ganttTypeRenderers";
import { AbstractTypeRenderer } from "./AbstractTypeRenderer";
import { FlightRenderer } from "./FlightRenderer";
import { MissionRenderer } from "./MissionRenderer";
import { MissionStatus, TaskNames } from "./constants";

export enum InfoTaskMode {
    ARR_DPT = 0,
    ARR_DPT_IMM_P = 1
}

type TaskData = {
    task: any,
    oag: any;
}
const iconsMap: { [key: string]: IconItem; } = {
    comment: {
        color: "green",
        name: "comments",
        tooltip: ""
    },
    passengerWithDisabilities: {
        name: "wheelchair"
    }
}

export class TaskRenderer extends AbstractTypeRenderer {

    protected getEntityName() {
        return "Task";
    }

    private getMissionStatusColor(data: TaskData) {
        const mission = data.task ? data.task.mission : (data.oag ? data.oag.mission : null);
        if (mission) {
            return MissionRenderer.getMissionColor(mission, 0.6);
        } else {
            return "grey";
        }
    };

    private getMainColor(data: TaskData) {
        let color = data.task.taskType ? data.task.taskType.color : XopsColorRegistry.INSTANCE.get(Color.OBJECT_SPECIAL_COLOR);
        return color ? Utils.getRGBAColor(color, 0.6) : undefined;
    };

    private getBorderColor(data: TaskData) {
        const missionType = data.task.missionType;
        if (missionType && missionType.color) {
            return missionType.color;
        }
        return "grey";
    };

    static getIcons(task: any) {
        if (!task) {
            return [];
        }
        let icons: IconItem[] = [];

        task.comment && icons.push(iconsMap.comment);
        task.taskGroup.passengerWithDisabilities && icons.push(iconsMap.passengerWithDisabilities);

        return icons;
    }

    getLabel(task: any) {
        return undefined;
    };

    protected canDrop(source: any, destination: any) {
        const { entities } = this.props.gantt.props;
        if (source.type === GANTT_TYPES.FLIGHT || source.type === GANTT_TYPES.TASK) {
            return false;
        } else if (source.type === GANTT_TYPES.MISSION) {
            const mission = AbstractGantt.findOne("Mission2", "id", source.entityId, entities);
            return mission.inactivityType ? false : true;
        }
        return true;
    }

    protected onDrop(source: any, destination: any) {
        let humanResourceId, missionId;
        const { entities } = this.props.gantt.props;
        if (source.type === GANTT_TYPES.HUMAN_RESOURCE) {
            humanResourceId = source.entityId;
            missionId = AbstractGantt.find("Mission2", "humanResourceId", humanResourceId, entities).filter(m => m.status != MissionStatus.MISSION_STATUS_FINISHED && !m.inactivityType).sort((m1: any, m2: any) => {
                return m2.endTime - m1.endTime;
            })?.[0]?.id; // last mission not finished without inactivityType
        } else if (source.type === GANTT_TYPES.MISSION) {
            missionId = source.entityId;
            humanResourceId = AbstractGantt.findOne("Mission2", "id", missionId, entities)?.humanResource?.id;
        }
        const taskIds = [destination.entityId];
        this.openAssignTaskToResource({ humanResourceId, missionId, taskIds });
    }

    protected renderTaskMode(data: TaskData) {
        const task = data.task;
        const mission = task?.mission ? task?.mission : data.oag?.mission;
        const arvName = task.taskGroup?.departure ? task.taskGroup?.rotationFlight?.name : task.taskGroup?.name;
        const depName = task.taskGroup?.departure ? task.taskGroup?.name : task.taskGroup?.rotationFlight?.name;
        const planeIdentifier = task.taskGroup?.planeIdentifier;
        const parking = task.taskGroup?.parking?.name;
        let infoTask = "";
        if ((this.props.gantt as GanttTasks).props.s.infoTaskMode === InfoTaskMode.ARR_DPT) {
            infoTask = (arvName || '-') + "/" + (depName || '-');
        }
        if ((this.props.gantt as GanttTasks).props.s.infoTaskMode === InfoTaskMode.ARR_DPT_IMM_P) {
            infoTask = (arvName || '-') + "/" + (depName || '-') + (planeIdentifier ? (' - ' + planeIdentifier) : '') + ' ' + (parking ? (' - ' + parking) : '');
        }
        return <div key={this.props.item.entityId} className="flex-container-row flex-center TaskGanttRenderer_TaskMode"
            style={{
                height: ITEM_HEIGHT - 2, // CC: I don't like this; when time, lets investigate another way to add gap between items
                backgroundColor: this.getMainColor(data),
                border: `1px solid ${mission?.missionType?.color || "grey"}`,
            }}>
            {!(this.props.gantt as GanttTasks).props.s.hideAssignedTasks &&
                <Icon name="circle" size="small" style={{ color: this.getMissionStatusColor(data) }} />
            }
            {FlightRenderer.getIcons(task.taskGroup).map((icon, idx) => <Icon size="small" key={"flight" + idx} name={icon.name} color={icon.color} />)}
            {TaskRenderer.getIcons(task)?.map((icon, idx) => <Icon key={"task" + idx} size="small" name={icon.name} color={icon.color} />)}
            <div style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>{infoTask}</div>
        </div>;
    }

    protected renderContent() {
        const { entities } = this.props.gantt.props;
        const data: TaskData = {
            task: AbstractGantt.findOne("Task", "id", this.props.item.entityId, entities),
            oag: AbstractGantt.findOne("ObjectActionGroup", "object.id", this.props.item.entityId, entities)
        }
        if (!data.task) {
            return <></>;
        }
        // TODO: in the future we'll add some icons to display alerts
        if ((this.props.gantt as GanttTasks).isTaskMode()) {
            return this.renderTaskMode(data);
        }
        // const isLoadMode = task && task.loadMode && task.loadMode !== "NONE";

        return <div key={this.props.item.entityId} className="flex-container-row flex-center"
            style={{
                height: ITEM_HEIGHT - 4, // CC: I don't like this; when time, lets investigate another way to add gap between items
                backgroundColor: this.getMainColor(data),
                border: `1px solid ${this.getBorderColor(data)}`,
                borderRadius: '3px',
            }}>
            <Icon name="circle" size="small" style={{ color: this.getMissionStatusColor(data) }} />
            {/* {isLoadMode === true ? <span style={optionalCircleStyle}>
                    A
                </span> : <></>} */}
        </div>
    }
}
