import { FilterOperators } from "@crispico/foundation-gwt-js";
import { apolloClient, Optional, TestUtils, Utils } from "@crispico/foundation-react";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { InitializationsForClient, MissionsAwaitingForDriversPageRendererMode } from "app";
import moment from "moment";
import React from "react";
import { Button, Header, Icon, Message, Segment } from "semantic-ui-react";
import { FLIGHTS_ASSIGNEMENTS_PAGE_CREATE_MISSION, MISSIONS_AWAITING_FOR_DRIVERS_PAGE_ASSIGN_HUMAN_RESOURCE_TO_MISSION, MISSIONS_AWAITING_FOR_DRIVERS_PAGE_LOAD_MISSIONS_QUERY, MISSIONS_AWAITING_FOR_DRIVERS_PAGE_LOAD_SETTINGS, MISSIONS_AWAITING_FOR_DRIVERS_PAGE_LOAD_TASKS_QUERY } from "../flight/flightsAssignmentsPage/queries";
import { MissionsAwaitingForDriversPage_Mission } from "apollo-gen/MissionsAwaitingForDriversPage_Mission";
import { MissionsAwaitingForDriversPage_loadMissionsQuery, MissionsAwaitingForDriversPage_loadMissionsQueryVariables, MissionsAwaitingForDriversPage_loadMissionsQuery_mission2Service_findByFilter_results_objectActionGroups_object_taskGroup, MissionsAwaitingForDriversPage_loadMissionsQuery_mission2Service_findByFilter_results_startAddress, MissionsAwaitingForDriversPage_loadMissionsQuery_mission2Service_findByFilter_results_type } from "apollo-gen/MissionsAwaitingForDriversPage_loadMissionsQuery";
import { missionsAwaitingForDriversPageMenuEntry } from "./Mission2EntityDescriptor";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import { MissionsAwaitingForDriversPage_loadSettingsQuery, MissionsAwaitingForDriversPage_loadSettingsQuery_mission2Service_missionsAwaitingForDriversPageSettings } from "apollo-gen/MissionsAwaitingForDriversPage_loadSettingsQuery";
import FlightFilterComponent, { FlightFilterComponentRRC, FlightFilters } from "pages/flight/FlightFilterComponent";
import _ from "lodash";
import { MissionsAwaitingForDriversPage_loadTasksQuery, MissionsAwaitingForDriversPage_loadTasksQueryVariables, MissionsAwaitingForDriversPage_loadTasksQuery_taskService_findByFilter_results } from "apollo-gen/MissionsAwaitingForDriversPage_loadTasksQuery";

type RenderData = {
    headerInfo: string;
    headerFlightsInfo: FlightInfo[];
    displayWaitingInfo?: boolean;
}

type FlightInfo = {
    departure: Optional<boolean>;
    headerInfo: string;
}

interface MissionsAwaitingForDriversPageSettings extends MissionsAwaitingForDriversPage_loadSettingsQuery_mission2Service_missionsAwaitingForDriversPageSettings {}
interface MissionType extends MissionsAwaitingForDriversPage_loadMissionsQuery_mission2Service_findByFilter_results_type {}
interface Address extends MissionsAwaitingForDriversPage_loadMissionsQuery_mission2Service_findByFilter_results_startAddress {}
interface Flight extends MissionsAwaitingForDriversPage_loadMissionsQuery_mission2Service_findByFilter_results_objectActionGroups_object_taskGroup {}
interface Task extends MissionsAwaitingForDriversPage_loadTasksQuery_taskService_findByFilter_results {}

type ResultData = {
    id: number,
    missionType: MissionType | undefined,
    flights: Flight[],
    startAddresses: Address[],
    endAddresses: Address[],
    startTime: number | undefined,
    endTime: number | undefined
    creationDate: number | undefined,
    comment: string | undefined,
}

export class MissionsAwaitingForDriversPageState extends State {
    missions: { [key: string]: MissionsAwaitingForDriversPage_Mission } = {};
    resultEntities: { [key: string]: ResultData } = {};
    data: { [key: string]: RenderData } = {};
    missionsAwaitingForDriversPageSettings: Optional<MissionsAwaitingForDriversPageSettings> = undefined;
    loading?: boolean;
    displayedMissions?: number[];
    filterValuesChanged: boolean = false;
}

export class MissionsAwaitingForDriversPageReducers<S extends MissionsAwaitingForDriversPageState = MissionsAwaitingForDriversPageState> extends Reducers<S> {

    update(resultEntities: { [key: string]: ResultData }, data: { [key: string]: RenderData }, filtersValue: FlightFilters) {
        this.s.resultEntities = resultEntities;
        this.s.data = data;
        this.changeDisplayedEntities(filtersValue);
        this.s.loading = false;
    }

    changeDisplayedEntities(filters: FlightFilters) {
        let displayedMissions: { id: number, date: any }[] = [];
        Object.keys(this.s.resultEntities).forEach(id => {
            const entity = this.s.resultEntities[id];
            let displayMission = true;
            const filterResult = entity.flights.length > 0 ? entity.flights.findIndex(flight => {
                let foundBySearchBar = true;
                let foundByFlightType = true;
                let foundByDepartureValue = true;
                let foundByLastKnownDateType = true;
                if (!Utils.isNullOrEmpty(filters.nameOrPlaneIdentifierValue)) {
                    foundBySearchBar = ((flight.name?.toUpperCase().search(filters.nameOrPlaneIdentifierValue!.toUpperCase()) !== -1)
                        || (flight.planeIdentifier?.toUpperCase().search(filters.nameOrPlaneIdentifierValue!.toUpperCase()) !== -1)
                        || (flight.parking?.name?.toUpperCase().search(filters.nameOrPlaneIdentifierValue!.toUpperCase()) !== -1));
                }
                if (!Utils.isNullOrEmpty(filters.departureValue)) {
                    foundByDepartureValue = filters.departureValue === flight.departure;
                }
                if (!Utils.isNullOrEmpty(filters.flightTypeValue) && flight.flightType) {
                    if (filters.flightTypeValue !== undefined && filters.flightTypeValue !== flight.flightType) {
                        foundByFlightType = false;
                    }
                }
                if (!Utils.isNullOrEmpty(filters.lastKnownDateTypeValue) && flight.lastKnownDateType) {
                    if (filters.lastKnownDateTypeValue !== undefined && filters.lastKnownDateTypeValue !== flight.lastKnownDateType) {
                        foundByFlightType = false;
                    }
                }
                return foundBySearchBar && foundByFlightType && foundByDepartureValue && foundByLastKnownDateType;
            }) : 0;
            if (filterResult === -1) {
                displayMission = false;
            }
            if (displayMission) {
                displayedMissions.push({ id: Number(id), date: entity.startTime });
            }
        })
        this.s.displayedMissions = displayedMissions.sort((a: any, b: any) => new Date(a.date).getTime() < new Date(b.date).getTime() ? -1 : 1).map(f => f.id);
    }
}

export class MissionsAwaitingForDriversPage extends React.Component<RRCProps<MissionsAwaitingForDriversPageState, MissionsAwaitingForDriversPageReducers>> {
    private flightFilterRef = React.createRef<FlightFilterComponent>();
    private timer: number | undefined = undefined;
    private static REFRESH_RATE: number = 5000; // 5s

    protected async loadPageSettings() {
        const result = (await apolloClient.query<MissionsAwaitingForDriversPage_loadSettingsQuery>({
            query: MISSIONS_AWAITING_FOR_DRIVERS_PAGE_LOAD_SETTINGS,
        })).data.mission2Service_missionsAwaitingForDriversPageSettings;

        this.props.r.setInReduxState({ missionsAwaitingForDriversPageSettings: result });
    }

    protected async loadData() {
        if (this.props.s.loading) {
            return;
        }
        this.props.r.setInReduxState({ loading: true });
        const pageSettings = this.props.s.missionsAwaitingForDriversPageSettings;
        if (pageSettings && pageSettings.workWithTasks) {
            await this.loadTasks();
        } else {
            await this.loadMissions();
        }
        this.props.r.setInReduxState({ loading: false });
    }

    protected prepareFindByFilterParams(workWithTasks: boolean): FindByFilterParams {
        const filters: Filter[] = [];
        if (workWithTasks) {
            filters.push(
                Filter.create("taskGroup.showFlightInGantt", FilterOperators.forBoolean.equals, "true"),
                Filter.create("taskGroup.date", FilterOperators.forDate.greaterThan, moment(Utils.now()).add(-1, "hours").toISOString()),
                Filter.create("taskGroup.date", FilterOperators.forDate.lessThan, moment(Utils.now()).add(12, "hours").toISOString()));
        } else {
            filters.push(
                Filter.create("status", FilterOperators.forString.equals, "new"),
                Filter.create("requestStatus", FilterOperators.forString.isEmpty, ""),
                Filter.create("startTime", FilterOperators.forDate.greaterThan, moment(Utils.now()).add(-1, "hours").toISOString()),
                Filter.create("startTime", FilterOperators.forDate.lessThan, moment(Utils.now()).add(12, "hours").toISOString()),
                Filter.create("humanResource", FilterOperators.forEntityManyToOne.isEmpty, ""));
        }

        // Be aware, if you change workWithTasks setting needs to update filters too
        const pageSettings = this.props.s.missionsAwaitingForDriversPageSettings;
        if (!Utils.isNullOrEmpty(pageSettings) && !Utils.isNullOrEmpty(pageSettings!.filters)) {
            filters.push(JSON.parse(pageSettings!.filters!));
        }

        // TD: found this filters but were not used in the query, not sure if we need them anymore
        // const { tempSettingsXops, currentOrganization } = AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().initializationsForClient as InitializationsForClient;
        // const organizationSettings = tempSettingsXops.tempUnitSettings.find(s => s.organizationId === currentOrganization?.id);
        // const missionTypes = organizationSettings?.missionTypesToDisplay;
        // if (missionTypes && missionTypes.length > 0) {
        //     let missionTypesFilter = Filter.createComposed(FilterOperators.forComposedFilter.or, []);
        //     for (let i = 0; i < missionTypes.length; i++) {
        //         missionTypesFilter.filters?.push(Filter.create(workWithTasks ? "missionType" : "type", FilterOperators.forEntityManyToOne.equals, missionTypes[i].id.toString()));
        //     }
        //     filters.push(missionTypesFilter);
        // }

        return FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.and, filters)).sorts([{ field: workWithTasks ? "taskGroup.date" : "startTime", direction: "ASC" }]);
    }

    protected processResultData(result: ResultData[]) {
        const { tempSettingsXops } = AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().initializationsForClient as InitializationsForClient;
        var newResultEntities: { [key: string]: ResultData } = {};
        var newRenderData: { [key: string]: RenderData } = {};
        if (Utils.isNullOrEmpty(result)){
            return;
        }

        result.forEach(resultEntity => {
            newResultEntities[resultEntity.id] = resultEntity;
            let rendererMode = MissionsAwaitingForDriversPageRendererMode.DEFAULT;
            if (resultEntity.missionType?.id) {
                const mTypeSettings = tempSettingsXops.tempMissionTypeSettings.find(s => s.missionType === resultEntity.missionType?.id);
                if (mTypeSettings) {
                    rendererMode = mTypeSettings.missionsAwaitingForDriversPageRendererMode;
                }
            }

            let headerInfo = "";
            let headerFlightsInfo: FlightInfo[] = [];
            if (rendererMode === MissionsAwaitingForDriversPageRendererMode.BAGGAGE_LAST_MINUTE) {
                let conveyorEjects: string[] = [];
                resultEntity.flights.forEach(flight => {
                    const fj = tempSettingsXops.tempFlightConveyorEjectSettings.find(fj => fj.parkings.find(p => p.id === flight.parking?.id));
                    if (fj) {
                        conveyorEjects.push(fj.conveyorEject);
                    }
                });
                headerInfo = _msg("FlightsAssignmentsPage.conveyorEject") + " " + (!Utils.isNullOrEmpty(conveyorEjects) ? conveyorEjects?.join(" ") : _msg("general.unknown"));
            } else {
                // CC: we should have some type of mission informations to display; not with all these ifs & strings
                // maybe using the FD renderers it will look better?
                resultEntity.flights.forEach(flight => {
                    headerFlightsInfo.push({
                        departure: flight.departure,
                        headerInfo: "[" + (flight.departure ? _msg("Flight.departure.true.label") : _msg("Flight.departure.false.label")) + "] " + flight.name + " "
                            + (flight.date ? moment(flight.date).format(Utils.timeFormat) : '') + " " + flight.parking?.name + " " + flight.planeIdentifier + " "
                    });
                });

                if (resultEntity.missionType?.name) {
                    headerInfo = resultEntity.missionType!.name + " ";
                }
                headerInfo += "["
                if (!Utils.isNullOrEmpty(resultEntity.startAddresses)) {
                    headerInfo += resultEntity.startAddresses.map(address => address.name).join(" ") + " " + _msg("general.at", moment(resultEntity.startTime).format(Utils.timeFormat));
                } else {
                    headerInfo += moment(resultEntity.startTime).format(Utils.timeFormat);
                }
                headerInfo += " - ";
                if (!Utils.isNullOrEmpty(resultEntity.endAddresses)) {
                    headerInfo += resultEntity.endAddresses.map(address => address.name).join(" ") + " " + _msg("general.at", moment(resultEntity.endTime).format(Utils.timeFormat));
                } else {
                    headerInfo += moment(resultEntity.endTime).format(Utils.timeFormat);
                }
                headerInfo += "]";
            }
            newRenderData[resultEntity.id] = {
                headerInfo: headerInfo,
                headerFlightsInfo: headerFlightsInfo,
                displayWaitingInfo: rendererMode === MissionsAwaitingForDriversPageRendererMode.BAGGAGE_LAST_MINUTE
            }
        })

        const filtersValue: FlightFilters = this.flightFilterRef.current?.props.s.filters!;
        this.props.r.update(newResultEntities, newRenderData, filtersValue);
    }

    // TODO temp method, needs to activate newOMS to remove this code
    protected calculateStartEndTimeForTask(task: Task) {
        const date: number = task.taskGroup.date;
        const duration = !Utils.isNullOrEmpty(task.duration) && task.duration! > 0 ? task.duration : 15;
        if (!Utils.isNullOrEmpty(task.offset) && task.offset! != 0) {
            task.startTime = moment(date).add(task.offset, "minutes").valueOf();
        } else {
            task.startTime = date;
        }
        task.endTime = moment(task.startTime).add(duration, "minutes").valueOf();
    }

    protected async loadTasks() {
        const result = (await apolloClient.query<MissionsAwaitingForDriversPage_loadTasksQuery, MissionsAwaitingForDriversPage_loadTasksQueryVariables>({
            query: MISSIONS_AWAITING_FOR_DRIVERS_PAGE_LOAD_TASKS_QUERY,
            variables: this.prepareFindByFilterParams(true),
            context: { showSpinner: false }
        })).data.taskService_findByFilter?.results;


        if (Utils.isNullOrEmpty(result)) {
            return;
        }

        // TODO take just unassigned tasks, needs to filter here becase oneToMany filter doesn't works on server
        const resultData: ResultData[] = result!.filter(t => Utils.isNullOrEmpty(t.objectActionGroups) && !Utils.isNullOrEmpty(t.taskGroup)).map(t => {
            this.calculateStartEndTimeForTask(t);
            return {
                id: t.id,
                missionType: t.missionType,
                startTime: t.startTime,
                endTime: t.endTime,
                flights: Utils.isNullOrEmpty(t.taskGroup) ? [] : [t.taskGroup],
                startAddresses: Utils.isNullOrEmpty(t.startAddress) ? [] : [t.startAddress],
                endAddresses: Utils.isNullOrEmpty(t.endAddress) ? [] : [t.endAddress],
            } as ResultData;
        });

        this.processResultData(resultData);
    }

    protected async loadMissions() {
        const result = (await apolloClient.query<MissionsAwaitingForDriversPage_loadMissionsQuery, MissionsAwaitingForDriversPage_loadMissionsQueryVariables>({
            query: MISSIONS_AWAITING_FOR_DRIVERS_PAGE_LOAD_MISSIONS_QUERY,
            variables: this.prepareFindByFilterParams(false),
            context: { showSpinner: false }
        })).data.mission2Service_findByFilter?.results;

        if (Utils.isNullOrEmpty(result)) {
            return;
        }

        const resultData: ResultData[] = result!.map(m => {
            return {
                id: m.id,
                missionType: m.type,
                startTime: m.startTime,
                endTime: m.endTime,
                flights: Utils.isNullOrEmpty(m.objectActionGroups) ? [] : _.uniqBy(m.objectActionGroups!.map(oag => oag.object!.taskGroup!), "id"),
                startAddresses: Utils.isNullOrEmpty(m.objectActionGroups) ? m.isSpecial && !Utils.isNullOrEmpty(m.startAddress) ? [m.startAddress] : [] : m.objectActionGroups!.map(oag => oag!.object.startAddress),
                endAddresses: Utils.isNullOrEmpty(m.objectActionGroups) ? m.isSpecial && !Utils.isNullOrEmpty(m.endAddress) ? [m.endAddress] : [] : m.objectActionGroups!.map(oag => oag!.object.endAddress),
                creationDate: m.creationDate,
                comment: (m.comment || '') + (m.comment2 ? (' ' + m.comment2) : '')
            } as ResultData;
        });

        this.processResultData(resultData);
    }

    protected async assignHumanResourceToMission(id: number) {
        const humanResource = (AppMetaTempGlobals.appMetaInstance as any).getCurrentHumanResource();
        const pageSettings = this.props.s.missionsAwaitingForDriversPageSettings;
        if (pageSettings && pageSettings.workWithTasks) {
            await apolloClient.mutate({ mutation: FLIGHTS_ASSIGNEMENTS_PAGE_CREATE_MISSION, variables: { flightId: this.props.s.resultEntities[id].flights[0].id, taskId: id, humanResourceId: humanResource?.id } })
        } else {
            await apolloClient.mutate({ mutation: MISSIONS_AWAITING_FOR_DRIVERS_PAGE_ASSIGN_HUMAN_RESOURCE_TO_MISSION, variables: { missionId: id, humanResourceId: humanResource?.id } });
        }
        
        humanResource ? AppMetaTempGlobals.history.push("/xops-mobile/missions") : this.loadData();
    }

    private startTimer() {
        this.timer = window.setTimeout(() => {
            this.loadData();
            this.startTimer();
        }, MissionsAwaitingForDriversPage.REFRESH_RATE);
    }

    private stopTimer() {
        clearTimeout(this.timer);
    }

    async componentDidMount() {
        if (TestUtils.storybookMode) {
            return;
        }
        await this.loadPageSettings();
        this.loadData();
        this.startTimer();
    }

    componentDidUpdate(prevProps: RRCProps<MissionsAwaitingForDriversPageState, MissionsAwaitingForDriversPageReducers>) {
        this.componentDidUpdateInternal(prevProps);
    }

    componentDidUpdateInternal(prevProps?: RRCProps<MissionsAwaitingForDriversPageState, MissionsAwaitingForDriversPageReducers>) {
        if (!prevProps || (this.props.s.filterValuesChanged !== prevProps.s.filterValuesChanged && this.props.s.filterValuesChanged === true)) {
            this.props.r.changeDisplayedEntities(this.flightFilterRef.current?.props.s.filters!);
            this.props.r.setInReduxState({ filterValuesChanged: false });
        }
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    protected renderMission(id: any) {
        const rawEntity = this.props.s.resultEntities[id];
        const renderData = this.props.s.data[id];
        const headerInfoSplit = renderData.headerInfo.split("[");
        const headerInfo = headerInfoSplit[0].trim();
        const entityInfo = headerInfoSplit[1] ? "[" + headerInfoSplit[1] : undefined;
        const missionTypeColor = rawEntity.missionType?.color && !renderData.displayWaitingInfo ? rawEntity.missionType?.color : undefined;
        var missionInfoElements: JSX.Element[] = Utils.isNullOrEmpty(renderData.headerFlightsInfo) ? [<Header>{entityInfo}</Header>] : [];
        renderData.headerFlightsInfo.forEach((flightInfo: any, index: number) => {
            const missionInfoElement = entityInfo ? <div key={index} className="flex-container-row flex-center small-margin-bottom" >
                <Header size="tiny" className="small-margin-bottom">
                    <Icon className="tiny-margin-right MissionsAwaitingForDriversPage_plane_icon" name="plane" style={{ transform: "rotate(" + (flightInfo.departure ? -45 : 45) + "deg)" + (flightInfo.departure ? "" : "translate(25%, -25%)") }} />
                    {flightInfo.headerInfo + entityInfo}
                </Header>
            </div> : <></>
            missionInfoElements.push(missionInfoElement);
        });

        const duration = moment.duration(moment(rawEntity.creationDate).diff(Utils.now()));
        return <Message key={id} color="red" className="less-margin-top-bottom less-padding MissionsAwaitingForDriversPage_message">
            <Message.Header>
                <div className="flex-container-row flex-center flex-grow small-margin-bottom">
                    <div className="flex-container-row flex-center flex-grow">
                        {headerInfo ? <div className="flex-container-row flex-center MissionsAwaitingForDriversPage_header" style={{ color: missionTypeColor, backgroundColor: !renderData.displayWaitingInfo ? 'lightgray' : undefined }}>
                            {!renderData.displayWaitingInfo ? <span style={{ float: 'left' }}><Icon name="exclamation circle" className="no-margin" /></span> : null}
                            <Header className="no-margin" size="tiny" style={{ color: missionTypeColor }}>{headerInfo}</Header>
                        </div> : missionInfoElements}
                    </div>
                    {renderData.displayWaitingInfo ? <div className="flex-container float-right MissionsAwaitingForDriversPage_message_right">
                        <Header as='h6' style={{ color: "var(--textColor)" }}>{_msg("MissionsAwaitingForDriversPage.waitingFor")}</Header>
                        <span>{duration.humanize()}</span>
                        <Header as='h6' style={{ color: "var(--textColor)" }}>{_msg("MissionsAwaitingForDriversPage.from", moment(rawEntity.creationDate).format(Utils.timeFormat))}</Header>
                    </div> : null}
                </div>
                {headerInfo ? missionInfoElements : null}
            </Message.Header>
            {rawEntity.comment && <Message.List>
                <Message.Item>{rawEntity.comment}</Message.Item>
            </Message.List>}
            <Button size="small" className="MissionsAwaitingForDriversPage_activate_btn" compact primary onClick={() => this.assignHumanResourceToMission(id)}>{_msg("general.activate")}</Button>
        </Message>
    }

    render() {
        const { loading, displayedMissions, missionsAwaitingForDriversPageSettings } = this.props.s;
        return <div className="flex-container flex-grow less-padding" >
            <Header as="h3" className="small-margin-top no-margin"><Icon name={missionsAwaitingForDriversPageMenuEntry().icon as SemanticICONS} />
                {missionsAwaitingForDriversPageMenuEntry().content}</Header>
            {missionsAwaitingForDriversPageSettings ? <Segment className="less-padding no-margin flex-container-row flex-center flex-wrap gap5">
                <Button key="refresh" className="small-margin-left" positive disabled={loading} onClick={() => this.loadData()} icon={<Icon loading={loading} name={loading ? "spinner" : "refresh"} />} />
                <FlightFilterComponentRRC
                    id="flightFilterComponent"
                    ref={this.flightFilterRef}
                    showDepartureFilter={missionsAwaitingForDriversPageSettings.showDepartureFilter || false}
                    showFlightTypeFilter={missionsAwaitingForDriversPageSettings.showFlightTypeFilter || false}
                    showLastKnownDateTypeFilter={missionsAwaitingForDriversPageSettings.showLastKnownDateTypeFilter || false}
                    onFilterChange={() => this.props.r.setInReduxState({ filterValuesChanged: true })} />
            </Segment> : null}
            <Segment className="flex-container flex-grow less-padding less-margin-top-bottom flex-center">
                {displayedMissions?.map(id => this.renderMission(id))}
            </Segment>
        </div>;
    }
}

export const MissionsAwaitingForDriversPageHOC = ReduxReusableComponents.connectRRC(MissionsAwaitingForDriversPageState, MissionsAwaitingForDriversPageReducers, MissionsAwaitingForDriversPage);


