import React from "react";
import { PrivateRoute, PrivateRouteProps, apolloClientHolder } from "@crispico/foundation-react";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { Utils } from '@crispico/foundation-react';
import { Header, Table, Segment } from "semantic-ui-react";
import { GET_MISSION, GET_TASK } from "./queries";
import moment from "moment";

interface EventValidationInfo {
    [key: string]: any;
    name: string;
    validatedAt: string;
    expectedBefore: string;
    delay: string;
    color: string;
    flightName: string;
    objectName: string;
}

interface EventMonitoringRule {
    event: string;
    offset: string;
    flight: string;
    e: string;
    o: string;
    f:string;
}

type MissionEventValidationPageProps = {
    entityName: string;
    entityId: number;
}

export class MissionEventValidationPageState extends State {
    data: EventValidationInfo[] = [];
    actualNumberOfEvents: number = 0;
    expectedNumberOfEvents: number = 0;
}

class Pair<A, B> {
    public a: any;
    public b: any;

    constructor(a: A, b: B) {
        this.a = a;
        this.b = b;
    }
}

export class MissionEventValidationPageReducers<S extends MissionEventValidationPageState = MissionEventValidationPageState> extends Reducers<S> {
}

type Props = RRCProps<MissionEventValidationPageState, MissionEventValidationPageReducers> & MissionEventValidationPageProps;

export class MissionEventValidationPage extends React.Component<Props> {

    async loadData(entityName: string, entityId: number) {
        if (!entityId) {
            return
        }
        let data: EventValidationInfo[] = [];
        let actualNumberOfEvents: number = 0;
        let expectedNumberOfEvents: number = 0;
        let entity: any;

        if (entityName === "Mission2") {
            entity = (await apolloClientHolder.apolloClient.query({
                query: GET_MISSION,
                variables: { id: entityId }
            })).data.mission2Service_findById;
        } else if (entityName === "Task") {
            entity = (await apolloClientHolder.apolloClient.query({
                query: GET_TASK,
                variables: { id: entityId }
            })).data.taskService_findById;
        }

        if (!entity) {
            return;
        }

        actualNumberOfEvents = entity.eventMonitoringActualNumberOfEvents || 0;
        expectedNumberOfEvents = entity.eventMonitoringExpectedNumberOfEvents || 0;

        for (let oag of entity.objectActionGroup) {
            if (!oag.object.eventMonitoringRulesJson) {
                continue;
            }
            MissionEventValidationPage.parseEventMonitoringRulesJson(oag.object.eventMonitoringRulesJson)
                .forEach((value) => data.push(this.processEvent(value.a, value.b[0].a, oag)));
        };
        data = data.sort((a, b) => a.flightName.localeCompare(b.flightName) || a.objectName.localeCompare(b.objectName) || a.expectedBefore.localeCompare(b.expectedBefore));
        this.props.r.setInReduxState({ actualNumberOfEvents, expectedNumberOfEvents, data });
    }

    processEvent(eventKey: string, eventValue: number, oag: any) {
        const missionEvent = oag.mission.events.find((x: any) => x.descriptorType === eventKey && x.oagId === oag.id);
        const creationDate = missionEvent ? new Date(missionEvent.creationdate) : undefined;
        const referenceTime = new Date(oag.object.taskGroup.date);
        const validatedAt = creationDate ? moment(creationDate).format(Utils.timeWithSecFormat) : null;
        referenceTime.setMinutes(referenceTime.getMinutes() + eventValue);
        const delay = this.getDelay(referenceTime, creationDate);

        return {
            name: eventKey, validatedAt, expectedBefore: moment(referenceTime).format(Utils.timeWithSecFormat), delay: this.getFormattedDelay(delay),
            color: this.getColor(delay, creationDate), flightName: oag.object.taskGroup.name, objectName: oag.object.name
        } as EventValidationInfo;
    }

    getColor(delay: number, creationDate?: Date) {
        let color: string = "black";
        if (!creationDate && delay > 0) {
            color = "red";
        }
        else if (creationDate && delay > 0) {
            color = "orange";
        }
        return color;
    }

    getDelay(referenceTime: Date, creationDate?: Date) {
        let delay: number = 0;

        if (!creationDate) {
            delay = Utils.now().valueOf() - referenceTime.valueOf();
        } else {
            delay = creationDate.valueOf() - referenceTime.valueOf();
        }

        return delay;
    }

    static parseEventMonitoringRulesJson(eventMonitoringRulesJson: string) {
        let eventsAndOffsets: Pair<string, Pair<number, string | undefined>[]>[] = [];
        if (!eventMonitoringRulesJson || eventMonitoringRulesJson.trim().length === 0) {
            return eventsAndOffsets;
        }

        if (eventMonitoringRulesJson.startsWith("[")) {
            let eventMonitoringRules: EventMonitoringRule[] = [];

            eventMonitoringRules = JSON.parse(eventMonitoringRulesJson.toLowerCase());

            eventMonitoringRules.forEach(item => {
                const event = (item.e || item.event).toUpperCase().trim();
                const flightType = (item.f || item.flight)?.toUpperCase().trim();
                if (flightType && "A".localeCompare(flightType) != 0 && "D".localeCompare(flightType) != 0) {
                    throw new Error("Incorrect flight type");
                }
                let offsetsAndFlight = undefined;
                for (let eventsAndOffset of eventsAndOffsets) {
                    if (eventsAndOffset.a == event) {
                        offsetsAndFlight = eventsAndOffset.b;
                    }
                }
                if (!offsetsAndFlight) {
                    offsetsAndFlight = [];
                }
                offsetsAndFlight.push(new Pair<number, string | undefined>(parseInt(item.o || item.offset), flightType));
                eventsAndOffsets.push(new Pair<string, Pair<number, string | undefined>>(event, offsetsAndFlight));
            });
        }
        else {
            const eventsAndOffsetsArray: string[] = eventMonitoringRulesJson.split(",");

            eventsAndOffsetsArray.forEach(eventAndOffsets => {
                const singularEventAndOffsetsAsArray: string[] = eventAndOffsets.trim().split(/[\s|]+/);

                if (singularEventAndOffsetsAsArray.length < 2) {
                    throw new Error("Incorrect non-json format");
                } else {
                    let offsetsAndFlight: Pair<number, string | undefined>[] = [];
                    for (let i = 0; i < singularEventAndOffsetsAsArray.length - 1; i++) {
                        offsetsAndFlight.push(new Pair<number, string | undefined>(parseInt(singularEventAndOffsetsAsArray[i + 1]), undefined));
                    }
                    eventsAndOffsets.push(new Pair<string, Pair<number, string>[]>(singularEventAndOffsetsAsArray[0].toUpperCase().trim(), offsetsAndFlight))
                }
            });
        }
        return eventsAndOffsets;
    }

    getFormattedDelay(delay: number) {
        if (delay <= 0) {
            return null;
        }

        let delayInSeconds = (delay / (1000) % 60).toFixed(0).toString();
        let delayInMinutes = (delay / (60 * 1000) % 60).toFixed(0).toString();
        let delayInHours = (delay / (60 * 60 * 1000) % 60).toFixed(0).toString();

        delayInSeconds = delayInSeconds.length === 2 ? delayInSeconds : "0" + delayInSeconds;
        delayInMinutes = delayInMinutes.length === 2 ? delayInMinutes : "0" + delayInMinutes;
        delayInHours = delayInHours.length === 2 ? delayInHours : "0" + delayInHours;

        return delayInHours + ":" + delayInMinutes + ":" + delayInSeconds;
    }

    async componentDidMount() {
        await this.loadData(this.props.entityName, this.props.entityId);
    }

    async componentDidUpdate(prevProps: Readonly<Props>) {
        if ((this.props.entityName && this.props.entityName != prevProps.entityName) || (this.props.entityId && this.props.entityId != prevProps.entityId)) {
            await this.loadData(this.props.entityName, this.props.entityId);
        }
    }

    render() {
        return <Segment basic>
            <Header as="h1">{`${this.props.s.actualNumberOfEvents}/${this.props.s.expectedNumberOfEvents} validated events`}</Header>
            <Table striped celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{_msg("MissionEventValidationPage.event.label")}</Table.HeaderCell>
                        <Table.HeaderCell>{_msg("MissionEventValidationPage.validatedAt.label")}</Table.HeaderCell>
                        <Table.HeaderCell>{_msg("MissionEventValidationPage.expectedBefore.label")}</Table.HeaderCell>
                        <Table.HeaderCell>{_msg("MissionEventValidationPage.delay.label")}</Table.HeaderCell>
                        <Table.HeaderCell>{_msg("MissionEventValidationPage.flight.label")}</Table.HeaderCell>
                        <Table.HeaderCell>{_msg("MissionEventValidationPage.flightObject.label")}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.props.s.data.map((dataRow, index) =>
                        <Table.Row key={"tr" + index}>
                            {Object.keys(dataRow).filter(x => x !== "color").map((x, index) =>
                                <Table.Cell key={"tc" + index} style={{ color: dataRow.color }}>{dataRow[x]}</Table.Cell>
                            )}
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
        </Segment>
    }
}

export const MissionEventValidationPageRRC = ReduxReusableComponents.connectRRC(MissionEventValidationPageState, MissionEventValidationPageReducers, MissionEventValidationPage);

export const missionEventValidationPageUrl = "/missionEventValidation/:entityName/:entityId";
export const missionEventValidationPageRoute = (computeRoute: (props: PrivateRouteProps) => JSX.Element) =>
    <PrivateRoute key="missionEventValidationPage"
        path={missionEventValidationPageUrl}
        render={(props) =>
            <MissionEventValidationPageRRC id="missionEventValidation" entityName={props.match.params.entityName!} entityId={Number(props.match.params.entityId)} />
        }
        computeRoute={computeRoute} />

export const missionEventValidationPageMenuEntry = () => {
    return {
        id: "missionEventValidationPage",
        content: _msg("MissionEventValidationPage.title"),
        to: missionEventValidationPageUrl, exact: true
    }
};
