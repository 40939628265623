import React from 'react'
import { Button, Input, Popup } from 'semantic-ui-react';
import { RRCProps, Reducers, ReduxReusableComponents, State } from '@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents';
import DropdownFieldEditor from '@crispico/foundation-react/entity_crud/fieldRenderersEditors/DropdownFieldEditor';
import { Formik } from 'formik';
import { SelectExt } from '@crispico/foundation-react/components/selectExt/SelectExt';
import { flightEntityDescriptor } from 'AppEntityDescriptors';

export type FlightFilters = {
    departureValue: boolean | undefined;
    flightTypeValue: string | undefined;
    lastKnownDateTypeValue: string | undefined;
    nameOrPlaneIdentifierValue: string | undefined;

}
class FlightFilterComponentState extends State {
    filters: FlightFilters = {
        departureValue: undefined,
        flightTypeValue: undefined,
        lastKnownDateTypeValue: undefined,
        nameOrPlaneIdentifierValue: undefined
    }
}

class FlightFilterComponentReducers<S extends FlightFilterComponentState = FlightFilterComponentState> extends Reducers<S> { }

type FlightFilterComponentProps = RRCProps<FlightFilterComponentState, FlightFilterComponentReducers> & {
    showDepartureFilter: boolean,
    showFlightTypeFilter: boolean,
    showLastKnownDateTypeFilter: boolean,
    onFilterChange(): void,
}

const departure = [
    { value: "arrival", label: _msg("Flight.arrival.label") },
    { value: "departure", label: _msg("Flight.departure.label") }
]

export default class FlightFilterComponent extends React.Component<FlightFilterComponentProps> {
    
    private searchInputRef = React.createRef<HTMLInputElement>();

    constructor(props: FlightFilterComponentProps) {
        super(props);
        this.onSearchKeyDown = this.onSearchKeyDown.bind(this);
    }

    componentDidUpdate(prevProps: FlightFilterComponentProps) {
        if (!prevProps || this.props.s.filters !== prevProps.s.filters) {
            this.props.onFilterChange();
        }
    }

    private onSearchKeyDown(e: any) {
        if (e.key === "Enter") {
            this.props.r.setInReduxState({
                filters: {
                    ...this.props.s.filters,
                    nameOrPlaneIdentifierValue: this.searchInputRef.current?.value.trim() || ''
                }
            });
        }
    }

    private getFlightTypeCurrentValue(flightType: string | undefined) {
        if (flightType === 'L') {
            return { value: flightType, label: _msg("Flight.flightType.L") } 
        } else if (flightType === 'M') {
            return { value: flightType, label: _msg("Flight.flightType.M") }
        } else if (flightType === 'S') {
            return { value: flightType, label: _msg("Flight.flightType.S") }
        } else {
            return '';
        }
    }

    render() {
        return (<>
            {this.props.showLastKnownDateTypeFilter && <div style={{ minWidth: "150px"}}>
                <Formik initialValues={{} as any} onSubmit={() => { }}  >
                    {formikProps => {
                        return <DropdownFieldEditor formikProps={formikProps} fieldDescriptor={flightEntityDescriptor.getField("lastKnownDateType")}
                            value={this.props.s.filters.lastKnownDateTypeValue === undefined ? '' : {value: this.props.s.filters.lastKnownDateTypeValue, label: _msg(`Flight.lastKnownDateType.${this.props.s.filters.lastKnownDateTypeValue}`)}}
                            placeholder={_msg("FlightFilterComponent.lastKnownDateTypesFilter.placeholder")}
                            onChange={(entity: any) => {
                                this.props.r.setInReduxState({
                                    filters: {
                                        ...this.props.s.filters,
                                        lastKnownDateTypeValue: entity?.value as string || undefined
                                    }
                                });
                            }
                        }
                        />
                    }}
                </Formik>
            </div>}

            {this.props.showDepartureFilter && <div style={{ minWidth: "150px" }}>
                <SelectExt placeholder={_msg("FlightFilterComponent.departureFilter.placeholder")}
                    value={ this.props.s.filters.departureValue === undefined ? '' : this.props.s.filters.departureValue ? departure[1] : departure[0] }
                    isMulti={false}
                    isClearable={true}
                    options={departure}
                    closeMenuOnSelect={true}
                    onChange={entity => {
                        const departureValue = entity?.value === undefined? undefined : entity?.value === "departure" ? true : false;
                        this.props.r.setInReduxState({
                            filters: {
                                ...this.props.s.filters,
                                departureValue
                            }
                        });
                    }}
                />
            </div>}

            {this.props.showFlightTypeFilter && <div style={{ minWidth: "150px" }}>
                <Formik initialValues={{} as any} onSubmit={() => { }}  >
                    {formikProps => {
                        return <DropdownFieldEditor formikProps={formikProps} fieldDescriptor={flightEntityDescriptor.getField("flightType")}
                            value={this.props.s.filters.flightTypeValue === undefined ? '' : this.getFlightTypeCurrentValue(this.props.s.filters.flightTypeValue)}
                            placeholder={_msg("FlightFilterComponent.flightTypesFilter.placeholder")}
                            onChange={(entity: any) => {
                                this.props.r.setInReduxState({
                                    filters: {
                                        ...this.props.s.filters,
                                        flightTypeValue: entity?.value as string || undefined
                                    }
                                });
                            }
                        }
                        />
                    }}
                </Formik>
            </div>}

            <Input type="text" placeholder={_msg("general.search")} onKeyDown={this.onSearchKeyDown} >
                <input ref={this.searchInputRef} />
                <Button icon='search' color="green" onClick={() => {
                    this.props.r.setInReduxState({
                        filters: {
                            ...this.props.s.filters,
                            nameOrPlaneIdentifierValue: this.searchInputRef.current?.value.trim() || undefined
                        }
                    });
                }}/>
                <Popup content={_msg("FlightFilterComponent.searchInfo")} trigger={<Button icon='info' circular />} />
            </Input>
            <Button icon='delete' color="red" onClick={() => {
                this.searchInputRef.current!.value = ''; this.props.r.setInReduxState({
                    filters: {
                        departureValue: undefined,
                        flightTypeValue: undefined,
                        lastKnownDateTypeValue: undefined,
                        nameOrPlaneIdentifierValue: undefined
                    }
                });
            }}/>
        </>);
    }
}

export const FlightFilterComponentRRC = ReduxReusableComponents.connectRRC(FlightFilterComponentState, FlightFilterComponentReducers, FlightFilterComponent);