import React from "react";
import { csvComment, csvNewLine, regexCsvText } from "@crispico/foundation-react/components/multiCsvEditor/MultiCsvEditor";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { Button, Checkbox, CheckboxProps, Segment, Table } from "semantic-ui-react";
import { ModalExt, Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { Formik } from "formik";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/DateFieldRenderer";
import { apolloClientHolder, FieldDescriptor, Optional, Utils } from "@crispico/foundation-react";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { FilterPreview } from "@crispico/foundation-react/components/CustomQuery/FilterPreview";
import { CopyInputDataFromDbConfigInput } from "apollo-gen/globalTypes";
import { GET_GANTT_ASSIGNMENT_ALGORITHM_DATE_FILTERS } from "../queries";
import { EntityFilterMode } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/EntityFilter";

export function csvGetEntities(csvText: string) {
    const result: string[] = [];
    if (!csvText || csvText.trim().length === 0) {
        return result;
    }

    const csvList = csvText.split(regexCsvText).filter(x => x && x.trim());
    let i = 0;
    while (i < csvList.length) {
        if (!csvList[i] || !csvList[i].startsWith(csvComment)) {
            i++;
            continue;
        }
        const fileSeparator: { entity: string } = JSON.parse(csvList[i].replace(csvComment, "").trim());
        i++;
        if (csvList[i] && !csvList[i].startsWith(csvComment)) {
            result[result.length] = fileSeparator.entity;
        }
    }
    return result;
}

class GanttAssignmentCopyInputDataState extends State {
    modalIsOpen = false;
    import = false; /*actually importAll, but it is necessary to have the same name as below in order not to duplicate the code with the one for delete*/
    delete = false;
    rows = {} as { [key: string]: { import: boolean, delete: boolean } };
    dateFilters = {} as { [key: string]: Filter };
}

class GanttAssignmentCopyInputDataReducers<S extends GanttAssignmentCopyInputDataState = GanttAssignmentCopyInputDataState> extends Reducers<S> {

}

export type GanttAssignmentEntityRequirement = {
    entity: string,
    fields: string[],
    defaultAdditionalFilter?: Filter,
    optional: boolean,
    importFromDb: boolean,
    fieldsForDateFilter: string[]
}

type GlobalProps = RRCProps<GanttAssignmentCopyInputDataState, GanttAssignmentCopyInputDataReducers> & {
    entity: { inputEntitiesCsv: string, outputEntitiesCsv: string, flightsStartDate: Date, flightsEndDate: Date },
    entityRequirements?: GanttAssignmentEntityRequirement[],
    algorithmName: string,
    copyInputDataFromDb: (config: CopyInputDataFromDbConfigInput[]) => void
};

export class GanttAssignmentCopyInputDataButton extends React.Component<GlobalProps> {

    filters: { [key: string]: Filter } = {};

    constructor(props: GlobalProps) {
        super(props);
        this.onCopyInputDataClick = this.onCopyInputDataClick.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.copyInputDataFromDb = this.copyInputDataFromDb.bind(this);
        this.getFilter = this.getFilter.bind(this);
    }

    protected async getGanttAssignmentAlgorithms() {
        const dateFilters: { [key: string]: Filter } =
            (await apolloClientHolder.apolloClient.query({ query: GET_GANTT_ASSIGNMENT_ALGORITHM_DATE_FILTERS, variables: { algorithm: this.props.algorithmName, startDate: this.props.entity.flightsStartDate, endDate: this.props.entity.flightsEndDate, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone } })).data["ganttAssignmentService_algorithmDateFilters"];

        Object.keys(dateFilters).forEach(key => dateFilters[key] = Filter.enableAllFilters(dateFilters[key])!);

        this.props.r.setInReduxState({ dateFilters });
    }

    protected onCopyInputDataClick() {
        if (!this.props.entityRequirements) {
            Utils.showGlobalAlert({ message: _msg("GanttAssignment.copyInputDataFromDbNoAlgorithmSelectedMessage"), severity: Severity.WARNING });
            return;
        }

        if (!this.props.entity?.flightsStartDate ) {
            Utils.showGlobalAlert({ message: _msg("GanttAssignment.flightsStartDateIsMandatory"), severity: Severity.WARNING });
            return;
        }

        if (!this.props.entity?.flightsEndDate ) {
            Utils.showGlobalAlert({ message: _msg("GanttAssignment.flightsEndDateIsMandatory"), severity: Severity.WARNING });
            return;
        }

        this.getGanttAssignmentAlgorithms();

        const entitiesWithData = csvGetEntities(this.props.entity.inputEntitiesCsv);
        const rows: { [key: string]: { import: boolean, delete: boolean } } = {};
        this.props.entityRequirements.forEach(x => {
            rows[x.entity] = { import: x.importFromDb, delete: entitiesWithData.includes(x.entity) && x.importFromDb }
            if (x.defaultAdditionalFilter) {
                this.filters[x.entity] = x.defaultAdditionalFilter;
            }
        });
        if (Object.keys(rows).length === 0) {
            Utils.showGlobalAlert({ message: _msg("GanttAssignment.copyInputDataFromDbMessage"), severity: Severity.INFO });
        } else {
            this.props.r.setInReduxState({ rows, modalIsOpen: true });
        }
    }

    protected onCheckboxChange(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) {
        const rows = Object.assign({}, this.props.s.rows);
        Object.keys(rows).forEach(key => rows[key] = { ...rows[key], [data.name as string]: data.checked });
        this.props.r.setInReduxState({ rows, [data.name as string]: data.checked });
    }

    protected copyInputDataFromDb() {
        const config: CopyInputDataFromDbConfigInput[] = []
        this.props.entityRequirements?.forEach(x => {
            const row = this.props.s.rows[x.entity];
            config[config.length] = { entityName: x.entity, importData: row.import, deleteDataBeforeImport: row.delete, filter: Filter.eliminateDisabledFilters(this.getFilter(x.entity)) }
        });
        this.props.copyInputDataFromDb(config);
        this.props.r.setInReduxState({ modalIsOpen: false })
    }

    protected getFilter(entityName: string) {
        let filter = this.filters[entityName];
        const dateFilter = this.props.s.dateFilters[entityName];
        if (!dateFilter) {
            return filter;
        }
        return Filter.createComposedForClient(FilterOperators.forComposedFilter.and, [dateFilter, filter]);
    }

    render() {
        return <>
            <Button secondary icon="copy outline" content={_msg("GanttAssignment.copyInputDataFromDb")} onClick={this.onCopyInputDataClick} />
            <ModalExt
                severity={Severity.CONFIRMATION}
                open={this.props.s.modalIsOpen}
                content={<Segment basic className="no-padding-top-bottom" >
                    <Segment className="buttonBar EntityEditorFormSimple_bar">
                        <Checkbox label={_msg("GanttAssignment.importAll")} name="import" checked={this.props.s.import} style={{ paddingRight: "1rem" }} onChange={this.onCheckboxChange} />
                        <Checkbox label={_msg("GanttAssignment.deleteAllBeforeImport")} name="delete" checked={this.props.s.delete} onChange={this.onCheckboxChange} />
                    </Segment>
                    <div style={{overflowY: 'auto', height: '350px'}}>
                    <Table compact celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell content={_msg("dto_crud.import")} />
                                <Table.HeaderCell content={_msg("GanttAssignment.deleteDataBeforeImport")} />
                                <Table.HeaderCell content={_msg("general.entityName")} />
                                <Table.HeaderCell content={_msg("GanttAssignment.fields")} />
                                <Table.HeaderCell content={_msg("general.filter")} />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.props.entityRequirements?.map(requirement => {
                                const ed = entityDescriptors[requirement.entity];
                                const row = this.props.s.rows[ed.name];

                                const filterFd = new FieldDescriptor();
                                filterFd.name = "filter";
                                filterFd.type = FieldType.filter;
                                filterFd.showPreviewButton = !requirement.fieldsForDateFilter || requirement.fieldsForDateFilter.length === 0;
                                filterFd.entityDescriptor = ed;
                                filterFd.mode = EntityFilterMode.OBJECT;

                                return row &&
                                    <Table.Row key={ed.name}>
                                        <Table.Cell textAlign="center" className="GanttAssignment_copyInputDataTableCell">
                                            <Checkbox checked={row.import} onClick={() => this.props.r.setInReduxState({ rows: { ...this.props.s.rows, [ed.name]: { ...row, import: !row.import } } })} />
                                        </Table.Cell>
                                        <Table.Cell textAlign="center" className="GanttAssignment_copyInputDataTableCell">
                                            <Checkbox checked={row.delete} onClick={() => this.props.r.setInReduxState({ rows: { ...this.props.s.rows, [ed.name]: { ...row, delete: !row.delete } } })} />
                                        </Table.Cell>
                                        <Table.Cell className="GanttAssignment_copyInputDataTableCell">{ed.getLabel()}</Table.Cell>
                                        <Table.Cell className="GanttAssignment_copyInputDataTableCell">{requirement.fields.map(field => ed.getField(field).getLabel()).join(", ")}</Table.Cell>
                                        <Table.Cell>
                                            {!filterFd.showPreviewButton && <>
                                                <div className="flex-container-row">{_msg("GanttAssignment.dateFilter")}: { _msg("Filter.operator.between")}&nbsp;<DateFieldRenderer entity={this.props.entity} fieldDescriptor={entityDescriptors["GanttAssignment"]?.getField("flightsStartDate")} value={this.props.entity?.flightsStartDate} />
                                                &nbsp; {_msg("Filter.operator.and")} &nbsp; <DateFieldRenderer entity={this.props.entity} fieldDescriptor={entityDescriptors["GanttAssignment"]?.getField("flightsEndDate")} value={this.props.entity?.flightsEndDate} />
                                                </div>
                                                <div>{_msg("GanttAssignment.additionalFilter")}:</div>
                                            </>}
                                            <Formik initialValues={{ [filterFd.name]: this.filters[ed.name] }} onSubmit={() => { }} >
                                                {formikProps => {
                                                    if (this.filters[ed.name] !== formikProps.values[filterFd.name]) {
                                                        this.filters[ed.name] = Filter.enableAllFilters(formikProps.values[filterFd.name])!;
                                                    }
                                                    return filterFd.renderFieldEditor(formikProps)
                                                }}
                                            </Formik>
                                            {!filterFd.showPreviewButton && <FilterPreview entityName={ed.name} displayName getFilter={() => this.getFilter(ed.name)} />}
                                        </Table.Cell>
                                    </Table.Row>
                            })}
                        </Table.Body>
                    </Table></div>
                </Segment>}
                onClose={() => this.props.r.setInReduxState({ modalIsOpen: false })}
                actions={[
                    <Button key="close" onClick={() => this.props.r.setInReduxState({ modalIsOpen: false })}>{_msg("general.cancel")}</Button>,
                    <Button secondary key="copyInputDataFromDb" disabled={Object.values(this.props.s.rows).filter(row => row.import).length === 0} icon="copy" content={_msg("GanttAssignment.copyInputDataFromDb")} onClick={this.copyInputDataFromDb} />
                ]}
            />
        </>
    }
}

export const GanttAssignmentCopyInputDataButtonRRC = ReduxReusableComponents.connectRRC(GanttAssignmentCopyInputDataState, GanttAssignmentCopyInputDataReducers, GanttAssignmentCopyInputDataButton);