import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import _ from "lodash";

interface Db {
    [key: string]: {
        [id: number]: any;
    };
}

export class GanttUtils {

    private static getHandler = (entityName: string, miniDb?: Db): ProxyHandler<any> => {
        return {
            get(target, property) {
                let value = Reflect.get(target, property);
                if (typeof property === 'symbol') {
                    return false;
                }
                const type = entityDescriptors[entityName]?.fields[property]?.type;
                if (value && typeof value === 'object') {
                    if (Array.isArray(value)) {
                        // don't work, because don't exist information in entityDescriptor for one-to-many fields
                        value = value.map(x => {
                            if (miniDb && type && miniDb[type]?.[x.id]) {
                                return GanttUtils.getProxy(type, miniDb[type]?.[x.id], miniDb);
                            } else return x;
                        })
                    }
                    if (miniDb && type && miniDb[type]?.[value.id]) {
                        return GanttUtils.getProxy(type, miniDb[type]?.[value.id], miniDb);
                    }
                }
                return value;
            }
        };
    };

    static getProxy(entityName: any, entity: any, miniDb?: Db) {
        // need this copy for change the property of entity, else the entity has one property read-only and non-configurable
        const entityCopy = _.cloneDeep(entity);
        return new Proxy(entityCopy, this.getHandler(entityName, miniDb));
    }

    static toEntityUid(entityName: string, id: number): string {
        return entityName + ":" + id;
    };

    static fromEntityUid(entityUid: string): { entityName: string, id: number } {
        const entityName = entityUid.split(":")[0];
        const id = Number(entityUid.split(":")[1]);
        return { entityName, id };
    }
}
