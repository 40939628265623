import { addEntityDescriptor, BigState, createSliceFoundation, EntityDescriptor, EntityTablePage, EntityTablePageProps, FieldDescriptor, getBaseImpures, getBaseReducers, PropsFrom, SliceEntityTablePage, sliceEntityTablePageOnlyForExtension, Utils } from "@crispico/foundation-react";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { DatePickerFieldEditor } from "@crispico/foundation-react/components/DatePicker/DatePickerFieldEditor";
import { TabRouterPane } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { InitializationsForClient, MapSettings } from "app";
import { RealTimeMapRRC } from "components/realTimeMap/RealTimeMap";
import { uniqueId } from "lodash";
import { equipmentResourceEntityDescriptor, MOBILE_DEVICE_TYPE } from "pages/EquipmentResource/equipmentResourceEntityDescriptor";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/DateFieldRenderer";

const additionalDateFieldEditorProps = FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: Utils.dateTimeWithSecFormat });
const additionalDateFieldRendererProps = FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { format: Utils.dateTimeWithSecFormat });

export const mobileDeviceEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
    name: "MobileDevice",
    defaultSort: { field: "lastUpdate", direction: "DESC" },
    miniFields: ["identifier"],
    icon: "mobile alternate",
})).addFieldDescriptor({ name: "status", icon: "signal", type: FieldType.dropdown})
   .addFieldDescriptor({ name: "updated", type: FieldType.date, icon: "database", additionalFieldEditorProps: additionalDateFieldEditorProps, additionalFieldRendererProps: additionalDateFieldRendererProps })
   .addFieldDescriptor({ name: "lastUpdate", type: FieldType.date, icon: "feed", additionalFieldEditorProps: additionalDateFieldEditorProps, additionalFieldRendererProps: additionalDateFieldRendererProps })
   .addTabDescriptor({ oneToManyEntityName: "MobileDeviceActivationHistory", oneToManyOppositeField: "mobileDevice", oneToManyEntityField: "id" })
   .addTabDescriptor({ oneToManyEntityName: "Position", oneToManyOppositeField: "plateNumber", oneToManyEntityField: "identifier" })

export const sliceMobileDeviceTable = mobileDeviceEntityDescriptor.infoTable.slice = createSliceFoundation(class Ext extends SliceEntityTablePage {

    initialState = {
        ...sliceEntityTablePageOnlyForExtension.initialState,
    }

    nestedSlices = {
        ...sliceEntityTablePageOnlyForExtension.nestedSlices
    }

    reducers = {
        ...sliceEntityTablePageOnlyForExtension.reducers, ...getBaseReducers<Ext>(this)
    }

    impures = {
        ...sliceEntityTablePageOnlyForExtension.impures, ...getBaseImpures<Ext>(this),
    }
}).setEntityDescriptor(mobileDeviceEntityDescriptor);

mobileDeviceEntityDescriptor.infoTable.wrappedComponentClass = class extends EntityTablePage<EntityTablePageProps & PropsFrom<typeof sliceMobileDeviceTable> & { mapSettings: MapSettings }> {

    constructor(props: EntityTablePageProps & PropsFrom<typeof sliceMobileDeviceTable> & { mapSettings: MapSettings }) {
        super(props);
    }

    protected getExtraTabPanes(): (TabRouterPane | null)[] {
        const that = this;
        let extraTabPanes = [...super.getExtraTabPanes(), {
            routeProps: { path: "/realTimeMap" },
            menuItemProps: { icon: "map outline", content: _msg("MapRealTime") },
            render: () => (<>
                {that.renderRealTimeMap(false, true, Filter.createForClient('equipmentType.name', FilterOperators.forString.equals, MOBILE_DEVICE_TYPE))}
            </>)
        }];
        return extraTabPanes;
    }

    protected renderRealTimeMap(showGoToTableButton: boolean = true, withCustomQueryBar: boolean = true, rootFilter: Filter | undefined) {
        const id = uniqueId("realTimeMap-tab-" + mobileDeviceEntityDescriptor.name);
        return <RealTimeMapRRC id={id} currentOrganizationToFilterBy={this.props.currentOrganizationToFilterBy}
            rootFilter={rootFilter} showCustomQueryBar={withCustomQueryBar} showGoToTableButton={showGoToTableButton} mapSettings={this.props.mapSettings} mapId={id} entityTypes={[equipmentResourceEntityDescriptor.name]}/>
    }
}

mobileDeviceEntityDescriptor.infoTable.mapBigStateToProps = (state: BigState, props: any) => {
    props.mapSettings = (state.AppContainer.initializationsForClient as InitializationsForClient).mapSettings;
}

