import { FilterOperators } from "@crispico/foundation-gwt-js";
import { createSliceFoundation, EntityDescriptor, EntityTablePage, EntityTablePageProps, FieldDescriptor, getBaseImpures, getBaseReducers, Optional, PropsFrom, SliceEntityTablePage, sliceEntityTablePageOnlyForExtension, Utils } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { TabRouterPane } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { fieldEditors, fieldRenderers } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FieldType, fieldTypeToPrimitiveFieldTypeMapping } from "@crispico/foundation-react/entity_crud/FieldType";
import { AggregateFunctionInput, AggregateFunctionType } from "apollo-gen/globalTypes";
import { FlightDepartureFieldEditor } from "./FlightDepartureFieldEditor";
import { FlightDepartureFieldRenderer } from "./FlightDepartureFieldRenderer";
import { CommentFieldDescriptor, DefaultShowFlightInGanttFieldDescriptor, FlightPlaneFieldDescriptor, ParkingFieldDescriptor, PaxNoFieldDescriptor, PaxParkingFieldDescriptor, RotationFlightFieldDescriptor } from "./FlightFieldDescriptors";
import { FlightsAssignmentsPageRRC } from "./flightsAssignmentsPage/FlightsAssignmentsPage";
import { FlightSchengenFieldRenderer } from "./FlightSchengenFieldRenderer";
import { FlightSchengenFieldEditor } from "./FlightSchengenFieldEditor";
import { FlightPlaneIdentifierFieldRender } from "./FlightPlaneIdentifierFieldRender";
import { DatePickerFieldEditor } from "@crispico/foundation-react/components/DatePicker/DatePickerFieldEditor";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/DateFieldRenderer";
import FlightFilterComponent, { FlightFilterComponentRRC, FlightFilters } from "./FlightFilterComponent";
import React from "react";
import { Segment } from "semantic-ui-react";
import lodash from 'lodash';

const FIELDS_FROM_DATA: string[] = ["passaerelle", "recurrentComment", "offset", "degradedMode", "busType", "averageVolume",
    "deltaEventCode", "lastModifiedUpdateDate", "lastModifiedOrigin", "lastModifiedDate", "lastModifiedDestination", "lastModifiedPlaneIdentifier",
    "lastModifiedParking", "lastModifiedPlaneType", "modifiedFlight", "delayedFlight", "dhcCTime", "EFuelDate", "EFuelState", "EFuelQuantity",
    "temporaryStorage", "hc", "eco", "crewShuttleModified", "alreadyEngaged", "modifiedObjects", "modifiedPlaneIdentifier", "alertCreateDefaultObjectsNoReset",
    "parkingChanged", "offloadStatus", "parkingType", "blockObjectCreation", "agentParking", "UAEventName", "hasArrived", "duration", "color"];

const additionalDateFieldEditorProps = FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: Utils.dateTimeWithSecFormat });
const additionalDateFieldRendererProps = FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { format: Utils.dateTimeWithSecFormat });
const sliceFlightEntityTablePage = createSliceFoundation(class Ext extends SliceEntityTablePage {

    initialState = {
        ...sliceEntityTablePageOnlyForExtension.initialState,
       flightFilters: undefined as Optional<FlightFilters>
    }

    reducers = {
        ...sliceEntityTablePageOnlyForExtension.reducers,
        ...getBaseReducers<Ext>(this)
    }

    impures = {
        ...sliceEntityTablePageOnlyForExtension.impures,
        ...getBaseImpures<Ext>(this),

        getAggregateFunctions(): AggregateFunctionInput[] | null {
            return [{ field: "baggages.id", type: AggregateFunctionType.COUNT }];
        },

        adjustFilterBeforeLoadSuper: sliceEntityTablePageOnlyForExtension.impures.adjustFilterBeforeLoad,
        adjustFilterBeforeLoad(filter: Filter): Filter {           
            filter = this.adjustFilterBeforeLoadSuper(filter);

            let newFilter = Filter.createComposed(FilterOperators.forComposedFilter.and, []);
            if (!Utils.isNullOrEmpty(this.getState().flightFilters?.departureValue)) {
                newFilter.filters?.push(Filter.create("departure", FilterOperators.forBoolean.equals, this.getState().flightFilters?.departureValue!.toString()));
            }
            if (!Utils.isNullOrEmpty(this.getState().flightFilters?.flightTypeValue)) {
                newFilter.filters?.push(Filter.create("flightType", FilterOperators.forString.equals, this.getState().flightFilters?.flightTypeValue));
            }
            if (!Utils.isNullOrEmpty(this.getState().flightFilters?.lastKnownDateTypeValue)) {
                newFilter.filters?.push(Filter.create("lastKnownDateType", FilterOperators.forString.equals, this.getState().flightFilters?.lastKnownDateTypeValue));
            }
            if (!Utils.isNullOrEmpty(this.getState().flightFilters?.nameOrPlaneIdentifierValue)) {
                newFilter.filters?.push(Filter.createComposed(FilterOperators.forComposedFilter.or, [
                    Filter.create("name", FilterOperators.forString.contains, this.getState().flightFilters?.nameOrPlaneIdentifierValue),
                    Filter.create("parking.name", FilterOperators.forString.contains, this.getState().flightFilters?.nameOrPlaneIdentifierValue),
                    Filter.create("planeIdentifier", FilterOperators.forString.contains, this.getState().flightFilters?.nameOrPlaneIdentifierValue),
                ]));
            }
            if (newFilter.filters!.length > 0) {
                newFilter.filters?.push(filter);
                return newFilter;
            }
            return filter;
        },
    }
});

class FlightEntityTablePage extends EntityTablePage<EntityTablePageProps & PropsFrom<typeof sliceFlightEntityTablePage>> {

    private flightFilterRef = React.createRef<FlightFilterComponent>();

    constructor(props: EntityTablePageProps & PropsFrom<typeof sliceFlightEntityTablePage>) {
        super(props);       
    }

    componentDidUpdate(prevProps: EntityTablePageProps & PropsFrom<typeof sliceFlightEntityTablePage>) {
        super.componentDidUpdate(prevProps);
        if (prevProps && !lodash.isEqual(prevProps.flightFilters, this.props.flightFilters)) {
            this.refresh();
        }
    }
    
    protected getExtraTabPanes(): (TabRouterPane | null)[] {
        let extraTabPanes = [...super.getExtraTabPanes(),
        {
            routeProps: { path: "/flightsAssignments" },
            menuItemProps: { icon: "plane", content: _msg("FlightsAssignmentsPage.title") },
            render: () => <FlightsAssignmentsPageRRC id='flightsAssignments' />
        }
        ];
        return extraTabPanes;
    }

    protected renderCompactBar(): JSX.Element {
        return <>{super.renderCompactBar()}
            <Segment className="less-padding less-margin-top-bottom flex-container-row flex-center flex-wrap gap5">
                <FlightFilterComponentRRC
                    id="flightFilterComponent"
                    ref={this.flightFilterRef}
                    showDepartureFilter={true}
                    showFlightTypeFilter={true}
                    showLastKnownDateTypeFilter={true}
                    onFilterChange={() => {
                        this.props.dispatchers.setInReduxState({ flightFilters: this.flightFilterRef.current?.props.s.filters });
                    }} />
            </Segment>
        </>
    }

}
export class FlightEntityDescriptor extends EntityDescriptor {

    protected customize() {
        this.addFieldDescriptor({ name: "date", type: FieldType.date, additionalFieldEditorProps: additionalDateFieldEditorProps, additionalFieldRendererProps: additionalDateFieldRendererProps })
            .addFieldDescriptor({ name: "initialDate", type: FieldType.date, additionalFieldEditorProps: additionalDateFieldEditorProps, additionalFieldRendererProps: additionalDateFieldRendererProps })
            //.addTabDescriptor({ oneToManyEntityName: "Task", oneToManyOppositeField: "taskGroup" })
            .addFieldDescriptor({ name: "name", type: FieldType.string, enabled: false })
            .addFieldDescriptor({ name: "aggFunc_count_baggages_id", type: FieldType.number, isAggregateField: true, filterable: false, sortable: false, isInDefaultColumnConfigForEditor: false })
            .addFieldDescriptor({ name: "departure", type: "departure" })
            .addFieldDescriptor({ name: "schengen", type: "schengen" })
            .addFieldDescriptor({ name: "planeIdentifier", type: "planeIdentifier" })
            .addFieldDescriptor(new FlightPlaneFieldDescriptor(FlightPlaneFieldDescriptor.ARRIVAL))
            .addFieldDescriptor(new FlightPlaneFieldDescriptor(FlightPlaneFieldDescriptor.DEPARTURE))
            .isInDefaultColumnConfig(true, "organization", "unit", "name", "airline", "number", "date", "initialDate", "airport", "parking", "showFlightInGantt", "origin", "destination", "departure", "planeIdentifier", "comment", "canceled", "planeType")
            .addTabDescriptor({ oneToManyEntityName: "Baggage", oneToManyOppositeField: "flight" })

        this.infoTable.slice = sliceFlightEntityTablePage.setEntityDescriptor(this);
        this.infoTable.wrappedComponentClass = FlightEntityTablePage;

        this.doForFields(FIELDS_FROM_DATA, fd => fd.filterable = false);
        this.doForFields(FIELDS_FROM_DATA, fd => fd.sortable = false);
    }
}

export const newFlightEntityDescriptor = new FlightEntityDescriptor({
    name: "Flight", icon: "plane",
    defaultFilter: Filter.createForClient("date", FilterOperators.forDate.today),
    defaultSort: { field: "date", direction: "DESC" },
    miniFields: ["name", "date", "origin", "destination"]
})
    .isInDefaultColumnConfig(true, "airline", "number", "origin", "destination", "airport", "date", "planeIdentifier", "staffNo", "passengersNo", "parking", "galery",
        "planeType", "paxParking", "defaultShowFlightInGantt", "sariaPort", "passaerelle", "comment", "oilGroup", "ifubs", "priority", "offset", "degradedMode",
        "unit", "groundAgent", "dayTemplate", "crewShuttle", "onHold", "blockAutoUpdates", "rotationFlight", "lastKnownDateType", "flightType")
    .addFieldDescriptor({ name: "defaultShowFlightInGantt" }, new DefaultShowFlightInGanttFieldDescriptor())
    .addFieldDescriptor({ name: "comment" }, new CommentFieldDescriptor())
    .addFieldDescriptor({ name: "parking" }, new ParkingFieldDescriptor())
    .addFieldDescriptor({ name: "paxParking" }, new PaxParkingFieldDescriptor())
    .addFieldDescriptor({ name: "passengersNo" }, new PaxNoFieldDescriptor())
    .addFieldDescriptor({ name: "flightType", type: FieldType.dropdown })
    .addFieldDescriptor({ name: "lastKnownDateType", type: FieldType.dropdown })
    .addFieldDescriptor({ name: "rotationFlight" }, new RotationFlightFieldDescriptor());

fieldEditors["departure"] = FlightDepartureFieldEditor;
fieldRenderers["departure"] = FlightDepartureFieldRenderer;
fieldTypeToPrimitiveFieldTypeMapping["departure"] = FieldType.boolean;
fieldEditors["schengen"] = FlightSchengenFieldEditor;
fieldRenderers["schengen"] = FlightSchengenFieldRenderer;
fieldTypeToPrimitiveFieldTypeMapping["schengen"] = FieldType.boolean;
fieldRenderers["planeIdentifier"] = FlightPlaneIdentifierFieldRender;
fieldTypeToPrimitiveFieldTypeMapping["planeIdentifier"] = FieldType.string;
