import { FilterOperators } from "@crispico/foundation-gwt-js";
import { apolloClientHolder, ConnectedComponentInSimpleComponent, ConnectedPageInfo, EntityTableSimpleRRC, RenderItemParams, Utils } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { DisplayMode, getHistoryGraphColor, HistoryGraphData, HistoryGraphItem, HistoryGraphItemProps, HistoryGraphItemReducers, HistoryGraphItemState } from "@crispico/foundation-react/pages/Audit/historyGraphItem/HistoryGraphItem";
import { ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { getPlateNumberFromIds_equipmentResourceService_findByFilter_results, getPlateNumberFromIds_equipmentResourceService_findByFilter_results_equipmentType } from "apollo-gen/getPlateNumberFromIds";
import { appMeta, MapSettings } from "app";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { eqImages256x256, getIcon, MapContainerLeaflet, MapContainerLeafletRRC, POLYLINE_TYPE } from "../../components/MapContainerLeaflet/MapContainerLeaflet";
import { GET_PLATE_NUMBERS_FROM_IDS, LOAD_POSITIONS_FOR_HISTORY_GRAPH_MAP, LOAD_TRACKS_FOR_HISTORY_GRAPH_MAP } from "./queries";
import { Button, Divider, Icon, Label, Message, Popup, Segment } from "semantic-ui-react";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { HistoryTrackForHistoryMapGraph } from "apollo-gen/HistoryTrackForHistoryMapGraph";
import { getDistance } from "geolib";
import { historyTrackEntityDescriptor } from "./equipmentResourceEntityDescriptor";
import { COLUMN_DEFAULT_WIDTH } from "@crispico/foundation-react/components/ColumnConfig/dataStructures";
import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import { loadPositionsForHistoryMapGraph_positionService_findByFilter_results as PositionForHistoryGraphMap } from "apollo-gen/loadPositionsForHistoryMapGraph";

const TRACK = "track";
const MAX_DISTANCE_BETWEEN_TRACKS = 10;
enum TrackColor { MOVEMENT = "green", STOP = "orange", QUESTION = "yellow" };
enum TreeIcon { PLUS = 'plus square outline', MINUS = 'minus square outline', POINT = 'point' };

export enum MapDisplayMode {
    TREE, TABLE, MAP
}

class HistoryGraphMapItemState extends HistoryGraphItemState {
    equipmentResourceMap = {} as {[id: number]: { plateNumber?: string, equipmentType: getPlateNumberFromIds_equipmentResourceService_findByFilter_results_equipmentType, positions: PositionForHistoryGraphMap[], tracks: HistoryTrackForHistoryMapGraph[], show: boolean }};
    mapDisplayMode = MapDisplayMode.TREE;
    openTrackPopup = undefined as { id: number, isStop: boolean } | undefined;
}

class HistoryGraphMapItemReducers<S extends HistoryGraphMapItemState = HistoryGraphMapItemState> extends HistoryGraphItemReducers<S> {
    showOrHideEquipmentResource(id: number) {
        this.s.equipmentResourceMap[id].show = !this.s.equipmentResourceMap[id].show;
    }
}

type HistoryGraphMapItemProps = RRCProps<HistoryGraphMapItemState, HistoryGraphMapItemReducers> & HistoryGraphItemProps & { editor: { props: { mapSettings: MapSettings, entity: { id: number, plateNumber: string } } } };

export class HistoryGraphMapItem extends HistoryGraphItem<HistoryGraphMapItemProps> {
    private mapContainerRef = React.createRef<MapContainerLeaflet>();

    getName() {
        return _msg("HistoryGraphMapItem.name");
    }

    protected componentDidUpdateInternal(prevProps?: HistoryGraphItemProps, prevState?: any) {
        super.componentDidUpdateInternal(prevProps, prevState);
        if (this.props.s.collapsedItem) {
            return;
        }
        if (prevProps && (prevProps.currentStartDate !== this.props.currentStartDate || prevProps.currentEndDate !== this.props.currentEndDate)) {
            this.addTracks(this.mapContainerRef.current!);
        }

        if (this.mapContainerRef.current && prevState && !_.isEqual(prevState.selected, this.state.selected)) {
            this.selectTrack(this.mapContainerRef.current, prevState.selected);
        }
    }

    componentWillUnmount() {
        this.mapContainerRef.current?.clearMap();
    }

    private clearTrack(mapContainer: MapContainerLeaflet) {
        mapContainer.clearMap([TRACK]);
    }

    private addTracks(mapContainer: MapContainerLeaflet) {
        this.clearTrack(mapContainer);
        this.setState({ selected: {} });
        this.props.entities.forEach(entity => {
            this.addTrack(mapContainer, entity.id);
        });
    }

    private addTrack(mapContainer: MapContainerLeaflet, id: number) {
        let positions = this.props.s.equipmentResourceMap[id]?.positions;
        if (!positions) {
            return;
        }
        if (this.props.currentStartDate && this.props.currentEndDate) {
            positions = positions.filter(pos => this.props.currentStartDate! <= pos.date && pos.date <= this.props.currentEndDate!);
        }
        if (positions.length === 0) {
            return;
        }
        let i: number;
        this.props.entities.forEach((entity, index) => {
            if (entity.id === id) {
                i = index;
            }
        });
        mapContainer.addOrUpdateLayers([{ id: id, points: positions, color: getHistoryGraphColor(i!) }], TRACK);
    }

    private selectTrack(mapContainer: MapContainerLeaflet, prevSelected?: {[id: number]: number}) {
        Object.keys(this.props.s.equipmentResourceMap).forEach(key => {
            const id = Number(key);
            const positions = this.props.s.equipmentResourceMap[id].positions;
            const pointId = this.state.selected[id];
            if (prevSelected && prevSelected[id] !== undefined && prevSelected[id] !== pointId) {
                mapContainer.props.r.unhighlightPoint({ layerType: TRACK, layerId: id, pointId: prevSelected[id] });
            }
            if (pointId == undefined) {
                return;
            }
            const trackStart = positions.find(pos => pos.id === pointId);
            if (!trackStart) {
                return;
            }
            mapContainer.props.r.highlightPoint({ layerType: TRACK, layerId: id, pointId: trackStart.id });
        });
    }

    protected async loadPlateNumbers() {
        const equipmentResourceMap = _.cloneDeep(this.props.s.equipmentResourceMap);
        const equipmentResourcesToLoad: number[] = [];
        this.props.entities.forEach(entity => {
            if (equipmentResourceMap[entity.id]?.plateNumber) {
                return;
            }
            if (!equipmentResourceMap[entity.id]) {
                equipmentResourceMap[entity.id] = { equipmentType: entity.equipmentType, positions: [], tracks: [], show: true };
            }
            if (entity.plateNumber) {
                equipmentResourceMap[entity.id].plateNumber = entity.plateNumber;
            } else {
                equipmentResourcesToLoad.push(entity.id);
            }
        });
        if (equipmentResourcesToLoad.length === 0) {
            this.props.r.setInReduxState({ equipmentResourceMap });
            return equipmentResourceMap;
        } 
        const params = FindByFilterParams.create().filter(Filter.create("id", FilterOperators.forNumber.in, equipmentResourcesToLoad.join(",")));
        const results: getPlateNumberFromIds_equipmentResourceService_findByFilter_results[] = (await apolloClientHolder.apolloClient.query({
            query: GET_PLATE_NUMBERS_FROM_IDS, context: { showSpinner: false }, variables: params
        })).data["equipmentResourceService_findByFilter"].results;
        results.forEach(result => {
            if (!result.plateNumber) {
                return;
            }
            equipmentResourceMap[result.id].plateNumber = result.plateNumber;
            equipmentResourceMap[result.id].equipmentType = result.equipmentType!;
            equipmentResourceMap[result.id].positions = [];
            equipmentResourceMap[result.id].tracks = [];
        });
        this.props.r.setInReduxState({ equipmentResourceMap });
        return equipmentResourceMap;
    }

    protected async refresh() {
        let equipmentResourceMap = await this.loadPlateNumbers();
        const ids = Object.keys(equipmentResourceMap).map(key => Number(key));
        const plateNumberFilter = Filter.createComposed(FilterOperators.forComposedFilter.or, []);
        ids.forEach(id => {
            if (!equipmentResourceMap[id].plateNumber) {
                return;
            }
            plateNumberFilter.filters?.push(Filter.create("plateNumber", FilterOperators.forString.equals, equipmentResourceMap[id].plateNumber));
        });        
        const startDate = moment(this.props.startDate).toISOString();
        const endDate = moment(this.props.endDate).toISOString();
        const positionsParams = FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.and, [
            Filter.create("date", FilterOperators.forDate.greaterThanOrEqualTo, startDate),
            Filter.create("date", FilterOperators.forDate.lessThanOrEqualTo, endDate),
            plateNumberFilter,
            Filter.create("latitude", FilterOperators.forNumber.isNotEmpty),
            Filter.create("longitude", FilterOperators.forNumber.isNotEmpty)
        ])).sorts([{ field: "date", direction: "ASC" }]);
        const tracksParams = FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.and, [
            Filter.create("equipmentResource", FilterOperators.forEntityManyToOne.in, ids.join(",")),
            Filter.createComposed(FilterOperators.forComposedFilter.and, [
                Filter.create("startTime", FilterOperators.forDate.greaterThanOrEqualTo, startDate),
                Filter.create("startTime", FilterOperators.forDate.lessThanOrEqualTo, endDate)
            ]),
            Filter.createComposed(FilterOperators.forComposedFilter.and, [
                Filter.create("endTime", FilterOperators.forDate.greaterThanOrEqualTo, startDate),
                Filter.create("endTime", FilterOperators.forDate.lessThanOrEqualTo, endDate)
            ])
        ]));
        
        const [positionsResult, tracksResult] = await Promise.all([
            apolloClientHolder.apolloClient.query({
                query: LOAD_POSITIONS_FOR_HISTORY_GRAPH_MAP, context: { showSpinner: false }, variables: positionsParams
            }),
            apolloClientHolder.apolloClient.query({
                query: LOAD_TRACKS_FOR_HISTORY_GRAPH_MAP, context: { showSpinner: false }, variables: tracksParams
            })
        ]);
        const positions: PositionForHistoryGraphMap[] = positionsResult.data["positionService_findByFilter"].results;
        const tracks: HistoryTrackForHistoryMapGraph[] = tracksResult.data["historyTrackService_findByFilter"].results;
        if (positions.length === 0 && tracks.length === 0) {
            return;
        }

        equipmentResourceMap = _.cloneDeep(equipmentResourceMap);
        const plateNumberToIdMap: {[key: string]: number} = {};
        Object.keys(equipmentResourceMap).forEach(key => {
            const id = Number(key);
            const plateNumber = equipmentResourceMap[id].plateNumber;
            if (plateNumber) {
                plateNumberToIdMap[plateNumber] = id;
            }
            equipmentResourceMap[id].positions = [];
            equipmentResourceMap[id].tracks = [];
        });

        positions.forEach(position => {
            const p = { ...position, date: moment(position.date).toDate().getTime() };
            if (!p.latitude && !p.latitude) {
                return;
            }
            equipmentResourceMap[plateNumberToIdMap[position.plateNumber]].positions.push(p);
        });
        tracks.forEach(track => {
            equipmentResourceMap[track.equipmentResource?.id].tracks.push({ ...track, startTime: moment(track.startTime).toDate().getTime(), endTime: moment(track.endTime).toDate().getTime() });
        });
        this.props.r.setInReduxState({ equipmentResourceMap });

        if (this.mapContainerRef.current) {
            this.addTracks(this.mapContainerRef.current);
            if (this.props.entities.length === 1 && this.props.s.equipmentResourceMap[this.props.entities[0].id]) {
                const positions = this.props.s.equipmentResourceMap[this.props.entities[0].id].positions;
                if (positions.length > 0) {
                    this.mapContainerRef.current.getMap().setView({ lat: positions[0].latitude!, lng: positions[0].longitude! }, this.mapContainerRef.current.getMap().getZoom());
                }
            }
        }
    }

    protected renderInfoButton() {
        return <></>;
    }

    protected renderHeaderButtons() {
        const showTracks = this.props.s.mapDisplayMode !== MapDisplayMode.MAP;
        const showAsTree = this.props.s.mapDisplayMode === MapDisplayMode.TREE;
        return <>
            <Button primary={showTracks} compact disabled={this.props.s.collapsedItem} onClick={() => this.props.r.setInReduxState({ mapDisplayMode: this.props.s.mapDisplayMode === MapDisplayMode.MAP ? MapDisplayMode.TREE : MapDisplayMode.MAP })}>{entityDescriptors["HistoryTrack"].getIcon()} {entityDescriptors["HistoryTrack"].getLabel(true)}</Button>
            {showTracks ? <Button compact disabled={this.props.s.collapsedItem} onClick={() => this.props.r.setInReduxState({ mapDisplayMode: showAsTree ? MapDisplayMode.TABLE : MapDisplayMode.TREE })} color={showAsTree ? "orange" : "teal"}><Icon name={showAsTree ? "list alternate outline" : "table"} /> {_msg("HistoryGraphMapItem.showAs." + (showAsTree ? "table" : "tree"))}</Button> : null}
            <div className="EntityTablePage_barDivider" />
            {super.renderHeaderButtons()}
        </>;
    }

    protected createPositionForTable(position: PositionForHistoryGraphMap): HistoryGraphData {
        return { id: position.id, date: moment(position.date).toDate().getTime(), label: "Position", value: "[" + position.latitude + " ," + position.longitude + "]" };
    }

    protected getData() {
        const props = this.props;
        const ids = Object.keys(props.s.equipmentResourceMap).map(key => Number(key));
        const compactTable = ids.length > 1 && Object.keys(this.state.selected).length > 0 && this.isAnimationOn();
        let data: HistoryGraphData[] = [];
        ids.forEach(id => {
            const positions = props.s.equipmentResourceMap[id].positions;
            let position = undefined;
            if (compactTable && !Utils.isNullOrEmpty(this.state.selected[id])) {
                position = positions.find(pos => pos.id === this.state.selected[id]);
            }
            data = data.concat((position ? [position] : positions).map(pos => this.createPositionForTable(pos)));
        });
        return data;
    }

    protected renderTracksTable() {
        const props = this.props;
        const columns = ["equipmentResource", "startTime", "endTime"].map(name => { return { name, width: COLUMN_DEFAULT_WIDTH }; });
        const ids = Object.keys(props.s.equipmentResourceMap).map(key => Number(key));
        let data: HistoryTrackForHistoryMapGraph[] = [];
        const selectedTracks = this.getSelectedTracks();
        const selectedTracksIds: number[] = [];
        Object.keys(selectedTracks).forEach(key => {
            const id = Number(key);
            selectedTracksIds.push(selectedTracks[id]);
        });
        ids.forEach(id => {
            data = data.concat(props.currentStartDate && props.currentEndDate ? props.s.equipmentResourceMap[id].tracks.filter(track => {
                return (track.startTime >= props.currentStartDate! && track.startTime <= props.currentEndDate!) || (track.endTime >= props.currentStartDate! && track.endTime <= props.currentEndDate!);
            }) : props.s.equipmentResourceMap[id].tracks);
        });
        let selectedAsParams = undefined;
        if (selectedTracksIds.length > 1) {
            selectedAsParams = data.reduce(function(a, b) {
                return selectedTracksIds.includes(a.id) && a.startTime > b.startTime ? a : b;
            }).id;
        } else {
            selectedAsParams = data.find(d => d.id === selectedTracksIds[0])?.id;
        }
        {/* Not all components are migrated to RRC so the id from EntityTableSimple can be conflicting if other table is rendered in the same page. */}
        return <EntityTableSimpleRRC id="entityTableSimple3" entityDescriptor={historyTrackEntityDescriptor} columns={columns} entitiesAsParams={data} selectedAsParams={selectedAsParams} />;
    }

    private getDuration(startTime?: any, endTime?: any) {
        if (!startTime || !endTime) {
            return "";
        }
        let minutes = Math.floor((new Date(endTime).valueOf() - new Date(startTime).valueOf()) / (60 * 1000));
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        hours = hours - (days * 24);
        minutes = minutes - (days * 24 * 60) - (hours * 60);
        return (days > 0 ? days + " d " : "") + (hours > 0 ? hours + " h " : "") + (minutes > 0 ? minutes + " min" : "");
    }

    protected renderTracksTree() {
        const selectedTracks = this.getSelectedTracks();
        const tracks = Object.keys(this.props.s.equipmentResourceMap).map((key, index) => {
            const id = Number(key);
            const equipmentResource = this.props.s.equipmentResourceMap[id];
            const positions = equipmentResource.positions;
            const tracks = equipmentResource.tracks;
            const identifier = tracks[0]?.equipmentResource?.identifier;
            if (!identifier) {
                return null;
            }
            const showInTracksTree = this.props.s.equipmentResourceMap[id].show;
            const trackSegments = tracks.map((track, index) => {
                if (!showInTracksTree) {
                    return null;
                }
                const startPosition = positions.find(position => position.date === track.startTime);
                if (!startPosition) {
                    return null;
                }
                let endPosition = undefined as PositionForHistoryGraphMap | undefined;
                if (track.endTime) {
                    endPosition = positions.find(positions => positions.date === track.endTime);
                    if (!endPosition) {
                        return null;
                    }
                } else {
                    endPosition = startPosition;
                    positions.forEach(position => {
                        if (position.date > endPosition!.date) {
                            endPosition = position.date;
                        }
                    })
                }
                const previousItem = tracks[index - 1];
                const previousItemEndPosition = previousItem ? positions.find(position => position.date === previousItem.startTime) : undefined;
                const distanceBetweenTracks = previousItemEndPosition ? getDistance({ latitude: startPosition.latitude!, longitude: startPosition.longitude! },
                    { latitude: previousItemEndPosition.latitude!, longitude: previousItemEndPosition.longitude! }, 1) : 0;
                const selected = selectedTracks[id] === track.id;
                return <Segment className="HistoryGraphMapItem_trackSegment" compact secondary={selected} color={selected ? "grey" : undefined}>
                    {this.renderTrackTreeItem(track.id, track, true, this.getDuration(previousItem?.endTime, startPosition.date), distanceBetweenTracks, equipmentResource.equipmentType)}
                    <Icon className="small-margin-left" name="arrow right" size="small" />
                    {this.renderTrackTreeItem(track.id, track, false, this.getDuration(startPosition.date, endPosition.date), 0, equipmentResource.equipmentType)}
                </Segment>;
            });
            return <Segment className="flex-grow">
                <div style={{ display: "flex" }}><Icon name="circle" style={{ color: getHistoryGraphColor(index) }}></Icon><span style={{flex: "1 1 auto"}}>{identifier}</span><Button size="mini" compact icon={<Icon name={showInTracksTree ? "minus" : "plus"} />} onClick={() => this.props.r.showOrHideEquipmentResource(id)} /></div>
                {trackSegments}
            </Segment>;
        }).filter(er => er);
        return <div className="small-margin-top flex-grow" style={{ display: "block" }}>{tracks.length > 0 ? tracks : <Message>{_msg("HistoryGraphMapItem.noDataAvailable")}</Message>}</div>;
    }

    protected renderTrackTreeItem(id: number, item: HistoryTrackForHistoryMapGraph, isStop: boolean, duration: string, distance: number, equipmentType: getPlateNumberFromIds_equipmentResourceService_findByFilter_results_equipmentType) {
        const isQuestion = distance > MAX_DISTANCE_BETWEEN_TRACKS;
        const color = isStop ? (isQuestion ? TrackColor.QUESTION : TrackColor.STOP) : TrackColor.MOVEMENT;
        const icon = isStop ? (isQuestion ? "question" : "stop") : "play";
        const vehicleIcon = equipmentType?.icon ? <img className="EqRes_mapIcon_svg EqRes_icon_svg" src={
            equipmentType.icon.endsWith(".svg") ? equipmentType.icon :(eqImages256x256[equipmentType.icon] || getIcon(equipmentType.icon)?.image.src)} alt={equipmentType.icon}></img>
        : null;
        const detail = <>
            <Label color={color} icon={icon} content={(isStop ? (isQuestion ? _msg("HistoricalMap.probablyStopped", duration) : _msg("HistoricalMap.stopped", duration)) : _msg("HistoricalMap.movement", duration))} />
            {isQuestion ?
                <div>
                    <b>{_msg("HistoricalMap.note")}:</b> {_msg("HistoricalMap.missingTelemetry")}
                    <br />
                    <b>{_msg("HistoricalMap.distance")}:</b> {distance.toFixed(2)} m
            </div> : null}
        </>;

        const info = moment(isStop ? item.startTime : item.endTime).format(Utils.timeFormat);
        return <Popup
            open={this.props.s.openTrackPopup?.id === id &&  this.props.s.openTrackPopup?.isStop === isStop}
            onOpen={() => this.props.r.setInReduxState({ openTrackPopup: { id, isStop } })}
            onClose={() => this.props.r.setInReduxState({ openTrackPopup: undefined })}
            closeOnEscape
            disabled={duration === ""}
            content={<>
                <div style={{ textAlign: "center" }}>{moment(isStop ? item.startTime : item.endTime).format(Utils.dateFormat)}</div>
                <Divider />
                {detail}
            </>}
            trigger={<span onClick={(e: React.MouseEvent<HTMLElement>) => { e.stopPropagation(); e.preventDefault(); }}>
                {isStop ? <>&nbsp;<Icon name={TreeIcon.POINT} /></> : vehicleIcon}&nbsp;
                {duration === "" ? info : <a href="/#" style={{ textDecoration: "underline", opacity: "1", color: "black" }}>{info}</a>}
                {duration === "" ? null : <Label color={color} icon={icon} style={{ padding: "5px" }} content={this.props.s.openTrackPopup ? <a href="/#" style={{ textDecoration: "underline", opacity: "1" }}>{duration}</a> : duration} />}
            </span>}
        />;
    }

    protected renderTracks() {
        return this.props.s.mapDisplayMode === MapDisplayMode.TREE ? this.renderTracksTree() : this.renderTracksTable();
    }

    protected getSelectedForEquipment(id: number) {
        const props = this.props;
        const prev_date_index = Object.assign([] as any[], props.s.equipmentResourceMap[id].positions)
            .sort((a, b) => (Number(b.date) - Number(a.date)))
            .findIndex(date => Number(date.date) - props.currentTime! <= 0);

        if (prev_date_index != -1) {
            return props.s.equipmentResourceMap[id].positions[prev_date_index];
        }
        return undefined;
    }

    protected getSelected() {
        const props = this.props;
        const selected: { [id: number]: number } = {};
        console.time("MAP");
        Object.keys(props.s.equipmentResourceMap).forEach(key => {
            const id = Number(key);
            const position = this.getSelectedForEquipment(id);
            if (position) {
                selected[id] = position.id;
            }
        });
        console.timeEnd("MAP");
        return selected;
    }

    protected getSelectedTracks() {
        const props = this.props;
        const selectedTracks: { [id: number]: number } = {};
        Object.keys(props.s.equipmentResourceMap).forEach(key => {
            const id = Number(key);
            const data = props.s.equipmentResourceMap[id];
            const position = this.getSelectedForEquipment(id);
            if (position) {
                const track = data.tracks.find(track => track.startTime <= position.date && position.date <= track.endTime);
                if (track) {
                    selectedTracks[id] = track.id;
                }
            }
        });
        return selectedTracks;
    }

    protected showError() {
        return false;
    }

    private renderMap() {
        return <div className="no-padding-margin flex-container flex-grow-shrink-no-overflow" style={{ height: this.getHeight() }}><MapContainerLeafletRRC id={this.getName() + this.props.s.displayMode} ref={this.mapContainerRef} saveCenterZoomInStorage={true}
            mapId="historyGraphMap" pruneClusterMode={false} bingAPIKey={appMeta.getMapSettings().bingAPIKey}
            layers={{[TRACK]: { layerType: POLYLINE_TYPE }}}
        /></div>;
    }

    protected renderItem() {
        const size = this.props.s.mapDisplayMode === MapDisplayMode.MAP ? "100%" : 
            // if position table is displayed, it takes 25% of the available space, this means we have 75% available to split here;
            // the remainder is split into thirds (66%), each third being 25% ogetDistancepositif the total width, giving us:
            // 50% for the map, 25% for the track table, 25% for the position table
            this.props.s.displayMode === DisplayMode.CHART ? "75%" : "66%";
        return <SplitPaneExt size={size} paneStyle={{ overflow: "hidden" }} pane2Style={{ maxHeight: this.getHeight() }}>
            {this.renderMap()}
            {this.renderTracks()}
        </SplitPaneExt>;
    }
}

export const HistoryGraphMapItemRRC = ReduxReusableComponents.connectRRC(HistoryGraphMapItemState, HistoryGraphMapItemReducers, HistoryGraphMapItem);
