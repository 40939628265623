import gql from "graphql-tag";

// XopsDiffUpdateService.getGanttDataNew
export const LOAD_DATA_FOR_GANTT_PAGE = gql`
    query getGanttData($date: String) {
        diffUpdateService_ganttData(clientDate: $date)
    }
`;

// AssignTaskToRessorcePage queries

export const MISSION_ASSIGN_OBJECT_TO_MISSION = gql`
    mutation mission2Service_assignObjectToMission($params: ParamsInput) {
        mission2Service_assignObjectToMission(params: $params) 
    }
`;

export const MISSION_CHECK_MISSION_VALIDATION = gql`
    mutation mission2Service_checkMissionValidation($missionEntityUid: String, $humanResourceEntityUid: String, $equipmentResourceEntityUid: String,
        $equipmentTypeEntityUid: String, $missionStartTime: String, $missionEndTime: String, $minimumDurationBetweenMissions: Int, $alertType: String,
        $specialMissionsOnly: Boolean, $checkHumanResource: Boolean) {
            mission2Service_checkMissionValidation(missionEntityUid: $missionEntityUid, humanResourceEntityUid: $humanResourceEntityUid,
                equipmentResourceEntityUid: $equipmentResourceEntityUid, equipmentTypeEntityUid: $equipmentTypeEntityUid, missionStartTime: $missionStartTime,
                missionEndTime: $missionEndTime, minimumDurationBetweenMissions: $minimumDurationBetweenMissions, alertType: $alertType,
                specialMissionsOnly: $specialMissionsOnly, checkHumanResource: $checkHumanResource)
        }
`

export const MISSION_SAVE = gql`
    mutation mission2Service_save($params: SaveParams_LongInput) {
        mission2Service_save(params: $params) {
            id
        }
    }
`

export const MISSION_DELETE = gql`
    mutation mission2Service_deleteById($id: Long) {
        mission2Service_deleteById(id: $id)
    }
`

export const OAG_DELETE = gql`
    mutation objectActionGroupService_deleteOAGById($id: Long) {
        objectActionGroupService_deleteOAGById(id: $id)
    }
`
