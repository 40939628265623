import { Utils } from "@crispico/foundation-react";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { InitializationsForClient, TempSettingsXops } from "app";
import _ from "lodash";
import { Color, XopsColorRegistry } from "pages/XopsColor/XopsColorRegistry";
import { AbstractGantt, ITEM_HEIGHT } from "../AbstractGantt";
import { GANTT_TYPES } from "../ganttTypeRenderers";
import { AbstractTypeRenderer } from "./AbstractTypeRenderer";
import { MissionStatus } from "./constants";

export class MissionRenderer extends AbstractTypeRenderer {

    static getMissionColor(mission: any, alpha: number = 0) {
        let color = "#808080"; // gray
        if (mission?.inactivityType && mission.inactivityType.color != undefined) {
            color = Utils.convertColorToHex(mission.inactivityType.color);
        } else {   
            if (!mission?.status) {
                color = XopsColorRegistry.INSTANCE.getColor(Color.NO_MISSION);
            }    
            if (mission.finishedByUser) {
                color = XopsColorRegistry.INSTANCE.getColor(Color.MISSION_STATUS_FINISHED_BYUSER);
            }    
            switch (mission?.status) {
                case MissionStatus.MISSION_STATUS_NEW:
                    color = XopsColorRegistry.INSTANCE.getColor(Color.MISSION_STATUS_NEW);
                    break;
                case MissionStatus.MISSION_STATUS_VIEWED:
                    color = XopsColorRegistry.INSTANCE.getColor(Color.MISSION_STATUS_VIEWED);
                    break;
                case MissionStatus.MISSION_STATUS_STARTED:
                    color = XopsColorRegistry.INSTANCE.getColor(Color.MISSION_STATUS_STARTED);
                    break;
                case MissionStatus.MISSION_STATUS_FINISHED:
                    color = XopsColorRegistry.INSTANCE.getColor(Color.MISSION_STATUS_FINISHED);
                    break;
                case MissionStatus.MISSION_STATUS_DRAFT:
                    color = XopsColorRegistry.INSTANCE.getColor(Color.MISSION_STATUS_DRAFT);
                    break;
                case MissionStatus.MISSION_STATUS_TRANSFERED:
                    color = XopsColorRegistry.INSTANCE.getColor(Color.MISSION_STATUS_TRANSFERED);
                    break;              
                case MissionStatus.MISSION_STATUS_IN_POSITION:
                    color = XopsColorRegistry.INSTANCE.getColor(Color.MISSION_STATUS_IN_POSITION);
                    break;
                case MissionStatus.MISSION_STATUS_START_LOADING:
                    color = XopsColorRegistry.INSTANCE.getColor(Color.MISSION_STATUS_START_LOADING);
                    break;
                case MissionStatus.MISSION_STATUS_STOP_LOADING:
                    color = XopsColorRegistry.INSTANCE.getColor(Color.MISSION_STATUS_STOP_LOADING);
                    break;
                case MissionStatus.MISSION_STATUS_START_PAUSE:
                    color = XopsColorRegistry.INSTANCE.getColor(Color.MISSION_STATUS_START_PAUSE);
                    break;
                case MissionStatus.MISSION_STATUS_VALIDATED:
                    color = XopsColorRegistry.INSTANCE.getColor(Color.MISSION_STATUS_FINISHED);
                    break;
                case MissionStatus.MISSION_STATUS_PLANNED_NOT_DONE:
                    color = XopsColorRegistry.INSTANCE.getColor(Color.MISSION_STATUS_PLANNED_NOT_DONE);
                    break;
            }
        }
        return Utils.getRGBAColor(color, alpha);
    }

    protected getEntityName() {
        return "Mission2";
    }

    protected getMiddleLabel(mission: any) {
        const { entities } = this.props.gantt.props;
        if (mission?.inactivityType) {
            return mission.inactivityType.name;
        }
        const oags = AbstractGantt.find("ObjectActionGroup", "mission.id", mission.id, entities);
        let flightNames = oags.map(oag => Utils.isNullOrEmpty(oag.object.taskGroup.name) ? "" : oag.object.taskGroup.name);
        if (flightNames.length === 0) {
            const tasks = AbstractGantt.find("Task", "mission.id", mission.id, entities);
            flightNames = tasks.map(task => Utils.isNullOrEmpty(task.taskGroup.name) ? "" : task.taskGroup.name);
        }
        return _.uniq(flightNames).join('/');
    };

    protected canDrop(source: any, destination: any) {
        const { entities } = this.props.gantt.props;
        const mission = AbstractGantt.findOne("Mission2", "id", destination.entityId, entities);
        const { currentOrganization, tempSettings } = AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().initializationsForClient as InitializationsForClient;
        if ((tempSettings as unknown as TempSettingsXops)?.tempUnitSettings?.length) {
            const hr = mission.humanResource;
            if (hr && currentOrganization && hr.unit.id != currentOrganization.id) {
                if (!hr.borrowedToUnit || hr.borrowedToUnit.id != currentOrganization.id) {
                    return false;
                }
            }
        }

        if (mission.status === MissionStatus.MISSION_STATUS_FINISHED || source.type === GANTT_TYPES.HUMAN_RESOURCE) {
            return false;
        }
        return true;
    }

    protected onDrop(source: any, destination: any) {
        if (source.type === GANTT_TYPES.MISSION) {
            // TODO: open mission form after migration
            return;
        }
        const { entities } = this.props.gantt.props;
        let taskIds;
        const missionId = destination.entityId;
        const humanResourceId = AbstractGantt.findOne("Mission2", "id", missionId, entities)?.humanResource?.id;
        if (source.type === GANTT_TYPES.TASK) {
            taskIds = [source.entityId];
        } else if (source.type === GANTT_TYPES.FLIGHT) {
            taskIds = AbstractGantt.find("Task", "taskGroup.id", source.entityId, entities).map(task => task.id);
        }
        this.openAssignTaskToResource({ humanResourceId, missionId, taskIds, initialSelectedTaskIds: taskIds });
    }

    protected renderContent() {
        const { entities } = this.props.gantt.props;
        const mission = AbstractGantt.findOne("Mission2", "id", this.props.item.entityId, entities);
        if (!mission) {
            return <></>;
        }
        const middleLabel = this.getMiddleLabel(mission);       
        const rightLabel = this.getRightLabel();       
     
        return <div key={this.props.item.entityId} className="flex-container-row flex-center TaskGanttRenderer_TaskMode"
                style={{
                    height: ITEM_HEIGHT - 2, // CC: I don't like this; when time, lets investigate another way to add gap between items
                    backgroundColor: MissionRenderer.getMissionColor(mission, 0.6),
                    border: `1px solid ${mission.type?.color || "grey"}`,
                    borderRadius: '1px',
                }}>
                <div style={{ fontWeight: 'bold' }}>
                    {middleLabel}
                </div>
                {rightLabel && <div>{rightLabel}</div>}
            </div>;
    }
} 
