import { AbstractGantt, GROUP_LABEL_PROPRETY } from "../AbstractGantt";
import { AbstractTypeRenderer } from "./AbstractTypeRenderer";
import { FlightInfoRenderer } from "./FlightInfoRenderer";

export class DepartureFlightRenderer extends AbstractTypeRenderer {

    protected renderTooltip() {
        const entity = AbstractGantt.findOne("Flight", "id", this.props.item.depFlightId, this.props.gantt.props.entities);
        return entity ? <FlightInfoRenderer entity={entity} /> : undefined;
    }

    protected renderContent(): JSX.Element {
        return <div className="wh100 flex-center flex-justify-content-center" onClick={this.onItemClick}>{this.props.item[GROUP_LABEL_PROPRETY.DEP_FLIGHT]}</div>
    }

    render() {
        return this.renderContent();
    }
}
