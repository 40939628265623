import { EntityDescriptor, ENT_READ, PrivateRoute, PrivateRouteProps, Utils, EntityEditorPage, PropsFrom, FieldDescriptor } from "@crispico/foundation-react";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { missionEntityDescriptor } from "AppEntityDescriptors";
import { MissionsAwaitingForDriversPageHOC } from "./MissionsAwaitingForDriversPage";
import { MissionEventValidationPageRRC } from "./MissionEventValidationPage";
import { DatePickerFieldEditor } from "@crispico/foundation-react/components/DatePicker/DatePickerFieldEditor";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/DateFieldRenderer";
import { MissionStatus } from "pages/gantt/typeRenderers/constants";

const additionalDateFieldEditorProps = FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: Utils.dateTimeWithSecFormat });
const additionalDateFieldRendererProps = FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { format: Utils.dateTimeWithSecFormat });

export class Mission2EntityDescriptor extends EntityDescriptor {

    protected customize() {
        this.isInDefaultColumnConfig(true, "creationDate", "humanResource", "equipmentResource", "startTime", "endTime", "type", "comment", "currentState", "mobileDevice")
            .removeFieldDescriptors("humanResourceId", "equipmentResourceId", "inactivityTypeId", "mobileDeviceId", "dataString", "dataVersion", "day", "currentStopId")
            .addFieldDescriptor({ name: "status", type: FieldType.dropdown })
            .addFieldDescriptor({ name: "creationDate", type: FieldType.date, additionalFieldEditorProps: additionalDateFieldEditorProps, additionalFieldRendererProps: additionalDateFieldRendererProps })
            .addFieldDescriptor({ name: "startTime", type: FieldType.date, additionalFieldEditorProps: additionalDateFieldEditorProps, additionalFieldRendererProps: additionalDateFieldRendererProps })
            .addFieldDescriptor({ name: "endTime", type: FieldType.date, additionalFieldEditorProps: additionalDateFieldEditorProps, additionalFieldRendererProps: additionalDateFieldRendererProps })
        this.infoEditor.wrappedComponentClass = class Ext extends EntityEditorPage<PropsFrom<typeof this.infoEditor.slice>> {
            protected getExtraTabPanes() {
                const pannes = super.getExtraTabPanes()
                pannes.push({
                    routeProps: { path: "/eventsValidation" }, menuItemProps: { content: _msg("MissionEventValidationPage.title") },
                    render: () => <MissionEventValidationPageRRC id="missionEventValidation" entityName="Mission2" entityId={this.props.entity?.id} />
                })
                return pannes;
            }
        }
    }
}

export const MissionsAwaitingForDriversPageUrl = "/MissionsAwaitingForDrivers";
export const missionsAwaitingForDriversPageRoute = (computeRoute: (props: PrivateRouteProps) => JSX.Element) =>
    <PrivateRoute key="missionsAwaitingForDriversPage"
        path={MissionsAwaitingForDriversPageUrl}
        render={(props) => <MissionsAwaitingForDriversPageHOC {...props} id="missionsAwaitingForDriversPage" />}
        computeRoute={computeRoute} />

export const missionsAwaitingForDriversPageMenuEntry = () => {
    return {
        id: "missionsAwaitingForDriversPage",
        content: _msg("MissionsAwaitingForDriversPage.title"),
        to: MissionsAwaitingForDriversPageUrl, exact: true, icon: "list",
        permission: Utils.pipeJoin([ENT_READ, missionEntityDescriptor.name])
    }
};
