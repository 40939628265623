import { FilterOperators } from "@crispico/foundation-gwt-js";
import { apolloClient, ConnectedComponentInSimpleComponent, ConnectedPageInfo, createSliceFoundation, EntityDescriptor, FieldDescriptor, getBaseImpures, getBaseReducers, Optional, Organization, PHONE_MODE_KEY, PropsFrom, SliceFoundation, StateFrom, Utils } from "@crispico/foundation-react";
import { CustomQueryBar, CUSTOM_QUERY_BAR_MODE, sliceCustomQueryBar } from "@crispico/foundation-react/components/CustomQuery/CustomQueryBar";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { Sort } from "@crispico/foundation-react/components/CustomQuery/SortBar";
import { Drawer } from "antd";
import { Pair_Double_DoubleInput } from "apollo-gen/globalTypes";
import { MapSettings, MarkerSettings } from "app";
import { ID, Location, MapContainerLeafletRRC, MapContainerLeaflet, MarkerData, PolygonData, POLYGON_TYPE, SelectedLayer, MARKER_TYPE } from "components/MapContainerLeaflet/MapContainerLeaflet";
import { DEFAULT_ZOOM_LEVEL } from "components/MapContainerLeaflet/MapLayerHelpers";
import { LOAD_ADDRESSES_FOR_REAL_TIME_MAP, LOAD_AIRPORT_DATA_BY_ID, LOAD_EQUIPMENT_RESOURCES_FOR_MAP_GO_TO_BUTTON, LOAD_FLIGHTS_FOR_TERRITORIES_FOR_MAP, LOAD_TERRITORIES_FOR_REAL_TIME_MAP } from "components/realTimeMap/queries";
import { push } from "connected-react-router";
import lodash from 'lodash';
import moment from "moment";
import { equipmentResourceEntityDescriptor } from "pages/EquipmentResource/equipmentResourceEntityDescriptor";
import { addressEntityDescriptor, flightEntityDescriptor } from "AppEntityDescriptors";
import { EquipmentResourceBigInfoArea } from "pages/EquipmentResource/EquipmentResourceUtils";
import React from "react";
import { Button, Icon, Segment, Label, Divider, Checkbox, Popup, Dropdown, Modal } from "semantic-ui-react";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import { v4 as uuid } from 'uuid';
import { entityDescriptors, getOrganizationFilter } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import Measure from "react-measure";
import { List as ListVirtualized, ListRowProps } from 'react-virtualized';
import { TerritoryForMap } from "apollo-gen/TerritoryForMap";
import { territoryEntityDescriptor } from "pages/Territory/territoryEntityDescriptor";
import { FieldInterval } from "@crispico/foundation-react/entity_crud/CrudSettings";
import { getDropdownItemLabel } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/DropdownFieldRenderer";
import { ColorRegistry } from "@crispico/foundation-react/utils/ColorRegistry";
import { QueryDetails, RealTimeMapEntityTypeFactories } from "./RealTimeMapEntityTypeFactory";
import { mobileDeviceEntityDescriptor } from "pages/MobileDevice/mobileDeviceEntityDescriptor";
import { MapGoToButton, RealTimeUtils } from "./RealTimeUtils";
import _ from "lodash";
import { AddressForMap } from "apollo-gen/AddressForMap";
import { FindByStringParams } from "@crispico/foundation-react/entity_crud/FindByStringParams";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { Link } from "react-router-dom";
import { FlightForMap } from "apollo-gen/FlightForMap";
import { loadFlightsForTerritoriesForMap, loadFlightsForTerritoriesForMapVariables } from "apollo-gen/loadFlightsForTerritoriesForMap";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import gql from "graphql-tag";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/DateFieldRenderer";
import { FlightDepartureFieldRenderer } from "pages/flight/FlightDepartureFieldRenderer";


export const MAP_CLUSTER_MODE_KEY = 'map.clusterMode';
export enum CLUSTER_MODE { PRUNE_CLUSTER = 'pruneCluster', CLASSIC_CLUSTER = 'classicCluster' };

export const refreshRateMillis: number = 2 * 1000;

export class RealTimeMapState extends State {
        entitiesList = {} as { [key: string]: any[] };
        entitiesToDisplayIds = {} as { [key: string]: any[] };
        mostRecentEntityUpdates = {} as { [key: string]: string };
        mostRecentUpdatedMapBounds = undefined as Optional<Pair_Double_DoubleInput[]>;
        entityToSelectAfterUpdates = {} as { [key: string]: any };
        selectedEntityType = "EquipmentResource" as string;
        loadUUID = undefined as Optional<string>;
        filterBarCheckboxes = {} as { [key: string]: boolean };
        
        territoriesForMap = [] as TerritoryForMap[];
        addressesForMap = [] as AddressForMap[];

        showStaticEntities = { [territoryEntityDescriptor.name]: false, [addressEntityDescriptor.name]: false } as { [key: string]: boolean };

        showFlightsForTerritories = false as boolean;
        flightsForMap = {} as { [key: string]: FlightForMap[] };
}

export class RealTimeMapReducers extends Reducers<RealTimeMapState> {

        updateEntities(updatedEntities: any[]) {
            if (!this.s.entitiesList[this.s.selectedEntityType]) {
                this.s.entitiesList[this.s.selectedEntityType] = updatedEntities;
            }

            // used to optimize Array.findIndex, don't remove this, without it, for 10000 equipments, time will increase with ~10 sec
            const eqMapIdIndex: {[key: number]: number} = {};
            this.s.entitiesList[this.s.selectedEntityType].forEach((eq, index) => eqMapIdIndex[eq.id] = index);
            updatedEntities.forEach(updatedEq => {
                const index = eqMapIdIndex[updatedEq.id];
                if (index !== null && index !== undefined) {
                    this.s.entitiesList[this.s.selectedEntityType][index] = updatedEq;
                } else {
                    this.s.entitiesList[this.s.selectedEntityType].push(updatedEq);
                }
            });
        }

        changeFilterBar(checkbox: string) {
            this.s.filterBarCheckboxes[checkbox] = !this.s.filterBarCheckboxes[checkbox];
        }

        updateEntityToSelectAfterUpdates(value: any) {
            this.s.entityToSelectAfterUpdates[this.s.selectedEntityType] = value
        }

        updateMostRecentEntityUpdates(value: any) {
            this.s.mostRecentEntityUpdates[this.s.selectedEntityType] = value
        }
        updateEntitiesToDisplayIds(entitiesIds: any[]) {
            this.s.entitiesToDisplayIds[this.s.selectedEntityType] = entitiesIds;
    }
}

type PropsNotFromState = {
    mapSettings: MapSettings,
    entityTypes: string[],
    rootFilter?: Filter,
    rootSort?: Sort[],
    showCustomQueryBar?: boolean,
    showGoToTableButton?: boolean,
    airport?: any,
    mapId?: string,
    currentOrganizationToFilterBy?: Organization
};

type RealTimeMapLocalState = { drawerOpen: boolean, measuredWidth: number, measuredHeight: number, modalOpen: boolean };
export type Props = RRCProps<RealTimeMapState, RealTimeMapReducers> & PropsNotFromState;
export class RealTimeMap extends React.Component<Props, RealTimeMapLocalState> {

    customQueryBarRef = React.createRef<CustomQueryBar>();
    protected ref = React.createRef<ConnectedComponentInSimpleComponent>();

        async loadEntities(tableFilter: Filter | undefined, tableSorts: Sort[], mapContainer: MapContainerLeaflet | undefined, mapMarkSettings: MapSettings, bounds?: Pair_Double_DoubleInput[]) {    
            const selectedEntityType = this.props.s.selectedEntityType;
            if (!mapContainer?.areBoundsSmallerThen(this.props.s.mostRecentUpdatedMapBounds, 0.001)) {
                // an offset was added so no changes to non-unpdated markers if the map center is modified a little
                // useful when searching & selecting ERs in visible area -> they are centered, but the map bounds doesn't change so much
                this.props.r.setInReduxState({ mostRecentUpdatedMapBounds: bounds });
                this.props.r.updateMostRecentEntityUpdates(undefined);
            }

            const realTimeEntityFactory = RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[selectedEntityType];
            const filters: Filter[] = [];
            const sorts: Sort[] = Object.assign([], tableSorts);

            if (tableFilter) {
                filters.push(tableFilter);
            }
            
            const orgFilter = getOrganizationFilter(realTimeEntityFactory.getEd(), global.currentOrganizationToFilterBy);
            if (orgFilter) {
                filters.push(orgFilter);
            }

            const uuid: Optional<string> = this.props.s.loadUUID;
            const customFields = Object.values(realTimeEntityFactory.getEd().fields).filter(x => x.isCustomField).map(x => x.name)

            const entityQueryDetails: QueryDetails = realTimeEntityFactory.getLoadEntitiesQueryDetails(mapMarkSettings, sorts);
            if (this.props.s.mostRecentEntityUpdates![selectedEntityType]) {
                entityQueryDetails.filters.push(Filter.create(realTimeEntityFactory.getMostRecentEntityUpdatesField(),
                    FilterOperators.forDate.greaterThan, moment(this.props.s.mostRecentEntityUpdates![selectedEntityType]).toISOString()));
            }
            
            const entities: any[] = (await apolloClient.query({
                query: entityQueryDetails.query,
                variables: { filter: Filter.createComposed(FilterOperators.forComposedFilter.and, filters.concat(entityQueryDetails.filters)), sorts: entityQueryDetails.sorts, coordinates: bounds, whichFields: customFields },
                context: { showSpinner: false }
            })).data[lodash.lowerFirst(realTimeEntityFactory.getEd().name) + "Service_findByRectangle"]?.results;

            if (uuid !== this.props.s.loadUUID) {
                return;
            }

            if (entities.length > 0) {
                this.props.r.updateEntities(entities);
                // get most recent date update from the newest entities 
                this.props.r.updateMostRecentEntityUpdates(entities[0]);
                this.updateEntitiesOnMapAndInList(selectedEntityType, mapContainer, mapMarkSettings, sorts);
            } else if (realTimeEntityFactory.getMarkerSettings(mapMarkSettings)?.colors.filter(color => color.showInFilterBar && color.useDurationBetweenNowAndFieldValue)?.length) {
                // updateEntitiesOnMapAndInList accordingly to current moment if settings has these types of checkboxes
                // ex: no update for entities but just the '<15min' is checked, so need to apply updates to verify the condition with time
                this.updateEntitiesOnMapAndInList(selectedEntityType, mapContainer, mapMarkSettings, sorts);
            } 
        }

        updateEntitiesOnMapAndInList(selectedEntityType: string, mapContainer: MapContainerLeaflet | undefined, mapSettings: MapSettings, sorts: Sort[]) {
            let entitiesToAddOrUpdate: any[] = [];
            let entitiesToRemove: any[] = [];
            let entitiesToDisplayIds: any[] = [];

            // update entities for list
            if (Object.keys(this.props.s.entitiesList).length > 0 && this.props.s.entitiesList[selectedEntityType].length > 0) {
                entitiesToAddOrUpdate = RealTimeUtils.filterEntitiesUsingFilterBar(mapSettings, this.props.s.filterBarCheckboxes, this.props.s.entitiesList[selectedEntityType], selectedEntityType);
                // sort entities after filtering according to sortBar
                if (sorts.length > 0){
                    const properties: string[] = [];
                    const orders: ("asc" | "desc") [] = [];
                    sorts.forEach(sort => {
                        properties.push(sort.field);
                        orders.push(sort.direction == "ASC" ? "asc" : "desc");
                    });

                    // for stopping position change in list, in case of equal to be ordered by id
                    properties.push("id");
                    orders.push("asc");
                    entitiesToAddOrUpdate = _.orderBy(entitiesToAddOrUpdate, properties, orders);
                }
                entitiesToDisplayIds = entitiesToAddOrUpdate.map(er => er.id);
                this.props.r.updateEntitiesToDisplayIds(entitiesToDisplayIds);
            }

            // update entities for map if mapContainer exists
            if (!mapContainer) {
                return;
            }

            // should clear layers from map if no entity correspond with filter bar
            if (entitiesToDisplayIds.length > 0) {
                entitiesToRemove = this.props.s.entitiesList[selectedEntityType].filter(entity => !entitiesToDisplayIds.includes(entity.id));
            } else {
                mapContainer.removeLayers(selectedEntityType);
                return;
            }

            if (!Utils.isNullOrEmpty(entitiesToRemove)) {
                mapContainer.removeLayers(selectedEntityType, entitiesToRemove!.map(entity => entity.id));
            }

            const markerSettings: Optional<MarkerSettings> = RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[selectedEntityType].getMarkerSettings(mapSettings);
            const updates: any[] = RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[selectedEntityType].getLayersForMap(entitiesToAddOrUpdate, markerSettings);
            if (updates.length > 0) {
                mapContainer.addOrUpdateLayers(updates, selectedEntityType, true);
            }

            if (this.props.s.entityToSelectAfterUpdates && this.props.s.entityToSelectAfterUpdates![selectedEntityType]) {
                mapContainer.props.r.setInReduxState({ selectedLayer: { id: this.props.s.entityToSelectAfterUpdates![selectedEntityType]?.id, type: selectedEntityType } });
                this.props.r.updateEntityToSelectAfterUpdates(undefined);
            }
        }

        selectEntity(item: Optional<{ id: any, type: string }>, mapContainer: MapContainerLeaflet) {
            mapContainer.props.r.setInReduxState({ selectedLayer: item ? { id: item.id, type: item.type } : undefined });
        }

        async getEntity(id: number, mapContainer: MapContainerLeaflet, mapSettings: MapSettings) {
            const selectedEntityType = this.props.s.selectedEntityType;
            const realTimeEntityFactory = RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[selectedEntityType];
            const entity: Optional<any> = (await apolloClient.query({
                query: realTimeEntityFactory.getFindEntityQueryDetails(mapSettings).query,
                variables: { id: id }
            })).data[lodash.lowerFirst(realTimeEntityFactory.getEd().name) + "Service_findById"];

            if (!entity){
                return;
            }

            const point: Optional<Location> = realTimeEntityFactory.getEntityPoint(entity);
            if (!point) {
                return;
            }

            mapContainer.props.r.setInReduxState({ center: [point.latitude, point.longitude] });
            if (MapContainerLeaflet.pointInsideBounds(this.props.s.mostRecentUpdatedMapBounds, point)) {
                // if marker is visible, select directly              
                mapContainer.props.r.setInReduxState({ selectedLayer: { id: entity.id, type: selectedEntityType } });
            } else { // marker not visible, wait until the update is done
                this.props.r.updateEntityToSelectAfterUpdates(id);
            }
        }

        async loadTerritoriesForMap(currentBounds?: Pair_Double_DoubleInput[]) {  
            const territories: Optional<TerritoryForMap[]> = (await apolloClient.query({
                query: LOAD_TERRITORIES_FOR_REAL_TIME_MAP,
                variables: {coordinates: currentBounds},
                context: { showSpinner: false }
            })).data.territoryService_findByRectangle?.results;

            if (territories && territories.length > 0){
                await this.props.r.setInReduxState({ territoriesForMap: territories });
            }    
        }

        async loadFlightsForTerritoriesForMap(mapContainer: Optional<MapContainerLeaflet>) {           
            const loadOperationName = "addressService_findByFilter";
            const query = gql(`query q($params: FindByFilterParamsInput) { 
                ${loadOperationName}(params: $params) { results { id name } } }`);
            const addresses = (await apolloClient.query({ query: query, variables: FindByFilterParams.create(), context: { showSpinner: false } })).data[loadOperationName].results;
            let ids: number[] = [];
            this.props.s.territoriesForMap.forEach(territory => {
                const address = addresses.find((a:{id : any, name: string}) => a.name === territory.name);
                if (address !== undefined) {
                    ids.push(address.id);
                }
            });
            if (ids.length === 0) {
                return;
            }
            const filters: Filter[] = [
                Filter.create("date", FilterOperators.forDate.greaterThan, moment(Utils.now()).add(0, "hours").toISOString()),
                Filter.create("date", FilterOperators.forDate.lessThan, moment(Utils.now()).add(12, "hours").toISOString()),
                Filter.create("parking.id", FilterOperators.forNumber.in, ids.join(", "))
            ];
            const flights = (await apolloClient.query<loadFlightsForTerritoriesForMap, loadFlightsForTerritoriesForMapVariables>({
                query: LOAD_FLIGHTS_FOR_TERRITORIES_FOR_MAP,
                variables: FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.and, filters)).sorts([{ field: "date", direction: "ASC" }]),
                context: { showSpinner: false }
            })).data.flightService_findByFilter?.results;
            

            let flightsPerTerritory: { [terrName: string]: FlightForMap[] } = {};
            let data: MarkerData[] = [];
            flights?.forEach((flight: FlightForMap) => {
                const territory = this.props.s.territoriesForMap.find(t => t.name === flight.parking?.name);
                if (territory && territory.name) {
                    if (flightsPerTerritory[territory.id] === undefined) {
                        flightsPerTerritory[territory.id] = [];
                        const point = MapContainerLeaflet.getBoundsCenterPoint(territory?.coordinates);
                        if (point && !Utils.isNullOrEmpty(point.a) && !Utils.isNullOrEmpty(point.b)) {
                            data.push({ id: flight.id, point: { longitude: point.a!, latitude: point.b! }, text: (flight.airline || "") + (flight.number || "") + " " + moment(flight.date).format(Utils.timeFormat) });
                        }
                    }
                    flightsPerTerritory[territory.id].push(flight);                   
                }
            });
            await this.props.r.setInReduxState({ flightsForMap: flightsPerTerritory });
            mapContainer?.addOrUpdateLayers(data, flightEntityDescriptor.name, true);
        }

        addTerritoriesOnMap(mapContainer: Optional<MapContainerLeaflet>) {
            let polygons: PolygonData[] = [];
            this.props.s.territoriesForMap?.forEach((territory: TerritoryForMap) => {
                const points: Location[] = [];
                territory.coordinates?.forEach(p => {
                    points.push({ longitude: p.a, latitude: p.b });
                });
                polygons.push({ id: territory.id, points: points, color: territory.color || undefined, text: territory.name || undefined});
            });
            mapContainer?.addOrUpdateLayers(polygons, territoryEntityDescriptor.name, true);
        }

        async loadAddressesOnMap(currentBounds?: Pair_Double_DoubleInput[]) {
            const addresses: Optional<AddressForMap[]> = (await apolloClient.query({
                query: LOAD_ADDRESSES_FOR_REAL_TIME_MAP,
                variables: {coordinates: currentBounds},
                context: { showSpinner: false }
            })).data.addressService_findByRectangle?.results;

            if (addresses && addresses.length > 0) {
                this.props.r.setInReduxState({ addressesForMap: addresses });
            }
        }

        addAddressesOnMap(mapContainer: Optional<MapContainerLeaflet>) {
            let data: MarkerData[] = [];
            this.props.s.addressesForMap?.forEach((a: AddressForMap) => {
                if (!Utils.isNullOrEmpty(a.longitude) && !Utils.isNullOrEmpty(a.latitude)) {
                    data.push({ id: a.id, point: { longitude: a.longitude!, latitude: a.latitude! }, text: a.name || "" });
                }
            });
            mapContainer?.addOrUpdateLayers(data, addressEntityDescriptor.name, true);
    }

    private mapContainerRef = React.createRef<MapContainerLeaflet>();
    private listRef = React.createRef<ListVirtualized>();
    public buttonBarRef: React.MutableRefObject<any> = React.createRef<any>();

    private timer: number | undefined = undefined;

    constructor(props: Props) {
        super(props);
        this.state = { drawerOpen: false, measuredWidth: 0, measuredHeight: 0, modalOpen: false };

        this.loadEntitiesOnMap = this.loadEntitiesOnMap.bind(this);
        this.updateStaticEntities = this.updateStaticEntities.bind(this);
        this.renderTooltipContent = this.renderTooltipContent.bind(this);
        this.renderMarkerIcon = this.renderMarkerIcon.bind(this);
        this.renderListRow = this.renderListRow.bind(this);
        this.onChangeSort = this.onChangeSort.bind(this);
        this.onChangeFilter = this.onChangeFilter.bind(this);
        this.onChangeSortDirection = this.onChangeSortDirection.bind(this);
    }

    onChangeSort() {
        this.props.showCustomQueryBar && this.loadEntitiesOnMap(true);
    }

    onChangeFilter() {
        this.props.showCustomQueryBar && this.loadEntitiesOnMap(true);
    }

    onChangeSortDirection() {
        this.mapContainerRef && Object.keys(this.props.s.entitiesList).length > 0 && this.updateEntitiesOnMapAndInList(this.props.s.selectedEntityType, this.mapContainerRef.current!, this.props.mapSettings, this.getRootSort());
    }

    protected getRootFilter(): Filter | undefined {
        let filter = this.props.showCustomQueryBar ? this.customQueryBarRef.current?.props.customQuery?.customQueryDefinitionObject.filter : this.props.rootFilter
        if (this.props.rootFilter) {
            if (filter) {
                filter = Filter.createComposedForClient(FilterOperators.forComposedFilter.and, [filter, this.props.rootFilter]);
            } else {
                filter = this.props.rootFilter;
            }
        }
        if (filter){
            filter = Filter.eliminateDisabledFilters(filter);
        } 

        return filter;
    }

    protected getRootSort(): Sort[] {
        return this.props.showCustomQueryBar ? (this.customQueryBarRef.current?.props.customQuery ? this.customQueryBarRef.current?.props.customQuery!.customQueryDefinitionObject.sorts : []) : this.props.rootSort ? this.props.rootSort : [];
    }

    protected async loadEntitiesOnMap(resetAtNextUpdate?: boolean) {
        if (this.mapContainerRef.current) {
            if (resetAtNextUpdate) {
                this.props.r.setInReduxState({ entitiesList: {}, entitiesToDisplayIds: {}, mostRecentEntityUpdates: {}, mostRecentUpdatedMapBounds: undefined });
                this.mapContainerRef.current!.removeLayers(this.props.s.selectedEntityType);
            }
            this.props.r.setInReduxState({ loadUUID: uuid() });
            await this.loadEntities(this.getRootFilter(), this.getRootSort(), this.mapContainerRef.current!, this.props.mapSettings, this.mapContainerRef.current!.getCurrentBounds(0.002));

            this.startTimer();
        }
    }

    protected async loadAndAddStaticEntitiesOnMap(entityDescriptorName: string) {
        if (entityDescriptorName == territoryEntityDescriptor.name) {
            await this.loadTerritoriesForMap(this.mapContainerRef.current?.getCurrentBounds());
            this.addTerritoriesOnMap(this.mapContainerRef.current);
            if (this.props.s.showFlightsForTerritories) {
                this.loadFlightsForTerritoriesForMap(this.mapContainerRef.current);
            }
        } else if (entityDescriptorName == addressEntityDescriptor.name) {
            await this.loadAddressesOnMap(this.mapContainerRef.current?.getCurrentBounds());
            this.addAddressesOnMap(this.mapContainerRef.current);
        }
    }

    protected showStaticEntitiesOnMap(entityDescriptorName: string, show: boolean) {
        if (show) {
            this.loadAndAddStaticEntitiesOnMap(entityDescriptorName);
        } else {
            this.mapContainerRef.current?.removeLayers(entityDescriptorName);
        }

        this.props.r.setInReduxState({ showStaticEntities: { ...this.props.s.showStaticEntities, [entityDescriptorName]: show } });
    }

    componentDidMount() {
        if (this.props.entityTypes && this.props.entityTypes.length == 1){
            this.props.r.setInReduxState({selectedEntityType: this.props.entityTypes[0]});
            const filterBarCheckboxes: {[key: string]: boolean} = {};
            if (this.props.mapSettings) {
                RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[this.props.entityTypes[0]]
                    .getMarkerSettings(this.props.mapSettings)?.colors.filter(color => color.showInFilterBar).forEach(color => {
                        RealTimeUtils.getMarkerColorIntervals(RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[this.props.entityTypes![0]!].getEd().name, color)
                        .forEach(interval => { filterBarCheckboxes[color.field + interval.from] = true; });
                    });
                this.props.r.setInReduxState({ filterBarCheckboxes: filterBarCheckboxes });
            }
            if (this.props.s.entitiesList[this.props.entityTypes[0]]?.length > 0) {
                this.updateEntitiesOnMapAndInList(this.props.s.selectedEntityType, this.mapContainerRef.current!, this.props.mapSettings, this.getRootSort());
            }
            this.componentDidUpdateInternal();

            this.loadEntitiesOnMap(true);
        } else {
            // multiple entities
        }
        
    }

    private startTimer() {
        this.stopTimer(); // if a previous one set -> stop it
        this.timer = window.setTimeout(() => this.loadEntitiesOnMap(), refreshRateMillis);
    }

    private stopTimer() {
        clearTimeout(this.timer);
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    private async setAirportCoordinates(id: number | string) {
        let result = undefined;
        if (typeof id === "number") {
            result = (await apolloClient.query({
                query: LOAD_AIRPORT_DATA_BY_ID,
                variables: { id },
                context: { showSpinner: false }
            })).data["airportService_findById"];
        }
        else {
            RealTimeUtils.setAirportCoordinates(id, this.mapContainerRef);
            return;
        }
        if (this.mapContainerRef.current && result && result.latitude && result.longitude) {
            this.mapContainerRef.current!.getMap().panTo([result.latitude, result.longitude]);
            this.mapContainerRef.current!.getMap().setZoom(DEFAULT_ZOOM_LEVEL);
        }
    }

    private componentDidUpdateInternal(prevProps?: Props, prevState?: RealTimeMapLocalState) {
        // verify if [0, 0] -> default value; if not [0, 0], then it means the center was set by value from session storage, so we don't want to reset it
        if (lodash.isEqual(this.mapContainerRef.current!.props.s.center, [0, 0]) && this.props.mapSettings.airport !== null) {
            this.setAirportCoordinates(this.props.mapSettings.airport);
        }

        if (prevProps && (this.props.airport !== prevProps?.airport) && this.props.airport.id) {
            this.setAirportCoordinates(this.props.airport.id);
        }

        RealTimeUtils.selectAirportOnCurrentOrganizationToFilterByChange(prevProps, this.props, this.mapContainerRef);

        if (prevState?.drawerOpen && !this.state.drawerOpen || prevState?.modalOpen && !this.state.modalOpen) {
            this.mapContainerRef.current!.props.r.setInReduxState({ selectedLayer: undefined });
        }
        if (prevProps?.s.showFlightsForTerritories !== this.props.s.showFlightsForTerritories) {
            this.mapContainerRef.current?.removeLayers(flightEntityDescriptor.name);
            this.props.r.setInReduxState({ flightsForMap: {} });
        }
        if (prevProps && (!lodash.isEqual(prevProps?.currentOrganizationToFilterBy, this.props.currentOrganizationToFilterBy)
            || (!this.props.showCustomQueryBar && !lodash.isEqual(prevProps?.rootFilter, this.props.rootFilter) || !lodash.isEqual(prevProps?.rootSort, this.props.rootSort)))) {
            this.loadEntitiesOnMap(true);
        }

        if (!lodash.isEqual(prevProps?.s.mostRecentUpdatedMapBounds, this.props.s.mostRecentUpdatedMapBounds)) {
            // check for every static entity if must be displayed on map
            Object.entries(this.props.s.showStaticEntities).forEach(([key, value]) => value ? this.loadAndAddStaticEntitiesOnMap(key) : null);
        }

        // if filter bar checkboxes were changed or existing sort fields directions were changed call updateEntitiesOnMapAndInList
        if (prevProps && this.mapContainerRef && Object.keys(this.props.s.entitiesList).length > 0 && (Object.keys(prevProps.s.filterBarCheckboxes).length > 0 && prevProps.s.filterBarCheckboxes !== this.props.s.filterBarCheckboxes)) {
            this.updateEntitiesOnMapAndInList(this.props.s.selectedEntityType, this.mapContainerRef.current!, this.props.mapSettings, this.getRootSort());
        }
    }

    componentDidUpdate(prevProps: Props, prevState: RealTimeMapLocalState) {
        this.componentDidUpdateInternal(prevProps, prevState);
    }

    private getMapGoToButtonProps() {
        const rootFilter = this.getRootFilter();
        const realtimeEntityFactory = RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[this.props.s.selectedEntityType];

        let filters = rootFilter ? realtimeEntityFactory.getFiltersForFindEntity().concat([rootFilter]) : realtimeEntityFactory.getFiltersForFindEntity()
        const organizationFilter = getOrganizationFilter(entityDescriptors[realtimeEntityFactory.getEd().name], global.currentOrganizationToFilterBy);
        if (organizationFilter) {
            filters.push(organizationFilter)
        }

        let mapGoToButtonProps = RealTimeUtils.getMapGoToButtonProps(this.mapContainerRef);

        mapGoToButtonProps["EquipmentResource"] = {
            label: _msg("EquipmentResource.label.plural"),
            onSelect: async (entity : any) => { await this.getEntity(entity.id, this.mapContainerRef.current!, this.props.mapSettings); },
            queryDetails: {
                query: LOAD_EQUIPMENT_RESOURCES_FOR_MAP_GO_TO_BUTTON,
                queryName: "equipmentResourceService_findByString",
                createQueryParams: (filter: Filter, searchQuery: string) => FindByStringParams.create().filter(filter).string(searchQuery ? searchQuery : ""),
                filter: Filter.createComposed(FilterOperators.forComposedFilter.and, filters),
                extraFields: realtimeEntityFactory.getAdditionalSearchFieldsForFindEntity()
            }
        }

        return mapGoToButtonProps;
    }

    renderTooltipContent(layerData: any, type: string, additionalInfo?: { pointId?: ID }): React.ReactElement {
        if (type == flightEntityDescriptor.name) {
            return <><div>{layerData.text}</div></>;
        } else if (type == territoryEntityDescriptor.name) {
            return <><div>{layerData.text}</div> {layerData.readableArea}</>;
        } else if (type == addressEntityDescriptor.name) {
            return <div className="flex-container">{layerData.text}</div>;
        } else {
            const entity = this.props.s.entitiesList[type]?.find(entity => entity.id === layerData.id!);
            return RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[type].renderTooltipContent(entity, this.props.mapSettings);
        }
    }

    renderMarkerIcon(markerData: MarkerData, type: string, aditionalStyles?: { selected?: boolean, hovered?: boolean }): React.ReactNode {
        if (type == flightEntityDescriptor.name) {
            let iconField = undefined;
            for (const [terrId, flights] of Object.entries(this.props.s.flightsForMap)) {
                const flight = flights.find(f => f.id === markerData.id);
                
                if (flight) {
                    iconField = flightEntityDescriptor.getField("departure").renderField(flight);
                    break;
                }
            }
           return <><span className='fa fa-stack fa-lg'>{iconField}</span>
                <div style={{ font: 'bold 12px Lato', whiteSpace: "nowrap" }}>{markerData.text}<br /><a onClick={() => this.setState({ modalOpen: true })}>More ...</a></div></>;
        } else if (type == addressEntityDescriptor.name) {
            const markerSettings: Optional<MarkerSettings> = this.props.mapSettings.markers.find(m => m.markerType === addressEntityDescriptor.name);
            return <><span className='fa fa-stack fa-lg'><i className={'fa fa-map-marker fa-stack-1x'} style={{ color: 'blue' }}></i></span>
                {markerSettings?.showTextUnderIcon ? <div style={{ font: 'bold 12px Lato', whiteSpace: "nowrap" }}>{markerData.text}</div> : undefined}</>;
        } else {
            const entity = this.props.s.entitiesList[type]?.find(entity => entity.id === markerData.id!);
            return RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[type].renderMarkerIcon(entity, this.props.mapSettings, markerData, aditionalStyles);
        }
    }

    onSelectedLayerChanged(prevValue?: Optional<SelectedLayer>){
        const selectedLayer = this.mapContainerRef.current!.props.s.selectedLayer;
        switch (selectedLayer?.type) {
            case territoryEntityDescriptor.name:
                break;
            case addressEntityDescriptor.name:
                break;
            case  flightEntityDescriptor.name: {
                this.setState({ modalOpen: this.props.s.showFlightsForTerritories });
                break;
            }
            default: {
                if (prevValue === undefined && selectedLayer !== undefined) {
                    const phoneMode:boolean = localStorage.getItem(PHONE_MODE_KEY) == "true";
                    !phoneMode && this.setState({ drawerOpen: true });
                }
        
                this.listRef.current?.forceUpdateGrid();
                if (selectedLayer) {
                    this.listRef.current?.scrollToRow(this.props.s.entitiesToDisplayIds[this.props.s.selectedEntityType]?.findIndex(id => id == selectedLayer.id));
                }
            }
        }       
    }

    renderFilterBar() {
        const phoneMode: boolean = localStorage.getItem(PHONE_MODE_KEY) == "true";
        var markerColors = this.props.mapSettings.markers.find(m => m.markerType === this.props.s.selectedEntityType)?.colors.filter(color => color.showInFilterBar);
        if (!markerColors || markerColors.length == 0) {
            return null;
        }
        const ed: EntityDescriptor = RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[this.props.s.selectedEntityType].getEd();
        return <Popup on='click' flowing position="bottom left"
            trigger={<Button icon={phoneMode} color="green"> <Icon name='filter' />{!phoneMode && _msg("MapRealTime.quickFilters")}</Button>}
            content={<div className="MapRealTime_filterBar_div">{markerColors?.map(color => {
                const fdChain = ed.getFieldDescriptorChain(color.field);
                const lastFd = fdChain[fdChain.length - 1];
                return <div className="MapRealTime_filterBar_color_container">
                    <Label size="small" attached="top" style={{ textAlign: 'center' }} content={<>{lastFd.getIcon()}{ed.getComposedFieldLabel(fdChain)}</>} />
                    {RealTimeUtils.getMarkerColorIntervals(ed.name, color)
                        .map(interval => {
                            const label = getDropdownItemLabel(lastFd, interval as FieldInterval);
                            const checkbox = <Checkbox label={label} checked={this.props.s.filterBarCheckboxes[color.field + interval.from]} onChange={() => this.props.r.changeFilterBar(color.field + interval.from)} />
                            const colorAsNumber = ColorRegistry.INSTANCE!.get(interval.color);
                            const colorAsHex = Utils.convertColorToHex(colorAsNumber);
                            const dotProps = {
                                className: "MapRealTime_filterBar_item_dot MapRealTime_filterBar_item_dot_" + color.area,
                                style: colorAsNumber ? { display: "inline-block", backgroundColor: colorAsHex } : undefined
                            };
                            if (color.area === "main") {
                                return <div className="MapRealTime_filterBar_item" style={colorAsNumber ? { borderColor: colorAsHex } : undefined}>{checkbox}</div>;
                            } else if (color.area === "topLeft" || color.area === "bottomLeft") {
                                return <>
                                    <div {...dotProps} />
                                    <div className="MapRealTime_filterBar_item" >{checkbox}</div>
                                </>
                            } else if (color.area === "topRight" || color.area === "bottomRight") {
                                return <>
                                    <div className="MapRealTime_filterBar_item" >{checkbox}</div>
                                    <div {...dotProps} />
                                </>;
                            }
                        }).map(interval => <div className="MapRealTime_filterBar_item_container">{interval}</div>)}
                </div>;
            })}</div>}
        />
    }

    protected renderListRow(listRowProps: ListRowProps) {
        const { props } = this;
        const entity = props.s.entitiesList[this.props.s.selectedEntityType].find(entity => entity.id === props.s.entitiesToDisplayIds[this.props.s.selectedEntityType][listRowProps.index])!;
        return (
            <div style={listRowProps.style} key={listRowProps.key}
                onClick={() => this.mapContainerRef.current!.props.r.setInReduxState({ selectedLayer: { id: entity.id, type: this.props.s.selectedEntityType } })}>
                <div className="flex-container-row flex-center gap5" style={{ backgroundColor: this.mapContainerRef.current!.props.s.selectedLayer?.id === entity.id ? "lightgray" : undefined }}>
                    {RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[this.props.s.selectedEntityType].renderListRow(entity, this.props.mapSettings)}
                </div>
                <Divider className="less-margin-top-bottom" />
            </div>
        )
    }

    protected updateStaticEntities() {
        if (this.props.s.showStaticEntities[territoryEntityDescriptor.name]) {
            this.loadAndAddStaticEntitiesOnMap(territoryEntityDescriptor.name);
        }
        if (this.props.s.showStaticEntities[addressEntityDescriptor.name]) {
            this.loadAndAddStaticEntitiesOnMap(addressEntityDescriptor.name);
        }        
    }

    renderTopBar() {
        const { props } = this;
        const phoneMode:boolean = localStorage.getItem(PHONE_MODE_KEY) == "true";
        const realtimeEntityFactory = RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[props.s.selectedEntityType];

        return (
            <Segment className="less-padding less-margin-bottom flex-container-row  flex-center">
                <span ref={this.buttonBarRef} />
                <div style={{ width: "100%", display: "flex" }}>
                    {!phoneMode && props.showCustomQueryBar ? <div className="CustomQueryBar_div">
                        {/* ConnectedPageInfo use mapId for sliceName because RealTimeMap can be rerendered and this construction
                        give an error if the slice has the same name every time */}
                        <ConnectedComponentInSimpleComponent ref={this.ref} info={new ConnectedPageInfo(sliceCustomQueryBar, CustomQueryBar, "customQueryBar-" + this.props.mapId,
                            { entityDescriptor: realtimeEntityFactory.getEd().name, screen: realtimeEntityFactory.getEd().name, mode: CUSTOM_QUERY_BAR_MODE.TABLE,ref: this.customQueryBarRef,onChangeFilter: this.onChangeFilter,onChangeSort: this.onChangeSort,onChangeSortDirection: this.onChangeSortDirection,
                            })} />

                    </div> : null}
                    {props.showGoToTableButton ? <Link to={realtimeEntityFactory.getEd().getEntityTableUrl()}><Button
                        key={_msg('CustomQueryBar.widget.goToTable')}><Icon name={realtimeEntityFactory.getEd().icon as SemanticICONS}></Icon>{_msg('CustomQueryBar.widget.goToTable')}</Button></Link> : null}
                    <div style={{ marginLeft: "auto", flexShrink: 0 }}>
                        {this.renderFilterBar()}
                        <MapGoToButton options={this.getMapGoToButtonProps()} />
                        <Dropdown trigger={<>{_msg("MapRealTime.showHide")}</>} icon='dropdown' button direction='left'>
                            <Dropdown.Menu>
                                <Dropdown.Item 
                                    text={territoryEntityDescriptor.getLabel(true)} 
                                    icon={props.s.showStaticEntities[territoryEntityDescriptor.name] ? "check square outline" : "square outline"}
                                    onClick={() => {
                                        const newValue = !props.s.showStaticEntities[territoryEntityDescriptor.name];
                                        if (!newValue && props.s.showFlightsForTerritories) {
                                            this.props.r.setInReduxState({showFlightsForTerritories: false})
                                        }
                                        this.showStaticEntitiesOnMap(territoryEntityDescriptor.name, newValue);
                                    }}
                                />
                                <Dropdown.Item 
                                    text={addressEntityDescriptor.getLabel(true)} 
                                    icon={props.s.showStaticEntities[addressEntityDescriptor.name] ? "check square outline" : "square outline"}
                                    onClick={() => this.showStaticEntitiesOnMap(addressEntityDescriptor.name, !props.s.showStaticEntities[addressEntityDescriptor.name])}
                                />                              
                                <Dropdown.Item 
                                    text={flightEntityDescriptor.getLabel(true) + " on territories"} 
                                    icon={props.s.showFlightsForTerritories ? "check square outline" : "square outline"}
                                    onClick={() => {
                                        const newValue = !props.s.showFlightsForTerritories;
                                        this.props.r.setInReduxState({showFlightsForTerritories: newValue})
                                        if (newValue) {
                                            this.showStaticEntitiesOnMap(territoryEntityDescriptor.name, true);
                                        }
                                    }}
                                />    
                            </Dropdown.Menu>
                        </Dropdown>
                        </div></div>
            </Segment>)
    }

    renderList() {
        return (
            <Measure bounds onResize={contentRect => this.setState({ measuredWidth: contentRect.bounds?.width || 0, measuredHeight: contentRect.bounds?.height || 0 })}>
                {({ measureRef }) => (<div ref={measureRef} className="flex-container flex-grow-shrink-no-overflow gap5">
                    <Label basic color="orange">{_msg("MapRealTime.noEquipmentResources", this.props.s.entitiesToDisplayIds[this.props.s.selectedEntityType]?.length)}</Label>
                    <ListVirtualized ref={this.listRef} className="wh100" style={{ padding: "5px", backgroundColor: "white" }}
                        width={this.state.measuredWidth}
                        height={this.state.measuredHeight}
                        rowCount={this.props.s.entitiesToDisplayIds[this.props.s.selectedEntityType] ? this.props.s.entitiesToDisplayIds[this.props.s.selectedEntityType].length : 0}
                        rowHeight={85} estimatedRowSize={85}
                        rowRenderer={this.renderListRow}
                    />
                </div>)}
            </Measure>
        )
    }

    renderDrawerEquipmentResource() {
        // if the ER is selected and a filter is applied and selected ER is no more visible, the drawer should be closed
        const eq = this.props.s.entitiesList[this.props.s.selectedEntityType]?.find(er => er.id === this.mapContainerRef?.current?.props.s.selectedLayer?.id)!
        if (!eq){
            return <></>
        }
        
        return (
            <Drawer data-cy={"drawerMRT"} className="MapRealTime_drawer" getContainer={false} closable={false}
                onClose={() => this.setState({ drawerOpen: false })}
                afterVisibleChange={visible => !visible}
                placement="right" visible={this.state.drawerOpen && this.mapContainerRef?.current?.props.s.selectedLayer?.type === equipmentResourceEntityDescriptor.name} width={this.state.drawerOpen ? 400 : 0}>
                {this.mapContainerRef?.current?.props.s.selectedLayer ?
                    <EquipmentResourceBigInfoArea eq={eq} mapMarkSettings={this.props.mapSettings} />
                    : undefined}
            </Drawer>
        )
    }

    render() {
        const phoneMode:boolean = localStorage.getItem(PHONE_MODE_KEY) == "true";   
        let flightsInTerritory = undefined;
        if (this.state.modalOpen && this.mapContainerRef?.current?.props.s.selectedLayer) {
            const selectedFlightId = this.mapContainerRef?.current?.props.s.selectedLayer.id;
            for (const [terrId, flights] of Object.entries(this.props.s.flightsForMap)) {
                const flight = flights.find(f => f.id === selectedFlightId);
                if (flight) {
                    flightsInTerritory = { territory: flight.parking?.name, list: this.props.s.flightsForMap[terrId] };
                    break;
                }
            }
        }
        return (<div className="flex-container flex-grow less-padding" >
            {this.renderTopBar()}
            <SplitPaneExt minSize={250} size={!phoneMode ? "25%" : "100%"}>
                {!phoneMode && this.renderList()}
                <div className="no-padding-margin flex-container flex-grow-shrink-no-overflow MapRealTime_topParent" >
                    {RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[this.props.s.selectedEntityType].getEd().name == equipmentResourceEntityDescriptor.name ? this.renderDrawerEquipmentResource() : null}
                    <MapContainerLeafletRRC id={"mapContainerLeafletRealTimeMap-" + this.props.mapId} ref={this.mapContainerRef} 
                        pruneClusterMode={localStorage.getItem(MAP_CLUSTER_MODE_KEY) === CLUSTER_MODE.PRUNE_CLUSTER} onCoordinatesChanged={this.updateStaticEntities}
                        renderTooltipContent={this.renderTooltipContent} renderMarkerIcon={this.renderMarkerIcon} onSelectedLayerChanged={(prevValue: Optional<SelectedLayer>) => this.onSelectedLayerChanged(prevValue)}
                        bingAPIKey={this.props.mapSettings.bingAPIKey} mapId={this.props.mapId} saveCenterZoomInStorage={true}
                        layers={{ [equipmentResourceEntityDescriptor.name]: RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[equipmentResourceEntityDescriptor.name].getMapContainerLeafletLayer(),
                        [territoryEntityDescriptor.name]: { layerType: POLYGON_TYPE, options: { flyToSelectedMarker: false, hideStyleOnSelectedLayer: true, hideStyleOnHoveredLayer: true }},
                        [flightEntityDescriptor.name]: { layerType: MARKER_TYPE, options: { flyToSelectedMarker: false, hideStyleOnSelectedLayer: true, hideStyleOnHoveredLayer: true, useCluster: false }},
                        [addressEntityDescriptor.name]: { layerType: MARKER_TYPE, options: { flyToSelectedMarker: false, hideStyleOnSelectedLayer: true, hideStyleOnHoveredLayer: true, useCluster: false }}}}
                         />
                </div>
            </SplitPaneExt>
            <ModalExt size='mini' transparentDimmer open={this.state.modalOpen} onClose={() => this.setState({ modalOpen: false })} >
                <Modal.Header as="h3">Next flights on {flightsInTerritory?.territory}</Modal.Header>
                <Modal.Content className="less-padding">
                    {flightsInTerritory
                        ? flightsInTerritory.list.map(flight => <Segment className="flex-container-row gap3 less-padding">
                            {flightEntityDescriptor.getField("departure").renderField(flight)}
                            <div className="flex-container-row">                                
                                {flightEntityDescriptor.getField("airline").renderField(flight)}
                                {flightEntityDescriptor.getField("number").renderField(flight)}
                            </div>
                            {flightEntityDescriptor.getField("planeIdentifier").renderField(flight)}
                            {flightEntityDescriptor.getField("date").renderField(flight, FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { format: Utils.dateTimeFormatShorter, formatForToday: Utils.timeFormat }))}
                        </Segment>                           
                        )
                        : "No flights"}
                </Modal.Content>                
            </ModalExt>
        </div>
        )
    }
}

export const RealTimeMapRRC = ReduxReusableComponents.connectRRC(RealTimeMapState, RealTimeMapReducers, RealTimeMap);
