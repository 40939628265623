import { apolloClient, EntityDescriptor, FieldDescriptor, Optional, PHONE_MODE_KEY, TestUtils, Utils } from "@crispico/foundation-react";
import { AdvancedSelectorHOC, AdvancedSelectorItem } from "@crispico/foundation-react/components/AdvancedSelector/AdvancedSelector";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { FindByStringParams } from "@crispico/foundation-react/entity_crud/FindByStringParams";
import { ColorRegistry } from "@crispico/foundation-react/utils/ColorRegistry";
import { AirportForMap } from "apollo-gen/AirportForMap";
import { MapSettings, MarkerSettings } from "app";
import { DEFAULT_BORDER_COLOR, HOVER_BACKGROUND, MapContainerLeaflet, MarkerData, TRANSPARENT_BACKGROUND } from "components/MapContainerLeaflet/MapContainerLeaflet";
import _ from "lodash";
import moment from "moment";
import React, { RefObject } from "react";
import { ReactNode } from "react";
import { Button, Icon, Label, Popup } from "semantic-ui-react";
import { LOAD_ADDRESSES_FOR_MAP_GO_TO_BUTTON, LOAD_AIRPORTS_FOR_MAP_GO_TO_BUTTON, LOAD_EQUIPMENT_RESOURCES_FOR_MAP_GO_TO_BUTTON, LOAD_TERRITORIES_FOR_MAP_GO_TO_BUTTON } from "./queries";
import { RealTimeMapEntityTypeFactories } from "./RealTimeMapEntityTypeFactory";
import { DocumentNode } from "graphql";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { LatLng, LatLngBounds } from "leaflet";
import { DEFAULT_ZOOM_LEVEL } from "components/MapContainerLeaflet/MapLayerHelpers";
import StringFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/StringFieldRenderer";

export type MarkerColorInterval = {
    label?: string;
    from: string;
    to?: string;
    color: string;
}

export type EntityColorSettings = {
    mainColorSettings?: {
        area: string;
        field: string;
        showInFilterBar: string;
        useDurationBetweenNowAndFieldValue: boolean | undefined;
        intervals: MarkerColorInterval[];
    } | undefined,
    mainColor?: number | undefined,
    topLeftColor?: number | undefined,
    topRightColor?: number | undefined,
    bottomLeftColor?: number | undefined,
    bottomRightColor?: number | undefined
};

export class RealTimeUtils {

    static renderEntityIcon(data: MarkerData, markerSettings: Optional<MarkerSettings>, iconElement?: JSX.Element, additionalStyles?: { selected?: boolean, hovered?: boolean }): ReactNode {
        return <><span className='fa fa-stack fa-lg' style={{ backgroundColor: (additionalStyles?.hovered || additionalStyles?.selected ? '' : TRANSPARENT_BACKGROUND), borderRadius: '0.6em' }}>
            <i className={'fa ' + (data.mainArea?.icon || 'fa-square-o') + ' fa-stack-2x'} style={{ color: (data.mainArea?.color || DEFAULT_BORDER_COLOR), marginTop: '1.5px' }} />
            {iconElement}
            {data.bottomRightArea ? <i className={'fa ' + (data.bottomRightArea.icon || 'fa-circle') + ' fa-stack-1x MapContainerLeaflet_cornered-br'} style={{ color: (data.bottomRightArea.color || undefined) }} /> : undefined}
            {data.bottomLeftArea ? <i className={'fa ' + (data.bottomLeftArea.icon || 'fa-circle') + ' fa-stack-1x MapContainerLeaflet_cornered-bl'} style={{ color: (data.bottomLeftArea.color || undefined) }} /> : undefined}
            {data.topRightArea ? <i className={'fa ' + (data.topRightArea.icon || 'fa-circle') + ' fa-stack-1x MapContainerLeaflet_cornered-tr'} style={{ color: (data.topRightArea.color || undefined) }} /> : undefined}
            {data.topLeftArea ? <i className={'fa ' + (data.topLeftArea.icon || 'fa-circle') + ' fa-stack-1x MapContainerLeaflet_cornered-tl'} style={{ color: (data.topLeftArea.color || undefined) }} /> : undefined}
        </span>{markerSettings?.showTextUnderIcon ? <div style={{ font: 'bold 12px Lato', whiteSpace: "nowrap", backgroundColor: (additionalStyles?.hovered || additionalStyles?.selected ? HOVER_BACKGROUND : TRANSPARENT_BACKGROUND), borderRadius: '0.3em', marginTop: '0.5px', position: 'absolute' }}>{data.text}</div> : undefined}</>;
    }

    static checkIfEntityIsInInterval(interval: MarkerColorInterval, useDurationBetweenNowAndFieldValue: boolean, value: any, fd: FieldDescriptor) {
        try {
            const from = Number(interval.from);
            if (useDurationBetweenNowAndFieldValue) {
                const diff = Utils.now().getTime() - moment(value).toDate().getTime();
                if (diff >= from && diff < Number(interval.to) || (interval.to === null && diff >= from)) {
                    return true;
                }
            } else if (interval.to) {
                if (value >= from && value < Number(interval.to)) {
                    return true;
                }
            } else {
                if (FieldType.boolean === fd.type) {
                    if (String(Boolean(value)) === interval.from) {
                        return true;
                    }
                } else if (!isNaN(from) && value >= from || value === interval.from || String(value) === interval.from) {
                    return true;
                }
            }
        } catch (e) {
            console.log("Error while RealTimeUtils.checkIfEntityIsInInterval: " + e);
        }
        return false;
    }

    static getMarkerFromEntity(entityDescriptorName: string, entity: any, markerSettings: Optional<MarkerSettings>): MarkerData {
        const colors = this.getEntityMapColors(entityDescriptorName, entity, markerSettings);
        let marker: MarkerData = { id: entity.id, text: markerSettings?.showTextUnderIcon ? entity.identifier : undefined, icon: entity.equipmentType?.icon || undefined, point: { longitude: entity.lastPointLongitude, latitude: entity.lastPointLatitude } };

        marker.mainArea = colors.mainColor && colors.mainColor !== -1 ? { color: Utils.convertColorToHex(colors.mainColor) } : undefined;
        marker.topLeftArea = colors.topLeftColor && colors.topLeftColor !== -1 ? { color: Utils.convertColorToHex(colors.topLeftColor) } : undefined;
        marker.topRightArea = colors.topRightColor && colors.topRightColor !== -1 ? { color: Utils.convertColorToHex(colors.topRightColor) } : undefined;
        marker.bottomLeftArea = colors.bottomLeftColor && colors.bottomLeftColor !== -1 ? { color: Utils.convertColorToHex(colors.bottomLeftColor) } : undefined;
        marker.bottomRightArea = colors.bottomRightColor && colors.bottomRightColor !== -1 ? { color: Utils.convertColorToHex(colors.bottomRightColor) } : undefined;

        return marker;
    }

    static getEntityMapColors(entityDescriptorName: string, entity: any, markerSettings: Optional<MarkerSettings>): EntityColorSettings {
        let settings: EntityColorSettings = {};

        if (TestUtils.storybookMode) {
            // there is an issue when accesing storybook for historicalMap because it doesn't find a mountedHelper for AppContainer
            // this is a provisory solution until we know what's missing
            return settings;
        }
        if (!markerSettings || !markerSettings.colors) {
            return settings;
        }
        const ed = entityDescriptors[entityDescriptorName];
        // get all fields for which MarkerColor has name and passed the condition
        const colorsMarkerFilters = ed.getFieldsFromSettings(markerSettings.colors, entity);
        markerSettings.colors.forEach(item => {
            // combine defaults with fields from custom templates 
            if (!colorsMarkerFilters.fields.concat(colorsMarkerFilters.defaultFields).includes(item.field)) {
                return;
            }
            let color = -1;
            try {
                const fd = ed.getField(item.field);
                const intervals = RealTimeUtils.getMarkerColorIntervals(entityDescriptorName, item);
                for (let i = 0; i < intervals.length; i++) {
                    if (RealTimeUtils.checkIfEntityIsInInterval(intervals[i], item.useDurationBetweenNowAndFieldValue, fd.getFieldValue(entity), fd)) {
                        color = ColorRegistry.INSTANCE!.get(intervals[i].color);
                        break;
                    }
                }
            } catch (e) {
                console.log("Field not found: " + item.field);
            }

            switch (item.area) {
                case "main":
                    settings.mainColor = color;
                    settings.mainColorSettings = item;
                    break;
                case "topLeft":
                    settings.topLeftColor = color;
                    break;
                case "topRight":
                    settings.topRightColor = color;
                    break;
                case "bottomRight":
                    settings.bottomRightColor = color;
                    break;
                case "bottomLeft":
                    settings.bottomLeftColor = color;
                    break;
            }
        })
        return settings;
    }

    static getMarkerColorIntervals(entityDescriptorName: string, color: EntityColorSettings["mainColorSettings"]) {
        if (color) {
            if (color.intervals?.length) {
                return color.intervals;
            }
            const fieldIntervals = crudSettings?.forEntities.find(fe => fe.entityName === entityDescriptorName)!.fieldDescriptorSettings.find(fds => fds.fieldRef === color.field)?.fieldIntervals;
            if (fieldIntervals) {
                return fieldIntervals as MarkerColorInterval[];
            }
        }
        return [];
    }

    static selectAirportOnCurrentOrganizationToFilterByChange(prevProps: any, props: any, mapContainer: RefObject<MapContainerLeaflet>) {
        let org = global.currentOrganizationToFilterBy;
        if (org && org.id !== prevProps?.currentOrganizationToFilterBy?.id) {
            let airport: Optional<AirportForMap>;
            while (!airport && org) {
                airport = (org as any).airport;
                org = org?.parent;
            }
            airport?.id && RealTimeUtils.setAirportCoordinates("", mapContainer, airport?.id);

        }
    }

    static filterEntitiesUsingFilterBar(mapSettings: MapSettings, fitlerBarCheckboxes: { [key: string]: boolean }, entities: any[], selectedEntityType: string) {
        const realTimeEntityFactory = RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[selectedEntityType];
        const markerSettings: Optional<MarkerSettings> = realTimeEntityFactory.getMarkerSettings(mapSettings)
        markerSettings?.colors.filter(color => color.showInFilterBar).forEach(color => {
            const intervals = RealTimeUtils.getMarkerColorIntervals(realTimeEntityFactory.getEd().name, color).filter(interval => fitlerBarCheckboxes[color.field + interval.from]);
            entities = !entities ? [] : entities.filter(entity => {
                const colorsMarkerFilters = realTimeEntityFactory.getEd().getFieldsFromSettings(markerSettings.colors, entity);
                // combine defaults with fields from custom templates and test if color needs to be applied for this entity
                if (!colorsMarkerFilters.fields.concat(colorsMarkerFilters.defaultFields).includes(color.field)) {
                    return true;
                }
                if (intervals.length === 0) {
                    return false
                }
                try {
                    const fieldInfo = this.getFieldInfo(entity, realTimeEntityFactory.getEd(), color.field);
                    for (let i = 0; i < intervals.length; i++) {
                        if (RealTimeUtils.checkIfEntityIsInInterval(intervals[i], color.useDurationBetweenNowAndFieldValue, fieldInfo.fielValue, fieldInfo.lastFd)) {
                            return true;
                        }
                    }
                } catch (e) {
                    console.log("Field not found: " + color.field);
                }

                return false;
            });
        });
        return entities;
    }

    static getMapGoToButtonProps(mapContainerRef: RefObject<MapContainerLeaflet>) {
        let mapGoToButtonProps = _.cloneDeep(defaultMapGoToButtonProps);

        // Here it can be added filters or custom callbacks for entity types
        for (let key of Object.keys(mapGoToButtonProps)) {
            if (entityDescriptors[key].fields["latitude"] && entityDescriptors[key].fields["longitude"]) {
                mapGoToButtonProps[key].onSelect = (entity: any) => {
                    if (entity.latitude && entity.longitude) {
                        mapContainerRef.current!.getMap().panTo([entity.latitude, entity.longitude]);
                    }
                }
            }
        }

        mapGoToButtonProps["Territory"].onSelect = async (entity: any) => {
            let northEastLon = -Number.MAX_VALUE;
            let northEastLat = -Number.MAX_VALUE;
            let southWestLon = Number.MAX_VALUE;
            let southWestLat = Number.MAX_VALUE;
            for (let i = 0; i < entity.coordinates.length; i++) {
                if (northEastLon < entity.coordinates[i].a) {
                    northEastLon = entity.coordinates[i].a;
                }
                if (northEastLat < entity.coordinates[i].b) {
                    northEastLat = entity.coordinates[i].b;
                }
                if (southWestLon > entity.coordinates[i].a) {
                    southWestLon = entity.coordinates[i].a;
                }
                if (southWestLat > entity.coordinates[i].b) {
                    southWestLat = entity.coordinates[i].b;
                }
            }
            mapContainerRef.current!.getMap().fitBounds(new LatLngBounds(new LatLng(southWestLat, southWestLon), new LatLng(northEastLat, northEastLon)),
                { padding: [30, 30] });
        }

        return mapGoToButtonProps;
    }

    static async setAirportCoordinates(code: string, mapContainerRef: RefObject<MapContainerLeaflet>, id?: number) {
        const filter = id ? Filter.create("id", FilterOperators.forNumber.equals, id.toString()) : Filter.create("code", FilterOperators.forString.equals, code)
        let result = (await apolloClient.query({
            query: LOAD_AIRPORTS_FOR_MAP_GO_TO_BUTTON,
            variables: FindByStringParams.create().filter(filter),
            context: { showSpinner: false }
        })).data["airportService_findByString"][0];

        if (mapContainerRef.current && result && result.latitude && result.longitude) {
            mapContainerRef.current!.getMap().panTo([result.latitude, result.longitude]);
            mapContainerRef.current!.getMap().setZoom(DEFAULT_ZOOM_LEVEL);
        }
    }

    static renderFieldsForListRow(entity: any, entityDescriptor: EntityDescriptor, mapSettings: MapSettings, listRowPopupFields: string[]) {
        const markerSetings: Optional<MarkerSettings> = RealTimeMapEntityTypeFactories.INSTANCE.entityTypes[entityDescriptor.name].getMarkerSettings(mapSettings);
        const rowFieldsInfo = entityDescriptors["EquipmentResource"].getFieldsFromSettings(markerSetings?.rowFields, entity);
        const fields = rowFieldsInfo.fields.length > 0 ? rowFieldsInfo.fields : rowFieldsInfo.defaultFields;
        const genericRowPopupFieldMessage = entityDescriptor.name + ".?.label"
        return <div className="flex-container gap3">
            <h4 className="no-margin">{entityDescriptor.getField("identifier").renderField(entity)}</h4>
            <div className="flex-container gap3">
                <div className="flex-container-row gap3">
                    {fields.map(field => {
                        const fieldInfo = this.getFieldInfo(entity, entityDescriptor, field);
                        return fieldInfo.lastFd.renderField(fieldInfo.lastEntityFromFdChain, FieldDescriptor.castAdditionalFieldRendererProps(StringFieldRenderer, { asLabel: true, showIcon: true, showTooltip: true }))
                    })}
                </div>
                {listRowPopupFields.length > 0 ?
                    <Popup flowing position="bottom center"
                        trigger={<span style={{ whiteSpace: "nowrap" }}>{_msg(genericRowPopupFieldMessage.replace('?', listRowPopupFields[0]))}: {moment(entity[listRowPopupFields[0]]).fromNow()}</span>}>
                        <Popup.Content className="flex-container-row gap3" >
                            {listRowPopupFields.map(field =>
                                <div className="flex-container flex-center">
                                    <div><Icon name="truck" />{_msg(genericRowPopupFieldMessage.replace('?', field))}</div>
                                    <Label>{entity[field] ? moment(entity[field]).format(Utils.dateTimeWithSecFormat) : _msg("general.notAvailable")}</Label>
                                </div>
                            )}
                        </Popup.Content>
                    </Popup>
                    : null}
            </div>
        </div>
    }

    static getFieldInfo(entity: any, entityDescriptor: EntityDescriptor, field: string): { fdChain: FieldDescriptor[], lastFd: FieldDescriptor, lastEntityFromFdChain: any, fielValue: any } {
        let fieldValue = undefined;
        let lastEntityFromFdChain = entity;
        const fdChain = entityDescriptor.getFieldDescriptorChain(field);
        fdChain.forEach(fd => {
            if (!lastEntityFromFdChain) {
                fieldValue = undefined;
                return;
            }
            fieldValue = fd.getFieldValue(lastEntityFromFdChain);
            lastEntityFromFdChain = fd.typeIsEntity() && fdChain.length > 1 ? lastEntityFromFdChain[fd.name] : lastEntityFromFdChain;
        });
        return { fdChain: fdChain, lastFd: fdChain[fdChain.length - 1], lastEntityFromFdChain: lastEntityFromFdChain, fielValue: fieldValue }
    }
}

export type MapGoToButtonQueryDetails = {
    query: DocumentNode,
    queryName: string,
    createQueryParams: Function,
    filter?: Filter,
    extraFields?: string[],
}

export type MapGoToButtonOption = {
    label: string,
    queryDetails: MapGoToButtonQueryDetails,
    onSelect?: (entity: any) => void
}

export type MapGoToButtonProps = {
    options: { [key: string]: MapGoToButtonOption }
};

export type MapGoToButtonState = {
    isMenuOpened: boolean | [number, number],
    data: { [key: string]: any }
};

const GO_TO_BOTTOM_PADDING = 10;

export class MapGoToButton extends React.Component<MapGoToButtonProps, MapGoToButtonState> {
    constructor(props: MapGoToButtonProps) {
        super(props);

        let data: { [key: string]: any } = {};
        Object.keys(this.props.options).map((key) => {
            data[key] = [];
        })

        this.state = {
            isMenuOpened: false,
            data
        };

        this.openMenu = this.openMenu.bind(this);
        this.onSelectEntity = this.onSelectEntity.bind(this);
    }

    protected async performQuery(entityDescriptor: EntityDescriptor, searchQuery?: string, filter?: Filter) {
        let result = (await apolloClient.query({
            query: this.props.options[entityDescriptor.name].queryDetails.query,
            variables: this.props.options[entityDescriptor.name].queryDetails.createQueryParams(filter, searchQuery),
            context: { showSpinner: false }
        })).data[this.props.options[entityDescriptor.name].queryDetails.queryName];

        return result;
    }

    protected async openMenu() {
        const rect = document.getElementById("goToButton")!.getBoundingClientRect();
        let data: { [key: string]: any } = {}
        for (let key of Object.keys(this.props.options)) {
            let result = await this.performQuery(entityDescriptors[key], undefined, this.props.options[key].queryDetails.filter);
            data[key] = result;
        }
        this.setState({ isMenuOpened: [rect.left, rect.bottom + GO_TO_BOTTOM_PADDING], data });
    }

    protected onSelectEntity(item: AdvancedSelectorItem) {
        if (this.props.options[item.data["type"]]) {
            this.props.options[item.data["type"]].onSelect!(item.data["entity"]);
        }
        this.setState({ isMenuOpened: false });
    }

    private getAdvancedSelectorData() {
        let data: AdvancedSelectorItem[] = [];
        Object.keys(this.props.options).map((key) => {
            data.push({
                id: key,
                level: 1,
                text: this.props.options[key].label,
                color: undefined,
                icon: entityDescriptors[key].icon,
                className: "MapGoToButton_header"
            });
            if (this.state.data[key].length === 0) {
                data.push({
                    id: key + "_noData",
                    level: 2,
                    text: _msg("MapGoToButton.noOptions.label"),
                    color: "grey"
                })
            }
            else {
                if (this.state.data[key].length === 10) {
                    data.push({
                        id: key + "_moreDataLabel",
                        level: 2,
                        text: _msg("MapGoToButton.moreOptions.label"),
                        color: "grey"
                    })
                }
                this.state.data[key].map((option: any) => {
                    let extraFieldsValues = "";
                    if (this.props.options[key].queryDetails.extraFields) {
                        this.props.options[key].queryDetails.extraFields?.map((field: any) => {
                            extraFieldsValues += " " + option[field];
                        })
                    }
                    data.push({
                        id: key + "_" + option.id,
                        level: 3,
                        text: entityDescriptors[key].toMiniString(option) + extraFieldsValues,
                        color: undefined,
                        data: {
                            type: key,
                            entity: option
                        },
                        disabled: (option["latitude"] === null) && (option["longitude"] === null),
                        tooltip: _msg("MapGoToButton.noCoordinates.label", key)
                    })
                });
            }
        });
        return data;
    }

    render() {
        const phoneMode: boolean = localStorage.getItem(PHONE_MODE_KEY) == "true";
        return <>
            <Button color='olive' icon={phoneMode} id="goToButton" onClick={this.openMenu}>
                <Icon name="paper plane outline" />
                {!phoneMode && _msg("MapRealTime.goTo")}
            </Button>
            <ModalExt
                onClose={() => this.setState({ isMenuOpened: false })}
                open={this.state.isMenuOpened}
                size='mini'
                transparentDimmer
                className="MapGoToButton_Modal"
            >
                <AdvancedSelectorHOC data={this.getAdvancedSelectorData()} id={"GoToSelectorRRC"}
                    onItemClick={this.onSelectEntity}
                    onSearchInputChanged={async (searchValue) => {
                        let data: { [key: string]: any } = {}
                        for (let key of Object.keys(this.props.options)) {
                            let result = await this.performQuery(entityDescriptors[key], searchValue, this.props.options[key].queryDetails.filter);
                            data[key] = result;
                        }
                        this.setState({ data });
                    }} />
            </ModalExt>
        </>
    }
}

export const defaultMapGoToButtonProps: { [key: string]: any } = {
    "Address": {
        label: _msg("Address.label.plural"),
        queryDetails: {
            query: LOAD_ADDRESSES_FOR_MAP_GO_TO_BUTTON,
            queryName: "addressService_findByString",
            createQueryParams: (filter: Filter, searchQuery: string) => FindByStringParams.create().filter(filter).string(searchQuery ? searchQuery : "")
        }
    },
    "Airport": {
        label: _msg("Airport.label.plural"),
        queryDetails: {
            query: LOAD_AIRPORTS_FOR_MAP_GO_TO_BUTTON,
            queryName: "airportService_findByString",
            createQueryParams: (filter: Filter, searchQuery: string) => FindByStringParams.create().filter(filter).string(searchQuery ? searchQuery : ""),
            extraFields: ["name"],
            filter: Filter.createComposed(FilterOperators.forComposedFilter.and, [
                Filter.create("latitude", FilterOperators.forNumber.isNotEmpty),
                Filter.create("longitude", FilterOperators.forNumber.isNotEmpty)
            ])
        }
    },
    "Territory": {
        label: _msg("Territory.label.plural"),
        queryDetails: {
            query: LOAD_TERRITORIES_FOR_MAP_GO_TO_BUTTON,
            queryName: "territoryService_findByString",
            createQueryParams: (filter: Filter, searchQuery: string) => FindByStringParams.create().filter(filter).string(searchQuery ? searchQuery : "")
        }
    },
}