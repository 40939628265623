import { EntityDescriptor, apolloClient, Utils, apolloClientHolder, FieldDescriptor } from "@crispico/foundation-react";
import React from "react";
import { CUSTOM_FIELDS, FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { POPULATE_HISTORY_TRACK_REPORT } from "./queries";
import { populateHistoryReport } from "apollo-gen/populateHistoryReport";
import { Segment, Button, Dimmer, Loader, Dropdown, ButtonGroup, Icon, Label } from "semantic-ui-react";
import { Cell, Column, Table } from "fixed-data-table-2";
import 'fixed-data-table-2/dist/fixed-data-table.css';
import { entityDescriptors, ID } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import gql from "graphql-tag";
import moment, { Moment } from "moment";
import { CrudGlobalSettings } from "@crispico/foundation-react/entity_crud/CrudGlobalSettings";
import Measure from "react-measure";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { DatePickerFieldEditor } from "@crispico/foundation-react/components/DatePicker/DatePickerFieldEditor";
import { Chart } from "../ChartTab";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { DatePickerReactCalendar } from "@crispico/foundation-react/components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import _ from "lodash";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import lodash from "lodash";
import { ResponsiveBar } from "@nivo/bar";

const refreshRateMillis: number = 2 * 1000;

interface Config {
    date: string;
    groupDataBy: string;
    selectedEquipments: string;
}

type EquipmentResource = {
    id: number,
    identifier: string,
}

export const historyTrackReportDescriptor = new EntityDescriptor({ name: "HistoryTrackReport" }, false)
    .addFieldDescriptor({ name: "date", type: FieldType.rangeDate, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: Utils.dateTimeFormat }) })
    .addFieldDescriptor(new class extends FieldDescriptor {
        name = "groupDataBy"
        type = "ColumnConfig"
        protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
            let options: Array<any> = [];
            options.push({ key: 0, text: "Week", value: "week" });
            options.push({ key: 1, text: "Month", value: "month" });
            options.push({ key: 2, text: "Year", value: "year" });
            options.push({ key: 3, text: "Whole period", value: "wholePeriod" });
            return (<Dropdown defaultValue={"wholePeriod"} selection options={options} value={props.fieldDescriptor.getFieldValue(props.formikProps.values)}
                onChange={(e, { value }) => props.formikProps.setFieldValue(props.fieldDescriptor.getFieldName(), value)} />);
        }
    })
    // TODO adjust it
    .addFieldDescriptor({ name: "selectedEquipments", type: FieldType.string })

export class HistoryTrackReportState extends State {
    rows = [[]] as [any[]];
    rowsTotal = {} as { [key: string]: number };
    percentage = 0;
    startDate = moment(Utils.now()).startOf('day').valueOf();
    endDate = moment(Utils.now()).endOf('day').valueOf();
    equipmentsOptions = [] as EquipmentResource[];
    selectedEquipmentsIds = "" as string;
    groupDataBy = undefined as string | undefined;
}

export class HistoryTrackReportReducers<S extends HistoryTrackReportState = HistoryTrackReportState> extends Reducers<S>  { }

type Props = RRCProps<HistoryTrackReportState, HistoryTrackReportReducers> & { config: Config, entity: Chart };
type LocalState = { measuredWidth: number | undefined, measuredHeight: number | undefined, showBarChart: boolean, barChartLayoutHorizontal: boolean, barChartGrouped: boolean }

export class HistoryTrackReport extends React.Component<Props, LocalState> {
    private timer: number | undefined = undefined;
    private fds = new Map<String, FieldDescriptor>();

    constructor(props: Props) {
        super(props);
        
        this.state = { measuredWidth: undefined, measuredHeight: undefined, showBarChart: false, barChartLayoutHorizontal: true, barChartGrouped: false };
        const { fds } = this;
        const edER = entityDescriptors["EquipmentResource"];

        const durationFD = new class extends FieldDescriptor {
            name = "duration";
            type = "custom";
            icon = "clock" as SemanticICONS;
            getFieldValue(values: any) {
                return moment.duration(super.getFieldValue(values), "milliseconds").format("d.hh:mm:ss", {trim: false});
            }
        }();

        const percentageFD = new class extends FieldDescriptor {
            name = "percentage";
            type = "custom";
            icon = "percent" as SemanticICONS;
            getFieldValue(values: any) {
                return super.getFieldValue(values) + "%";
            }
        }();

        const dateFD = new class extends FieldDescriptor {
            name = "date";
            type = "custom";
            getFieldValue(values: any) {
                const value = super.getFieldValue(values);
                if (!value) {
                    return value;
                }
                return moment(value).format(Utils.dateTimeFormat);
            }
        }();

        fds.set("odometer", edER.getField("odometer"));
        fds.set("startTime", dateFD);
        fds.set("distance", edER.getField("odometer"));
        if (edER.fields["speed"]) {
            fds.set("maxSpeed", edER.getField("speed"));
        }
        fds.set("engineHours", edER.getField("engineHours"));
        fds.set("periodDuration", durationFD);
        fds.set("engineDuration", durationFD);
        fds.set("movingDuration", durationFD);
        fds.set("idleDuration", durationFD);
        fds.set("stopDuration", durationFD);
        fds.set("moving/total", percentageFD);
        fds.set("idle/total", percentageFD);
    }
        
    async prepareData(savedData: string) {
        const result: { [key: string]: { [key: string]: any } } = JSON.parse(savedData)
        let rows: [string[]];

        // !!! IMPORTANT: the order is important for chart too, not just for table, if any changes here, please adapt getBarChartData()
        const rowsNames = ['vehicle', 'make', 'model', 'startTime', 'count', 'distance', 'maxSpeed', 'odometer', 'engineHours', 'periodDuration', 'engineDuration',
            'movingDuration', 'idleDuration', 'stopDuration', 'overSpeed', 'harshBraking', 'overAcceleration', 'overRPM'];

        if (this.props.entity.type == "tripPerformance") {
            rowsNames.push('score', 'moving/total', 'idle/total');
        }
        rows = [rowsNames];
        const rowsTotal: {[key: string]: number} = {'vehicle': 0, 'count': 0, 'distance': 0, 'maxSpeed': 0, 'periodDuration': 0, 'engineDuration': 0,
                                                    'movingDuration': 0, 'idleDuration': 0, 'stopDuration': 0, 'overSpeed': 0, 'harshBraking': 0, 'overAcceleration': 0, 
                                                    'overRPM': 0, 'moving/total': 0, 'idle/total': 0 };

        for(const value of Object.values(result).sort((a,b) => a["vehicle"].localeCompare(b["vehicle"]) == 0 ? a["startTime"].localeCompare(b["startTime"]) : a["vehicle"].localeCompare(b["vehicle"]))) {
            rows.push(rowsNames.map(rowName => {
                if (rowName == 'make' || rowName == "model") {
                    const model: string[] = value["model"] && value["model"].length > 0 ? value["model"].substring(1, value["model"].length - 1).split("/") : [];
                    
                    if (rowName == 'make' && model.length > 0) {
                        return model[0];
                    }
                    if (rowName == 'model' && model.length > 1) {
                        return model[1];
                    }
                    return "";
                }
                if (rowName == 'stopDuration') {
                    return value["periodDuration"] - value["movingDuration"] - value["idleDuration"];
                }
                return value[rowName];
            }));
            
            rowsTotal["vehicle"]++;
            rowsTotal["count"] += value["count"];
            rowsTotal["distance"] += value["distance"];
            if (rowsTotal["maxSpeed"] < value["maxSpeed"]) {
                rowsTotal["maxSpeed"] = value["maxSpeed"];
            }
            rowsTotal["periodDuration"] += value["periodDuration"];
            rowsTotal["engineDuration"] += value["engineDuration"];
            rowsTotal["movingDuration"] += value["movingDuration"];
            rowsTotal["idleDuration"] += value["idleDuration"];
            rowsTotal["stopDuration"] += value["periodDuration"] - value["movingDuration"] - value["idleDuration"];
            rowsTotal["overSpeed"] += value["overSpeed"] || 0;
            rowsTotal["harshBraking"] += value["harshBraking"] || 0;
            rowsTotal["overAcceleration"] += value["overAcceleration"] || 0;
            rowsTotal["overRPM"] += value["overRPM"] || 0;
            rowsTotal["moving/total"] = ((rowsTotal["moving/total"] * (rowsTotal["vehicle"] - 1)) + value["moving/total"]) / rowsTotal["vehicle"] ;
            rowsTotal["idle/total"] = ((rowsTotal["idle/total"] * (rowsTotal["vehicle"] - 1)) + value["idle/total"]) / rowsTotal["vehicle"];
        }

        this.props.r.setInReduxState({ percentage: 100, rows, rowsTotal });
    }

    async populateReportImpure(entityId: number, reportType: string) {
        this.props.r.setInReduxState({rows: [[]] as [string[]], percentage: 0});
        const result: any = (await apolloClient.mutate<populateHistoryReport>({
            mutation: POPULATE_HISTORY_TRACK_REPORT,
            variables: { from: this.props.s.startDate, to: this.props.s.endDate, equipmentsIds: this.props.s.selectedEquipmentsIds, entityId, reportType, groupDataBy: this.props.s.groupDataBy },
            context: { showSpinner: false }
        }));
    }

    async getEquipmentsForDropdown() {
        const loadOperationName = `equipmentResourceService_findByFilter`;
        const loadQuery = gql(`query q($params: FindByFilterParamsInput) { 
            ${loadOperationName}(params: $params) {
                results { id identifier } totalCount
            }
        }`);
        const result: EquipmentResource[] = (await apolloClient.query({
            query: loadQuery,
            variables: FindByFilterParams.create(),
            context: { showSpinner: false }
        })).data[loadOperationName].results;

        this.props.r.setInReduxState({ equipmentsOptions: result });
    }

    exportDataAsCSV(config: Config, entity: Chart) {
        const rows = lodash.cloneDeep(this.props.s.rows);

        const valuesTotal = []
        for (const field of rows[0]) {
            valuesTotal.push(this.props.s.rowsTotal[field]);
        }
        rows.push(valuesTotal);

        for (var i = 1; i < rows.length; i++) {
            for (var j = 0; j < rows[0].length; j++) {
                const fd = this.fds.get(rows[0][j]);
                if (!fd) {
                    continue;
                }
                const jsonValue = { [fd.name] : fd.getFieldValueConvertedToMeasurementUnit(rows[i][j]) };
                const simulatedEntity = fd.isCustomField ? { [CUSTOM_FIELDS]: jsonValue } : jsonValue;
                rows[i][j] = fd.getFieldValue(simulatedEntity);
            }
        }
        
        for (var i = 0; i < rows[0].length; i++) {
            const fd = this.fds.get(rows[0][i]);
            rows[0][i] = _msg(historyTrackReportDescriptor.name + "." + rows[0][i]) + " " + (fd?.getMeasurementUnitLabel() ? "(" + fd!.getMeasurementUnitLabel() + ")" : "");
        }

        Utils.exportToCsv(entity.name + "_" + new Date().getTime() + ".csv", rows);
    }
    
    async checkIfDataAvailable(entity: Chart) {
        const loadOperationName = "chartService_findById";
        const query = gql(`query q($id: ${CrudGlobalSettings.INSTANCE.defaultGraphQlIdType}) {
            ${loadOperationName}(id: $id) {
                ${ID} savedData percentage
            }
        }`);

        const result = (await apolloClientHolder.apolloClient.query({ query: query, variables: { id : entity.id } })).data[loadOperationName];

        this.props.r.setInReduxState({ percentage: result.percentage });
        if (result.percentage === 100) {
            await this.prepareData(result.savedData);
        }
    }

    async componentDidMount() {
        await this.getEquipmentsForDropdown();
        
        const date = this.props.config.date.split(",");
        if (date.length > 1) {
            this.props.r.setInReduxState({startDate: moment(date[0]).valueOf(), endDate: moment(date[1]).valueOf()});
        } 
        this.props.r.setInReduxState({ groupDataBy: this.props.config.groupDataBy });
        this.props.r.setInReduxState({ selectedEquipmentsIds: this.props.config.selectedEquipments });

        if (this.props.entity.savedData) {
            this.deserializeSavedData(this.props.entity.savedData);
        } else if (!this.props.entity.percentage || this.props.entity.percentage === 0) {
            this.populateReport();
        } else {
            this.props.r.setInReduxState({ percentage: this.props.entity.percentage });
            if (this.props.entity.percentage !== 100) {
                this.startTimer();
            }
        }
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    protected deserializeSavedData(savedData: string) {
        this.prepareData(savedData);
    }

    protected populateReport() {
        this.populateReportImpure(this.props.entity.id, this.props.entity.type!);
        this.startTimer();
    }

    private startTimer() {
        this.timer = window.setTimeout(() => {
            this.checkIfDataAvailable(this.props.entity);
            if (this.props.s.percentage !== 100) {
                this.startTimer();
            }
        }, refreshRateMillis);
    }

    private stopTimer() {
        clearTimeout(this.timer);
    }

    protected renderFieldHistoryHeader() {
        const options: { key: number, text: string, value: string }[] = this.props.s.equipmentsOptions.map(er => { return { key: er.id, text: er.identifier, value: er.id.toString() } });
        return <div className="flex-center">
            {_msg("general.from")}: &nbsp;
            <DatePickerReactCalendar onChange={(date: Moment | null) => this.props.r.setInReduxState({ startDate: date?.valueOf() })} value={moment(this.props.s.startDate)} format={Utils.dateTimeFormat} />
            &nbsp;
            {_msg("general.to")}: &nbsp;
            <DatePickerReactCalendar onChange={(date: Moment | null) => this.props.r.setInReduxState({ endDate: date?.valueOf() })} value={moment(this.props.s.endDate)} format={Utils.dateTimeFormat} />
            &nbsp;
            {_msg("HistoryTrackReport.groupDataBy.label")}: &nbsp;
            <Dropdown value={this.props.s.groupDataBy} selection options={[{ key: 0, text: "Week", value: "week" }, { key: 1, text: "Month", value: "month" },
            { key: 2, text: "Year", value: "year" }, { key: 3, text: "Whole period", value: "wholePeriod" }]} onChange={(e, { value }) => this.props.r.setInReduxState({ groupDataBy: value as string })} />
            &nbsp;
            {_msg("HistoryTrackReport.selectedEquipments.label")}: &nbsp;
            <Dropdown clearable value={this.props.s.selectedEquipmentsIds?.split(",")} style={{ zIndex: 999 }} multiple selection options={options} search={true} placeholder="All"
                onChange={(e, { value }) => {
                    this.props.r.setInReduxState({ selectedEquipmentsIds: (value as string[]).filter(x => x != "").join(',') })
                }} />
            
        </div>
    }

    getTableWidth() {
        return this.state?.measuredWidth ? this.state.measuredWidth : 0;
    }

    getTableHeight() {
        return this.state?.measuredHeight ? this.state.measuredHeight - 10 : 0
    }

    renderFieldHistoryTable() {
        return <Table rowsCount={this.props.s.rows.length - 1} rowHeight={50} width={this.getTableWidth()} maxHeight={this.getTableHeight()}
                        headerHeight={50} touchScrollEnabled isColumnResizing={true} isColumnReordering={true} footerHeight={40} >
            {this.props.s.rows[0].map((field: string, index) => {
                return <Column key={field} columnKey={field}
                    allowCellsRecycling width={index < 3 ? 150 : 100} isResizable={true} isReorderable={true}
                    flexGrow={index === this.props.s.rows[0].length - 1 ? 1 : undefined}
                    header={props => {
                        const fd = this.fds.get(field);
                        if (fd) {
                            return <Cell>
                                {fd.getIcon()} {_msg(historyTrackReportDescriptor.name + "." + field)} {fd.getMeasurementUnitLabel() ? "(" + fd.getMeasurementUnitLabel() + ")" : ""}
                            </Cell>
                        }
                        return <Cell>{_msg(historyTrackReportDescriptor.name + "." + field)}</Cell>}
                    }
                    cell={props => {
                        const value = this.props.s.rows[props.rowIndex + 1][index];
                        const fd = this.fds.get(field);
                        if (fd) {
                            const jsonValue = { [fd.name] : value };
                            const simulatedEntity = fd.isCustomField ? { [CUSTOM_FIELDS]: jsonValue } : jsonValue;
                            return <Cell>
                                {fd.renderField(simulatedEntity)}
                            </Cell>
                        }

                        return <Cell>
                            {value}
                        </Cell>
                    }}
                    footer={props => {
                        const value = this.props.s.rowsTotal[field];
                        const fd = this.fds.get(field);
                        if (fd) {
                            const jsonValue = { [fd.name] : value};
                            const simulatedEntity = fd.isCustomField ? { [CUSTOM_FIELDS]: jsonValue } : jsonValue;
                            return <Cell>
                                {fd.renderField(simulatedEntity)}
                            </Cell>
                        }
                        return <Cell>
                            {value}
                        </Cell>
                    }}
                    /> 
                })}
        </Table>
    }

    protected getBarChartData() {
        const rows: [any[]] = _.clone(this.props.s.rows);
        // first row contains rows names
        rows.shift();
        const barChartData = rows.map((row: any[]) => {
            let intervalDuration = 0;
            switch (this.props.s.groupDataBy) {
                case "week": {
                    intervalDuration = 7 * 24 * 60 * 60 * 1000;
                    break;
                }
                case "month": {
                    intervalDuration = moment(row[3]).endOf("month").daysInMonth() * 24 * 60 * 60 * 1000;
                    break;
                }
                case "year": {
                    intervalDuration = moment(row[3]).endOf("year").dayOfYear() * 24 * 60 * 60 * 1000;
                    break;
                }
                default: {
                    intervalDuration = this.props.s.endDate - this.props.s.startDate
                }
            }

            const data: any = {};
            data["movingDuration_value"] = row[11];
            data["idleDuration_value"] = row[12];
            data["stopDuration_value"] = row[13];
            data["notInUsed_value"] = intervalDuration - row[11] - row[12] - row[13];
            data["movingDuration"] = Math.floor(row[11] / intervalDuration * 10000) / 100;
            data["idleDuration"] = Math.floor(row[12] / intervalDuration * 10000) / 100;
            data["stopDuration"] = Math.floor(row[13] / intervalDuration * 10000) / 100;
            data["notInUsed"] = Math.floor((100 - data["movingDuration"] - data["idleDuration"] - data["stopDuration"]) * 100) / 100;
            if (data["notInUsed"] < 0) {
                data["notInUsed"] = 0;
            }
            data["identifier"] = row[0] + " (" + moment(row[3]).format(Utils.dateFormat) + ")";
            return data;
        });
        return barChartData;
    }

    protected formatMilis(milis: number) {
        return moment.duration(milis, "milliseconds").format("d.hh:mm:ss", { trim: false });
    }
    
    renderBarChart() {
        return <div className="TaskKPIPage_barChart flex-grow" style={{overflowY:"hidden"}}>
            <ResponsiveBar
                data={this.getBarChartData()} layout={this.state.barChartLayoutHorizontal ? 'horizontal' : 'vertical'} indexBy="identifier"
                groupMode={this.state.barChartGrouped ? "grouped" : "stacked"} keys={["movingDuration", "idleDuration", "stopDuration", "notInUsed"]}
                margin={{ top: 0, right: 135, left: 150, bottom: 30 }} padding={0.3} innerPadding={1} borderRadius={5} borderWidth={3}
                colors={ ["#008000", "#FFCC00", "#808080", "lightgray"] } borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                minValue={"auto"} maxValue={"auto"}
                axisBottom={{ tickSize: 5, tickPadding: 5, tickRotation: 0, format: (value: any) => this.state.barChartLayoutHorizontal ? value + '%' : value }}
                axisLeft={{ tickSize: 5, tickPadding: 5, tickRotation: 0, format: (value: any) => !this.state.barChartLayoutHorizontal ? value + '%' : value }}
                labelSkipWidth={30} labelSkipHeight={12} labelTextColor={{ from: 'color', modifiers: [['darker', 8]] }}
                animate={false}
                label={(value: any) => value.value + '%' + (value.value > 6 ? " - " + this.formatMilis(value.data[value.id + "_value"]) : "")}
                tooltip={(value: any) => <Label basic>{value.value + '% - ' + this.formatMilis(value.data[value.id + "_value"])} ({_msg("HistoryTrackReport." + value.id)})</Label>}
                legendLabel={(value: any) => _msg("HistoryTrackReport." + value.id)}
                legends={[
                    { 
                        dataFrom: 'keys',
                        anchor: 'top-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
            />
        </div>
    }

    render() {
        return <>
            <Segment compact>
                {this.renderFieldHistoryHeader()}
                &nbsp;
                <Button color='green' onClick={(event: any) => this.populateReport()}>{_msg("FieldsHistoryReport.generate")}</Button>
                <Button disabled={this.props.s.percentage != 100} onClick={(event: any) => this.exportDataAsCSV(this.props.config, this.props.entity)}>{_msg("FieldsHistoryReport.exportCsv")}</Button>
                <Button primary onClick={(event: any) => this.setState({ showBarChart: !this.state.showBarChart })}> {!this.state.showBarChart ? _msg("HistoryTrackReport.showChart") : _msg("HistoryTrackReport.showTable")}</Button>
                {this.state.showBarChart ? <ButtonGroup>
                    <Button color="orange" onClick={(event: any) => this.setState({ barChartLayoutHorizontal: !this.state.barChartLayoutHorizontal })}> {!this.state.barChartLayoutHorizontal ? _msg("HistoryTrackReport.horizontal") : _msg("HistoryTrackReport.vertical")}</Button>
                    <Button color="olive" onClick={(event: any) => this.setState({ barChartGrouped: !this.state.barChartGrouped })}> {this.state.barChartGrouped ? _msg("HistoryTrackReport.stacked") : _msg("HistoryTrackReport.grouped")}</Button>
                </ButtonGroup>
                    : undefined}
                </Segment>
            <Segment compact basic className="flex-container flex-grow no-padding-top-bottom no-margin">
                <Dimmer active={this.props.s.percentage != 100}>
                    <Loader size='large'>Loading {this.props.s.percentage + "%"}</Loader>
                </Dimmer>
                <Measure bounds onResize={contentRect => this.setState({ measuredWidth: contentRect.bounds?.width, measuredHeight: contentRect.bounds?.height })}>
                    {({ measureRef }) => (<div className="flex-container flex-grow" ref={measureRef}>
                        {this.state.showBarChart ? this.renderBarChart() : this.renderFieldHistoryTable()}
                    </div>)}
                </Measure>
            </Segment>
        </>;
    }
}

export const HistoryTrackReportRRC = ReduxReusableComponents.connectRRC(HistoryTrackReportState, HistoryTrackReportReducers, HistoryTrackReport);