import { FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import StringFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/StringFieldRenderer";
import { DOMESTIC, INTERNATIONAL } from "./FlightSchengenFieldEditor";

export class FlightSchengenFieldRenderer extends StringFieldRenderer<FieldRendererProps> {

    protected getText(): String {
        const { value } = this.props;
        if (value == undefined || null) {
            return '';
        }
        return value ? DOMESTIC : INTERNATIONAL;
    }
}
