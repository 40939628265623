import React, { RefObject } from "react";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { GanttDoubleDisplayMode, GanttDoublePage, GanttDoublePageRRC } from "pages/gantt/GanttDouble";
import { ModalExt, Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { Button, Dropdown, Header, Input, Modal, Popup, Segment } from "semantic-ui-react";
import { apolloClientHolder } from "@crispico/foundation-react/apolloClient";
import { COPY_GANTT_ASSIGNMENT_TO_DB, COPY_GANTT_ASSIGNMENT_TO_DB_EM, COPY_INPUT_DATA_FROM_DB, CREATE_TASKS, DELETE_OUTPUT_DATA_FROM_DB, GET_GANTT_ASSIGNMENT_ALGORITHMS, RECALCULATE_GANTT_ASSIGNMENT, RUN_GANTT_ASSIGNMENT_ALGORITHM } from "./queries";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import _ from "lodash";
import { CopyInputDataFromDbConfigInput } from "apollo-gen/globalTypes";
import { GanttAssignmentCopyInputDataButtonRRC, GanttAssignmentEntityRequirement } from "./ganttButtons/GanttAssignmentCopyInputData";
import { GanttAssignmentDeleteDataButtonRRC } from "./ganttButtons/GanttAssignmentDeleteData";
import { ganttAssignmentEntityDescriptor } from "AppEntityDescriptors";
import { GanttAssignmentEntities, ResourcesData } from "./GanttAssignmentEntityDescriptor";
import { DatePickerReactCalendar } from "@crispico/foundation-react/components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar";
import { Moment } from "moment";
import moment from "moment";

export const DYNAMIC_PROGRAMMING_ALGORITHM = "DynamicProgramming";
const GANTT_ASSIGNMENT_ALGORITHM_SELECTED = "ganttAssignmentAlgorithmSelected";

class GanttAssignmentPageState extends State {
    copyFlightsModal = false;
    deleteDataFor = "";
    warningFor = "";
    initialValues = {};
    currentlyUsedResources = 0;
    algorithms: { [key: string]: { entityRequirements: GanttAssignmentEntityRequirement[] } } = {};
    selectedAlgorithm = "";
    popupOpened = false;
    modalOpenedForRecalculate = false;
    modalOpenedForDeleteNotStartedMissions = false;
}

class GanttAssignmentPageReducers<S extends GanttAssignmentPageState = GanttAssignmentPageState> extends Reducers<S> { }

type Props = RRCProps<GanttAssignmentPageState, GanttAssignmentPageReducers> & { entity: any, entities: GanttAssignmentEntities, resourcesData: ResourcesData, saveEntity?: (entity: any) => void };

export class GanttAssignmentPage extends React.Component<Props> {

    protected ganttDoublePageRef = React.createRef<GanttDoublePage>();
    protected copyFlightsFromDbFilter?: Filter = undefined;
    protected tobBarRef: RefObject<HTMLDivElement> = React.createRef();

    constructor(props: Props) {
        super(props);
        this.updateInputOutputCsv = this.updateInputOutputCsv.bind(this);
        this.copyInputDataFromDb = this.copyInputDataFromDb.bind(this);
    }

    componentDidMount() {
        this.getGanttAssignmentAlgorithms();
        if (this.props.s.selectedAlgorithm === "") {
            const selectedAlgorithm = localStorage.getItem(GANTT_ASSIGNMENT_ALGORITHM_SELECTED);
            if (selectedAlgorithm) {
                this.props.r.setInReduxState({ selectedAlgorithm });
            }
        }
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: Props) {
        this.componentDidUpdateInternal(prevProps);
    }

    protected async componentDidUpdateInternal(prevProps?: Props) {
        if (!prevProps || !_.isEqual(prevProps.entities, this.props.entities)) {
            this.onChangeEntities();
        }
        if (!prevProps || !_.isEqual(prevProps.resourcesData, this.props.resourcesData)) {
            this.onChangeResourcesData();
        }
    }

    private onChangeEntities() {
        this.ganttDoublePageRef.current?.props.r.setInReduxState({ entities: this.props.entities });
    }

    private onChangeResourcesData() {
        this.props.r.setInReduxState({ currentlyUsedResources: this.props.resourcesData.currentlyUsed });
        this.ganttDoublePageRef.current?.props.r.setInReduxState({ hideResources: this.props.resourcesData.hide });
    }

    protected async runGanttAssignmentAlgorithm() {
        if (!this.props.entity || !this.props.saveEntity) {
            return;
        }
        if (!Object.keys(this.props.s.algorithms).includes(this.props.s.selectedAlgorithm)) {
            Utils.showGlobalAlert({ title: _msg("GanttAssignment.noAlgorithmSelected.title"), message: _msg("GanttAssignment.noAlgorithmSelected.message"), severity: Severity.WARNING });
            return;
        }
        const entity = (await apolloClientHolder.apolloClient.query({ query: RUN_GANTT_ASSIGNMENT_ALGORITHM, variables: { algorithm: this.props.s.selectedAlgorithm, assignmentId: this.props.entity.id } })).data["ganttAssignmentService_runGanttAssignmentAlgorithm"];
        this.props.saveEntity(entity);
    }

    protected async getGanttAssignmentAlgorithms() {
        const algorithms: { [key: string]: { entityRequirements: GanttAssignmentEntityRequirement[] } } =
            (await apolloClientHolder.apolloClient.query({ query: GET_GANTT_ASSIGNMENT_ALGORITHMS })).data["ganttAssignmentService_ganttAssignmentAlgorithms"];

        Object.values(algorithms).forEach(algorithm => algorithm.entityRequirements
            .forEach(entityRequirement => entityRequirement.defaultAdditionalFilter = Filter.enableAllFilters(entityRequirement.defaultAdditionalFilter)));

        this.props.r.setInReduxState({ algorithms });
    }

    protected async createTasks() {
        if (!this.props.entity || !this.props.saveEntity) {
            return;
        }
        if (!this.props.entities["Flight"]) {
            Utils.showGlobalAlert({ title: _msg("GanttAssignment.emptyInput.title"), message: _msg("GanttAssignment.emptyInput.message"), severity: Severity.WARNING });
            return;
        }
        const entity = (await apolloClientHolder.apolloClient.query({ query: CREATE_TASKS, variables: { algorithm: this.props.s.selectedAlgorithm, assignmentId: this.props.entity.id } })).data["ganttAssignmentService_createTasks"];
        this.props.saveEntity(entity);
    }

    protected async recalculateGanttAssignment(startDate: number, endDate: number) {
        const id = (await apolloClientHolder.apolloClient.mutate({ mutation: RECALCULATE_GANTT_ASSIGNMENT, variables: { algorithm: this.props.s.selectedAlgorithm, assignmentId: this.props.entity.id, startDate: startDate, endDate: endDate, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone } })).data["ganttAssignmentService_recalculateGanttAssignment"];
        window.open("#" + ganttAssignmentEntityDescriptor.getEntityEditorUrl(id) + "/gantt");
    }

    protected async deleteNotStartedMissions(startDate: number, endDate: number) {
        const response = (await apolloClientHolder.apolloClient.mutate({ mutation: DELETE_OUTPUT_DATA_FROM_DB, variables: { startDate: startDate, endDate: endDate, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone } })).data["ganttAssignmentService_deleteOutputDataBetweenDates"];
        Utils.showGlobalAlert({ title: _msg("general.info"), message: response ? _msg("GanttAssignment.deleteTodayNotStartedMissions.message.done") : _msg("GanttAssignment.deleteTodayNotStartedMissions.message.failed"), severity: response ? Severity.CONFIRMATION : Severity.ERROR });
    }

    async copyGanttAssignmentToDb() {
        if (!this.props.entity) {
            return;
        }
        await apolloClientHolder.apolloClient.mutate({ mutation: COPY_GANTT_ASSIGNMENT_TO_DB, variables: { assignmentId: this.props.entity?.id, algorithm: this.props.s.selectedAlgorithm } });
    }

    async copyGanttAssignmentToDbEm() {
        if (!this.props.entity) {
            return;
        }
        await apolloClientHolder.apolloClient.mutate({ mutation: COPY_GANTT_ASSIGNMENT_TO_DB_EM, variables: { assignmentId: this.props.entity?.id, algorithm: this.props.s.selectedAlgorithm } });
    }

    async copyInputDataFromDb(config: CopyInputDataFromDbConfigInput[]) {
        if (!this.props.entity || !this.props.saveEntity) {
            return;
        }
        const entity = (await apolloClientHolder.apolloClient.query({ query: COPY_INPUT_DATA_FROM_DB, variables: { assignmentId: this.props.entity.id, algorithm: this.props.s.selectedAlgorithm, config } })).data["ganttAssignmentService_copyInputDataFromDb"];
        this.props.saveEntity(entity);
    }

    protected updateInputOutputCsv(input: string, output: string) {
        if (!this.props.entity || !this.props.saveEntity) {
            return;
        }
        this.props.saveEntity({ ...this.props.entity, inputEntitiesCsv: input, outputEntitiesCsv: output });
    }

    protected changeInputCurrentlyUsedResources(currentlyUsedResources: number) {
        const resourcesData = this.props.resourcesData;
        if (resourcesData.available === undefined || resourcesData.needed === undefined) {
            return;
        }
        if (currentlyUsedResources < 0) {
            currentlyUsedResources = 0;
        } else if (currentlyUsedResources > resourcesData.available && currentlyUsedResources > resourcesData.needed) {
            currentlyUsedResources = resourcesData.needed > resourcesData.available ? resourcesData.needed : resourcesData.available;
        }

        this.updateCurrentlyUsedEquipmentResources(currentlyUsedResources);
    }

    protected updateCurrentlyUsedEquipmentResources(currentlyUsedResources: number) {
        if (!this.props.entities["EquipmentResource"]) {
            return;
        }
        let hideResources: { [key: string]: number[] } = {};       
        if (!hideResources["EquipmentResource"]) {
            hideResources["EquipmentResource"] = [];
        }
        this.props.r.setInReduxState({ currentlyUsedResources });
        hideResources["EquipmentResource"] = this.ganttDoublePageRef.current?.getGanttResourcesRef()?.getResourcesWithTheLeastTimeUsed(currentlyUsedResources) || [];
        this.ganttDoublePageRef.current?.props.r.setInReduxState({ hideResources });
    }


    protected renderTabBar() {
        return <>
            <Button primary icon="bars" onClick={() => this.props.r.setInReduxState({ popupOpened: true })}></Button>
            <ModalExt open={this.props.s.popupOpened} style={{ width: '95%' }} onClose={() => this.props.r.setInReduxState({ popupOpened: false })} >
                <Modal.Content className="wh100">
                    <Segment className="buttonBar EntityEditorFormSimple_bar less-padding less-margin-top-bottom" >
                        <div>{_msg("GanttAssignmentEntityEditor.assignmentAlgorithm")}:</div>
                        <Dropdown className="small-margin-left" selection placeholder="..." value={this.props.s.selectedAlgorithm} options={Object.keys(this.props.s.algorithms).map(a => { return { text: _.startCase(_.camelCase(a)), value: a } })} onChange={(e, data) => {
                            this.props.r.setInReduxState({ selectedAlgorithm: data.value as string });
                            localStorage.setItem(GANTT_ASSIGNMENT_ALGORITHM_SELECTED, data.value as string);
                        }} />
                        <GanttAssignmentDeleteDataButtonRRC id="GanttAssignmentDeleteData" entity={this.props.entity} updateInputOutput={this.updateInputOutputCsv} />
                        <GanttAssignmentCopyInputDataButtonRRC id="GanttAssignmentCopyInputData" entity={this.props.entity} entityRequirements={this.props.s.algorithms[this.props.s.selectedAlgorithm]?.entityRequirements} algorithmName={this.props.s.selectedAlgorithm} copyInputDataFromDb={this.copyInputDataFromDb} />
                        {this.props.s.selectedAlgorithm == DYNAMIC_PROGRAMMING_ALGORITHM ? <Button primary icon="copy" content={_msg("GanttAssignment.createTasks")} onClick={() => this.createTasks()} /> : null}
                        {this.props.s.selectedAlgorithm ? <Button positive content={_msg("GanttAssignment.runAlgorithm")} onClick={() => this.runGanttAssignmentAlgorithm()} /> : null}
                        <Dropdown trigger={<>{_msg("GanttAssingment.copyGanttAssignmentToDb")}</>} icon='dropdown' floating button>
                            <Dropdown.Menu>
                                <Dropdown.Item text={"Service"} onClick={() => this.copyGanttAssignmentToDb()} />
                                <Dropdown.Item text={"EntityManager"} onClick={() => this.copyGanttAssignmentToDbEm()} />
                            </Dropdown.Menu>
                        </Dropdown>
                        <Popup key="deleteNotStartedMissionsPopup" content={_msg("GanttAssignment.flightsDates.tooltip")} wide position="top center" trigger={<Button negative content={_msg("GanttAssignment.deleteTodayNotStartedMissions")} onClick={() => this.props.r.setInReduxState({ modalOpenedForDeleteNotStartedMissions: true })} />} />
                        {this.props.s.selectedAlgorithm == DYNAMIC_PROGRAMMING_ALGORITHM ? <Popup key="recalculatePopup" content={_msg("GanttAssignment.flightsDates.tooltip")} wide position="top center" trigger={<Button positive content={_msg("GanttAssignment.recalculate")} onClick={() => this.props.r.setInReduxState({ modalOpenedForRecalculate: true })} />} /> : null}
                        
                        <div style={{ flexGrow: 2 }}></div>
                        {this.props.s.selectedAlgorithm == DYNAMIC_PROGRAMMING_ALGORITHM ? <div className="small-margin-right">
                            <div>{_msg("GanttAssignmentEntityEditor.neededResources", this.props.resourcesData.needed !== undefined ? this.props.resourcesData.needed : "-")}</div>
                            <div>{_msg("GanttAssignmentEntityEditor.availableResources")}: <Input value={this.props.s.currentlyUsedResources} onChange={data => this.changeInputCurrentlyUsedResources(Number(data.target.value))} ><input className='less-padding' style={{ width: 80, textAlign: "center" }} /></Input> <Button icon="plus" compact size="mini" onClick={() => this.changeInputCurrentlyUsedResources(this.props.s.currentlyUsedResources + 1)} />
                                <Button icon="minus" compact size="mini" onClick={() => this.changeInputCurrentlyUsedResources(this.props.s.currentlyUsedResources - 1)} />
                            </div>
                        </div> : null}
                    </Segment>
                </Modal.Content>
            </ModalExt>
            <SelectDatesPopup open={this.props.s.modalOpenedForRecalculate || this.props.s.modalOpenedForDeleteNotStartedMissions} startDateLabel={ganttAssignmentEntityDescriptor.getField("flightsStartDate").getLabel()} endDateLabel={ganttAssignmentEntityDescriptor.getField("flightsEndDate").getLabel()}
                onOkClickCallback={(startDate: number, endDate: number) => {
                    if (this.props.s.modalOpenedForRecalculate) {
                        this.recalculateGanttAssignment(startDate, endDate);
                    } else if (this.props.s.modalOpenedForDeleteNotStartedMissions) {
                        this.deleteNotStartedMissions(startDate, endDate);
                    }
                }} onClose={() => this.props.r.setInReduxState({ modalOpenedForRecalculate: false, modalOpenedForDeleteNotStartedMissions: false })}
            />
        </>
    }

    render() {
        const startDate = this.props.entity?.flightsStartDate ? moment(this.props.entity?.flightsStartDate).add(-2, 'hours').toString() : moment().startOf('day').toString();
        const endDate = this.props.entity?.flightsEndDate ? moment(this.props.entity?.flightsEndDate).add(2, 'hours').toString() : moment().endOf('day').toString();
        return <div className="flex-container flex-grow-shrink-no-overflow">
            <Segment className={"flex-container-row flex-center less-padding less-margin-bottom gap5"}>
                {this.renderTabBar()}
                <div className="flex-container-row flex-center gap5" ref={this.tobBarRef} />
            </Segment>
            <GanttDoublePageRRC id="GanttAssignmentPage_GanttDoublePage" ref={this.ganttDoublePageRef} startDate={startDate} endDate={endDate} portalContainerForTopBar={this.tobBarRef.current} displayMode={GanttDoubleDisplayMode.DEFAULT} />
        </div>;
    }
}

type SelectDatesPopupProps = {
    open: boolean,
    startDateLabel: string,
    endDateLabel: string,
    onClose(): void,
    onOkClickCallback: (startDate: number, endDate: number) => void
}
type SelectDatesPopupState = {
    startDate: number,
    endDate: number
}
export class SelectDatesPopup extends React.Component<SelectDatesPopupProps, SelectDatesPopupState> {

    constructor(props: SelectDatesPopupProps) {
        super(props)
        this.state = {
            startDate: moment().valueOf(),
            endDate: moment().endOf('day').valueOf()
        }
    }

    componentDidUpdate(prevProps: Readonly<SelectDatesPopupProps>): void {
        if (prevProps.open != this.props.open){
            this.setState({ startDate: moment().valueOf(), endDate: moment().endOf('day').valueOf() });
        }
    }

    render() {
        return <>
            <ModalExt size='mini' open={this.props.open} closeIcon onClose={() => this.props.onClose()} >
                <Modal.Content className="wh100">
                    <Segment className="flex-container">
                        <div>
                            <Header textAlign="left" content={this.props.startDateLabel} size="tiny" />
                            <DatePickerReactCalendar onChange={(date: Moment | null) => this.setState({ startDate: date?.valueOf()! })} value={moment(this.state.startDate)} format={Utils.dateTimeFormat} />
                            <Header textAlign="left" content={this.props.endDateLabel} size="tiny" />
                            <DatePickerReactCalendar onChange={(date: Moment | null) => this.setState({ endDate: date?.valueOf()! })} value={moment(this.state.endDate)} format={Utils.dateTimeFormat} />
                        </div>                        
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button positive content={_msg("general.ok")} onClick={() => {
                            this.props.onOkClickCallback(this.state.startDate, this.state.endDate);
                            this.props.onClose();
                        }} />
                </Modal.Actions>
            </ModalExt>
        </>
    }
}

export const GanttAssignmentPageRRC = ReduxReusableComponents.connectRRC(GanttAssignmentPageState, GanttAssignmentPageReducers, GanttAssignmentPage);
