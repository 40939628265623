import { FilterOperators } from "@crispico/foundation-gwt-js";
import { apolloClient, DummyToRememberPeopleToCast, FieldDescriptor, Utils } from "@crispico/foundation-react";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { SelectExtOption, SelectExt } from "@crispico/foundation-react/components/selectExt/SelectExt";
import { AssociationFieldEditor, AssociationEditorProps, AssociationExtraProps } from "@crispico/foundation-react/entity_crud/AssociationFieldEditor";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { FieldEditorProps, FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { LoadModeEnum } from "apollo-gen/globalTypes";
import { InitializationsForClient } from "app";
import { FormikProps } from "formik";
import _ from "lodash";
import { ReactNode } from "react";
import { ActionMeta } from "react-select";
import { Form, Icon, Radio } from "semantic-ui-react";
import { CREATE_ADDRESS, LOAD_CONNECTION_FLIGHTS, LOAD_CONNECTION_FLIGHTS_IDS, LOAD_FLIGHT_DETAILS, LOAD_TASK_TYPES, LOAD_TYPOLOGIES, LOAD_VALUES_GROUP } from "./queries";
import StringFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/StringFieldRenderer";

export const CONTAINER_TASK_NAME = "CTR";
export const CAMION_TASK_NAME = "CAMION";
export const CAMION_DEBARQUE_TASK_NAME = "CAMION DEBARQUE";
export const DOLLY_CPC_TASK_NAME = "DOLLY_CPC";

export class LoadModeFieldDescriptor extends FieldDescriptor {
    protected renderFieldInternal(RendererClass: any, props: FieldRendererProps, entity: any): ReactNode {
        return entity[this.name] && <>{_msg("LoadModeEnum." + entity[this.name].toLowerCase() + ".label")}</>;
    }

    renderFieldEditor(formikProps: FormikProps<any>, additionalFieldEditorProps?: DummyToRememberPeopleToCast) {
        let options = this.fieldDescriptorSettings?.fieldIntervals?.map((fieldInterval) => {
            return {
                value: fieldInterval.from,
                label: _msg("LoadModeEnum." + fieldInterval.from.toLowerCase() + ".label")
            }
        })
        let selectedOption = formikProps.values[this.name] ?
            {
                value: formikProps.values[this.name],
                label: _msg("LoadModeEnum." + formikProps.values[this.name].toLowerCase() + ".label")
            } :
            null
        return <SelectExt isMulti={false} isDisabled={formikProps.values["taskType"] && formikProps.values["taskType"].name !== CONTAINER_TASK_NAME}
            closeMenuOnSelect value={selectedOption} onChange={(data: any, e: any) => formikProps.setFieldValue(this.name, data.value)}
            options={options ? options : []} />;
    }
}

export class QuantityFieldDescriptor extends FieldDescriptor {
    renderFieldEditor(formikProps: FormikProps<any>, additionalFieldEditorProps?: DummyToRememberPeopleToCast) {
        return <Form.Input disabled={formikProps.values["taskType"] && formikProps.values["taskType"].name === CONTAINER_TASK_NAME} type="number" value={formikProps.values[this.name]}
            onChange={((e, data) => formikProps.setFieldValue(this.name, data.value))} />;
    }
}

export class VraIndexFieldDescriptor extends FieldDescriptor {
    renderFieldEditor(formikProps: FormikProps<any>, additionalFieldEditorProps?: DummyToRememberPeopleToCast) {
        return <Form.Input type="number" value={formikProps.values[this.name]} presicion={2} step="0.001"
            onChange={((e, data) => formikProps.setFieldValue(this.name, Number(data.value)))} />;
    }
}

export class FillPercentageFieldDescriptor extends FieldDescriptor {
    renderFieldEditor(formikProps: FormikProps<any>, additionalFieldEditorProps?: DummyToRememberPeopleToCast) {
        return <Form.Input min="0" type="number" value={formikProps.values[this.name]}
            onChange={((e, data) => formikProps.setFieldValue(this.name, data.value))} />;
    }
}

export class StartAddressFieldDescriptor extends FieldDescriptor {
    protected checkDepartureForTaskGroup(formikProps: FormikProps<any>) {
        return formikProps.values["taskGroup"] && formikProps.values["taskGroup"].departure;
    }

    protected checkTaskTypeName(formikProps: FormikProps<any>, type: string) {
        return formikProps.values["taskType"] && formikProps.values["taskType"].name === type
    }

    protected checkConditionsForCamion(formikProps: FormikProps<any>) {
        return (this.checkDepartureForTaskGroup(formikProps) && this.checkTaskTypeName(formikProps, CAMION_TASK_NAME)) ||
            (!this.checkDepartureForTaskGroup(formikProps) && this.checkTaskTypeName(formikProps, CAMION_DEBARQUE_TASK_NAME));
    }

    renderFieldEditor(formikProps: FormikProps<any>, additionalFieldEditorProps?: DummyToRememberPeopleToCast) {
        return <AssociationFieldEditor fieldDescriptor={this} formikProps={formikProps} innerEntityDescriptor={entityDescriptors["Address"]}
            isDisabled={this.checkConditionsForCamion(formikProps)} />;
    }
}

export class EndAddressFieldDescriptor extends StartAddressFieldDescriptor {
    protected checkDepartureForTaskGroup(formikProps: FormikProps<any>) {
        return formikProps.values["taskGroup"] && !formikProps.values["taskGroup"].departure;
    }
}

export class PriorityFieldDescriptor extends FieldDescriptor {
    renderFieldEditor(formikProps: FormikProps<any>, additionalFieldEditorProps?: DummyToRememberPeopleToCast) {
        return <PriorityFieldEditor fieldDescriptor={this} formikProps={formikProps} queryLimit={-1} />;
    }
}

export class FlightFieldDescriptor extends FieldDescriptor {
    renderFieldEditor(formikProps: FormikProps<any>, additionalFieldEditorProps?: DummyToRememberPeopleToCast) {
        return <FlightFieldEditor innerEntityDescriptor={entityDescriptors["Flight"]}
            fieldDescriptor={this} formikProps={formikProps} selectedValue={formikProps.values["taskGroup"]} />;
    }
}

export class GalleyFieldDescriptor extends FieldDescriptor {
    renderFieldEditor(formikProps: FormikProps<any>, additionalFieldEditorProps?: DummyToRememberPeopleToCast) {
        return <GalleyFieldEditor fieldDescriptor={this} formikProps={formikProps} queryLimit={-1} />;
    }
}

export class CommentFieldDescriptor extends FieldDescriptor {
    renderFieldEditor(formikProps: FormikProps<any>, additionalFieldEditorProps?: DummyToRememberPeopleToCast) {
        return <Form.TextArea value={formikProps.values[this.name]}
            onChange={((e, data) => formikProps.setFieldValue(this.name, data.value))} />;
    }
    protected renderFieldInternal(RendererClass: any, props: FieldRendererProps, entity: any): ReactNode {
        return super.renderFieldInternal(CommentFieldRenderer, props, entity);
    }
}

class CommentFieldRenderer extends StringFieldRenderer<FieldRendererProps> {

    private getDepartureHc(flight: any): string | undefined {
        if (!flight) {
            return undefined;
        }
        if (flight.departure) {
            return !Utils.isNullOrEmpty(flight.hc) && flight.hc != "0" ? flight.hc : undefined;
        } else {
            return flight.rotationFlight && !Utils.isNullOrEmpty(flight.rotationFlight.hc) && flight.rotationFlight.hc != "0" ? flight.rotationFlight.hc : undefined;
        }
    }

    protected getText(): string {
        const { value, entity } = this.props;
        if (Utils.isNullOrEmpty(value)) {
            return "";
        }
        let text: string = value;
        if (text.indexOf("start_hc") != -1) {
            const departureHc = this.getDepartureHc(entity.taskGroup);
            if (departureHc) {
                text = text.replace(/%value_hc%/g, departureHc);
                text = text.replace(/(%start_hc%)|(%end_hc%)/g, "");
            } else {
                text = text.replace(/(%start_hc%)|(%end_hc%)|(%value_hc%)/g, "");
            }
        }
        return text;
    }
}

export class TaskParameterFieldDescriptor extends FieldDescriptor {
    labels = ["-", _msg("Task.taskParameter.estimated.label"), _msg("Task.taskParameter.incoherent.label"), _msg("Task.taskParameter.verify.label")];

    protected renderFieldInternal(RendererClass: any, props: FieldRendererProps, entity: any): ReactNode {
        if (entity.taskParameter !== null) {
            return <span>{this.labels[entity.taskParameter]}</span>
        }
        return <></>;
    }

    renderFieldEditor(formikProps: FormikProps<any>, additionalFieldEditorProps?: DummyToRememberPeopleToCast) {
        return <Form.Group inline>
            {this.labels.map((label, index) => <Form.Field control={Radio} label={label} value={index}
                onChange={(e: any, data: any) => { formikProps.setFieldValue(this.name, data.value); }}
                checked={formikProps.values[this.name] === index} />)}
        </Form.Group>;
    }
}

export class ConnectionFlightFieldDescriptor extends FieldDescriptor {
    renderFieldEditor(formikProps: FormikProps<any>, additionalFieldEditorProps?: DummyToRememberPeopleToCast) {
        const { tempSettingsXops } = AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().initializationsForClient as InitializationsForClient;

        return this.name !== "value" ?
            <ConnectionFlightFieldEditor innerEntityDescriptor={entityDescriptors["Flight"]} flightId={formikProps.values["flightId"]}
                fieldDescriptor={this} formikProps={formikProps} onChange={async (option) => {
                    let currentMissionTypeSettings = undefined;
                    if (formikProps.values["missionType"]) {
                        currentMissionTypeSettings = tempSettingsXops.tempMissionTypeSettings.find((value) => value.missionType === formikProps.values["missionType"].id);
                    }
                    if (option && currentMissionTypeSettings && currentMissionTypeSettings.setObjectEndAddressFromFlightConnectionParking) {
                        let endAddress = (await apolloClient.query({
                            query: LOAD_FLIGHT_DETAILS,
                            variables: { Id: option.id },
                            context: { showSpinner: false }
                        })).data["flightService_findById"]["parking"];

                        formikProps.setFieldValue("endAddress", endAddress);
                    } else {
                        formikProps.setFieldValue("endAddress", null);
                    }
                }} /> :
            <AssociationFieldEditor fieldDescriptor={this} innerEntityDescriptor={entityDescriptors["Flight"]} formikProps={formikProps} />;
    }
}

export class TaskTypeFieldDescriptor extends FieldDescriptor {
    async getOppositeAddressOnTaskTypeChanged(formikProps: FormikProps<any>) {
        if (formikProps.values["taskGroup"].paxParking) {
            return formikProps.values["taskGroup"].paxParking;
        }
        if (formikProps.values["taskGroup"].sariaPort && formikProps.values["taskGroup"].sariaPort.length > 0) {
            return (await apolloClient.mutate({
                mutation: CREATE_ADDRESS,
                variables: { sariaPort: formikProps.values["taskGroup"].sariaPort },
                context: { showSpinner: false }
            })).data["addressService_orCreateAddress"];
        }
        return null;
    }

    renderFieldEditor(formikProps: FormikProps<any>, additionalFieldEditorProps?: DummyToRememberPeopleToCast) {
        return <TaskTypeFieldEditor innerEntityDescriptor={entityDescriptors["TaskType"]} isClearable queryLimit={-1}
            fieldDescriptor={this} formikProps={formikProps} onChange={async (entity) => {
                if (formikProps.values["flightId"] && (!formikProps.values["endAddress"] || !formikProps.values["startAddress"])) {
                    if (formikProps.values["taskGroup"] && formikProps.values["taskGroup"].departure && !formikProps.values["endAddress"]) {
                        formikProps.setFieldValue("endAddress", formikProps.values["taskGroup"].parking);
                        formikProps.setFieldValue("startAddress", await this.getOppositeAddressOnTaskTypeChanged(formikProps));
                    } else if (formikProps.values["taskGroup"] && !formikProps.values["taskGroup"].departure && !formikProps.values["startAddress"]) {
                        formikProps.setFieldValue("startAddress", formikProps.values["taskGroup"].parking);
                        formikProps.setFieldValue("endAddress", await this.getOppositeAddressOnTaskTypeChanged(formikProps));
                    }
                }

                if (entity && entity.name === DOLLY_CPC_TASK_NAME && !formikProps.values["id"] &&
                    formikProps.values["taskGroup"] && formikProps.values["taskGroup"].departure) {
                    formikProps.setFieldValue("startAddress", formikProps.values["endAddress"]);
                }

                if (entity && entity.name === DOLLY_CPC_TASK_NAME && !formikProps.values["id"] &&
                    formikProps.values["taskGroup"] && !formikProps.values["taskGroup"].departure) {
                    formikProps.setFieldValue("endAddress", formikProps.values["startAddress"]);
                }

                if (!formikProps.values["name"]) {
                    formikProps.setFieldValue("name", entity.name);
                }

                if (entity && entity.name !== CONTAINER_TASK_NAME) {
                    formikProps.setFieldValue("quantity", 1);
                    formikProps.setFieldValue("loadMode", LoadModeEnum.NONE);
                }

                if (!entity) {
                    formikProps.setFieldValue("startAddress", null);
                    formikProps.setFieldValue("endAddress", null);
                }
            }} />;
    }
}
export class TaskTypeFieldEditor extends AssociationFieldEditor<FieldEditorProps & AssociationEditorProps & AssociationExtraProps> {
    protected async performQuery(searchQuery: string, operationName?: string) {
        let result = (await apolloClient.query({
            query: LOAD_TASK_TYPES,
            variables: FindByFilterParams.create().pageSize(this.props.queryLimit || -1),
            context: { showSpinner: false }
        })).data["taskTypeService_findByFilter"]["results"];

        this.setState({ entities: result });
    }
}

export class PriorityFieldEditor extends AssociationFieldEditor<FieldEditorProps & AssociationEditorProps & AssociationExtraProps> {
    protected async performQuery(searchQuery: string, operationName?: string) {
        let result = (await apolloClient.query({
            query: LOAD_TYPOLOGIES,
            variables: FindByFilterParams.create().pageSize(this.props.queryLimit || -1),
            context: { showSpinner: false }
        })).data["typologyService_findByFilter"]["results"];

        this.setState({ entities: result });
    }

    protected updateCurrentOption(value: any, action: ActionMeta<any>) {
        this.setState({ selectedOption: value.entity });
        this.changeSelectedValue(!value ? null : value.value);
    }

    protected getSelectExtProps() {
        let options: SelectExtOption[] = this.state.entities!.map(entity => ({
            label: entity.name,
            value: entity.name
        }));

        let selectedValue: SelectExtOption | null = !this.props.formikProps!.values[this.props.fieldDescriptor.name] ? null : {
            label: this.props.formikProps!.values[this.props.fieldDescriptor.name],
            value: this.props.formikProps!.values[this.props.fieldDescriptor.name],
        };

        return { options, selectedValue }
    }
}

export class GalleyFieldEditor extends PriorityFieldEditor {
    protected async performQuery(searchQuery: string, operationName?: string) {
        let results = (await apolloClient.query({
            query: LOAD_VALUES_GROUP,
            variables: FindByFilterParams.create().filter(Filter.create("type", FilterOperators.forString.like, "galley")),
            context: { showSpinner: false }
        })).data["valuesGroupService_findByFilter"]["results"];

        this.setState({ entities: results });
    }

    protected getSelectExtProps() {
        let options: SelectExtOption[] = this.state.entities!.map(entity => ({
            label: entity.values,
            value: entity.values
        }));

        let selectedValue: SelectExtOption | null = !this.props.formikProps!.values[this.props.fieldDescriptor.name] ? null : {
            label: this.props.formikProps!.values[this.props.fieldDescriptor.name],
            value: this.props.formikProps!.values[this.props.fieldDescriptor.name],
        };

        return { options, selectedValue };
    }
}

export class ConnectionFlightFieldEditor extends AssociationFieldEditor<FieldEditorProps & AssociationEditorProps & AssociationExtraProps & {
    flightId: number
}> {
    protected async performQuery(searchQuery: string, operationName?: string) {
        let { enabled, ...filter } = Filter.createComposedForClient(FilterOperators.forComposedFilter.or, [
            Filter.create("outgoingFlight", FilterOperators.forNumber.equals, this.props.flightId.toString()),
            Filter.create("incomingFlight", FilterOperators.forNumber.equals, this.props.flightId.toString())
        ])

        let ids: any[] = (await apolloClient.query({
            query: LOAD_CONNECTION_FLIGHTS_IDS,
            variables: FindByFilterParams.create().pageSize(-1).filter(filter),
            context: { showSpinner: false }
        })).data["flightConnexionService_findByFilter"]["results"].map((entity: any) => entity.id);

        if (ids.length === 0) {
            return;
        }

        let results: any[] = (await apolloClient.query({
            query: LOAD_CONNECTION_FLIGHTS,
            variables: FindByFilterParams.create().pageSize(-1).filter(Filter.create("id", FilterOperators.forNumber.in, ids.toString())),
            context: { showSpinner: false }
        })).data["flightService_findByFilter"]["results"];

        this.setState({ entities: results });
    }
}

export class FlightFieldEditor extends AssociationFieldEditor<FieldEditorProps & AssociationEditorProps & AssociationExtraProps> {
    protected getSelectExtProps() {
        return {
            ...super.getSelectExtProps(),
            isDisabled: this.props.fieldDescriptor.name === "taskGroup" && this.props.formikProps.values["taskGroup"]
        }
    }
}