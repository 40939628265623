import { ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { RowLayer } from "@crispico/react-timeline-10000";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Button, Checkbox, Dropdown, DropdownItemProps, Icon, Input, Segment, SemanticICONS } from "semantic-ui-react";
import { AbstractGantt, AbstractGanttProps, AbstractGanttReducers, AbstractGanttState, GanttData, GanttGroup, GanttItem, ITEM_HEIGHT } from "./AbstractGantt";
import { GANTT_TYPES, GanttTypeRenderer } from "./ganttTypeRenderers";
import { flightEntityDescriptor, taskEntityDescriptor } from "AppEntityDescriptors";
import { Column, Table } from "fixed-data-table-2";
import { GanttUtils } from "./GanttUtils";
import { apolloClientHolder, Utils } from "@crispico/foundation-react";
import gql from "graphql-tag";
import { isFlexMode } from "app";
import { InfoTaskMode } from "./typeRenderers/TaskRenderer";

const FIXED_FLIGHT_SEGMENT_WIDTH: number = 2; // value from flex, should be configurable
const FIXED_TASK_SEGMENT_WIDTH: number = 0.5;

export enum GanttTasksMode {
    FLIGHT_TASKS = "FLIGHT_TASKS",
    TASKS = "TASKS"
}

class GanttTasksState extends AbstractGanttState {
    mode: GanttTasksMode | undefined;
    hideAssignedTasks: boolean | undefined;
    filterTaskType: string[] | undefined;
    filterStatusOptions: DropdownItemProps[] | undefined;
    selectedStatus: string | undefined;
    textSearched: string | undefined;
    infoTaskMode: InfoTaskMode = InfoTaskMode.ARR_DPT;
}
class GanttTasksReducers<S extends GanttTasksState = GanttTasksState> extends AbstractGanttReducers<S> { }

type GanttTasksProps = AbstractGanttProps & { showDatePicker?: boolean, showStatusFilter: boolean };
type Props = RRCProps<GanttTasksState, GanttTasksReducers> & GanttTasksProps;

export class GanttTasks extends AbstractGantt<GanttTasksProps, GanttTasksReducers, GanttTasksState, { searchInput: string }> {

    constructor(props: Props) {
        super(props);
        this.isTaskMode = this.isTaskMode.bind(this);
        this.flexMode_onDropOnGantt = this.flexMode_onDropOnGantt.bind(this);
        this.state = { searchInput: '' };
    }

    private async getFlightSettings() {
        const filterStatusOptions: DropdownItemProps[] = [];
        const { lastKnownDateTypes } = (await apolloClientHolder.apolloClient.query({
            query: gql(`query q { 
               flightService_settings {
                    lastKnownDateTypes
                }
            }`)
        })).data["flightService_settings"];

        lastKnownDateTypes.forEach((lastKnownDateType: any) => {
            const label = _msg({ missingKeyStrategy: "RETURN_NULL" }, `Flight.lastKnownDateType.${lastKnownDateType}.label`);
            filterStatusOptions.push({ value: lastKnownDateType, text: !label ? lastKnownDateType : label });

        })
        filterStatusOptions.push({ value: _msg("general.all"), text: _msg("general.all") });
        this.props.r.setInReduxState({ filterStatusOptions, selectedStatus: _msg("general.all") });
    }

    componentDidMount() {
        this.getFlightSettings();
        let mode: GanttTasksMode = (window.sessionStorage.getItem(GANTT_TASKS_DISPLAY_MODE) || GanttTasksMode.TASKS) as GanttTasksMode;
        let hideAssignedTasks: boolean = JSON.parse(window.sessionStorage.getItem(GANTT_TASKS_HIDE_ASSIGNED_TASKS) || "true");

        this.props.r.setInReduxState({ mode, hideAssignedTasks });

        if (isFlexMode()) {
            window.addEventListener("dropOnGantt", this.flexMode_onDropOnGantt);
            globalThis.ganttTasksTableWidthChange = (widthTablePercent: number) => {
                const tableWidth = window.innerWidth / widthTablePercent;
                this.props.r.setInReduxState({ tableWidth });
            }
            globalThis.ganttTasksDisplayIntervalChange = ([start, end]: [number, number]) => {
                this.props.r.setInReduxState({ start, end });
            }
            globalThis.setGanttTasksTableWidthAndDisplayInterval = ([widthTablePercent, start, end]: [number, number, number]) => {
                const tableWidth = window.innerWidth / widthTablePercent;
                this.props.r.setInReduxState({ tableWidth, start, end });
            }
            globalThis.ganttTasksFilter = (filter: string[]) => {
                this.props.r.setInReduxState({ filterTaskType: filter });
            }
            globalThis.ganttTasksInfoTaskMode = (mode: number) => {
                this.props.r.setInReduxState({ infoTaskMode: (mode as InfoTaskMode) | InfoTaskMode.ARR_DPT });
            }
            if (globalThis.js_to_as) {
                globalThis.js_to_as(["getGanttMissionTableWidthAndDisplayInterval"], "setGanttTasksTableWidthAndDisplayInterval")
                globalThis.js_to_as(["getGanttTasksFilter"], "ganttTasksFilter");
                globalThis.js_to_as(["getGanttTasksInfoTaskMode"], "ganttTasksInfoTaskMode");
            }
        }
    }

    flexMode_onDropOnGantt(event: any) {
        const details = JSON.parse(event.detail);
        const ganttBodyElement = document.querySelector(`.rct9k-id-${this.timelineId} .rct9k-grid`)
        if (!ganttBodyElement) {
            return;
        }
        const { top, bottom, left, right } = ganttBodyElement.getBoundingClientRect();
        if (details.clientX < right && details.clientX > left && details.clientY > top && details.clientY < bottom) {
            const header = document.getElementById("root")!;
            const startTime = this.getTimeAtPixel(details.clientX - details.offsetX).valueOf();
            const endTime = startTime + details.duration;
            const detail = {
                entityUid: details.entityUid,
                startTime,
                endTime
            }
            header.dispatchEvent(new CustomEvent("openEditor", { detail: JSON.stringify(detail) }));
        }
    }

    protected async componentDidUpdateInternal(prevProps?: Props) {
        super.componentDidUpdateInternal(prevProps);
        let shouldRefreshGanttData = false;
        if (prevProps && prevProps?.s.hideAssignedTasks != this.props.s.hideAssignedTasks) {
            window.sessionStorage.setItem(GANTT_TASKS_HIDE_ASSIGNED_TASKS, this.props.s.hideAssignedTasks ? "true" : "false");
            shouldRefreshGanttData = true;
        }
        if (prevProps && prevProps?.s.mode != this.props.s.mode) {
            window.sessionStorage.setItem(GANTT_TASKS_DISPLAY_MODE, this.props.s.mode as GanttTasksMode);
            shouldRefreshGanttData = true;
        }
        if (prevProps && (prevProps?.s.filterTaskType != this.props.s.filterTaskType ||
            prevProps?.s.textSearched != this.props.s.textSearched ||
            prevProps?.s.selectedStatus != this.props.s.selectedStatus)) {
            shouldRefreshGanttData = true;
        }
        if (shouldRefreshGanttData) {
            this.processData(this.props.entities);
        }
    }

    public isTaskMode() {
        return this.props.s.mode === GanttTasksMode.TASKS;
    }

    private checkConditionsForMission(mission: any) {
        let hasMission = false;
        if ((mission.humanResource && !(this.props.hideResources?.["HumanResource"] && this.props.hideResources["HumanResource"]?.findIndex(id => id === mission.humanResource.id) !== -1))) {
            hasMission = true;
        }
        if (!hasMission && mission.equipmentResource && !(this.props.hideResources?.["EquipmentResource"] && this.props.hideResources["EquipmentResource"]?.findIndex(id => id === mission.equipmentResource.id) !== -1)) {
            hasMission = true;
        }
        if (!hasMission && mission.equipmentType && !(this.props.hideResources?.["EquipmentType"] && this.props.hideResources["EquipmentType"]?.findIndex(id => id === mission.equipmentType.id) !== -1)) {
            hasMission = true;
        }
        return hasMission;
    }

    protected addTaskItems(items: { [uid: string]: GanttItem }, entities: any, flight: any, index: number) {
        let taskCounter = 0;
        AbstractGantt.find("Task", "taskGroup.id", flight.id, entities).forEach((task: any) => {
            let hasMission = false;
            if (task.mission) {
                AbstractGantt.find("Mission2", "id", task.mission.id, entities).find(mission => {
                    hasMission = this.checkConditionsForMission(mission);
                });
            } else {
                AbstractGantt.find("ObjectActionGroup", "object.id", task.id, entities).find((oag: any) => {
                    AbstractGantt.find("Mission2", "id", oag.mission.id, entities).find(mission => {
                        hasMission = this.checkConditionsForMission(mission);
                    });
                });
            }
            if ((this.props.s.hideAssignedTasks && hasMission) || (this.props.s.filterTaskType && this.props.s.filterTaskType.length && !this.props.s.filterTaskType.find(taskType => taskType === task.taskType.name))) {
                return;
            }
            const entityUid = GanttUtils.toEntityUid(taskEntityDescriptor.name, task.id);
            if (items[entityUid]) {
                console.log(`The Task(id = ${task.id}) ${taskEntityDescriptor.toMiniString(task)} from Flight(id = ${flight.id}) ${flightEntityDescriptor.toMiniString(flight)} exist in gantt!`);
                return;
            }
            let startTime = task.startTime, endTime = task.endTime;

            const flightWidth = FIXED_FLIGHT_SEGMENT_WIDTH;
            if (!task.startTime && !task.endTime) {
                startTime = moment(flight.date).add(0.01 + (this.isTaskMode() ? 0 : flightWidth) + taskCounter * FIXED_TASK_SEGMENT_WIDTH, 'h').valueOf();
                taskCounter++;
                endTime = moment(flight.date).add((this.isTaskMode() ? 0 : flightWidth) + taskCounter * FIXED_TASK_SEGMENT_WIDTH, 'h').valueOf();
            } else if (!startTime) {
                startTime = moment(endTime).add(-flightWidth, 'h').valueOf();
            } else if (!endTime) {
                endTime = moment(startTime).add(flightWidth, 'h').valueOf();
            }
            if (startTime > endTime) {
                console.log(`The Task(id = ${task.id}) ${taskEntityDescriptor.toMiniString(task)} from Flight(id = ${flight.id}) ${flightEntityDescriptor.toMiniString(flight)} has startTime > endTime; it isn't displayed in gantt!`);
                return;
            }

            items[entityUid] = {
                key: entityUid,
                row: index,
                start: startTime,
                end: endTime,
                entityId: task.id,
                type: GANTT_TYPES.TASK
            }
        });
    }

    protected processData(newEntities: any) {
        let flights: any = {};
        if (newEntities) {
            const entities = newEntities;
            flights = entities["Flight"] ? _.cloneDeep(entities["Flight"]) : {};
        }
        let layers: RowLayer[] = [], groups: GanttGroup[] = [], items: { [uid: string]: GanttItem } = {};

        if (Object.keys(flights).length === 0) {
            groups.push({ id: 0 });
        }
        const { textSearched, selectedStatus } = this.props.s;
        const sortedFlights = Object.keys(flights).map(key => Number.parseFloat(key))
            .filter(key => {
                const flight = flights[key];
                if (flight.showFlightInGantt) {
                    let showFlight = true;
                    if (!Utils.isNullOrEmpty(textSearched)) {
                        showFlight = ((flight?.airline || "") + (flight?.number || "")).toUpperCase().search(textSearched!.toUpperCase()) >= 0 ||
                            (flight.planeIdentifier || "").toUpperCase().search(textSearched!.toUpperCase()) >= 0;
                        if (!showFlight && flight.rotationFlight?.id) {
                            const rotationFlight = AbstractGantt.findOne("Flight", "id", key, newEntities).rotationFlight;
                            showFlight = ((rotationFlight?.airline || "") + (rotationFlight?.number || "")).toUpperCase().search(textSearched!.toUpperCase()) >= 0 ||
                                (rotationFlight.planeIdentifier || "").toUpperCase().search(textSearched!.toUpperCase()) >= 0;
                        }
                    }
                    if (showFlight && !Utils.isNullOrEmpty(selectedStatus) && selectedStatus != _msg("general.all")) {
                        showFlight = flight.lastKnownDateType === selectedStatus;
                    }
                    return showFlight;
                }
                return false;
            }).map(key => {
                const flight = AbstractGantt.findOne("Flight", "id", key, newEntities);
                let startDate: number = moment(flight.date).valueOf();
                let endDate: number = startDate;
                if (flight.rotationFlight?.id) {
                    if (flight.departure) {
                        startDate = moment(flight.rotationFlight.date).valueOf();
                    } else {
                        endDate = moment(flight.rotationFlight.date).valueOf();
                    }
                }
                return { flight, startDate, endDate };
            }).sort((a, b) => a.startDate === b.startDate ? 0 : a.startDate > b.startDate ? 1 : -1);

        let groupIndex = -1;

        sortedFlights.forEach((x) => {
            const arvFlight = x.flight.departure ? x.flight.rotationFlight : x.flight;
            const depFlight = x.flight.departure ? x.flight : x.flight.rotationFlight;

            // filter flights
            // if arv - dep already added => don't add it again
            if (groups.find(group => group.arvFlightId === arvFlight?.id && group.depFlightId === depFlight?.id)) {
                return;
            }
            groupIndex++;

            if (arvFlight?.showFlightInGantt) {
                this.addTaskItems(items, newEntities, arvFlight, this.isTaskMode() ? 0 : groupIndex);
            }
            if (depFlight?.showFlightInGantt) {
                this.addTaskItems(items, newEntities, depFlight, this.isTaskMode() ? 0 : groupIndex);
            }
            groups.push({
                id: groupIndex,
                arvFlightName: (arvFlight?.airline || "") + (arvFlight?.number || "") + (arvFlight?.parking?.name ? ((" (" + arvFlight?.parking?.name) + ")") : ""),
                depFlightName: (depFlight?.airline || "") + (depFlight?.number || "") + (depFlight?.parking?.name ? ((" (" + depFlight?.parking?.name) + ")") : ""),
                entityId: x.flight.id,
                arvFlightId: arvFlight?.id,
                depFlightId: depFlight?.id
            });
            if (this.isTaskMode()) {
                // if task mode don't add flight items and layers
                return;
            }
            let itemFlight: RowLayer = {
                rowNumber: groupIndex,
                start: x.startDate,
                end: x.endDate,
                style: { background: `lightgrey` }
            };

            layers.push(itemFlight);
        });

        if (this.isTaskMode()) {
            // if show only tasks, reset the group to one group for show 1 row on gantt
            groups = [{ id: 0 } as GanttGroup];
        }
        this.props.r.setInReduxState({ data: { layers, items: Object.values(items), groups } });
    }

    protected entitiesChangedHandler(newEntities: any) {
        this.processData(newEntities);
    }

    protected onSelectionChange(selectedItems: (number | string)[]) {
        if (isFlexMode()) {
            globalThis.js_to_as(["onSelectionChange", ...selectedItems]);
        }
    }

    protected getAcceptedType() {
        return "Task";
    }

    protected getTableRowsCount() {
        return this.isTaskMode() ? 0 : 2;
    }

    protected getTableWidth() {
        if (this.isTaskMode()) {
            return 0;
        }
        return super.getTableWidth();
    }

    protected renderTableColumns() {
        return this.isTaskMode() ? super.renderTableColumns() : [
            <Column key={0} columnKey={0}
                width={this.props.s.tableWidth / 2} header={<HeaderRenderer iconName="plane" rotation={45} />}
                cell={({ rowIndex }) => this.props.s.data.groups[rowIndex] && < GanttTypeRenderer item={{ ...this.props.s.data.groups[rowIndex], type: GANTT_TYPES.ARV_FLIGHT }} gantt={this} />}
            />,
            <Column key={1} columnKey={1} flexGrow={1}
                width={this.props.s.tableWidth / 2} header={<HeaderRenderer iconName="plane" rotation={-45} />}
                cell={({ rowIndex }) => this.props.s.data.groups[rowIndex] && < GanttTypeRenderer item={{ ...this.props.s.data.groups[rowIndex], type: GANTT_TYPES.DEP_FLIGHT }} gantt={this} />}
            />
        ];
    }

    protected renderTopBar(): React.ReactNode {
        const optionsGroup: DropdownItemProps[] = [{ value: GanttTasksMode.FLIGHT_TASKS, text: _msg("GanttTasks.modeFlights.label") }, { value: GanttTasksMode.TASKS, text: _msg("GanttTasks.modeTasks.label") }]
        return <>
            {!isFlexMode() ? this.renderAdditionalTopBar() : null}
            <div className="flex-container">{_msg("GanttTasks.displayMode.title")}
                <Dropdown selection options={optionsGroup} value={this.props.s.mode} onChange={(e, data) =>
                    this.props.r.setInReduxState({ mode: data.value as GanttTasksMode })
                } />
            </div>
            <div className="flex-container">{_msg("GanttTasks.hideAssignedTasks.title")}
                <Checkbox checked={this.props.s.hideAssignedTasks} onClick={(event, data) =>
                    this.props.r.setInReduxState({ hideAssignedTasks: data.checked ? true : false })
                } />
            </div>
        </>;
    }

    protected renderAdditionalTopBar() {
        return <>
            <div className="flex-container-row">
                <Input className="flex-grow" placeholder={_msg("GanttTasks.searchFlight.label")} value={this.state.searchInput}
                    onKeyDown={(e: any) => e.key === 'Enter' && this.props.r.setInReduxState({ textSearched: this.state.searchInput })}
                    onChange={(e) => this.setState({ searchInput: e.target.value as string })}>
                </Input>
                <Button icon='search' color="green" onClick={(e: any) => { this.props.r.setInReduxState({ textSearched: this.state.searchInput }) }} />
                <Button icon='delete' color="red" onClick={(e: any) => { this.setState({ searchInput: '' }); this.props.r.setInReduxState({ textSearched: '' }) }} />
            </div>
            {this.props.showStatusFilter ? <div className="flex-container">{_msg("Flight.lastKnownDate.label")}
                <Dropdown selection options={this.props.s.filterStatusOptions} value={this.props.s.selectedStatus} onChange={(e, data) =>
                    this.props.r.setInReduxState({ selectedStatus: data.value as string })
                } />
            </div> : null}
        </>
    }

    render() {
        return <>
            {isFlexMode() ? <Segment className="flex-container gap5" style={{ width: this.props.s.tableWidth }}>
                {this.renderAdditionalTopBar()}
            </Segment> : null}
            {super.render()}
        </>;
    }
}
class HeaderRenderer extends React.Component<{ iconName: SemanticICONS, rotation?: number }> {
    render() {
        return <div className="wh100 flex-center flex-justify-content-center">
            <Icon name={this.props.iconName} style={{ transform: "rotate(" + (this.props.rotation ? this.props.rotation : 0) + "deg)" }} />
        </div>;
    }
}

export const GanttTasksRRC = ReduxReusableComponents.connectRRC(GanttTasksState, GanttTasksReducers, GanttTasks);

const GANTT_TASKS_DISPLAY_MODE = 'ganttTasks.displayMode';
const GANTT_TASKS_HIDE_ASSIGNED_TASKS = 'ganttTasks.hideAssignedTasks';
