import { apolloClient, DispatchersFrom, EntityEditorFormSimple, Utils } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { PeriodPicker, PeriodPickerRRC } from "@crispico/foundation-react/components/periodPicker/PeriodPicker";
import { Reducers, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import gql from "graphql-tag";
import moment from "moment";
import { Moment } from "moment";
import React from "react";
import { Button, Icon, Popup, Segment } from "semantic-ui-react";
import { sliceEntityEditorPageChart } from "./chartEntityDescriptor";

export class ChartTabState extends State {
    startDate = undefined as string | undefined;
    endDate = undefined as string | undefined;
}

export class ChartTabReducers<S extends ChartTabState = ChartTabState> extends Reducers<S> {
    prepareData(p: { savedData: string, config: ChartConfig }) {
        // nop; to be implemented by each chart type
    }
}

export interface Chart {
    id: any | null;
    name: string | null;
    type: string | null;
    config: string | null;
    savedData: string | null;
    percentage: number | null;
    generateAutomatically: number | null;
    sendEmailTo: string | null;
  }
export interface ChartConfig {
    startDate: string;
    endDate: string;
    equipmentFilter: Filter;
    territoryIds: Array<number>;
    tolerance: number;
}

export type ChartTabProps = RRCProps<ChartTabState, ChartTabReducers> & { editorDispatchers: DispatchersFrom<typeof sliceEntityEditorPageChart>, entity: Chart, config: ChartConfig, zeroTrainingMode: boolean };
export type ChartTabLocalState = {};
export abstract class ChartTab<C extends ChartTabProps, L extends ChartTabLocalState> extends React.Component<C, L> {
    entityEditorForm = React.createRef<EntityEditorFormSimple>();
    periodPickerRef = React.createRef<PeriodPicker>();

    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: ChartTabProps) {
        this.componentDidUpdateInternal(prevProps);
    }

    componentDidUpdateInternal(prevProps?: ChartTabProps) {
        const props = this.props;
        if (!prevProps && props.entity.savedData) {
            if (props.zeroTrainingMode) { return };
            props.r.setInReduxState({ startDate: moment(props.config.startDate).toISOString(), endDate: moment(props.config.endDate).toISOString() });
            props.r.prepareData({ savedData: props.entity.savedData, config: props.config });
        }
    }
    
    async generateChart(entity: Chart, config: ChartConfig, editorDispatchers: DispatchersFrom<typeof sliceEntityEditorPageChart>) {
        const startDate = this.periodPickerRef.current?.getStartDate();
        const endDate = this.periodPickerRef.current?.getEndDate();
        await editorDispatchers.save({...entity, config: {...config, startDate: moment(startDate).toDate(), endDate: moment(endDate).toDate() }});
        await apolloClient.query({
            query: gql(`query q($id: Long!, $startDate: String, $endDate: String) {
                chartService_runForcedGeneration(id: $id, startDate: $startDate, endDate: $endDate)
                }`),
            variables: { id: entity.id, startDate, endDate }
        });
        editorDispatchers.load(entity.id);
    }

    private s2ab(s: string) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i != s.length; ++i) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    }

    async generateXlsx(entity: Chart, config: ChartConfig) {
        const startDate = this.periodPickerRef.current?.getStartDate();
        const endDate = this.periodPickerRef.current?.getEndDate();
        const result = (await apolloClient.query({
            query: gql(`query q($id: Long!, $startDate: String, $endDate: String) {
                chartService_runForcedExport(id: $id, startDate: $startDate, endDate: $endDate)
                }`),
            variables: { id: entity.id, startDate, endDate }
        })).data["chartService_runForcedExport"];

        var blob = new Blob([this.s2ab(atob(result))], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"
        });

        // @ts-ignore
        if (navigator.msSaveBlob) { // IE 10+
            // @ts-ignore
            navigator.msSaveBlob(blob, filename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", "Chart_" + entity.name + "_" + moment(Utils.now()).toISOString() + ".xlsx");
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    renderTopBar(startDate: Moment, endDate: Moment) {
        const props = this.props;
        return <Segment className="flex-container-row flex-center less-padding no-margin">
            <PeriodPickerRRC id="chartPeriodPicker" ref={this.periodPickerRef} initialValues={{ startDate: startDate.toISOString(), endDate: endDate.toISOString() }} />
            <Popup
                trigger={<Button className="small-margin-left" color='green' onClick={(event: any) => this.generateChart(props.entity, props.config, props.editorDispatchers)}>{_msg("EquipmentUsageReport.generate")}</Button>}
                position="right center"
            >{_msg("HistogramCountInTerritoriesTab.generate.tooltip")}</Popup>
            <Button primary onClick={() => this.generateXlsx(props.entity, props.config)} ><Icon name="download" /> {_msg("dto_crud.export")}</Button>
            {props.s.startDate && props.s.endDate && props.entity.savedData ? <span className="flex-grow-shrink-no-overflow" style={{textAlign: "right"}}>{_msg("HistogramCountInTerritoriesTab.generate.period")}: <small>{startDate.format(Utils.dateTimeFormat)}</small> &#8212; <small>{endDate.format(Utils.dateTimeFormat)}</small></span> : null}
        </Segment>;
    }
}

export function getChartGenerationPeriodFromConfig(config: ChartConfig) {
    const startDate = moment(config.startDate).startOf("day").toDate().getTime();
    const endDate = startDate === moment(config.endDate).startOf("day").toDate().getTime() ? moment(config.endDate).toDate().getTime() : moment(config.startDate).endOf("day").toDate().getTime();
    return { startDate, endDate };
}
