import { apolloClient, apolloClientHolder, PrivateRoute, PrivateRouteProps, Utils } from "@crispico/foundation-react";
import { DatePickerReactCalendar } from "@crispico/foundation-react/components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import _ from "lodash";
import moment from "moment";
import React, { RefObject } from "react";
import { Button, Checkbox, Icon, Modal, Segment } from "semantic-ui-react";
import { GanttDoubleDisplayMode, GanttDoublePage, GanttDoublePageRRC } from "./GanttDouble";
import { LOAD_DATA_FOR_GANTT_PAGE } from "./queries";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import gql from "graphql-tag";
import { isFlexMode } from "app";

const REFRESH_RATE = 3000;

export interface RealTimeGanttPageSettings {
    degradedMode: boolean;
}

export class RealTimeGanttPageState extends State {
    settings: RealTimeGanttPageSettings | undefined;
    dateForQueryAsString: string | undefined;
    autoRefresh: boolean | undefined;
    settingsModal = false as boolean | [number, number];
    isLoading = false;
}

export class RealTimeGanttPageReducers<S extends RealTimeGanttPageState = RealTimeGanttPageState> extends Reducers<S> {
}

type RealTimeGanttPageProps = RRCProps<RealTimeGanttPageState, RealTimeGanttPageReducers> & { displayMode: GanttDoubleDisplayMode }
export class RealTimeGanttPage extends React.Component<RealTimeGanttPageProps> {
    protected ganttDoublePageRef = React.createRef<GanttDoublePage>();
    protected topBarRef: RefObject<HTMLDivElement> = React.createRef();

    private timer: number | undefined = undefined;

    constructor(props: any) {
        super(props);
        this.closeSettingModal = this.closeSettingModal.bind(this);
    }

    private async getSetting() {
        const settings = (await apolloClientHolder.apolloClient.query({
            query: gql(`query q { 
                mission2Service_realTimeGanttPageSettings {
                    degradedMode
                }
            }`)
        })).data["mission2Service_realTimeGanttPageSettings"];
        this.props.r.setInReduxState({ settings })
    }

    componentDidMount() {
        let autoRefresh = window.sessionStorage.getItem(REAL_TIME_GANTT_PAGE_AUTO_REFRESH) as boolean | string | null,
            dateForQueryAsString = window.sessionStorage.getItem(REAL_TIME_GANTT_PAGE_DATE_FOR_QUERY);
        if (autoRefresh === null) {
            autoRefresh = true;
        } else {
            autoRefresh = autoRefresh === "true";
        }
        if (dateForQueryAsString === null) {
            dateForQueryAsString = moment().format(Utils.dateFormat);
        }
        this.props.r.setInReduxState({ autoRefresh, dateForQueryAsString });

        this.getSetting();

        if (isFlexMode()) {
            globalThis.ganttDateChange = (date: number) => {
                this.props.r.setInReduxState({ dateForQueryAsString: moment(date).format(Utils.dateFormat) });
            }
        }
    }

    componentDidUpdate(prevProps: RealTimeGanttPageProps) {
        if (prevProps.s.dateForQueryAsString !== this.props.s.dateForQueryAsString) {
            window.sessionStorage.setItem(REAL_TIME_GANTT_PAGE_DATE_FOR_QUERY, this.props.s.dateForQueryAsString || moment().format(Utils.dateFormat));
            this.loadEntities();
        }
        if (prevProps.s.autoRefresh != this.props.s.autoRefresh) {
            window.sessionStorage.setItem(REAL_TIME_GANTT_PAGE_AUTO_REFRESH, this.props.s.autoRefresh ? "true" : "false");
            clearTimeout(this.timer);
            this.startTimer();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    private startTimer() {
        this.timer = this.props.s.autoRefresh ? window.setTimeout(() =>  this.loadEntities(), REFRESH_RATE) : undefined;
    }

    private loadEntities() {
        this.props.r.setInReduxState({ isLoading: true });
        apolloClient.query({
            query: LOAD_DATA_FOR_GANTT_PAGE,
            variables: { date: this.props.s.dateForQueryAsString },
            context: { showSpinner: false }
        }).then(async result => {
            if (this.ganttDoublePageRef.current) {
                const entities = result.data["diffUpdateService_ganttData"];
                await this.ganttDoublePageRef.current!.props.r.setInReduxState({ entities });
                this.props.r.setInReduxState({ isLoading: false });
    
                this.startTimer();
            }           
        })
    }

    private closeSettingModal() {
        this.props.r.setInReduxState({ settingsModal: false });
    }

    protected renderTabBar() {
        if (isFlexMode()) {
            // on flex mode hide the top bar
            return null;
        }
        return <Segment className={"flex-container-row less-padding less-margin-bottom"} style={{ zIndex: 10 }}>
            <DatePickerReactCalendar showButton allowClear format={Utils.dateFormat}
                value={moment(this.props.s.dateForQueryAsString, Utils.dateFormat)}
                onChange={(value) =>
                    this.props.r.setInReduxState({ dateForQueryAsString: moment(value?.toDate()).format(Utils.dateFormat) })
                } />
            <div className="flex-container-row">
                <Button className="less-padding" id="settingsButtonRef" color="green" size="tiny" icon="settings" onClick={(e) => {
                    const rect = document.getElementById("settingsButtonRef")!.getBoundingClientRect();
                    this.props.r.setInReduxState({ settingsModal: [rect.left, rect.bottom] });
                }} />
                {this.props.s.isLoading && <Icon name="spinner" color="red" size="large" loading />}
                <ModalExt className="RealTimeGanttPage_settingsModal" transparentDimmer open={this.props.s.settingsModal}
                    onMount={() => /* rerender because the content contains the portalContainer for gantts */  this.forceUpdate()}
                    onClose={this.closeSettingModal} >
                    <Modal.Content>
                        <div className="flex-container">{_msg("RealTimeGanttPage.autoRefresh.title")}
                            <Checkbox toggle size="tiny" checked={this.props.s.autoRefresh} onClick={(event, data) =>
                                this.props.r.setInReduxState({ autoRefresh: data.checked ? true : false })
                            } />
                        </div>
                        <div className="flex-container gap5" ref={this.topBarRef} />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.closeSettingModal} >{_msg("general.close")}</Button>
                    </Modal.Actions>
                </ModalExt>
            </div>
        </Segment>
    }

    render() {
        return <>
            {this.renderTabBar()}
            <GanttDoublePageRRC id="GanttDoublePageRRC" ref={this.ganttDoublePageRef} portalContainerForTopBar={this.topBarRef.current} displayMode={this.props.displayMode}
                startDate={moment(this.props.s.dateForQueryAsString, Utils.dateFormat).startOf('day').toString()} endDate={moment(this.props.s.dateForQueryAsString, Utils.dateFormat).endOf('day').toString()} />
        </>;
    }
}

export const RealTimeGanttPageRRC = ReduxReusableComponents.connectRRC(RealTimeGanttPageState, RealTimeGanttPageReducers, RealTimeGanttPage);

export const realTimeGanttPageUrl = "/RealTimeGantt";
export const realTimeGanttPageRoute = (computeRoute: (props: PrivateRouteProps) => JSX.Element) =>
    <PrivateRoute key="realTimeGanttPage"
        path={realTimeGanttPageUrl}
        render={(props) => {
            const displayMode = new URLSearchParams(props.location.search).get("displayMode") === GanttDoubleDisplayMode.FLEX_OBJECT_GANTT ? GanttDoubleDisplayMode.FLEX_OBJECT_GANTT : GanttDoubleDisplayMode.DEFAULT;
            return <RealTimeGanttPageRRC displayMode={displayMode} id="realTimeGanttPage" />
        }
        }
        computeRoute={computeRoute} />

export const realTimeGanttPageMenuEntry = () => {
    return {
        id: "realTimeGanttPage",
        content: _msg("RealTimeGanttPage.title"),
        to: realTimeGanttPageUrl, exact: true, icon: "chart bar outline",
    }
};

const REAL_TIME_GANTT_PAGE_AUTO_REFRESH = 'realTimeGanttPage.autoRefresh';
const REAL_TIME_GANTT_PAGE_DATE_FOR_QUERY = 'realTimeGanttPage.dateForQuery';
