import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { AbstractGantt, GROUP_LABEL_PROPRETY } from "../AbstractGantt";
import { AbstractTypeRenderer } from "./AbstractTypeRenderer";
import { InitializationsForClient, TempSettingsXops } from "app";
import { GANTT_TYPES } from "../ganttTypeRenderers";
import { MissionStatus } from "./constants";
import { HumanResourceInfoRenderer } from "./HumanResourceInfoRenderer";

export class HumanResourceRenderer extends AbstractTypeRenderer {

    protected getEntityName() {
        return "HumanResource";
    }

    protected renderTooltip() {
        return <HumanResourceInfoRenderer entityId={this.props.item.entityId} gantt={this.props.gantt} />;
    }

    protected canDrop(source: any, destination: any) {
        const { entities } = this.props.gantt.props;
        const { currentOrganization, tempSettings } = AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.getState().initializationsForClient as InitializationsForClient;
        if ((tempSettings as unknown as TempSettingsXops)?.tempUnitSettings?.length) {
            const hr = AbstractGantt.findOne("HumanResource", "id", destination.entityId, entities);
            if (hr && currentOrganization && hr.organization.id != currentOrganization.id) {
                if (!hr.borrowedToUnit || hr.borrowedToUnit.id != currentOrganization.id) {
                    return false;
                }
            }
        }
        if (source.type === GANTT_TYPES.HUMAN_RESOURCE || source.type === GANTT_TYPES.MISSION) {
            return false;
        }
        return true;
    }

    protected onDrop(source: any, destination: any) {
        const { entities } = this.props.gantt.props;
        let taskIds;
        if (source.type === GANTT_TYPES.TASK) {
            taskIds = [source.entityId];
        } else if (source.type === GANTT_TYPES.FLIGHT) {
            taskIds = AbstractGantt.find("Task", "taskGroup.id", source.entityId, entities).map(task => task.id);
        }
        const humanResourceId = destination.entityId;
        const missionId = AbstractGantt.find("Mission2", "humanResourceId", humanResourceId, entities).filter(m => m.status != MissionStatus.MISSION_STATUS_FINISHED && !m.inactivityType).sort((m1: any, m2: any) => {
            return m2.endTime - m1.endTime;
        })?.[0]?.id; // last mission not finished without inactivityType
        this.openAssignTaskToResource({ humanResourceId, missionId, taskIds, initialSelectedTaskIds: taskIds });
    }

    protected renderContent(): JSX.Element {
        return <div className="wh100 flex-center flex-justify-content-center" onClick={this.onItemClick}>{this.props.item[GROUP_LABEL_PROPRETY.HUMAN_RESOURCE]}</div>
    }
}
