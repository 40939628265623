import { FilterOperators } from "@crispico/foundation-gwt-js";
import { Utils } from "@crispico/foundation-react";
import { apolloClient } from "@crispico/foundation-react/apolloClient";
import { Optional } from "@crispico/foundation-react/CompMeta";
import { ClientCustomQuery } from "@crispico/foundation-react/components/CustomQuery/ClientCustomQuery";
import { CustomQueryBar, CUSTOM_QUERY_BAR_MODE, sliceCustomQueryBar } from "@crispico/foundation-react/components/CustomQuery/CustomQueryBar";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { getOrganizationFilter } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { ShareLinkLogic } from "@crispico/foundation-react/entity_crud/ShareLinkLogic";
import { ConnectedComponentInSimpleComponent, ConnectedPageInfo } from "@crispico/foundation-react/reduxHelpers/ConnectedPageHelper";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents"
import { ResponsiveBar } from "@nivo/bar";
import { Progress } from "antd";
import { loadTasksForKPIPage_taskService_findByFilter_results } from "apollo-gen/loadTasksForKPIPage";
import { ganttAssignmentEntityDescriptor, taskEntityDescriptor } from "AppEntityDescriptors";
import gql from "graphql-tag";
import _ from "lodash";
import moment from "moment";
import { equipmentResourceEntityDescriptor } from "pages/EquipmentResource/equipmentResourceEntityDescriptor";
import React from "react";
import { Button, Dropdown, DropdownItemProps, DropdownProps, Header, Icon, Label, Loader, Popup, Segment } from "semantic-ui-react";
import { LOAD_TASKS_FOR_KPI_PAGE } from "./queries";

const ALL_ORGS = "ALL_ORGS";
const NULL_ORG = "NULL_ORG";
// TEMP 
const NOT_SURE_CORRECT_CALCULATIONS = "";
// TODO add ID for this customFields
export const GA_START_TIME = "gaStartTimeID";
export const GA_END_TIME = "gaEndTimeID";
enum BarChartPeriodType { HOUR = "hour", DAY = "day", MONTH = "month", YEAR = "year", ALL_PERIOD = "allPeriod" }
const dateFormats = { hour: Utils.dateTimeFormat, day: Utils.dateFormat, month: 'MM/YYYY', year: 'YYYY', allPeriod: "" }

interface Task extends loadTasksForKPIPage_taskService_findByFilter_results {
    customFields: { [key: string]: string[] }
}

interface KpiField {
    rawValue: number;
    displayedValue: any;
    title: string;
    asProgress?: boolean;
    color?: string;
    measurementSymbol?: string;
}

interface Sum {
    value: number;
    operandsNr: number;
}

interface DataForSum {
    operationTimeDone?: number,
    operationTimeLost?: number,
    connectTime?: number,
    taskDuration?: number,
    firstGaTaskDuration?: number
}

interface TaskExport {
    startTime: string,
    endTime: string,
    ganttStart: string,
    ganttEnd: string,
    operationTimeDone: string,
    opportunityLost: string,
    airline: string,
    registrationArrival: string,
    registrationDeparture: string,
}

type KpiSums = { [key: string]: Sum }
type KpiSumsPerTimePeriod = { [key: string]: KpiSums }
type KpiSumsPerPrganization = { [key: string]: KpiSumsPerTimePeriod }

type KpiData = { [key: string]: KpiField }
type KpiDataPerTimePeriod = { [key: string]: KpiData }
type KpiDataPerOrganization = { [key: string]: KpiDataPerTimePeriod }

type BarChartData = { date: string, value: number, label: string }

export class TaskKPIPageState extends State {
    tasks = [] as Task[];
    kpiDataPerOrgs = {} as KpiDataPerOrganization;
    tasksForExport = [] as TaskExport[]; 
    equipmentsCountPerOrgs = {} as { [key: string]: number };
    ganttAssignmentsPerPeriod = {} as { [key: string]: { id: number, startTime: number, endTime: number } | undefined };
    loading = false as boolean;
}

export class TaskKPIPageReducers extends Reducers<TaskKPIPageState> {
    updateEquipmentsCountPerOrgs(qualifiedName: string, count: number) {
        this.s.equipmentsCountPerOrgs[qualifiedName] = count;
    }

    updateGanttAssignmentsPerPeriod(period: string, ganttAssignment: { id: number, startTime: number, endTime: number } | undefined) {
        this.s.ganttAssignmentsPerPeriod[period] = ganttAssignment;
    }
}

type PropsNotFromState = {
    customQuery: Optional<ClientCustomQuery>
};

type LocalState = {
    selectedPeriodForBarChart: BarChartPeriodType,
    selectedKpiId: string,
}
export type Props = RRCProps<TaskKPIPageState, TaskKPIPageReducers> & PropsNotFromState;
export class TaskKPIPage extends React.Component<Props, LocalState> {

    protected cqRef = React.createRef<ConnectedComponentInSimpleComponent>();
    protected customQueryBarRef = React.createRef<CustomQueryBar>();

    constructor(props: Props) {
        super(props);
        this.state = {
            selectedKpiId: "apuOff",
            selectedPeriodForBarChart: BarChartPeriodType.DAY,
        }
        this.onChangeFilter = this.onChangeFilter.bind(this);
    }

    componentDidMount() {
        if (this.props.customQuery) {
            this.customQueryBarRef.current?.props.dispatchers.updateCustomQuery(this.props.customQuery);
        }
    }

    protected async onChangeFilter() {
        const customQueryFilter = Filter.eliminateDisabledFilters(this.customQueryBarRef.current?.props.customQuery!.customQueryDefinitionObject.filter!);
        const validDataFilter = Filter.createComposed(FilterOperators.forComposedFilter.and,
            [Filter.create("startTime", FilterOperators.forDate.isNotEmpty, undefined),
                Filter.create("endTime", FilterOperators.forDate.isNotEmpty, undefined)])
        const queryFilter = Filter.createComposed(FilterOperators.forComposedFilter.and, customQueryFilter ? [customQueryFilter, validDataFilter] : [validDataFilter]);
        this.props.r.setInReduxState({ tasks: [], loading: true })
        const data: Task[] = (await apolloClient.query({
            query: LOAD_TASKS_FOR_KPI_PAGE,
            variables: FindByFilterParams.create().filter(queryFilter),
            context: { showSpinner: false }
        })).data["taskService_findByFilter"].results;

        if (data.length == 0) {
            this.props.r.setInReduxState({ loading: false });
            return;
        }

        this.props.r.setInReduxState({ tasks: data })
        const kpiDataPerOrgs: KpiDataPerOrganization = await this.getKPIData(data);
        this.props.r.setInReduxState({ kpiDataPerOrgs: kpiDataPerOrgs, loading: false })
    }

    protected async getGanttAssignmentAndUpdateGaTimes(date: string, qualifiedName: string) {
        const filter = Filter.createComposed(FilterOperators.forComposedFilter.and, [Filter.create("flightsStartDate", FilterOperators.forDate.dayOf, date)]);
        const organizationFilter = getOrganizationFilter(ganttAssignmentEntityDescriptor, { id: 0, name: qualifiedName, qualifiedName: qualifiedName });
        const loadOperationName = `${_.lowerFirst(ganttAssignmentEntityDescriptor.name)}Service_findByFilter`;
        const loadQuery = gql(`query q($params: FindByFilterParamsInput) { 
            ${loadOperationName}(params: $params) {
                results { id flightsStartDate flightsEndDate }
            }
        }`);
        if (organizationFilter) {
            filter.filters!.push(organizationFilter);
        }
        const ganttAssignments = (await apolloClient.query({
            query: loadQuery,
            variables: FindByFilterParams.create().filter(filter).sorts([{ field: "flightsStartDate", direction: 'ASC' }]),
            context: { showSpinner: false }
        })).data[loadOperationName].results;

        await this.props.r.updateGanttAssignmentsPerPeriod(date, ganttAssignments.length === 0 ? undefined : { id: ganttAssignments[0].id, startTime: moment(ganttAssignments[0].flightsStartDate).valueOf(), endTime: moment(ganttAssignments[0].flightsEndDate).valueOf() });
    }

    protected async getAndUpdateEquipmentsCount(qualifiedName: string) {
        const filter = Filter.createComposed(FilterOperators.forComposedFilter.and, [Filter.create("available", FilterOperators.forBoolean.equals, "true")]);
        const organizationFilter = getOrganizationFilter(equipmentResourceEntityDescriptor, { id: 0, name: qualifiedName, qualifiedName: qualifiedName });
        const loadOperationName = `${_.lowerFirst(equipmentResourceEntityDescriptor.name)}Service_findByFilter`;
        const loadQuery = gql(`query q($params: FindByFilterParamsInput) { 
            ${loadOperationName}(params: $params) {
                results { id } totalCount
            }
        }`);
        if (organizationFilter) {
            filter.filters!.push(organizationFilter);
        }
        const data = (await apolloClient.query({
            query: loadQuery,
            variables: FindByFilterParams.create().filter(filter).countMode(true),
            context: { showSpinner: false }
        })).data[loadOperationName];
        await this.props.r.updateEquipmentsCountPerOrgs(qualifiedName, data.totalCount);
    }

    protected getInitialSums(): KpiSums {
        return {
            sumOperationTimeDone: { value: 0, operandsNr: 0 },
            sumOperationTimeLost: { value: 0, operandsNr: 0 },
            sumTasksDuration: { value: 0, operandsNr: 0 },
            sumFirstGaTasksDuration: { value: 0, operandsNr: 0 },
            sumEquipmentConnectDuration: { value: 0, operandsNr: 0 },
        }
    }
    
    protected addToSum(sum: Sum, value?: number) {
        if (value) {
            sum.value += value;
            sum.operandsNr++;
        }
    }

    protected updateSumsPerOrganization(sumsPerOrgs: KpiSumsPerPrganization, orgs: string[], period: string, data: DataForSum) {
        for (const org of orgs) {
            sumsPerOrgs[org] = sumsPerOrgs[org] ? sumsPerOrgs[org] : {};
            sumsPerOrgs[org][period] = sumsPerOrgs[org][period] ? sumsPerOrgs[org][period] : this.getInitialSums();
            this.addToSum(sumsPerOrgs[org][period].sumOperationTimeDone, data.operationTimeDone);
            this.addToSum(sumsPerOrgs[org][period].sumOperationTimeLost, data.operationTimeLost);
            this.addToSum(sumsPerOrgs[org][period].sumTasksDuration, data.taskDuration);
            this.addToSum(sumsPerOrgs[org][period].sumFirstGaTasksDuration, data.firstGaTaskDuration);
            this.addToSum(sumsPerOrgs[org][period].sumEquipmentConnectDuration, data.connectTime);
        }
    }

    protected async getKPIData(tasks: Task[]) {
        const sumsPerOrgs: KpiSumsPerPrganization = {};
        const tasksForExport: TaskExport[] = [];
        for (const t of tasks) {
            const orgQN = t.taskGroup.organization?.qualifiedName ? t.taskGroup.organization?.qualifiedName : NULL_ORG;
            const flightArrivalDate = moment(t.taskGroup.arrivalDate).valueOf();
            const flightDateISO = moment(t.taskGroup.date).set("hours", 0).set("minutes", 0).toISOString();
            // TODO needs improvements...
            if (!Object.keys(this.props.s.ganttAssignmentsPerPeriod).find(date => date === flightDateISO)) {
                await this.getGanttAssignmentAndUpdateGaTimes(flightDateISO, orgQN)
            }
            if (!Object.keys(this.props.s.equipmentsCountPerOrgs).find(org => org === orgQN)) {
                await this.getAndUpdateEquipmentsCount(orgQN);
            }
            const period = moment(t.startTime).set('minutes', 0).format(Utils.dateTimeFormat);
            const taskStartTime = moment(t.startTime).valueOf();
            const taskEndTime = moment(t.endTime).valueOf();
            const oagGanttStartTime = t.mission?.startTime ? moment(t.mission?.startTime).valueOf() : 0;
            const oagGanttEndTime = t.mission?.endTime ? moment(t.mission?.endTime).valueOf() : 0;
            const gaStartTime = this.props.s.ganttAssignmentsPerPeriod[flightDateISO] ? this.props.s.ganttAssignmentsPerPeriod[flightDateISO]!.startTime : 0;
            const gaEndTime = this.props.s.ganttAssignmentsPerPeriod[flightDateISO] ? this.props.s.ganttAssignmentsPerPeriod[flightDateISO]!.endTime : 0;
            const mission = t.mission;
            const equipmentResource = mission?.equipmentResource;
            const operationTimeDone = equipmentResource && mission?.status == "finished" ? oagGanttEndTime - oagGanttStartTime : undefined;
            const operationTimeLost = mission && !equipmentResource ? oagGanttEndTime - oagGanttStartTime : mission && equipmentResource && mission!.status == "finished" ? (taskEndTime - taskStartTime) - (oagGanttEndTime - oagGanttStartTime) : undefined;
            const firstGaTaskDuration = gaEndTime - gaStartTime;
            const dataForSum: DataForSum = {
                connectTime: flightArrivalDate && oagGanttStartTime > 0 ? oagGanttStartTime - flightArrivalDate : undefined,
                operationTimeDone: operationTimeDone,
                operationTimeLost: operationTimeLost,
                taskDuration: taskEndTime - taskStartTime,
                firstGaTaskDuration: firstGaTaskDuration,
            }
            tasksForExport.push({
                startTime: moment(t.startTime).toISOString(),
                endTime: moment(t.endTime).toISOString(),
                ganttStart: oagGanttStartTime > 0 ? moment(oagGanttStartTime).toISOString() : "",
                ganttEnd: oagGanttEndTime > 0 ? moment(oagGanttEndTime).toISOString() : "",
                operationTimeDone: operationTimeDone ? moment.duration(operationTimeDone).as("minute") + "m" : "",
                opportunityLost: operationTimeLost && firstGaTaskDuration > 0 ? Math.round((operationTimeLost / firstGaTaskDuration) * 100).toString() + "%" : "",
                airline: t.taskGroup.airline!,
                registrationArrival: t.taskGroup.arrivalPlaneIdentifier ? t.taskGroup.arrivalPlaneIdentifier : "",
                registrationDeparture: t.taskGroup.departurePlaneIdentifier ? t.taskGroup.departurePlaneIdentifier : "",
            });
            // update sums per organization together with the day and the custom period which will be used in calculation for displaying the value on each KPI 
            this.updateSumsPerOrganization(sumsPerOrgs, [orgQN, ALL_ORGS], period, dataForSum);
            this.updateSumsPerOrganization(sumsPerOrgs, [orgQN, ALL_ORGS], BarChartPeriodType.ALL_PERIOD, dataForSum);
        }
        this.props.r.setInReduxState({ tasksForExport });

        let worstOperationDone: { [key: string]: {value: number, orgQualifiedName: string} } = {}
        let bestOperationDone: { [key: string]: {value: number, orgQualifiedName: string} } = {}
        const kpiDataPerOrgs: KpiDataPerOrganization = {};
        for (const org of Object.keys(sumsPerOrgs)) {
            const sumsPerPeriod = sumsPerOrgs[org];
            for (const period of Object.keys(sumsPerPeriod)) {
                const sums = sumsPerPeriod[period];
                kpiDataPerOrgs[org] = kpiDataPerOrgs[org] ? kpiDataPerOrgs[org] : {};
                const apuOffRawValue = sums.sumTasksDuration.value > 0 ? sums.sumOperationTimeDone.value / sums.sumTasksDuration.value : 0;
                const operationDoneRawValue = sums.sumFirstGaTasksDuration.value > 0 ? sums.sumOperationTimeDone.value / sums.sumFirstGaTasksDuration.value : 0;
                const opportunityLostRawValue = sums.sumFirstGaTasksDuration.value > 0 ? sums.sumOperationTimeLost.value / sums.sumFirstGaTasksDuration.value : 0;
                const residualApuOnRawValue = sums.sumTasksDuration.value - sums.sumOperationTimeDone.value;
                const timeToConnectRawValue = sums.sumEquipmentConnectDuration.operandsNr > 0 ? sums.sumEquipmentConnectDuration.value / sums.sumEquipmentConnectDuration.operandsNr : 0;
                const opportunityTimeRawValue = sums.sumFirstGaTasksDuration.operandsNr > 0 ? sums.sumFirstGaTasksDuration.value / sums.sumFirstGaTasksDuration.operandsNr : 0;
                const useTimeRawValue = sums.sumOperationTimeDone.operandsNr > 0 ? sums.sumOperationTimeDone.value / sums.sumOperationTimeDone.operandsNr : 0;
                const efficiencyRawValue = opportunityTimeRawValue > 0 ? sums.sumOperationTimeDone.value / opportunityTimeRawValue : 0;
                const coverageRawValue = sums.sumTasksDuration.operandsNr > 0 ? sums.sumOperationTimeDone.operandsNr / sums.sumTasksDuration.operandsNr : 0;
                const averageTotalOfUsePerMachineRawValue = this.props.s.equipmentsCountPerOrgs[org] > 0 ? sums.sumOperationTimeDone.value / this.props.s.equipmentsCountPerOrgs[org] : 0;
                const averageTotalOfOpportunityPerMachineRawValue = this.props.s.equipmentsCountPerOrgs[org] > 0 ? sums.sumFirstGaTasksDuration.value / this.props.s.equipmentsCountPerOrgs[org] : 0;
                kpiDataPerOrgs[org][period] = {
                    apuOff: { rawValue: Number(apuOffRawValue.toFixed(4)), displayedValue: Math.round(apuOffRawValue * 100), title: _msg("TaskKPIPage.KPI.ApuOff"), asProgress: true, color: 'purple' },
                    operationDone: { rawValue: Number(operationDoneRawValue.toFixed(4)), displayedValue: Math.round(operationDoneRawValue * 100), title: _msg("TaskKPIPage.KPI.OperationDone"), asProgress: true, color: 'green' },
                    opportunityLost: { rawValue: Number(opportunityLostRawValue.toFixed(4)), displayedValue: Math.round(opportunityLostRawValue * 100), title: _msg("TaskKPIPage.KPI.OpportunityLost"), asProgress: true, color: 'red' },
                    residualApuOn: { rawValue: Number(residualApuOnRawValue.toFixed(2)), displayedValue: Math.round(moment.duration(residualApuOnRawValue).as("hour")), title: _msg("TaskKPIPage.KPI.ResidualApuOn"), measurementSymbol: 'h' },
                    timeToConnect: { rawValue: Number(timeToConnectRawValue.toFixed(2)), displayedValue: Math.round(moment.duration(timeToConnectRawValue).as("minute")), title: _msg("TaskKPIPage.KPI.TimeToConnect"), measurementSymbol: 'm' },
                    opportunityTime: { rawValue: Number(opportunityTimeRawValue.toFixed(2)), displayedValue: Math.round(moment.duration(opportunityTimeRawValue).as("hour")), title: _msg("TaskKPIPage.KPI.OpportunityTime") + NOT_SURE_CORRECT_CALCULATIONS, measurementSymbol: 'h' },
                    useTime: { rawValue: Number(useTimeRawValue.toFixed(2)), displayedValue: Math.round(moment.duration(useTimeRawValue).as("hour")), title: _msg("TaskKPIPage.KPI.UseTime") + NOT_SURE_CORRECT_CALCULATIONS, measurementSymbol: 'h' },
                    efficiency: { rawValue: Number(efficiencyRawValue.toFixed(2)), displayedValue: Math.round(efficiencyRawValue * 100), title: _msg("TaskKPIPage.KPI.Efficiency"), measurementSymbol: '%' },
                    coverage: { rawValue: Number(coverageRawValue.toFixed(2)), displayedValue: sums.sumOperationTimeDone.operandsNr + "/" + sums.sumTasksDuration.operandsNr, title: _msg("TaskKPIPage.KPI.Coverage") },
                    averageTotalOfUsePerMachine: { rawValue: Number(averageTotalOfUsePerMachineRawValue.toFixed(2)), displayedValue: Math.round(moment.duration(averageTotalOfUsePerMachineRawValue).as("hour")), title: _msg("TaskKPIPage.KPI.AverageTotalOfUsePerMachine") + NOT_SURE_CORRECT_CALCULATIONS, measurementSymbol: 'h' },
                    averageTotalOfOpportunityPerMachine: { rawValue: Number(averageTotalOfOpportunityPerMachineRawValue.toFixed(2)), displayedValue: Math.round(moment.duration(averageTotalOfOpportunityPerMachineRawValue).as("hour")), title: _msg("TaskKPIPage.KPI.AverageTotalOfOpportunityPerMachine") + NOT_SURE_CORRECT_CALCULATIONS, measurementSymbol: 'h' },
                }
                if (org != ALL_ORGS) {
                    bestOperationDone[period] = bestOperationDone[period] ? bestOperationDone[period] : { value: Number.MIN_SAFE_INTEGER, orgQualifiedName: "UNKNOWN" }
                    worstOperationDone[period] = worstOperationDone[period] ? worstOperationDone[period] : { value: Number.MAX_SAFE_INTEGER, orgQualifiedName: "UNKNOWN" }
                    if (kpiDataPerOrgs[org][period].operationDone.displayedValue > bestOperationDone[period].value) {
                        bestOperationDone[period].value = kpiDataPerOrgs[org][period].operationDone.displayedValue;
                        bestOperationDone[period].orgQualifiedName = org;
                    }
                    if (kpiDataPerOrgs[org][period].operationDone.displayedValue < worstOperationDone[period].value) {
                        worstOperationDone[period].value = kpiDataPerOrgs[org][period].operationDone.displayedValue;
                        worstOperationDone[period].orgQualifiedName = org;
                    }
                }
            }
        }

        kpiDataPerOrgs[ALL_ORGS][BarChartPeriodType.ALL_PERIOD].worstOrg = { rawValue: Number.MAX_SAFE_INTEGER, displayedValue: "UNKNOWN", title: _msg("TaskKPIPage.KPI.WorstOrganization")}
        kpiDataPerOrgs[ALL_ORGS][BarChartPeriodType.ALL_PERIOD].bestOrg = { rawValue: Number.MIN_SAFE_INTEGER, displayedValue: "UNKNOWN", title: _msg("TaskKPIPage.KPI.BestOrganization")}
        for (const period of Object.keys(worstOperationDone)) {
            kpiDataPerOrgs[ALL_ORGS][period].worstOrg = { rawValue: worstOperationDone[period].value, displayedValue: worstOperationDone[period].orgQualifiedName, title: _msg("TaskKPIPage.KPI.WorstOrganization")}
            kpiDataPerOrgs[ALL_ORGS][period].bestOrg = { rawValue: bestOperationDone[period].value, displayedValue: bestOperationDone[period].orgQualifiedName, title: _msg("TaskKPIPage.KPI.BestOrganization")}
            if (kpiDataPerOrgs[ALL_ORGS][period].worstOrg.rawValue <  kpiDataPerOrgs[ALL_ORGS][BarChartPeriodType.ALL_PERIOD].worstOrg.rawValue) {
                kpiDataPerOrgs[ALL_ORGS][BarChartPeriodType.ALL_PERIOD].worstOrg.rawValue = kpiDataPerOrgs[ALL_ORGS][period].worstOrg.rawValue;
                kpiDataPerOrgs[ALL_ORGS][BarChartPeriodType.ALL_PERIOD].worstOrg.displayedValue = kpiDataPerOrgs[ALL_ORGS][period].worstOrg.displayedValue;
            }
            if (kpiDataPerOrgs[ALL_ORGS][period].bestOrg.rawValue >  kpiDataPerOrgs[ALL_ORGS][BarChartPeriodType.ALL_PERIOD].bestOrg.rawValue) {
                kpiDataPerOrgs[ALL_ORGS][BarChartPeriodType.ALL_PERIOD].bestOrg.rawValue = kpiDataPerOrgs[ALL_ORGS][period].bestOrg.rawValue;
                kpiDataPerOrgs[ALL_ORGS][BarChartPeriodType.ALL_PERIOD].bestOrg.displayedValue = kpiDataPerOrgs[ALL_ORGS][period].bestOrg.displayedValue;
            }
        }
        return kpiDataPerOrgs;
    }

    protected exportKPIData() {
        let rows: any[] = [];
        const kpiData = this.props.s.kpiDataPerOrgs[ALL_ORGS][BarChartPeriodType.ALL_PERIOD];
        if (Object.entries(kpiData).length > 0) {
            // displayed values as in UI
            Object.entries(kpiData).forEach(entry => {
                const key = entry[0];
                const value = entry[1];
                const measurementSymbol = value.measurementSymbol || (value.asProgress ? "%" : "");
                const displayedValue = this.getLabelValue(value.displayedValue, measurementSymbol);
                rows.push([value.title, displayedValue]);
                if (key == "operationDone" || key == "opportunityLost") {
                    const milis = Number(value.rawValue);
                    rows.push([value.title, Math.round(moment.duration(milis).as("hour")) + "h"]);
                }
            })
            Utils.exportToCsv("KPI_data_" + moment(Utils.now()).toISOString() + ".csv", rows);
        }
        const tasksForExport = this.props.s.tasksForExport;
        if (tasksForExport.length > 0) {
            rows = [];
            const keys = Object.keys(tasksForExport[0]);
            rows.push(keys.map(key => { return _msg("TaskKPIPageTaskExport." + key + ".title") }));
            tasksForExport.forEach(task => {
                rows.push(keys.map(key => { return task[(key as unknown as keyof TaskExport)] }));
            });
            Utils.exportToCsv("KPI_tasks_" + moment(Utils.now()).toISOString() + ".csv", rows);
        }
    }

    protected renderCustomQuery() {
        return <Segment className="less-padding less-margin-bottom flex-container-row flex-center">
            <div className="CustomQueryBar_div">
                <ConnectedComponentInSimpleComponent ref={this.cqRef} info={new ConnectedPageInfo(sliceCustomQueryBar, CustomQueryBar, "SliceCustomQueryBarTaskKPIPage", { ref: this.customQueryBarRef })} screen={taskEntityDescriptor.name}
                    entityDescriptor={taskEntityDescriptor.name} mode={CUSTOM_QUERY_BAR_MODE.TABLE} onChangeFilter={this.onChangeFilter} />
            </div>
                      
            <Button size="mini" icon="eye" disabled={Object.keys(this.props.s.ganttAssignmentsPerPeriod).length == 0} content={_msg("GanttAssignment.label.plural")} onClick={() => {
                const ids = Object.values(this.props.s.ganttAssignmentsPerPeriod).filter(value => value !== undefined).map((value) => { return value!.id; });
                window.open("#" + new ShareLinkLogic().createLink(false, ganttAssignmentEntityDescriptor, Filter.createForClient("id", FilterOperators.forNumber.in, _.join(_.uniq(ids), ","), true), [{ field: "flightsStartDate", direction: 'ASC' }]));
            }} />
            <Button size="mini" primary disabled={this.props.s.tasks.length == 0 || Object.keys(this.props.s.kpiDataPerOrgs).length == 0} onClick={() => this.exportKPIData()}><Icon name="download"/>{_msg("dto_crud.export")}</Button>
        </Segment>
    }

    protected renderKPIs(data: KpiData) {
        return <div className="TaskKPIPage_KPIS_container flex-container-row gap5">{Object.keys(data).map(key => this.renderKPI(data[key]))}</div>
    }

    protected renderKPI(kpi: KpiField): JSX.Element {
        return <Segment key={kpi.title} className="TaskKPIPage_KPI_element_segment flex-container no-margin">
            {kpi.asProgress ? <Progress style={{ marginBottom: '20px' }} type={"dashboard"} percent={this.getPercentValue(kpi.displayedValue)}
                status="active" strokeColor={kpi.color} width={86} format={(percent?: number) => { return <Header size="medium">{percent + "%"}</Header> }}></Progress>
                : <Popup trigger={<Header size="large" className="TaskKPIPage_KPI_element_label">{this.getLabelValue(kpi.displayedValue, kpi.measurementSymbol)}</Header>}
                    content={this.getLabelValue(kpi.displayedValue, kpi.measurementSymbol)} position="top center"/>}
            <Label size="small" basic attached={"bottom"}>{kpi.title}</Label>
        </Segment>
    }

    protected getLabelValue(value: string | number, measurementSymbol?: string) {
        let val = value;
        if (measurementSymbol == "%") {
            val = this.getPercentValue(Number(value));
        }
        return (val ? val : 0) + (measurementSymbol || "")
    }

    protected getPercentValue(value: number) {
        return (value > 100 ? 100 : (value < 0 ? 0 : value));
    }

    protected getBarChartData(): BarChartData[] {
        const kpiId = this.state.selectedKpiId;
        // e.g. period = 13/12/2011 08:00 -> hour = 13/12/2011 08:00, day = 13/12/2011, month = 12/2011, year = 2011
        const dateFormat = dateFormats[this.state.selectedPeriodForBarChart];
        const kpiDataPerTimePeriod: KpiDataPerTimePeriod = this.props.s.kpiDataPerOrgs[ALL_ORGS];
        const barChartSumPerPeriod: { [key: string]: Sum } = {};
        const barChartCoveragePerPeriod: { [key: string]: { doneTasks: number, notDoneTasks: number } } = {};
        const barChartOperationDonePerPeriodAndOrg: { [key: string]: { [key: string]: { value: number, qualifiedName: string } } } = {};
        for (const periodKpiField of Object.entries(kpiDataPerTimePeriod)) {
            const period = periodKpiField[0];
            if (period == BarChartPeriodType.ALL_PERIOD) {
                continue;
            }
            const periodFormated = moment(period, Utils.dateTimeFormat).format(dateFormat);
            if (kpiId == 'coverage') {
                barChartCoveragePerPeriod[periodFormated] = barChartCoveragePerPeriod[periodFormated] ? barChartCoveragePerPeriod[periodFormated] : { doneTasks: 0, notDoneTasks: 0 };
                const displayedValue = ((periodKpiField[1][kpiId].displayedValue) as string).split('/');
                barChartCoveragePerPeriod[periodFormated].doneTasks += Number(displayedValue[0]);
                barChartCoveragePerPeriod[periodFormated].notDoneTasks += Number(displayedValue[1]);
            } else if (kpiId == "worstOrg" || kpiId == "bestOrg") {
                const currentValue = { value: periodKpiField[1][kpiId].rawValue, qualifiedName: periodKpiField[1][kpiId].displayedValue };
                barChartOperationDonePerPeriodAndOrg[periodFormated] = barChartOperationDonePerPeriodAndOrg[periodFormated] ? barChartOperationDonePerPeriodAndOrg[periodFormated] : {}
                if (barChartOperationDonePerPeriodAndOrg[periodFormated][periodKpiField[1][kpiId].displayedValue]) {
                    barChartOperationDonePerPeriodAndOrg[periodFormated][periodKpiField[1][kpiId].displayedValue].value += currentValue.value;
                } else {
                    barChartOperationDonePerPeriodAndOrg[periodFormated][periodKpiField[1][kpiId].displayedValue] = currentValue;
                }
            } else {
                barChartSumPerPeriod[periodFormated] = barChartSumPerPeriod[periodFormated] ? barChartSumPerPeriod[periodFormated] : { value: 0, operandsNr: 0 };
                this.addToSum(barChartSumPerPeriod[periodFormated], periodKpiField[1][kpiId].displayedValue);
            }
        }

        if (kpiId == 'coverage') {
            return Object.entries(barChartCoveragePerPeriod).map(coveragePeriod => ({ date: coveragePeriod[0], value: Number((coveragePeriod[1].doneTasks / coveragePeriod[1].notDoneTasks).toFixed(2)), label: coveragePeriod[1].doneTasks + "/" + coveragePeriod[1].notDoneTasks }))
        } else if (kpiId == "worstOrg" || kpiId == "bestOrg") {
            const barChartData: BarChartData[] = [];
            for (const periodEntry of Object.entries(barChartOperationDonePerPeriodAndOrg)) {
                const values = Object.values(periodEntry[1]);
                const org = kpiId == "worstOrg" ? _.minBy(values, "value") : _.maxBy(values, "value");
                barChartData.push({ date: periodEntry[0], value: Number(moment.duration(org!.value).as('hour').toFixed(2)), label: this.getLabelValue(org!.qualifiedName + " / " + moment.duration(org!.value).as('hour').toFixed(2), "h") });
            }
            return barChartData;
        }

        return Object.entries(barChartSumPerPeriod).map(periodSum => {
            let value = periodSum[1].value;
            if (kpiDataPerTimePeriod[BarChartPeriodType.ALL_PERIOD][kpiId].asProgress || kpiDataPerTimePeriod[BarChartPeriodType.ALL_PERIOD][kpiId].measurementSymbol == "%") {
                value = this.getPercentValue(value);
            }
            return { date: periodSum[0], value: Number((value / periodSum[1].operandsNr).toFixed(2)), label: Math.round(value / periodSum[1].operandsNr).toString() }
        });
    }

    protected renderBarChartLabel(value: any, progressKpi?: boolean, measurementSymbol?: string) {
        return value.data.label + (progressKpi ? "%" : (measurementSymbol ? measurementSymbol : ""));
    }

    protected renderBarChartDetails(data: KpiData) {
        const options: DropdownItemProps[] = Object.keys(data).map((key) => { return { text: data[key].title, value: key } });
        const progressKpi = data[this.state.selectedKpiId].asProgress;
        const measurementSymbol = data[this.state.selectedKpiId].measurementSymbol
        return <Segment className="TaskKPIPage_barChart_segment less-margin-top-bottom" compact size="small">
            <Segment>
                <Dropdown as={Button} defaultValue={Object.keys(data)[0]} selection options={options}
                    onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => this.setState({ selectedKpiId: data.value as string })} />
                &nbsp;
                <Button.Group primary>
                    <Button disabled={this.state.selectedPeriodForBarChart == BarChartPeriodType.YEAR} onClick={() => this.setState({ selectedPeriodForBarChart: BarChartPeriodType.YEAR })}>{_msg("TaskKPIPage.yearly")}</Button>
                    <Button disabled={this.state.selectedPeriodForBarChart == BarChartPeriodType.MONTH} onClick={() => this.setState({ selectedPeriodForBarChart: BarChartPeriodType.MONTH })}>{_msg("TaskKPIPage.monthly")}</Button>
                    <Button disabled={this.state.selectedPeriodForBarChart == BarChartPeriodType.DAY} onClick={() => this.setState({ selectedPeriodForBarChart: BarChartPeriodType.DAY })}>{_msg("TaskKPIPage.daily")}</Button>
                    <Button disabled={this.state.selectedPeriodForBarChart == BarChartPeriodType.HOUR} onClick={() => this.setState({ selectedPeriodForBarChart: BarChartPeriodType.HOUR })}>{_msg("TaskKPIPage.hourly")}</Button>
                </Button.Group>
            </Segment>
            <div className="TaskKPIPage_barChart">
                <ResponsiveBar
                    data={this.getBarChartData()} indexBy="date" groupMode={"grouped"}
                    margin={{ top: 20, right: 40, left: 40, bottom: 20 }} padding={0.3} innerPadding={1} borderRadius={5}
                    colors={{ scheme: 'paired' }} borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                    minValue={progressKpi || measurementSymbol == "%" ? 0 : "auto"} maxValue={progressKpi || measurementSymbol == "%" ? 100 : "auto"}
                    axisBottom={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
                    axisLeft={{ tickSize: 5, tickPadding: 5, tickRotation: 0, format: (value: any) => value + (progressKpi ? "%" : (measurementSymbol ? measurementSymbol : "")) }}
                    label={(value: any) => this.renderBarChartLabel(value, progressKpi, measurementSymbol)} labelSkipWidth={12} labelSkipHeight={12} labelTextColor={{ from: 'color', modifiers: [['darker', 8]] }}
                    animate={true}
                />
            </div>
        </Segment>
    }

    render() {
        const kpiData = this.props.s.kpiDataPerOrgs[ALL_ORGS] && Object.keys(this.props.s.kpiDataPerOrgs[ALL_ORGS]).length > 0 ? this.props.s.kpiDataPerOrgs[ALL_ORGS][BarChartPeriodType.ALL_PERIOD] : undefined
        return <div className="flex-container flex-grow less-padding" >
            {this.renderCustomQuery()}
            {this.props.s.loading ? <Loader active size="big">{_msg("general.loading")}</Loader> :
                kpiData && this.props.s.tasks.length > 0 ? <div>
                    {this.renderKPIs(kpiData)}
                    {this.renderBarChartDetails(kpiData)}
                </div>
                    : <Segment className="HistoryCompare_noData" size="big" inverted color="orange" compact ><Icon name="exclamation triangle" /> {_msg("general.no.data")}</Segment>
            }
        </div>
    }
}

export const TaskKPIPageRRC = ReduxReusableComponents.connectRRC(TaskKPIPageState, TaskKPIPageReducers, TaskKPIPage);