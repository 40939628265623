import { FilterOperators } from "@crispico/foundation-gwt-js";
import { ENT_DELETE, ENT_SAVE, EntityDescriptor, EntityEditorFormSimple, EntityEditorPage, EntityTablePage, EntityTablePageProps, FieldDescriptor, Optional, PropsFrom, SliceEntityEditorPage, SliceEntityTablePage, Utils, apolloClientHolder, createSliceFoundation, getBaseImpures, getBaseReducers, sliceEntityEditorPageOnlyForExtension, sliceEntityTablePageOnlyForExtension } from "@crispico/foundation-react";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { OverrideableElement } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { ID } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import StringFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/StringFieldRenderer";
import { getAdditionalFieldsToRequest } from "@crispico/foundation-react/pages/dashboard/DashboardEntityDescriptor";
import { ganttAssignmentEntityDescriptor, humanResourceEntityDescriptor, missionEntityDescriptor, taskEntityDescriptor } from "AppEntityDescriptors";
import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import lodash from "lodash";
import { MissionEventValidationPageRRC } from "pages/Mission2/MissionEventValidationPage";
import { TaskKPIPageRRC } from "./TaskKPIPage";
import { Button, Modal, Popup, Segment } from "semantic-ui-react";
import { CommentFieldDescriptor, ConnectionFlightFieldDescriptor, EndAddressFieldDescriptor, FillPercentageFieldDescriptor, FlightFieldDescriptor, GalleyFieldDescriptor, LoadModeFieldDescriptor, PriorityFieldDescriptor, QuantityFieldDescriptor, StartAddressFieldDescriptor, TaskParameterFieldDescriptor, TaskTypeFieldDescriptor, VraIndexFieldDescriptor } from "./TaskFieldDescriptors";
import React from "react";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { equipmentResourceEntityDescriptor } from "pages/EquipmentResource/equipmentResourceEntityDescriptor";
import { EXECUTE_ALL_OPERATIONS } from "pages/ganttAssignment/queries";
import { SelectDatesPopup } from "pages/ganttAssignment/GanttAssignmentPage";

const OPTIONS_LOCAL_STORAGE_ITEM = "TaskEntityTablePage.options";
interface TaskEntityTablePageOptions {
    showHumanResourcesWithoutMissions: boolean,
    showEquipmentResourcesWithoutMissions: boolean
}

const taskOptionsEntityDescriptor = new EntityDescriptor({ name: "TaskEntityTablePageOptions" })
    .addFieldDescriptor({ name: "showHumanResourcesWithoutMissions", type: FieldType.boolean })
    .addFieldDescriptor({ name: "showEquipmentResourcesWithoutMissions", type: FieldType.boolean });

const FIELDS_FROM_DATA = ["lastScanDate", "lastScannerIdentifier", "lastAgentIdentifier",
    "createdByEventId", "offloadStatus", "crewBaggageVersion", "sentBaggageUpdate", "resetCitePNBaggages",
    "endAddressIsTemporaryStorage", "startAddressIsTemporaryStorage", "temporaryStoragePairObject",
    "baggagesLimitControled", "createdBy", "hasSpecialColor"];

const STEP_COUNT: number = 10;
const sliceTaskEntityTablePage = createSliceFoundation(class Ext extends SliceEntityTablePage {

    initialState = {
        ...sliceEntityTablePageOnlyForExtension.initialState,
        options: {} as TaskEntityTablePageOptions,
        humanResourcesWithoutMissions: undefined as Optional<[]>,
        humanResourcesWithoutMissionsCount: undefined as Optional<number>,
        humanResourcesWithoutMissionsLoading: false as boolean,
        equipmentResourcesWithoutMissions: undefined as Optional<[]>,
        equipmentResourcesWithoutMissionsCount: undefined as Optional<number>,
        equipmentResourcesWithoutMissionsLoading: false as boolean,
        openModalForExecutAllOperations: false as boolean,
    }

    reducers = {
        ...sliceEntityTablePageOnlyForExtension.reducers,
        ...getBaseReducers<Ext>(this)
    }

    impures = {
        ...sliceEntityTablePageOnlyForExtension.impures,
        ...getBaseImpures<Ext>(this),
    }
});

const sliceTaskEntityEditorPage = createSliceFoundation(class Ext extends SliceEntityEditorPage {
    initialState = {
        ...sliceEntityEditorPageOnlyForExtension.initialState,
    }

    reducers = {
        ...sliceEntityEditorPageOnlyForExtension.reducers,
        ...getBaseReducers<Ext>(this)
    }

    impures = {
        ...sliceEntityEditorPageOnlyForExtension.impures,
        ...getBaseImpures<Ext>(this),

        // Overrieded parent function to query extra fields for flight
        getLoadQueryParams() {
            const ed = this.getSlice().entityDescriptor;
            const loadOperationName = `${lodash.lowerFirst(ed.name)}Service_findById`;
            let state = undefined;
            try {
                state = this.getState();
            } catch {
                // method called from another screen, this.getState() throws error
            }
            const additionalFieldsToRequest = state?.attachedDashboards ? state.attachedDashboards.map(dashboard => getAdditionalFieldsToRequest(dashboard)) : [];
            let fieldsToRequestStr = ed.getGraphQlFieldsToRequest(Array.prototype.concat.apply(this.getSlice().getFieldsToRequest(), additionalFieldsToRequest)) + " " + this.getSlice().getAdditionalGraphQl();
            // add extra fields to query for editor for some properties
            fieldsToRequestStr = fieldsToRequestStr.replace("taskGroup { id", "taskGroup { id departure parking { id name } paxParking { id name } sariaPort");
            return {
                loadOperationName,
                loadQuery: gql(`query q($id: ${this.getSlice().getGraphQlIdType()}!) { 
                    ${loadOperationName}(id: $id) {
                        ${fieldsToRequestStr}
                    }
                }`)
            };
        },
    }
});

class TaskEntityTablePage extends EntityTablePage<EntityTablePageProps & PropsFrom<typeof sliceTaskEntityTablePage>> {

    hrFindByFilterOperationName!: string;
    hrFindByFilter!: DocumentNode;

    erFindByFilterOperationName!: string;
    erFindByFilter!: DocumentNode;

    taskFindByFilterOperationName!: string;
    taskFindByFilter!: DocumentNode;

    constructor(props: EntityTablePageProps & PropsFrom<typeof sliceTaskEntityTablePage>) {
        super(props);
        this.initQueries();
    }

    protected async resetStateBeforeRefresh() {
        super.resetStateBeforeRefresh();
        await this.props.dispatchers.setInReduxState({
            humanResourcesWithoutMissions: undefined,
            humanResourcesWithoutMissionsCount: undefined,
            humanResourcesWithoutMissionsLoading: false,
            equipmentResourcesWithoutMissions: undefined,
            equipmentResourcesWithoutMissionsCount: undefined,
            equipmentResourcesWithoutMissionsLoading: false
        });
    }

    componentDidMount() {        
        const options = window.localStorage.getItem(OPTIONS_LOCAL_STORAGE_ITEM);
        if (options) {
            try {
                this.props.dispatchers.setInReduxState({ options: JSON.parse(options) as TaskEntityTablePageOptions });
            } catch(e) {
                // swallow error, the value stored in local storage is corrupt
            }
        }
        super.componentDidMount();
    }

    initQueries() {
        this.hrFindByFilterOperationName = `${lodash.lowerFirst(humanResourceEntityDescriptor.name)}Service_findByFilter`;
        this.hrFindByFilter = gql(`query q($params: FindByFilterParamsInput) { 
                ${this.hrFindByFilterOperationName}(params: $params) {
                    results { ${ID} identifier firstName lastName } 
                }
            }`);
        this.erFindByFilterOperationName = `${lodash.lowerFirst(equipmentResourceEntityDescriptor.name)}Service_findByFilter`;
        this.erFindByFilter = gql(`query q($params: FindByFilterParamsInput) { 
                ${this.erFindByFilterOperationName}(params: $params) {
                    results { ${ID} identifier } 
                }
            }`);
        this.taskFindByFilterOperationName = `${lodash.lowerFirst(taskEntityDescriptor.name)}Service_findByFilter`;
        this.taskFindByFilter = gql(`query q($params: FindByFilterParamsInput) { 
                ${this.taskFindByFilterOperationName}(params: $params) {
                    results { ${ID} mission { id humanResource { id } equipmentResource { id } } objectActionGroups { id mission { id humanResource { id } equipmentResource { id } } } } 
                }
            }`);    
    }

    async componentDidUpdateInternal(prevProps?: EntityTablePageProps & PropsFrom<typeof sliceTaskEntityTablePage>) {
        super.componentDidUpdateInternal(prevProps);

        if (!prevProps || !lodash.isEqual(prevProps.options, this.props.options)) {
            window.localStorage.setItem(OPTIONS_LOCAL_STORAGE_ITEM, JSON.stringify(this.props.options));
        }
        const shouldUpdateHRsWithoutMissions =
            prevProps && (
            prevProps.options?.showHumanResourcesWithoutMissions !== this.props.options.showHumanResourcesWithoutMissions ||
            (prevProps.humanResourcesWithoutMissionsCount !== this.props.humanResourcesWithoutMissionsCount && this.props.humanResourcesWithoutMissionsCount === undefined));
        const shouldUpdateERsWithoutMissions =
            prevProps && (
            prevProps.options?.showEquipmentResourcesWithoutMissions !== this.props.options.showEquipmentResourcesWithoutMissions ||
            (prevProps.equipmentResourcesWithoutMissionsCount !== this.props.equipmentResourcesWithoutMissionsCount && this.props.equipmentResourcesWithoutMissionsCount === undefined));

        if (shouldUpdateHRsWithoutMissions || shouldUpdateERsWithoutMissions) {              
            let tasksWithMissions = [];
            this.props.dispatchers.setInReduxState({ humanResourcesWithoutMissionsLoading: shouldUpdateHRsWithoutMissions, equipmentResourcesWithoutMissionsLoading: shouldUpdateERsWithoutMissions });
            const filters: Filter[] = [];
            const { filter } = this.props.dispatchers.getCustomQueryDefinitionForLoad();
            if (filter != null) {
                filters.push(filter);
            }
            filters.push(Filter.create("mission", FilterOperators.forEntityManyToOne.isNotEmpty));
            tasksWithMissions = (await apolloClientHolder.apolloClient.query({
                query: this.taskFindByFilter,
                variables: FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.and, filters)),
                context: { showSpinner: false }
            })).data[this.taskFindByFilterOperationName].results;

            if (shouldUpdateHRsWithoutMissions) {
                const filters: Filter[] = [];
                filters.push(Filter.create("lastName", FilterOperators.forString.isNotEmpty));
                if (tasksWithMissions.length > 0) {
                    const withHRs = tasksWithMissions.filter((t: any) => !Utils.isNullOrEmpty(t.mission.humanResource));
                    if (withHRs.length > 0) {
                        filters.push(Filter.create("id", FilterOperators.forNumber.notIn, withHRs.map((t: any) => t.mission.humanResource.id).join(",")));
                    } 
                }
                const result = (await apolloClientHolder.apolloClient.query({
                    query: this.hrFindByFilter,
                    variables: FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.and, filters)).sorts([{ field: "lastName", direction: "ASC" }]),
                    context: { showSpinner: false }
                })).data[this.hrFindByFilterOperationName].results;
                this.props.dispatchers.setInReduxState({ humanResourcesWithoutMissionsCount: STEP_COUNT, humanResourcesWithoutMissions: result, humanResourcesWithoutMissionsLoading: false });
            }
            if (shouldUpdateERsWithoutMissions) {
                const filters: Filter[] = [];
                filters.push(Filter.create("identifier", FilterOperators.forString.isNotEmpty));
                if (tasksWithMissions.length > 0) {
                    const withERs = tasksWithMissions.filter((t: any) => !Utils.isNullOrEmpty(t.mission.equipmentResource));
                    if (withERs.length > 0) {
                        filters.push(Filter.create("id", FilterOperators.forNumber.notIn, withERs.map((t: any) => t.mission.equipmentResource.id).join(",")));
                    }                    
                }
                const result = (await apolloClientHolder.apolloClient.query({
                    query: this.erFindByFilter,
                    variables: FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.and, filters)).sorts([{ field: "identifier", direction: "ASC" }]),
                    context: { showSpinner: false }
                })).data[this.erFindByFilterOperationName].results;
                this.props.dispatchers.setInReduxState({ equipmentResourcesWithoutMissionsCount: STEP_COUNT, equipmentResourcesWithoutMissions: result, equipmentResourcesWithoutMissionsLoading: false });
            }
        }
    }
    protected renderCompactBar() {
        return <>{super.renderCompactBar()}
            {this.props.options.showHumanResourcesWithoutMissions
                ? <Segment className="flex-container-row flex-center flex-wrap no-margin-top less-margin-bottom gap5 less-padding">
                    {humanResourceEntityDescriptor.getIcon()}
                    {this.props.humanResourcesWithoutMissionsLoading ? <label>{_msg("general.loading")}</label> : <></>}
                    {this.props.humanResourcesWithoutMissionsCount ?
                        <>
                            {this.props.humanResourcesWithoutMissions!.slice(0, this.props.humanResourcesWithoutMissionsCount).map((hr: any) =>
                                <div key={hr.id}>
                                    {humanResourceEntityDescriptor.getField("lastName").renderField(hr, FieldDescriptor.castAdditionalFieldRendererProps(StringFieldRenderer, { asLink: true }))}
                                </div>
                            )}
                            {this.props.humanResourcesWithoutMissionsCount < this.props.humanResourcesWithoutMissions!.length
                                ? <Button basic color="blue"
                                    onClick={() => this.props.dispatchers.setInReduxState({ humanResourcesWithoutMissionsCount: this.props.humanResourcesWithoutMissionsCount! + STEP_COUNT })}>
                                    {_msg("general.seeMore")}
                                </Button>
                                : <></>
                            }
                        </>
                        : <></>}
                </Segment> : <></>}
            {this.props.options.showEquipmentResourcesWithoutMissions
                ? <Segment className="flex-container-row flex-center flex-wrap no-margin-top less-margin-bottom gap5 less-padding">
                    {equipmentResourceEntityDescriptor.getIcon()}
                    {this.props.equipmentResourcesWithoutMissionsLoading ? <label>{_msg("general.loading")}</label> : <></>}     
                    {this.props.equipmentResourcesWithoutMissionsCount ?
                        <>
                            {this.props.equipmentResourcesWithoutMissions!.slice(0, this.props.equipmentResourcesWithoutMissionsCount).map((er: any) =>
                                <div key={er.id}>
                                    {equipmentResourceEntityDescriptor.getField("identifier").renderField(er, FieldDescriptor.castAdditionalFieldRendererProps(StringFieldRenderer, { asLink: true }))}
                                </div>
                            )}
                            {this.props.equipmentResourcesWithoutMissionsCount < this.props.equipmentResourcesWithoutMissions!.length
                                ? <Button basic color="blue" onClick={() => this.props.dispatchers.setInReduxState({ equipmentResourcesWithoutMissionsCount: this.props.equipmentResourcesWithoutMissionsCount! + STEP_COUNT })}>
                                    {_msg("general.seeMore")}
                                </Button> : <></>
                            }
                        </>
                        : <></>}
                </Segment> : <></>}
            <SelectDatesPopup open={this.props.openModalForExecutAllOperations} startDateLabel={ganttAssignmentEntityDescriptor.getField("flightsStartDate").getLabel()} endDateLabel={ganttAssignmentEntityDescriptor.getField("flightsEndDate").getLabel()}
                onClose={() => this.props.dispatchers.setInReduxState({ openModalForExecutAllOperations: false })} onOkClickCallback={async (startDate: number, endDate: number) => {
                    await apolloClientHolder.apolloClient.mutate({
                        mutation: EXECUTE_ALL_OPERATIONS, variables: { startDate: startDate, endDate: endDate, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone }
                    });
                    this.refresh();
                }}
            />
        </>
    }

    protected preRenderButtons(params: {}): Array<OverrideableElement> {
        return [{
            element: <Popup key="recalculatePopup" content={_msg("Task.table.recalculate.tooltip")} wide position="right center" trigger={
                <Button key="recalculateButton" positive onClick={async () => {
                    this.props.dispatchers.setInReduxState({ openModalForExecutAllOperations: true })
                }} disabled={!AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_SAVE, ganttAssignmentEntityDescriptor.name])) ||
                    !AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_DELETE, missionEntityDescriptor.name])) ||
                    !AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_DELETE, taskEntityDescriptor.name]))}
                >{_msg("Task.table.recalculate")}</Button>
            } />
        }, {
            element: <TaskOptionsButton key="options" options={this.props.options} onChange={(options: TaskEntityTablePageOptions) => this.props.dispatchers.setInReduxState({ options })} />
        }
        ];
    }

    protected getExtraTabPanes() {
        const pannes = super.getExtraTabPanes()
        pannes.push({
            routeProps: { path: "/taskKPIPage" }, menuItemProps: { content: _msg("TaskKPIPage.title") },
            render: () => <TaskKPIPageRRC id="taskKPIPage" customQuery={this.props.customQueryBar.customQuery}/>
        });
        return pannes;
    }
}

class TaskEntityTableEditor extends EntityEditorPage<PropsFrom<typeof sliceTaskEntityEditorPage>> {
    protected getExtraTabPanes() {
        const pannes = super.getExtraTabPanes()
        pannes.push({
            routeProps: { path: "/eventsValidation" }, menuItemProps: { content: _msg("MissionEventValidationPage.title") },
            render: () => <MissionEventValidationPageRRC id="missionEventValidation" entityName="Task" entityId={this.props.entity?.id} />
        })
        return pannes;
    }
}

export class TaskEntityDescriptor extends EntityDescriptor {
    constructor() {
        super({
            name: "Task",
            miniFields: ["taskGroup.name", "taskType.name", "name"],
            defaultFilter: Filter.createForClient("taskGroup.date", FilterOperators.forDate.today),
            defaultSort: [{ field: "taskGroup.date", direction: "DESC" }],
        });
    }

    protected customize() {
        this.infoTable.slice = sliceTaskEntityTablePage.setEntityDescriptor(this);
        this.infoTable.wrappedComponentClass = TaskEntityTablePage;

        this.infoEditor.slice = sliceTaskEntityEditorPage.setEntityDescriptor(this);
        this.infoEditor.wrappedComponentClass = TaskEntityTableEditor;
        this.doForFields(FIELDS_FROM_DATA, fd => fd.filterable = false);
        this.doForFields(FIELDS_FROM_DATA, fd => fd.sortable = false);
    }

}

export const newTaskEntityDescriptor = new TaskEntityDescriptor()
    .isInDefaultColumnConfig(true, "missionType", "taskType", "name", "quantity", "processedBaggages", "weight", "particularity", "priority",
        "startAddress", "endAddress", "connectionFlight", "taskParameter", "position", "leftOverlap", "rightOverlap", "comment", "taskGroup",
        "duration", "offset", "galley", "approachNumber", "messageCreation", "vraIndex", "exceptionEvent", "finishMissionEvent", "eventMonitoringRulesJson",
        "emrjUseRotationFlightAsReference", "extraDemand", "extraDemandValidated", "requiredEquipmentResourceQualificationType", "equipmentResourceFillPercentage", "loadMode")

    .addFieldDescriptor({ name: "loadMode" }, new LoadModeFieldDescriptor())
    .addFieldDescriptor({ name: "quantity" }, new QuantityFieldDescriptor())
    .addFieldDescriptor({ name: "taskParameter" }, new TaskParameterFieldDescriptor())
    .addFieldDescriptor({ name: "comment" }, new CommentFieldDescriptor())
    .addFieldDescriptor({ name: "equipmentResourceFillPercentage" }, new FillPercentageFieldDescriptor())
    .addFieldDescriptor({ name: "connectionFlight" }, new ConnectionFlightFieldDescriptor())
    .addFieldDescriptor({ name: "taskType" }, new TaskTypeFieldDescriptor())
    .addFieldDescriptor({ name: "startAddress" }, new StartAddressFieldDescriptor())
    .addFieldDescriptor({ name: "endAddress" }, new EndAddressFieldDescriptor())
    .addFieldDescriptor({ name: "priority" }, new PriorityFieldDescriptor())
    .addFieldDescriptor({ name: "galley" }, new GalleyFieldDescriptor())
    .addFieldDescriptor({ name: "taskGroup" }, new FlightFieldDescriptor())
    .addFieldDescriptor({ name: "vraIndex" }, new VraIndexFieldDescriptor());

type TaskOptionsButtonProps = {
    options: TaskEntityTablePageOptions,
    onChange?: (options: TaskEntityTablePageOptions) => void
}
class TaskOptionsButton extends React.Component<TaskOptionsButtonProps, { modalOpen: boolean | [number, number] }> {
    editorRef = React.createRef<EntityEditorFormSimple>();
 
    constructor(props: TaskOptionsButtonProps) {
        super(props);

        this.state = { modalOpen: false };
        this.onApply = this.onApply.bind(this);
    }

    protected openModalEditor() {
        const rect = document.getElementById("optionsBtnRef")!.getBoundingClientRect();
        this.setState({ modalOpen: [rect.left, rect.bottom] });
    }

    protected onApply() {
        this.props.onChange && this.props.onChange(this.editorRef.current?.formikContext.values as TaskEntityTablePageOptions);
        this.setState({ modalOpen: false });
    }

    render() {
        return <>
            <Button id="optionsBtnRef" color="orange" onClick={() => this.openModalEditor()} icon="settings" />
            <ModalExt open={this.state.modalOpen} transparentDimmer onClose={() => this.setState({ modalOpen: false })}>
                <Modal.Header></Modal.Header>
                <Modal.Content>
                    <EntityEditorFormSimple ref={this.editorRef}
                        entity={this.props.options}
                        entityDescriptor={taskOptionsEntityDescriptor}
                        hideButtonBar
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={this.onApply}>{_msg("general.apply")}</Button>
                    <Button onClick={() => this.setState({ modalOpen: false })}>{_msg("general.cancel")}</Button>
                </Modal.Actions>
            </ModalExt>
        </>;
    }

}

