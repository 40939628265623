import { GROUP_LABEL_PROPRETY } from "../AbstractGantt";
import { AbstractTypeRenderer } from "./AbstractTypeRenderer";

export class EquipmentResourceRenderer extends AbstractTypeRenderer {

    protected renderContent(): JSX.Element {
        return <div className="wh100 flex-center flex-justify-content-center">{this.props.item[GROUP_LABEL_PROPRETY.EQUIPMENT_RESOURCE]}</div>
    }

    render() {
        return this.renderContent();
    }
}
