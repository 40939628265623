import { FieldDescriptor, Utils } from "@crispico/foundation-react";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/DateFieldRenderer";
import StringFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderersEditors/StringFieldRenderer";
import React from "react";
import { Icon } from "semantic-ui-react";

export class MissionInfoRenderer extends React.Component<{ entity: any }> {

    render() {
        const ed = entityDescriptors["Mission2"];
        const options = FieldDescriptor.castAdditionalFieldRendererProps(StringFieldRenderer, { asLabel: true, showTooltip: true });
        const optionsForDate = FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { asLabel: true, showTooltip: true, format: Utils.timeFormat });
        const { entity } = this.props;
        return entity ? <div className="flex-container-row flex-center flex-wrap gap3">
            {ed.getField("type").renderField(entity, options)}
            {ed.getField("inactivityType").renderField(entity, options)}
            {ed.getField("startAddress").renderField(entity, options)}
            {ed.getField("startTime").renderField(entity, optionsForDate)}
            <div><Icon name="arrow right" /></div>
            {ed.getField("endAddress").renderField(entity, options)}
            {ed.getField("endTime").renderField(entity, optionsForDate)}
            {ed.getField("humanResource").renderField(entity, options)}
            {ed.getField("equipmentResource").renderField(entity, options)}
            {ed.getField("equipmentType").renderField(entity, options)}
        </div> : null;
    }

}
