import React from "react";
import { AbstractGantt, GanttGroup } from "../AbstractGantt";
import { Draggable, Droppable } from "@crispico/foundation-react/components/DragAndDrop/DragAndDrop";
import { IconItem } from "../ganttTypeRenderers";
import { AssignTasksToResourcePageRRC } from "../AssignTasksToResourcePage";
import { GanttUtils } from "../GanttUtils";

export type AbstractTypeRendererProps = {
    item: GanttGroup;
    onDrop?: (data: any) => void;
    gantt: AbstractGantt,
    className?: string
};

export class AbstractTypeRenderer<P extends AbstractTypeRendererProps = AbstractTypeRendererProps> extends React.Component<P> {

    constructor(props: any) {
        super(props);
        this.canDrop = this.canDrop.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.renderTooltip = this.renderTooltip.bind(this);
        this.onItemClick = this.onItemClick.bind(this);
    }

    protected getEntityName(): string | undefined {
        return undefined;
    }

    protected getAcceptedType() {
        return ["Mission2", "Flight", "Task", "HumanResource", "EquipmentResource"];
    }

    protected canDrop(source: any, destination: any) {
        return true;
    }

    protected onDrop(source: any, destination: any) {

    }

    protected getBackgroundColor() {
        return undefined;
    };

    protected getLeftLabel() {
        return undefined;
    };

    protected getRightLabel() {
        return undefined;
    };

    protected renderContent() {
        return <></>;
    }

    protected openAssignTaskToResource(assignTaskToResourceProps: any) {
        this.props.gantt.showModal(true, <AssignTasksToResourcePageRRC {...assignTaskToResourceProps} id="assignTasksToResource"
            onAssign={this.props.gantt.closeModal} onCancel={this.props.gantt.closeModal} />);
    }

    protected renderTooltip(): any {
        return undefined;
    }

    protected onItemClick(e: any) {
        const content = this.renderTooltip();
        this.props.gantt.showModal([e.clientX, e.clientY], content);
    }

    render() {
        const { item } = this.props;
        return <Droppable className={this.props.className + " flex-container"} item={item} accept={this.getAcceptedType()} canDrop={this.canDrop} drop={this.onDrop} >
            <Draggable item={item} type={this.getEntityName()}
                onDragStart={(ev: any) => {
                    const data = {
                        entityUid: GanttUtils.toEntityUid(this.getEntityName()!, item.entityId),
                        offsetX: ev.clientX - ev.target!.getBoundingClientRect().x,
                        offsetY: ev.clientY - ev.target!.getBoundingClientRect().y,
                        duration: item.end - item.start
                    };
                    ev.dataTransfer.setData("text/plain", JSON.stringify(data));
                }} >
                {this.renderContent()}
            </Draggable>
        </Droppable>
    }
}
